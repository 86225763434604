import React from 'react';
import PropTypes from 'prop-types';
import FeedbackTable from '../FeedbackTable';
import '../style.scss';

const Index = ({ history, data, setData, requestFeedbackHandler, sendFeebackHandler }) => {
    return (
        <div className='my-feedback-container'>
            <FeedbackTable
                history={history}
                feedbackGiven={true}
                data={data}
                setData={setData}
                requestFeedbackHandler={requestFeedbackHandler}
                sendFeebackHandler={sendFeebackHandler}
            />
        </div>
    );
};

Index.propTypes = {
    history: PropTypes.object,
    data: PropTypes.object,
    setData: PropTypes.func,
    requestFeedbackHandler: PropTypes.func,
    sendFeebackHandler: PropTypes.func,
};

export default Index;
