import React from 'react';
import PropTypes from 'prop-types';

const Index = ({ name, flagUrl }) => {
    return (
        <p className='country-selection-filter mb-0'>
            {flagUrl && <img src={flagUrl} alt='' />}
            {name}
        </p>
    );
};

Index.propTypes = {
    name: PropTypes.string,
    flagUrl: PropTypes.string,
};

export default Index;
