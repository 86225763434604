import React from 'react';
import PropTypes from 'prop-types';
import RecognitionBalance from './RecognitionBalance';
import './styles.scss';

const Index = ({ history }) => {
    return (
        <div className='action-panel-container'>
            <RecognitionBalance />
        </div>
    );
};

Index.propTypes = {
    history: PropTypes.object,
};

export default Index;
