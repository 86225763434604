import React, { useState } from 'react';
import { useToaster } from 'Context/SnackbarContext';
import { shallowEqual, useSelector } from 'react-redux';
import { useMutation } from 'react-query';
import EWButton from 'components/ReusableComponents/EWButton';
import InviteViaEmail from './InviteViaEmail';
import InviteViaBulkUpload from './InviteViaBulkUpload';
import InviteViaURL from './InviteViaURL';
import EWModal from 'components/ReusableComponents/EWModal';
import { inviteUsers } from './apiFunctions';
import { showSnackBarMessage, validateEmail } from 'utils/HelperFunctions';
import PropTypes from 'prop-types';
import './style.scss';

const INVITE_TYPE = { EMAIL: 'email', BULK_UPLOAD: 'bulk-upload', URL: 'url' };

const INVITE_TABS = [
    {
        type: INVITE_TYPE.EMAIL,
        text: 'Enter email',
    },
    {
        type: INVITE_TYPE.BULK_UPLOAD,
        text: 'Bulk upload (in .csv)',
    },
    {
        type: INVITE_TYPE.URL,
        text: 'URL',
    },
];

const Index = ({ open, onClose }) => {
    const { SetSnackbar } = useToaster();
    const { joiningLink } = useSelector(mapStateToProps, shallowEqual);
    const [selectedType, setSelectedType] = useState(INVITE_TYPE.EMAIL);
    const [email, setEmail] = useState();
    const [csvFile, setCSVFile] = useState({ file: null, name: null });

    const { mutateAsync: inviteUsersToWorkspace, isLoading } = useMutation(inviteUsers);

    const sendViaEmail = () => {
        if (!email) {
            return;
        }

        const emails = email.split(',').map((email) => email.trim());
        const invalid = [];
        emails.forEach((email) => {
            if (!validateEmail(email)) {
                invalid.push(email);
            }
        });
        if (invalid.length > 0) {
            showSnackBarMessage(SetSnackbar, 'error', 'Please enter valid emails');
            return;
        }
        sendInviteToUsers(email);
    };

    const handleSendInvite = () => {
        if (selectedType === INVITE_TYPE.EMAIL) {
            sendViaEmail();
        } else if (selectedType === INVITE_TYPE.BULK_UPLOAD) {
            sendInviteToUsers();
        } else if (selectedType === INVITE_TYPE.URL) {
            onClose();
        }
    };

    const sendInviteToUsers = async (emails) => {
        try {
            const formData = new FormData();
            if (selectedType === INVITE_TYPE.EMAIL) {
                formData.append('emails', emails);
            }
            if (selectedType === INVITE_TYPE.BULK_UPLOAD) {
                formData.append('csv', csvFile.file);
            }
            const { data } = await inviteUsersToWorkspace({ apiData: formData });
            showSnackBarMessage(SetSnackbar, 'success', data?.message);
            onClose();
        } catch (error) {
            showSnackBarMessage(SetSnackbar, 'error', error?.message);
        }
    };

    const modalData = {
        heading: 'Invite Users',
        rightButtonText: selectedType === INVITE_TYPE.URL ? 'Close' : 'Send invite',
        handleRightButtonClick: handleSendInvite,
        disabled:
            (selectedType === INVITE_TYPE.EMAIL && !email) ||
            (selectedType === INVITE_TYPE.BULK_UPLOAD && !csvFile?.file) ||
            isLoading,
        loading: isLoading,
    };

    return (
        <EWModal open={open} width='584px' height='380px' onClose={onClose} modalData={modalData}>
            <div className='container-invite-users'>
                <p className='invite-users-sub-title'>How do you want to invite users?</p>
                <div className='invite-users-options'>
                    {INVITE_TABS.map((invite) => (
                        <EWButton
                            key={invite.type}
                            buttonText={invite.text}
                            plainTextButton={true}
                            border={selectedType === invite.type}
                            whiteBorder={selectedType !== invite.type}
                            primary={false}
                            onClick={() => setSelectedType(invite.type)}
                        />
                    ))}
                </div>
                {selectedType === INVITE_TYPE.EMAIL && <InviteViaEmail email={email} setEmail={setEmail} />}
                {selectedType === INVITE_TYPE.BULK_UPLOAD && (
                    <InviteViaBulkUpload csvFile={csvFile} setCSVFile={setCSVFile} />
                )}
                {selectedType === INVITE_TYPE.URL && <InviteViaURL joiningLink={joiningLink} />}
            </div>
        </EWModal>
    );
};

const mapStateToProps = ({ Workspace }) => ({
    joiningLink: Workspace?.joiningLink || '',
});

Index.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
};

export default Index;
