import React from 'react';
import PropTypes from 'prop-types';
import Awardee from './Awardee';
import { ReactComponent as EmptyAwardees } from 'Assets/images/empty-awardees.svg';
import clsx from 'clsx';
import { EMPLOYEE_AWARDS } from '../../EmployeeAwards/constants';
import EWButton from 'components/ReusableComponents/EWButton';
import './style.scss';

const EmptyRecentAwardees = ({ handleSetupAwards }) => {
    return (
        <div className='empty-awardees'>
            <EmptyAwardees />
            <p>
                The winners of scheduled/ <br />
                ongoing awards will be <br /> displayed here
            </p>
            <EWButton buttonText='Go to Awards Dashboard' onClick={handleSetupAwards} />
        </div>
    );
};

const Index = ({ history, awardees, userId, loading }) => {
    const handleSetupAwards = () => history.push(EMPLOYEE_AWARDS.HOME);

    if (loading) {
        return null;
    }

    return (
        <div className={clsx({ 'container-recent-awardees': true })}>
            <h3>Recent Awardees</h3>
            {!awardees?.length && <EmptyRecentAwardees handleSetupAwards={handleSetupAwards} />}
            {awardees?.length > 0 &&
                awardees.map((award) => <Awardee key={award?._id} award={award} userId={userId} />)}
        </div>
    );
};

EmptyRecentAwardees.propTypes = {
    handleSetupAwards: PropTypes.func,
};

Index.propTypes = {
    history: PropTypes.object,
    awardees: PropTypes.array,
    userId: PropTypes.string,
    loading: PropTypes.bool,
};

export default Index;
