import React from 'react';
import PropTypes from 'prop-types';
import Modal from '@material-ui/core/Modal';
import CloseIcon from '@material-ui/icons/Close';
import EWButton from 'components/ReusableComponents/EWButton';

const Index = ({ instructionOpen, setInstructionOpen, instructionData }) => {
    const handleClose = () => {
        setInstructionOpen(false);
    };

    const style = {
        position: 'absolute',
        maxHeight: '701px',
        height: '80vh',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        background: '#FFFFFF',
        border: '1px solid #D2D9EB',
        boxSizing: 'border-box',
        borderRadius: '4px',
        width: '690px',
    };

    const content = {
        maxHeight: '601px',
        height: '65vh',
        overflowY: 'scroll',
        paddingRight: '6px',
    };

    const body = (
        <div style={style} className='modal-style remove-channel-medal'>
            <div className='d-flex justify-content-between mb-2'>
                <h5 className='mt-4'>Instructions to Redeem</h5>
                <EWButton
                    plainTextButton={true}
                    primary={false}
                    buttonStyleClass='pointer-cursor'
                    onClick={handleClose}
                >
                    <CloseIcon style={{ margin: '20px 8px 0 0', fill: '#15222F' }} />
                </EWButton>
            </div>
            <div
                style={content}
                dangerouslySetInnerHTML={{
                    __html: instructionData,
                }}
            />
        </div>
    );

    return (
        <div>
            <Modal
                open={instructionOpen}
                onClose={handleClose}
                aria-labelledby='simple-modal-title'
                aria-describedby='simple-modal-description'
            >
                {body}
            </Modal>
        </div>
    );
};

Index.propTypes = {
    instructionOpen: PropTypes.bool,
    setInstructionOpen: PropTypes.func,
    instructionData: PropTypes.string,
};

export default Index;
