import PropTypes from 'prop-types';
import React from 'react';
import clsx from 'clsx';
import './style.scss';

const Index = ({ horizontal, bgcolorActive, bgcolorTotal, text, percentage, left }) => {
    return (
        <div
            className={clsx({
                'percentage-bar-chart': true,
                'vertical-bar': !horizontal,
                'response-left-align': horizontal && left,
                'response-right-align': horizontal && !left,
            })}
            style={{ backgroundColor: bgcolorTotal }}
        >
            <div style={{ backgroundColor: bgcolorActive, width: `${percentage}%` }} />
            <p>{text}</p>
        </div>
    );
};

Index.propTypes = {
    horizontal: PropTypes.bool,
    bgcolorActive: PropTypes.string,
    bgcolorTotal: PropTypes.string,
    text: PropTypes.string,
    percentage: PropTypes.number,
    left: PropTypes.bool,
};

export default Index;
