import React from 'react';
import ImageStack from 'components/ReusableComponents/ImageStack';
import { ReactComponent as HourGlass } from 'Assets/images/hourglass.svg';
import EWButton from 'components/ReusableComponents/EWButton';
import PropTypes from 'prop-types';
import './style.scss';

const Index = ({ heading, subHeading, handleClick, users }) => {
    return (
        <div className='approval-container-banner'>
            <div className='image-stack' data-testid='image-stack'>
                <HourGlass className='hour-glass' />
                <ImageStack members={users} width={32} height={32} borderRadius={50} count={5} />
            </div>
            <div>
                <p className='approval-heading'>{heading}</p>
                <p className='approval-subheading'>{subHeading}</p>
                <EWButton
                    buttonStyleClass='ml-3'
                    buttonText={'View all'}
                    primary={false}
                    plainTextButton
                    width='212px'
                    border={true}
                    onClick={handleClick}
                />
            </div>
        </div>
    );
};

Index.propTypes = {
    heading: PropTypes.string,
    subHeading: PropTypes.string,
    handleClick: PropTypes.func,
    users: PropTypes.array,
};

export default Index;
