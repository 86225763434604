import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from 'react-query';
import Modal from '@material-ui/core/Modal';
import { useToaster } from 'Context/SnackbarContext';
import MemberCard from 'components/ReusableComponents/MemberDetailsCard';
import CustomFilterDropdown from 'components/ReusableComponents/CustomFilterDropdown';
import { useModifyUser, useModifyOrganisation } from 'components/Dashboard/Content/Users/ApiHooks';
import CloseIcon from '@material-ui/icons/Close';
import { shallowEqual, useSelector } from 'react-redux';
import EWButton from 'components/ReusableComponents/EWButton';
import { showSnackBarMessage } from 'utils/HelperFunctions';
import { GET_MANAGERS_LIST, GET_PAGINATED_DEPARTMENT } from 'Services/apiKeys';
import { getManagerList, getDepartments } from 'Services/apiFunctions';
import './style.scss';

const Index = ({ assign, assignType, selectedMember, open, setOpen }) => {
    const { SetSnackbar } = useToaster();
    const { team } = useSelector(mapStateToProps, shallowEqual);
    const [departmentList, setDepartmentList] = useState([]);
    const [managerList, setManagerList] = useState([]);
    const [selectedFilter, setSelectedFilter] = useState(`Select ${assignType}`);
    const [list, setList] = useState([...selectedMember]);
    const [removedMember, setRemovedMember] = useState([]);

    const btnName = 'Save';
    const title = `Edit ${assignType} details`;

    const handleManagersList = (data) => {
        const tempList = data.map((user) => ({ ...user, label: user?.userName, value: user?.userName }));
        setManagerList(tempList);
    };

    const handleDepartmentsList = (data) => {
        const tempList = data.map((department) => ({
            ...department,
            label: department?.name,
            value: department?.name,
        }));
        setDepartmentList(tempList);
    };

    const { isLoading: loadingManagersList } = useQuery([GET_MANAGERS_LIST], getManagerList, {
        enabled: assign,
        onSuccess: (data) => handleManagersList(data?.managers || []),
        onError: (err) => showSnackBarMessage(SetSnackbar, 'error', err?.message),
    });

    const { isLoading: loadingDepartmentsList } = useQuery([GET_PAGINATED_DEPARTMENT], getDepartments, {
        onSuccess: (data) => handleDepartmentsList(data?.departments || []),
        onError: (err) => showSnackBarMessage(SetSnackbar, 'error', err?.message),
    });

    const errorMessage = (error) => showSnackBarMessage(SetSnackbar, 'error', error?.message);

    const onError = (err) => errorMessage(err);

    const onSuccess = () => {
        setOpen(false);
        showSnackBarMessage(SetSnackbar, 'success', 'Updated Successfully');
    };

    const { mutateAsync: modifyUser, isLoading: isUpdatingManager } = useModifyUser({ onSuccess, onError });
    const { mutateAsync: updateDepartment, isLoading: isUpdatingDept } = useModifyOrganisation({ onSuccess, onError });

    const handleAssignManager = () => {
        const data = {
            userIDs: list.filter((member) => member.selected).map((member) => member._id),
            managerId: selectedFilter.id,
        };
        modifyUser({ apiData: data, team });
    };

    const handleAddDepartment = async () => {
        if (!selectedFilter?.id || !selectedFilter?.name) {
            showSnackBarMessage(SetSnackbar, 'error', 'Please select department');
            return;
        }
        try {
            const data = {
                id: selectedFilter.id,
                name: selectedFilter.name,
                users: [],
                removeUser: removedMember,
            };
            list.forEach((member) => (member.selected ? data.users.push(member._id) : null));
            await updateDepartment(data);
        } catch (err) {
            showSnackBarMessage(SetSnackbar, 'error', err?.message);
        }
    };

    const handleNewUser = (index) => {
        const temp = [...list];
        temp[index].selected = !temp[index].selected;
        if (selectedMember.includes(temp[index]?._id)) {
            setRemovedMember([...new Set([...removedMember, temp[index]?._id])]);
        }
        setList(temp);
    };

    const handleAction = assign ? handleAssignManager : handleAddDepartment;
    const handleChange = (type, value) => {
        switch (type) {
            case 'Manager':
                setSelectedFilter(managerList[value]);
                break;
            case 'Department':
                setSelectedFilter(departmentList[value]);
                break;
            default:
                break;
        }
    };

    useEffect(() => {
        const tempArray = selectedMember.map((member) => ({ ...member, selected: true }));
        setList([...tempArray]);
    }, [selectedMember]);

    const content = (
        <div className='member-assign-modal reassign-manager p-3'>
            <div className='d-flex justify-content-between '>
                <h3 className='header-3'>{title}</h3>
                <CloseIcon className='close-icon' onClick={() => setOpen(false)} />
            </div>
            <div className='user-dropdown'>
                <CustomFilterDropdown
                    title={assignType.toUpperCase()}
                    filterOptions={assign ? managerList : departmentList}
                    selectedName={selectedFilter.name || `Select ${assignType}`}
                    optionsSelected={selectedFilter.name}
                    handleSelection={(index) => handleChange(assignType, index)}
                    dropDownID='select-manager'
                    dropdownStyleClass='width-100'
                    singleSelect
                    search
                    buttonStyleClass='mr-0'
                />
            </div>
            <div className='inner-addon-left pt-2 mt-3'>
                <p className='changing-subtext'>Selected users for this {assignType}</p>

                {list?.length > 0 ? (
                    <div className='mt-3 all-manager-list '>
                        {list.map((user, index) => {
                            return user?.isActive ? (
                                <MemberCard
                                    key={user._id}
                                    member={user}
                                    selected={user?.selected}
                                    handleChange={() => handleNewUser(index)}
                                />
                            ) : null;
                        })}
                    </div>
                ) : (
                    <div>
                        <div className='empty-team-box-second' style={{ marginLeft: '132px' }}></div>
                        <p className='empty-team-text'>
                            Couldn't find any person with <br /> that name
                        </p>
                    </div>
                )}
            </div>
            {list?.length > 0 ? (
                <div className='button-container d-flex justify-content-end align-items-center mt-3 width-100'>
                    <EWButton
                        buttonText={btnName}
                        onClick={handleAction}
                        loading={loadingDepartmentsList || loadingManagersList || isUpdatingDept || isUpdatingManager}
                        disabled={loadingDepartmentsList || loadingManagersList || isUpdatingDept || isUpdatingManager}
                    />
                </div>
            ) : null}
        </div>
    );

    return (
        <Modal open={open}>
            <div className='reassign-modal-container'>{content}</div>
        </Modal>
    );
};

const mapStateToProps = ({ Workspace }) => ({ team: Workspace.team });

Index.propTypes = {
    assign: PropTypes.bool,
    assignType: PropTypes.string,
    selectedMember: PropTypes.array,
    open: PropTypes.bool,
    setOpen: PropTypes.func,
};

export default Index;
