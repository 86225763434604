import React from 'react';
import './style.scss';
import { isSlack } from 'utils/HelperFunctions';

const Index = () => {
    const platform = localStorage.getItem('platform');
    return (
        <div className='onboardingWrapper'>
            <div className='content-section'>
                <div className='unauthorized-onboarding'>
                    <p>
                        Someone is already in the process of installing EngageWith with your{' '}
                        {isSlack(platform) ? 'Slack' : 'Microsoft Teams'} workspace. If you want to take over the
                        integration process for your team, email us at <span>support@springworks.in</span>
                    </p>
                </div>
            </div>
        </div>
    );
};

export default Index;
