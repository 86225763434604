import React from 'react';
import PropTypes from 'prop-types';
import CustomRadioButton from 'components/Styles/CustomRadioButton';
import clsx from 'clsx';

const DropdownList = ({ data, selectedCohort, selectedCohortId, selectedTab, handleSelection }) => {
    if (!data) return null;
    return (
        <div>
            {data.map((item, index) => {
                const isSelected =
                    (selectedCohort && selectedCohortId === item._id) ||
                    (!selectedCohort && selectedTab === 0 && index === 0);
                const displayValue = `${item.name}${item.type === 'manager' ? "'s team" : ''}`;
                return (
                    <div
                        key={item?._id || index}
                        className={clsx({ 'dropdown-item': !isSelected, 'dropdown-item-selected': isSelected })}
                    >
                        {item._id ? (
                            <div className='width-100'>
                                <CustomRadioButton
                                    checked={isSelected}
                                    onChange={() => handleSelection(item)}
                                    title={displayValue}
                                    name={`cr${index}`}
                                    size={24}
                                    bold={isSelected}
                                />
                            </div>
                        ) : (
                            <p className='dropdown-section'>{item?.name}</p>
                        )}
                    </div>
                );
            })}
        </div>
    );
};

DropdownList.propTypes = {
    data: PropTypes.array,
    selectedCohort: PropTypes.object,
    selectedCohortId: PropTypes.string,
    selectedTab: PropTypes.number,
    handleSelection: PropTypes.func,
};

export default DropdownList;
