import React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import CustomLoader from 'components/ReusableComponents/CustomLoader';
import { CustomCheckbox } from 'components';
import PropTypes from 'prop-types';
import './style.scss';
import { find } from 'lodash';

const UserFilterList = ({ dropdownData, fetchNextPage, hasNextPage, selectedUsers, handleUserSelection }) => {
    return (
        <div className='cr-user-filter-dropdown'>
            {dropdownData?.pages[0]?.data?.users?.length > 0 && (
                <InfiniteScroll
                    dataLength={dropdownData?.pages?.length * 9}
                    next={fetchNextPage}
                    hasMore={hasNextPage}
                    height={200}
                    loader={<CustomLoader size={10} />}
                >
                    {dropdownData?.pages?.map((pageData) =>
                        pageData?.data?.users?.map((user) => (
                            <div className='cr-user-filter-dropdown-item' key={user._id}>
                                <CustomCheckbox
                                    checked={!!find(selectedUsers, (selectedUser) => selectedUser?._id === user?._id)}
                                    onClick={() => handleUserSelection(user)}
                                />
                                <span>{user.userName}</span>
                            </div>
                        ))
                    )}
                </InfiniteScroll>
            )}
        </div>
    );
};

UserFilterList.propTypes = {
    dropdownData: PropTypes.object,
    fetchNextPage: PropTypes.func,
    hasNextPage: PropTypes.bool,
    selectedUsers: PropTypes.array,
    handleUserSelection: PropTypes.func,
};

export default UserFilterList;
