import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

const Tabs = ({ hideManager, handleTabChange, selectedTab }) => {
    const TAB_NAMES = ['Organisation', 'Department', 'Manager'];
    return (
        <div className='dropdown-tabs'>
            {TAB_NAMES.filter((tabname) => (hideManager ? tabname !== TAB_NAMES[2] : true)).map((tabName, index) => (
                <button
                    key={tabName}
                    className={clsx({ 'tab-name': true, 'tab-name-selected': index === selectedTab })}
                    onClick={() => handleTabChange(index)}
                >
                    {tabName}
                </button>
            ))}
        </div>
    );
};

Tabs.propTypes = {
    hideManager: PropTypes.bool,
    handleTabChange: PropTypes.func,
    selectedTab: PropTypes.number,
};

export default Tabs;
