import React from 'react';
import { useMutation, useQueryClient } from 'react-query';
import ConfirmModal from 'components/ReusableComponents/ConfirmModal';
import { useToaster } from 'Context/SnackbarContext';
import { GET_FEEDBACK_CATEGORIES_LIST } from 'Services/apiKeys';
import { showSnackBarMessage } from 'utils/HelperFunctions';
import { deleteCategory } from '../../Services/apiFunctions';
import PropTypes from 'prop-types';

const RemoveCategoryModal = ({ categories, deleteIndex, setDeleteModal }) => {
    const { mutateAsync: deleteCategoryHandler, isLoading } = useMutation(deleteCategory);
    const queryClient = useQueryClient();
    const { SetSnackbar } = useToaster();

    const handleClose = () => {
        setDeleteModal({ open: false, index: -1 });
    };

    const removeValue = async () => {
        try {
            await deleteCategoryHandler(categories[deleteIndex]);
            queryClient.setQueryData([GET_FEEDBACK_CATEGORIES_LIST], (oldData) => {
                const tempData = { ...oldData };
                tempData.categories.splice(deleteIndex, 1);
                return tempData;
            });
            showSnackBarMessage(SetSnackbar, 'success', 'Category deleted successfully');
            handleClose();
        } catch (err) {
            showSnackBarMessage(
                SetSnackbar,
                'error',
                err?.message || 'Unable to delete value at the moment. Try again later.'
            );
        }
    };

    const text = {
        heading: 'Delete category',
        subHeading: 'Are you sure you want to delete this category?',
        confirm: 'Delete',
    };

    return (
        <ConfirmModal open={true} onClose={handleClose} onConfirm={removeValue} data={text} loading={isLoading} red />
    );
};
RemoveCategoryModal.propTypes = {
    categories: PropTypes.array,
    deleteIndex: PropTypes.number,
    setDeleteModal: PropTypes.func,
};
export default RemoveCategoryModal;
