import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';
import InputField from 'components/ReusableComponents/InputField';
import ErrorField from 'components/ReusableComponents/ErrorField';

const RecognitionSetup = ({ kudos, shoutout, handleChange }) => {
    const error = (kudos && Number(kudos) === 0) || (shoutout && Number(shoutout) === 0);

    return (
        <div className='setup-container'>
            <InputField
                inputID='kudos'
                label='KUDOS'
                placeholder='Enter number of points for Kudos'
                value={kudos}
                handleChange={handleChange}
            />

            <InputField
                inputID='shoutout'
                label='SHOUTOUT'
                placeholder='Enter number of points for Shoutout'
                value={shoutout}
                handleChange={handleChange}
            />

            {!!error && <ErrorField errorText={'Please enter a numeric value greater than 0 to continue.'} />}
        </div>
    );
};

RecognitionSetup.propTypes = {
    kudos: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    shoutout: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    handleChange: PropTypes.func,
};

export default RecognitionSetup;
