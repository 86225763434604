import React from 'react';
import PropTypes from 'prop-types';
import Table from 'components/ReusableComponents/Table';
import VertMenu from 'components/ReusableComponents/VertMenu';
import CustomLoader from 'components/Styles/CustomLoader';
import './style.scss';

export default function Index({
    noSortColumns,
    columnWidth,
    listHeader,
    totalCount,
    listData,
    menuOption,
    handleMenuOption,
    country,
    className,
    handlePagination,
    loading,
    fetching,
    currentPage,
    setCurrentPage,
}) {
    const paginationProps = {
        currentPage,
        totalCount,
        setCurrentPage,
        onChange: handlePagination,
    };

    const getActiveMembersRowElement = (activeMembers) => {
        if (country) {
            return activeMembers;
        }
        return `${activeMembers} member` + (activeMembers > 1 ? `s` : ``);
    };

    const tableData = listData?.map((member) => {
        const activeMembers = member?.users?.length;
        const rowElementId = country ? member.name : member?.id;
        return {
            id: rowElementId,
            row: [
                // Department
                <div key={rowElementId} className='org-name d-flex justify-content-start text-capitalize ml-2'>
                    {member.name}
                    {member?.default && <span className='tag-default'>Default</span>}
                </div>,
                // Active Members
                getActiveMembersRowElement(activeMembers),
                //Menu
                <div key={rowElementId} style={{ width: '50px', height: '40px' }}>
                    <div className='hide-action'>
                        <VertMenu
                            optionMenu={member?.default ? menuOption.slice(0, 1) : menuOption}
                            placement={'bottom-end'}
                            getSelected={(optionIndex) => handleMenuOption(menuOption[optionIndex], member)}
                        />
                    </div>
                </div>,
            ],
        };
    });

    if (loading) {
        return <CustomLoader />;
    }

    return (
        <div className='org-table'>
            <Table
                header={listHeader}
                data={tableData}
                noSortColumns={noSortColumns}
                paginationProps={paginationProps}
                loading={loading || fetching}
                columnWidth={columnWidth}
                className={className}
            />
        </div>
    );
}

Index.propTypes = {
    noSortColumns: PropTypes.array,
    columnWidth: PropTypes.array,
    listHeader: PropTypes.array,
    totalCount: PropTypes.number,
    listData: PropTypes.array,
    menuOption: PropTypes.array,
    handleMenuOption: PropTypes.func,
    country: PropTypes.bool,
    className: PropTypes.string,
    handlePagination: PropTypes.func,
    loading: PropTypes.bool,
    fetching: PropTypes.bool,
    currentPage: PropTypes.number,
    setCurrentPage: PropTypes.func,
};
