export const RewardStats = {
    totalPointsGiven: 0,
    pointGivenChange: 'NA',
    totalAppreciation: 0,
    appreciationGivenChange: 'NA',
    totalRedemptions: 0,
    redemptionChange: 'NA',
    participationRate: '0.00',
    participationRateChange: 0,
};
