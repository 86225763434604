import React from 'react';
import EllipsisText from 'components/ReusableComponents/EllipsisText';
import PropTypes from 'prop-types';
import './style.scss';

const Index = ({ userName, pictureURL, recognitionType, showDownArrowIcon = true }) => {
    return (
        <div className='recognition-header-user'>
            <img
                width={22}
                height={22}
                className='user-image'
                src={pictureURL || require('Assets/images/defaultUser.png')}
                alt=''
            />
            <span>
                <strong>
                    <EllipsisText text={userName} white />
                </strong>{' '}
                gave <strong>{recognitionType}</strong> to {showDownArrowIcon ? '⬇️' : ''}
            </span>
        </div>
    );
};

Index.propTypes = {
    userName: PropTypes.string,
    pictureURL: PropTypes.string,
    recognitionType: PropTypes.string,
    showDownArrowIcon: PropTypes.bool,
};

export default Index;
