import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import PropTypes from 'prop-types';

import './style.scss';

const Index = ({
    right,
    score,
    increasedBy,
    promoters,
    detractors,
    passives,
    respondents,
    respondentTotal,
    previousScore,
}) => {
    const doughnutData = {
        labels: ['Promoters', 'Detractors', 'Passives'],
        datasets: [
            {
                label: 'eNPS score',
                data: respondents && !isNaN(score) > 0 ? [promoters?.count, detractors?.count, passives?.count] : [1],
                backgroundColor: respondents > 0 && !isNaN(score) ? ['#32dba1', '#ff6760', '#fac032'] : '#E5E7EA',
                hoverOffset: 4,
            },
        ],
    };

    const options = {
        plugins: {
            legend: {
                display: false,
            },
        },
        cutout: '75%',
        borderWidth: 0,
    };

    const size = right ? '200px' : '100px';

    const ArrowIcon = increasedBy < 0 ? ArrowDropDownIcon : ArrowDropUpIcon;

    return (
        <div className={`doughnut-main-container ${right ? 'd-flex justify-content-start align-items-center' : ''}`}>
            <div className='doughnut-container' style={right ? { marginRight: '36px' } : { margin: ' 0 auto 22px' }}>
                <Doughnut data={doughnutData} options={options} width={size} height={size} />
                {!isNaN(score) && respondents > 0 ? (
                    <div className='doughnut-center-data'>
                        <h1>{Math.round(score)}</h1>
                        {previousScore !== null && (
                            <>
                                <p style={increasedBy < 0 ? { color: '#ff6760' } : {}}>
                                    {Math.abs(increasedBy).toFixed(2)}
                                    <ArrowIcon />
                                </p>
                                <p>From earlier</p>
                            </>
                        )}
                    </div>
                ) : (
                    <div className='doughnut-center-data'>
                        <p>No Score Yet</p>
                    </div>
                )}
            </div>
            <div className='doughnut-legend-data'>
                {right && (
                    <p>
                        Total respondents: {respondents}/{respondentTotal}
                    </p>
                )}
                <div>
                    <div style={{ backgroundColor: '#32dba1' }} />
                    <p className='legend'>Promoters </p>
                    <b className=' font-size-16 '>{promoters?.count || ` - `}</b> <span>|</span>{' '}
                    <b>{promoters?.percentage ? `${Math.round(promoters?.percentage)} %` : ' - '} </b>
                </div>
                <div>
                    <div style={{ backgroundColor: '#ff6760' }} />
                    <p className='legend'>Detractors </p>
                    <b className=' font-size-16 '>{detractors?.count || ` - `}</b> <span>|</span>{' '}
                    <b>{detractors?.percentage ? `${Math.round(detractors?.percentage)} %` : ' - '} </b>
                </div>
                <div>
                    <div style={{ backgroundColor: '#fac032' }} />
                    <p className=' legend'>Passives </p>
                    <b className=' font-size-16 '>{passives?.count || ` - `}</b> <span>|</span>{' '}
                    <b>{passives?.percentage ? `${Math.round(passives?.percentage)} %` : ' - '} </b>
                </div>
            </div>
        </div>
    );
};

Index.propTypes = {
    right: PropTypes.bool,
    score: PropTypes.string,
    increasedBy: PropTypes.number,
    promoters: PropTypes.object,
    detractors: PropTypes.object,
    passives: PropTypes.object,
    respondents: PropTypes.number,
    respondentTotal: PropTypes.number,
    previousScore: PropTypes.number,
};

export default Index;
