import React, { useEffect, useState } from 'react';
import CustomFilterDropdown from 'components/ReusableComponents/CustomFilterDropdown';
import { patchData } from 'Services/apiCall';
import PropTypes from 'prop-types';
import { useToaster } from 'Context/SnackbarContext';
import './style.scss';
import { updateWorkspaceDetails } from 'redux/workspace/actions';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';
import { showSnackBarMessage, isSlack } from 'utils/HelperFunctions';

const Index = ({ channelData, type, selectedChannel, disabled, handleSelection }) => {
    const { SetSnackbar } = useToaster();
    const dispatch = useDispatch();
    const { config, platform } = useSelector(mapStateToProps, shallowEqual);
    const [dropdownSelect, setDropdownSelect] = useState(selectedChannel);
    const platformBasedName = isSlack(platform) ? 'channel' : 'team';
    const defaultDropdownLabel = `Select ${platformBasedName}`;

    const snackbarMessage = `Default ${
        type === 'defaultRecognitionChannel' ? 'Recognition' : 'special events wishes'
    } ${platformBasedName} updated successfully`;

    const handleDropdownSelect = async (index) => {
        setDropdownSelect(channelData[index].label);
        let settingsApiData;
        const apiData = {
            enabled: true,
            ...channelData[index],
        };
        handleSelection({ ...apiData });
        if (type === 'defaultRecognitionChannel') {
            settingsApiData = {
                defaultRecognitionChannel: apiData,
            };
        }
        const previousConfigState = config;
        try {
            updateWorkspaceDetails(dispatch, { config: { ...config, ...settingsApiData } });
            await patchData('recognition/configure/workspace', settingsApiData);
            showSnackBarMessage(SetSnackbar, 'success', snackbarMessage);
        } catch (err) {
            updateWorkspaceDetails(dispatch, { config: previousConfigState });
            setDropdownSelect(defaultDropdownLabel);
            showSnackBarMessage(SetSnackbar, 'error', err?.response?.data?.message);
        }
    };

    useEffect(() => {
        if (disabled) {
            setDropdownSelect(defaultDropdownLabel);
        } else {
            setDropdownSelect(selectedChannel);
        }
    }, [disabled]);

    return (
        <div className={clsx({ 'recognition-channel': true, 'disable-view': disabled })}>
            <CustomFilterDropdown
                title={platformBasedName}
                filterOptions={channelData}
                selectedName={dropdownSelect}
                optionsSelected={dropdownSelect !== defaultDropdownLabel}
                buttonStyleClass='width-300'
                dropDownID={type}
                handleSelection={handleDropdownSelect}
                search
                singleSelect
            />
        </div>
    );
};

Index.propTypes = {
    channelData: PropTypes.array,
    type: PropTypes.string,
    selectedChannel: PropTypes.string,
    disabled: PropTypes.bool,
    handleSelection: PropTypes.func,
};

const mapStateToProps = ({ Workspace }) => ({
    config: Workspace.config,
    platform: Workspace.platform,
});

export default Index;
