import React, { useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useMutation } from 'react-query';
import EWModal from 'components/ReusableComponents/EWModal';
import FeedbackUserSearchDropdown from '../Components/FeedbackUserSearchDropdown';
import InputField from 'components/ReusableComponents/InputField';
import { useToaster } from 'Context/SnackbarContext';
import CustomUserTags from 'components/ReusableComponents/CustomUserTags';
import { find } from 'lodash';
import { requestFeedback } from '../../Services/apiFunctions';
import { showSnackBarMessage } from 'utils/HelperFunctions';
import { FEEDBACK_TEXT_LENGTH_LIMIT } from 'constants.js';
import './style.scss';

const Index = ({ open, onClose, onSuccess }) => {
    const { SetSnackbar } = useToaster();
    const { userId } = useSelector(mapStateToProps, shallowEqual);
    const [feedbackMessage, setFeedbackMessage] = useState('');
    const [selectedUsers, setSelectedUsers] = useState([]);

    const handleUserSelection = (user) => {
        if (find(selectedUsers, (selectedUser) => selectedUser?._id === user?._id)) {
            setSelectedUsers(selectedUsers.filter((item) => item?._id !== user?._id));
        } else {
            setSelectedUsers([...selectedUsers, user]);
        }
    };

    const { mutate: requestFeedbackMessage, isLoading: isRequestingFeedback } = useMutation(requestFeedback, {
        onSuccess: (data) => {
            onSuccess(data.userNames);
        },
        onError: (err) => showSnackBarMessage(SetSnackbar, 'error', err?.message),
    });

    const handleSendFeedback = () => {
        if (feedbackMessage.length > FEEDBACK_TEXT_LENGTH_LIMIT) {
            showSnackBarMessage(
                SetSnackbar,
                'error',
                `Message length should be less than ${FEEDBACK_TEXT_LENGTH_LIMIT} characters`
            );
            return;
        }
        let selectedUserIds = selectedUsers.map((item) => item._id);
        if (selectedUserIds.includes(userId)) {
            showSnackBarMessage(SetSnackbar, 'error', 'Cannot request feedback to self');
            return;
        }
        const apiData = {
            users: selectedUserIds,
            message: feedbackMessage,
        };
        requestFeedbackMessage({ apiData });
    };

    // Data for send feedback modal
    const modalData = {
        heading: 'Request 360º Feedback',
        subHeading: 'Request constructive and actionable feedback from your co-workers',
        rightButtonText: 'Request Feedback',
        handleRightButtonClick: handleSendFeedback,
        leftButtonText: 'Cancel',
        handleLeftButtonClick: onClose,
        loading: isRequestingFeedback,
        disabled: !selectedUsers.length > 0,
    };

    if (!open) {
        return null;
    }

    return (
        <div>
            <EWModal open={open} onClose={onClose} width='528px' modalData={modalData}>
                <div className='container-send-feedback'>
                    <span className='csf-dropdown-heading'>Select whom to request</span>
                    <FeedbackUserSearchDropdown
                        dropDownID='search-user'
                        dropdownWidth={480}
                        dropdownHeight={193}
                        selectedUsers={selectedUsers}
                        handleUserSelection={handleUserSelection}
                        placeholder='Select users'
                    />
                    <div className='csr-modal-search-users-selected'>
                        <CustomUserTags users={selectedUsers} onRemove={(users) => setSelectedUsers(users)} />
                    </div>

                    <InputField
                        inputID='feedback_message'
                        label='Request message (Optional)'
                        labelClass='csf-dropdown-heading mt-3'
                        value={feedbackMessage}
                        placeholder='Enter your suggestion message here'
                        width='480px'
                        height='95px'
                        textArea
                        handleChange={(_id, value) => setFeedbackMessage(value)}
                    />
                </div>
            </EWModal>
        </div>
    );
};

const mapStateToProps = ({ User }) => ({
    userId: User?._id,
});

Index.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    onSuccess: PropTypes.func,
};

export default Index;
