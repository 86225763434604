import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from 'components';

const ConditionalTooltipWrapper = ({ condition, title, children }) =>
    condition ? (
        <Tooltip white title={title}>
            {children}
        </Tooltip>
    ) : (
        <>{children}</>
    );

ConditionalTooltipWrapper.propTypes = {
    condition: PropTypes.bool.isRequired,
    title: PropTypes.string,
    children: PropTypes.any,
};

export default ConditionalTooltipWrapper;
