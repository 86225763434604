export const SAMPLE_FILE_URL =
    'https://assets-springengage.s3.amazonaws.com/production/feature-samples/Coupon+Code+sample+file.csv';

export const MAX_FILE_SIZE = 1; // in MB

export const SUPPORTED_IMAGES = ['.jpeg', '.jpg', '.png'];

export const ERRORTEXT = 'Required fields cannot be empty!';

export const POINT_VALUE_ERROR = 'Please enter a value greater that 1';

export const CATEGORYLIST = ['Work Perks', 'Charity', 'Company Swag'];

export const CATALOG = {
    HOME: '/dashboard/redemptions/catalog',
    CREATE_CUSTOM_REWARD: '/dashboard/redemptions/catalog/create-custom-reward',
};
