import React, { useEffect, useState } from 'react';
import { CustomSwitch, CustomCheckbox } from 'components';
import PropTypes from 'prop-types';
import SuggesionAdminBox from '../SuggestionBoxAdmin/index';
import { changeAdminModalData, selectChannelModalData } from '../../../constants';
import SelectionModal from '../SelectionModal/index';
import AdminSelectionModal from 'components/ReusableComponents/AdminSelectionModal';
import { useQuery } from 'react-query';
import { getIntegratedChannelsList } from 'Services/apiFunctions';
import { GET_INTEGRATED_CHANNELS } from 'Services/apiKeys';
import { useToaster } from 'Context/SnackbarContext';
import EWButton from 'components/ReusableComponents/EWButton';
import './style.scss';
import clsx from 'clsx';

const Index = ({ index, option, toggleConfiguration }) => {
    const { SetSnackbar } = useToaster();
    const [selectedUser, setSelectedUser] = useState();
    const [openChangeAdmin, setOpenChangeAdmin] = useState(false);
    const [openChangeChannels, setOpenChangeChannels] = useState(false);
    const [channelsData, setChannelsData] = useState([]);

    const openSnackbar = (variant, message) => {
        SetSnackbar({ open: true, variant, message: message || 'Some error occurred' });
    };
    useEffect(() => {
        setSelectedUser(option.admin);
    }, [option]);

    useQuery([GET_INTEGRATED_CHANNELS], getIntegratedChannelsList, {
        onSuccess: (data) => {
            if (data?.length) {
                setChannelsData(
                    data.map((channel) => ({
                        ...channel,
                        label: channel.channelName,
                        value: channel.channelID,
                    }))
                );
            }
        },
        onError: (err) => openSnackbar('error', err?.message),
    });

    return (
        <div className='suggestion-box-configuration-op-container'>
            <div className='configuration-wrapper'>
                <div className='configuration-heading-container'>
                    <div className='configuration-new-badge'>{option?.heading}</div>
                    <p>{option?.subtext}</p>
                </div>
                {/* toggle the configuration for suggestion box, anonymous, post postPublicly */}
                {!option.changeAdmin && (
                    <div className='configuration-switch-container'>
                        <CustomSwitch
                            data-testid='suggestionConfigSwitch'
                            checked={option.isEnabled}
                            onClick={() => {
                                toggleConfiguration(index, option.key, !option.isEnabled);
                                if (option.key === 'postPublicly' && !option.suggestionChannel) {
                                    setOpenChangeChannels(true);
                                }
                            }}
                            name={'suggestionBoxCheck' + index}
                        />
                    </div>
                )}
            </div>
            {/* change the suggestion admin */}
            {option.changeAdmin && (
                <div className='configuration-admin-container'>
                    {option.admin && <SuggesionAdminBox data={option.admin} title='admin' />}
                    <EWButton
                        buttonText={'Change Admin'}
                        primary={false}
                        plainTextButton
                        onClick={() => {
                            setOpenChangeAdmin(true);
                        }}
                    />
                </div>
            )}
            {option.adminApproval && (
                <div className={clsx({ 'container-disabled': !option.isEnabled })}>
                    <div className='configuration-admin-container'>
                        {option.suggestionChannel && (
                            <SuggesionAdminBox data={option.suggestionChannel} title='channel' />
                        )}
                        <EWButton
                            buttonText={'Change Channel'}
                            primary={false}
                            plainTextButton
                            onClick={() => {
                                setOpenChangeChannels(true);
                            }}
                        />
                    </div>
                    <div className={'csf-config'}>
                        <div className='csf-checkbox'>
                            <CustomCheckbox
                                data-testid='configCheckbox'
                                checked={option.approvalRequired}
                                onClick={() => {
                                    toggleConfiguration(index, 'approvalRequired', !option.approvalRequired);
                                }}
                                label='Approval from admin required'
                            />
                        </div>
                        <p>
                            All public suggestions will go to the suggestion box admin for approval before being posted
                            on the selected channel
                        </p>
                        <div className='csf-checkbox'>
                            <CustomCheckbox
                                data-testid='votingCheckbox'
                                checked={option.allowVote}
                                onClick={() => {
                                    toggleConfiguration(index, 'allowVote', !option.allowVote);
                                }}
                                label='Allow Voting'
                            />
                        </div>
                        <p>Employees can upvote or downvote anonymously on public suggestions</p>
                    </div>
                </div>
            )}
            {openChangeChannels && (
                <SelectionModal
                    open={openChangeChannels}
                    modalData={selectChannelModalData}
                    setOpen={setOpenChangeChannels}
                    dropdownData={channelsData}
                    selectedOption={option.suggestionChannel}
                    handleSave={(value) => {
                        toggleConfiguration(index, 'suggestionChannel', value);
                        setOpenChangeChannels(false);
                    }}
                    platform={'slack'}
                    onClose={() => {
                        setOpenChangeChannels(false);
                    }}
                />
            )}
            {openChangeAdmin && option.admin && (
                <AdminSelectionModal
                    open={openChangeAdmin}
                    data={changeAdminModalData}
                    selectedUser={selectedUser}
                    setSelectedUser={setSelectedUser}
                    selectedOption={option.admin}
                    onConfirm={() => {
                        toggleConfiguration(index, 'admin', selectedUser);
                        setOpenChangeAdmin(false);
                    }}
                    onClose={() => {
                        setOpenChangeAdmin(false);
                    }}
                />
            )}
        </div>
    );
};

Index.propTypes = {
    index: PropTypes.number,
    option: PropTypes.object,
    toggleConfiguration: PropTypes.func,
};

export default Index;
