import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as SearchIcon } from 'Assets/images/search.svg';
import clsx from 'clsx';
import './style.scss';

const CustomSearchBox = ({ handleSearch, width, ref, value, placeholder, containerClassName }) => {
    return (
        <div
            style={width ? { width } : {}}
            className={clsx({
                'd-flex flex-row-reverse justify-content-start searchbox-container': true,
                [containerClassName]: containerClassName,
            })}
        >
            <input type='text' placeholder={placeholder} ref={ref} value={value} onChange={handleSearch} />
            <SearchIcon />
        </div>
    );
};

CustomSearchBox.propTypes = {
    handleSearch: PropTypes.func,
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    ref: PropTypes.any,
    value: PropTypes.string,
    placeholder: PropTypes.string,
    containerClassName: PropTypes.string,
};

export default CustomSearchBox;
