import React from 'react';
import PropTypes from 'prop-types';
import { Switch, Route } from 'react-router-dom';
import Celebration from './CelebrationsHome';
import EmployeesWithMissingDates from './EmployeesWithMissingDates';
import { CELEBRATIONS } from './constants';

const Index = ({ history }) => {
    return (
        <Switch>
            <Route exact path={CELEBRATIONS.CELEBRATIONS} render={() => <Celebration history={history} />} />
            <Route
                exact
                path={CELEBRATIONS.EMPLOYEES_WITHOUT_ANY_DATES}
                render={() => <EmployeesWithMissingDates history={history} />}
            />
        </Switch>
    );
};

Index.propTypes = {
    history: PropTypes.object,
};

export default Index;
