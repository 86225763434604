import { actions } from './actionEvents';
import { getData, postData } from '../../Services/apiCall';
import { pick } from 'lodash';
import { showSnackBarMessage } from 'utils/HelperFunctions';
import { PLAN_STATUS } from '../../components/Dashboard/Content/PaymentsV2/constants';

const showError = (error, SetSnackbar) => showSnackBarMessage(SetSnackbar, 'error', error?.response?.data?.message);

export const GetPaymentInfo = async (dispatch, { user }) => {
    return getData(`api/v2/${user?.team}/subscription/setup?type=Monthly`).then(({ data }) => {
        dispatch({ type: actions.SET_DATA, payload: { data } });
        return data;
    });
};

export const AddWalletBalance = async (dispatch, { amount }) => {
    try {
        if (!amount) throw new Error('Amount should be a number');
        const { data } = await postData('wallet', { amount });
        if (data) dispatch({ type: actions.ADD_WALLET_BALANCE, payload: amount });
        return { result: true, data: amount };
    } catch (error) {
        return { error, result: false, data: 0 };
    }
};

export const GetSubscriptionDetails = async (dispatch, SetSnackbar) => {
    try {
        const { data } = await getData('payment/getPaymentDetails');
        dispatch({ type: actions.SET_DATA, payload: { data } });
        return data;
    } catch (error) {
        // fail in the API means the subscription is not active and will not break the page flow
        dispatch({
            type: actions.SET_DATA,
            payload: {
                data: {
                    subscription: {
                        status: PLAN_STATUS.CANCELLED,
                    },
                },
            },
        });
        showError(error, SetSnackbar);
    }
};

export const GetCardsList = async (dispatch, SetSnackbar) => {
    try {
        const { data, status } = await getData(`api/getCustomerCards`);
        if (status === 200) {
            dispatch({ type: actions.UPDATE_DATA, payload: { cardList: data } });
            return data;
        }
        return null;
    } catch (error) {
        showError(error, SetSnackbar);
    }
};

export const GetStripeSecret = async (amount = 50) => {
    try {
        const {
            data: { data },
        } = await getData(`wallet/link/${amount}`);
        return data;
    } catch (e) {
        return null;
    }
};

export const HandleCardData = async (dispatch, payload, SetSnackbar) => {
    const cardData = pick(payload.card, ['brand', 'exp_month', 'exp_year', 'last4', 'country', 'funding']);
    const bodyData = {
        actionType: payload.actionType,
        paymentMethodId: payload.id,
        isDefault: !!payload.isDefault,
    };

    try {
        await postData('api/v2/paymentmethod', bodyData);
        dispatch({ type: actions.SET_CARD_DATA, payload: { cardData } });
        SetSnackbar({ open: true, variant: 'success', message: 'Card added successfully' });
        return true;
    } catch (err) {
        SetSnackbar({ open: true, variant: 'error', message: err?.response?.data?.message || 'Something went wrong' });
        return false;
    }
};

export const updateRedemptionWalletDetails = (dispatch, payload) => {
    dispatch({ type: actions.UPDATE_DATA, payload });
};
