import React from 'react';
import ArrowDownward from '@material-ui/icons/ArrowDropDown';
import ArrowDropUp from '@material-ui/icons/ArrowDropUp';
import { ReactComponent as ArrowUpDown } from 'Assets/images/ArrowUpDown.svg';
import { ReactComponent as InfoIcon } from 'Assets/images/info-grey-filled.svg';
import { default as Tooltip } from 'components/Styles/Tooltip';
import clsx from 'clsx';
import { formatNumber } from 'utils/HelperFunctions';
import CountUp from 'react-countup';
import PropTypes from 'prop-types';

const RewardsStatItem = ({ name, count, imageIcon, percentageChange, info }) => {
    let ArrowIcon;
    if (percentageChange > 0) {
        ArrowIcon = ArrowDropUp;
    } else if (percentageChange < 0) {
        ArrowIcon = ArrowDownward;
    } else {
        ArrowIcon = ArrowUpDown;
    }

    const formatCount = (count) => Math.abs(formatNumber(count));

    /*
     * if the count is 1 digit, then we start from 0,
     * if 2 digit, then from number - 10,
     * if 3 digits, then number - 100
     * and so on
     */
    const countUpStart = (count) => {
        if (!count) {
            return 0;
        }
        const numberOfDigits = count.toString().length;
        const countValue = count - Math.pow(10, numberOfDigits - 1);
        return countValue >= 0 ? countValue : 0;
    };

    return (
        <div className='stats-item'>
            <div className='stats-item-top'>
                <div>
                    {name}
                    <Tooltip arrow title={info}>
                        <InfoIcon className='info-icon' />
                    </Tooltip>
                </div>
                <img src={imageIcon} alt='' />
            </div>
            <div className='stats-item-bottom'>
                <CountUp
                    start={countUpStart(formatCount(count))}
                    end={formatCount(count)}
                    duration={1}
                    className='stat-item-count'
                />
                {count > 0 && !isNaN(percentageChange) && (
                    <>
                        <ArrowIcon
                            className={clsx({
                                'color-green': percentageChange > 0,
                                'color-red': percentageChange < 0,
                                'mb-1': percentageChange === 0,
                            })}
                        />
                        <p
                            className={clsx({
                                percentageChange: true,
                                'color-green': percentageChange > 0,
                                'color-red': percentageChange < 0,
                            })}
                        >
                            {Math.abs(formatNumber(percentageChange))}%
                        </p>
                    </>
                )}
            </div>
        </div>
    );
};

RewardsStatItem.propTypes = {
    name: PropTypes.string,
    count: PropTypes.string,
    imageIcon: PropTypes.string,
    percentageChange: PropTypes.string,
    info: PropTypes.string,
};

export default RewardsStatItem;
