export { default as Lander } from './Lander/Lander';

export { default as CustomCheckbox } from './Styles/CustomCheckbox';
export { default as Onboarding } from './OnboardingV2/index';
export { default as CustomSwitch } from './Styles/CustomSwitch';
export { default as Dashboard } from './Dashboard';
export { default as UnauthorizedScreen } from './Unauthorized';

export { default as Inactive } from './Inactive';
export { default as Tooltip } from './Styles/Tooltip';
export { default as CustomRadio } from './Styles/CustomRadioButton';
