import React from 'react';
import PropTypes from 'prop-types';
import NotificationItemComponents from './EventTypes';
import InfiniteScroll from 'react-infinite-scroll-component';
import CustomLoader from 'components/ReusableComponents/CustomLoader';
import '../../styles.scss';
import { NOTIFICATIONS_REDIRECTS } from '../../constants';

const NotificationList = ({ history, notificationsData, next, hasMore, handleClose }) => {
    const notificationsLength = notificationsData?.pages[0]?.new;

    return (
        <div className=' notifications-main-container'>
            {notificationsLength > 0 && (
                <div className='no-of-notifications-container'>
                    <p className='no-of-notifications'>{notificationsLength} new notifications</p>
                </div>
            )}
            <div className='pb-3'>
                <InfiniteScroll
                    dataLength={notificationsData?.pages?.length * 10}
                    next={next}
                    hasMore={hasMore}
                    height={750}
                    loader={<CustomLoader size={10} />}
                >
                    {notificationsData?.pages?.map((pageData) =>
                        pageData?.data?.map((notification) => {
                            const componentModule = NotificationItemComponents[notification?.module];
                            const NotificationComponent = componentModule?.[notification?.event];
                            return NotificationComponent ? (
                                <NotificationComponent
                                    key={notification._id}
                                    data={notification}
                                    history={history}
                                    handleClose={handleClose}
                                    navigateTo={NOTIFICATIONS_REDIRECTS[notification?.event]}
                                />
                            ) : null;
                        })
                    )}
                </InfiniteScroll>
            </div>
        </div>
    );
};

NotificationList.propTypes = {
    history: PropTypes.object,
    notificationsData: PropTypes.object,
    next: PropTypes.func,
    hasMore: PropTypes.bool,
    handleClose: PropTypes.func,
};

export default NotificationList;
