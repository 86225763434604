import React from 'react';
import './style.scss';
import CustomFilterDropdown from 'components/ReusableComponents/CustomFilterDropdown';
import CustomSearchBox from 'components/Styles/CustomSearchBox';
import EmptyScreen from '../EmptyScreen';
import Table from 'components/ReusableComponents/Table';
import { debounce } from 'lodash';
import PropTypes from 'prop-types';

const Index = ({ orgFilter, orgAnalysis, handleChange, data, selectedOrgFilter, loading }) => {
    const handleSearchText = debounce((value) => {
        handleChange(`orgSearch`, value);
    }, 500);

    let paragraphText = '';

    if (!orgAnalysis.orgManagerSetUp) {
        if (!orgAnalysis.orgSetUp) {
            paragraphText = 'Setup your organization to view the organization wise eNPS analysis.';
        } else if (selectedOrgFilter.label === 'Managers') {
            paragraphText = 'Assign managers to your team to view eNPS score across all Manager’s team';
        } else {
            paragraphText = 'Add departments to view the eNPS score across all the departments';
        }
    }

    return (
        <div className='org-analysis'>
            <div className='head'>
                <div className='head-left d-inline-block'>
                    <h3 className='header-3 d-inline'>Organization Analysis</h3>
                    {orgAnalysis.orgSetUp && (
                        <div className='d-inline-block '>
                            <CustomFilterDropdown
                                filterOptions={orgFilter}
                                selectedName={selectedOrgFilter.label}
                                optionsSelected
                                handleSelection={(index) => handleChange('orgFilter', index)}
                                dropDownID='org-Analysis'
                                singleSelect
                                buttonStyleClass='width-180 ml-3'
                                dropdownHeight={'auto'}
                            />
                        </div>
                    )}

                    <div style={{ height: '48px' }}>
                        {!loading && (
                            <div className='mt-2'>
                                {orgAnalysis.orgManagerSetUp && (
                                    <p>
                                        {' '}
                                        {`If the ${selectedOrgFilter.value} has less than 3 responses, results will not be displayed.`}{' '}
                                    </p>
                                )}
                                {!orgAnalysis.orgManagerSetUp && <p>{paragraphText}</p>}
                            </div>
                        )}
                    </div>
                </div>

                {orgAnalysis.orgManagerSetUp && (
                    <div className='head-right d-inline-block '>
                        <CustomSearchBox
                            width={'300px'}
                            handleSearch={(e) => {
                                handleSearchText(e.target.value);
                            }}
                            placeholder={`Search ${selectedOrgFilter.value} name`}
                        />
                    </div>
                )}
            </div>

            <div className='body'>
                {!orgAnalysis.orgManagerSetUp ? (
                    <EmptyScreen
                        orgAnalysis={orgAnalysis}
                        selectedOrgFilter={selectedOrgFilter}
                        handleChange={handleChange}
                    />
                ) : (
                    <Table {...data} loading={loading} />
                )}
            </div>
        </div>
    );
};

Index.propTypes = {
    orgFilter: PropTypes.array,
    orgAnalysis: PropTypes.object,
    handleChange: PropTypes.func,
    data: PropTypes.object,
    loading: PropTypes.bool,
    selectedOrgFilter: PropTypes.object,
};

export default Index;
