import React, { useState } from 'react';
import Header from '../CustomTableContainer/Header';
import List from '../CustomTableContainer/List';
import '../Department/style.scss';
import { useToaster } from 'Context/SnackbarContext';
import ConfirmModal from '../CustomTableContainer/ConfirmModal';
import NewOrganisationModal from 'components/Modals/NewOrganisationModal';
import { getOrgCountries, deleteCountry } from 'Services/apiFunctions';
import CustomLoader from 'components/ReusableComponents/CustomLoader';
import { countryMapper, showSnackBarMessage } from 'utils/HelperFunctions';
import { useQuery, useQueryClient, useMutation } from 'react-query';
import { GET_PAGINATED_COUNTRY, GET_PROFILE_DATA } from 'Services/apiKeys';
import { debounce, differenceWith } from 'lodash';
import { getCountries } from 'utils/countryUtils';
import { EDIT, DELETE, ORG_MENU_OPTION } from '../constants';
import './style.scss';

const allCountries = getCountries();

const Index = () => {
    const queryClient = useQueryClient();
    const btnName = '+Add new Country';
    const searchPlaceholder = 'Search for country';
    const noSortColumns = [0, 1, 2];
    const columnWidth = ['33%', '66%', '1%'];
    const listHeader = ['country', 'active users', ''];
    const { SetSnackbar } = useToaster();
    const [open, setOpen] = useState(false);
    const [editData, setEditData] = useState(undefined);
    const [currentPage, setCurrentPage] = useState(1);
    const [confirmModal, setConfirmModal] = useState({ open: false, type: '', country: null });
    const [search, setSearch] = useState('');
    const COUNTRY = 'country';

    const countryComparator = (a, b) => a.countryCode === b.countryCode;
    const limit = 10;

    const {
        data: countryData,
        isLoading,
        isFetching,
    } = useQuery([GET_PAGINATED_COUNTRY, currentPage, limit, search], getOrgCountries, {
        keepPreviousData: true,
        refetchOnWindowFocus: false,
        retry: 0,
    });

    const handleDeleteCountry = () => {
        setCurrentPage(1);
        showSnackBarMessage(SetSnackbar, 'success', `${confirmModal.country?.name} deleted Successfully!`);
        queryClient.invalidateQueries(GET_PAGINATED_COUNTRY);
        queryClient.invalidateQueries(GET_PROFILE_DATA);
        setConfirmModal({ open: false, type: '', country: {} });
    };

    const { mutate: removeCountry, isLoading: deletingCountry } = useMutation(deleteCountry, {
        onSuccess: handleDeleteCountry,
        onError: () => setConfirmModal({ open: false, type: '', country: {} }),
    });

    const mappedCountries = countryData?.countries?.map((item) => countryMapper(item, allCountries)) || [];

    const unMappedCountries = differenceWith(allCountries, countryData?.countries, countryComparator);

    const handleConfirmModalOK = async () => {
        if (confirmModal.type === COUNTRY) {
            removeCountry(confirmModal.country?.countryCode);
        }
    };

    // Menu Operations
    const handleMenuOption = (option, country) => {
        switch (option.value) {
            case EDIT:
                setEditData(country);
                setOpen(!open);
                break;
            case DELETE:
                setConfirmModal({ open: true, type: COUNTRY, country });
                break;
            default:
                break;
        }
    };

    const addCountry = () => {
        setEditData(undefined);
        setOpen(!open);
    };

    const handleSearchChange = debounce((value) => {
        setCurrentPage(1);
        setSearch(value);
    }, 300);

    if (isLoading) {
        return <CustomLoader marginTop />;
    }

    if (!search && countryData?.totalCount < 1) {
        return (
            <div>
                <div className='empty-department'>
                    <img src={require('Assets/images/empty_location.svg')} alt='empty-country' className='mt-5' />
                    <br />
                    <button className='ew-btn pb-btn mt-3 ' onClick={addCountry}>
                        +Add new Country
                    </button>
                </div>
                {open && (
                    <NewOrganisationModal
                        organisationType={COUNTRY}
                        setOrgModal={setOpen}
                        setCurrentPage={setCurrentPage}
                        targetCountry={
                            editData
                                ? mappedCountries.find((item) => item.name === editData?.name)
                                : unMappedCountries[0]
                        }
                        countries={editData ? mappedCountries : unMappedCountries}
                        editData={editData}
                    />
                )}
            </div>
        );
    }

    return (
        <>
            <div className='mt-2 overall-dept-container'>
                <Header
                    title='All Countries'
                    btnName={btnName}
                    handleSearch={handleSearchChange}
                    searchPlaceholder={searchPlaceholder}
                    handleAddButton={addCountry}
                />
                {countryData?.countries?.length > 0 && (
                    <List
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                        noSortColumns={noSortColumns}
                        columnWidth={columnWidth}
                        listHeader={listHeader}
                        totalCount={countryData?.totalCount}
                        listData={countryData?.countries}
                        menuOption={ORG_MENU_OPTION}
                        country={true}
                        loading={isLoading}
                        fetching={isFetching}
                        handleMenuOption={handleMenuOption}
                        handlePagination={setCurrentPage}
                        className='shadow-none'
                    />
                )}
                {countryData?.countries?.length <= 0 && (
                    <div className='empty-department'>
                        <img src={require('Assets/images/empty-flag.svg')} alt='empty-flag' className='mt-5' />
                        <p className='empty-state-content mt-4'>No results found</p>
                    </div>
                )}
            </div>
            <ConfirmModal
                open={confirmModal.open}
                type={COUNTRY}
                name={confirmModal.country?.name || ''}
                userCount={confirmModal.country?.users?.length}
                onConfirm={handleConfirmModalOK}
                onClose={() => setConfirmModal({ open: false })}
                loading={deletingCountry}
            />
            {open && (
                <NewOrganisationModal
                    organisationType={COUNTRY}
                    setOrgModal={setOpen}
                    setCurrentPage={setCurrentPage}
                    targetCountry={
                        editData ? mappedCountries.find((item) => item.name === editData?.name) : unMappedCountries[0]
                    }
                    countries={editData ? mappedCountries : unMappedCountries}
                    editData={editData}
                />
            )}
        </>
    );
};

export default Index;
