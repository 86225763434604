import { SET_BANNER_SCENARIOS } from './constant';
import { PLAN_STATUS } from 'components/Dashboard/Content/PaymentsV2/constants';
import { RECOGNITION_CONFIG } from '../Recognitions/Configuration/constants';
import { REDEMPTIONS } from '../RedemptionConfigurations/constants';
import { CATALOG } from '../Catalog/constant';
/*--------------- Below are the functions/handlers used for Reward List */

export const isSubscriptionAndWalletDataAvailable = (subscription, wallet) => {
    return subscription && wallet;
};

export const checkSubscription = (subscription, wallet) => {
    return (
        subscription?.status !== PLAN_STATUS.CANCELLED &&
        (subscription?.isPaymentMethodAdded || wallet?.balance > 0 || wallet?.hasBalance)
    );
};

export const isPaymentMethodAvailable = (subscription, wallet) => {
    // when payment method is not added but wallet balance is present
    return subscription?.isPaymentMethodAdded || wallet?.balance > 0 || wallet?.hasBalance;
};

export const isRedemptionEnabled = ({ subscription, config, wallet }) => {
    return checkSubscription(subscription, wallet) && config?.redemption;
};

export const isVouchersEnabled = ({ subscription, config, xoxoday, wallet }) => {
    return isRedemptionEnabled({ config, subscription, wallet }) && xoxoday?.vouchers?.length > 0;
};

export const handleSetBannerProps = (scenario, { isAdmin, setShowBanner, setBannerProps, adminEmail }) => {
    if (isAdmin) {
        switch (scenario) {
            case SET_BANNER_SCENARIOS.NO_PAYMENT_METHOD:
                setShowBanner(true);
                setBannerProps({
                    title: SET_BANNER_SCENARIOS.NO_PAYMENT_METHOD,
                    subHeading: `You'll not be able to redeem rewards until you add a payment method. Note: Custom rewards can still be redeemed.`,
                    cta: 'Add a payment method now',
                    redirectTo: '/dashboard/payments',
                });
                break;
            case SET_BANNER_SCENARIOS.NO_VOUCHERS_FOR_COUNTRY:
                setShowBanner(true);
                setBannerProps({
                    title: SET_BANNER_SCENARIOS.NO_VOUCHERS_FOR_COUNTRY,
                    subHeading:
                        'Redemptions are currently disabled for your country, please go to Configurations to set it up.',
                    cta: 'Go to Configurations',
                    redirectTo: REDEMPTIONS.CONFIGURATION,
                });
                break;
            case SET_BANNER_SCENARIOS.NO_VOUCHERS:
                setShowBanner(true);
                setBannerProps({
                    title: 'Redemptions are disabled',
                    subHeading: `Reward redemptions are currently disabled for your workspace.`,
                    cta: 'Enable Redemptions',
                    redirectTo: RECOGNITION_CONFIG.CONFIGURATION,
                });
                break;
            case SET_BANNER_SCENARIOS.REDEMPTIONS_NOT_ENABLED:
                setShowBanner(true);
                setBannerProps({
                    title: 'Redemptions disabled.',
                    subHeading: `Reward redemptions are currently disabled for your workspace.`,
                    cta: 'Enable Redemptions',
                    redirectTo: REDEMPTIONS.CONFIGURATION,
                });
                break;
            case SET_BANNER_SCENARIOS.NO_VOUCHERS_AND_CONVERSION_ENABLED:
                setShowBanner(true);
                setBannerProps({
                    title: SET_BANNER_SCENARIOS.NO_VOUCHERS_AND_CONVERSION_ENABLED,
                    subHeading: 'You have not enabled any gift cards yet, to enable please go to the catalog page.',
                    cta: 'Enable gift cards',
                    redirectTo: CATALOG.HOME,
                });
                break;
            default:
                setShowBanner(false);
                setBannerProps({});
                break;
        }
    } else {
        switch (scenario) {
            case SET_BANNER_SCENARIOS.NO_VOUCHERS:
            case SET_BANNER_SCENARIOS.REDEMPTIONS_NOT_ENABLED:
            case SET_BANNER_SCENARIOS.NO_VOUCHERS_AND_CONVERSION_ENABLED:
                setShowBanner(true);
                setBannerProps({
                    title: SET_BANNER_SCENARIOS.REDEMPTIONS_NOT_ENABLED,
                    subHeading: `Reward redemptions are currently disabled for your workspace. Please reach out to ${
                        adminEmail || 'an admin'
                    } to enable redemptions.`,
                    cta: null,
                    redirectTo: null,
                });
                break;
            case SET_BANNER_SCENARIOS.NO_VOUCHERS_FOR_COUNTRY:
                setShowBanner(true);
                setBannerProps({
                    title: SET_BANNER_SCENARIOS.REDEMPTIONS_NOT_ENABLED,
                    subHeading: `Reward redemptions are currently disabled for your country by your organization. Please reach out to ${
                        adminEmail || 'an admin'
                    } to enable redemptions.`,
                    cts: null,
                    redirectTo: null,
                });
                break;
            case SET_BANNER_SCENARIOS.NO_PAYMENT_METHOD:
            default:
                setShowBanner(false);
                setBannerProps({});
                break;
        }
    }
};

export const handlePurchase = ({ history, purchaseItem }) => {
    history.push(`/dashboard/redemptions/redeem/confirm-purchase?id=${purchaseItem.productId}`);
};

const processBannerSettings = ({
    xoxoday,
    userCountry,
    subscription,
    wallet,
    bannerPropsArgs,
    config,
    setAllowCustomCouponRedemption,
    userCountryRedemption,
}) => {
    if (!config?.redemption) {
        handleSetBannerProps(SET_BANNER_SCENARIOS.REDEMPTIONS_NOT_ENABLED, bannerPropsArgs);
    } else if (!isPaymentMethodAvailable(subscription, wallet)) {
        handleSetBannerProps(SET_BANNER_SCENARIOS.NO_PAYMENT_METHOD, bannerPropsArgs);
        setAllowCustomCouponRedemption(true);
    } else if (userCountry && !userCountryRedemption) {
        handleSetBannerProps(SET_BANNER_SCENARIOS.NO_VOUCHERS_FOR_COUNTRY, bannerPropsArgs);
        setAllowCustomCouponRedemption(false);
    } else if (!userCountryRedemption && !isVouchersEnabled({ subscription, wallet, config, xoxoday })) {
        handleSetBannerProps(SET_BANNER_SCENARIOS.NO_VOUCHERS, bannerPropsArgs);
        setAllowCustomCouponRedemption(false);
    } else if (!isVouchersEnabled({ subscription, wallet, config, xoxoday })) {
        handleSetBannerProps(SET_BANNER_SCENARIOS.NO_VOUCHERS_AND_CONVERSION_ENABLED, bannerPropsArgs);
        setAllowCustomCouponRedemption(false);
    } else {
        handleSetBannerProps(SET_BANNER_SCENARIOS.NONE, bannerPropsArgs);
        setAllowCustomCouponRedemption(true);
    }
};

export const handleRewardsListEffect = ({
    xoxoday,
    userCountry,
    config,
    bannerPropsArgs,
    setLoading,
    subscription,
    workspaceWallet,
    setAllowCustomCouponRedemption,
    userCountryRedemption,
}) => {
    if (!xoxoday) {
        return setLoading(true);
    }
    processBannerSettings({
        xoxoday,
        userCountry,
        subscription,
        wallet: workspaceWallet,
        bannerPropsArgs,
        config,
        setAllowCustomCouponRedemption,
        userCountryRedemption,
    });
    setLoading(false);
};
