import React from 'react';
import PropTypes from 'prop-types';
import Modal from '@material-ui/core/Modal';
import { Tooltip } from 'components';
import CloseIcon from '@material-ui/icons/Close';
import './style.scss';

const Index = ({ open, setOpen, users }) => {
    const content = (
        <div className='requested-user-container'>
            <div className='requested-user-header'>
                <p>Requested Users</p>
                <CloseIcon className='close-icon' onClick={() => setOpen(false)} />
            </div>
            <div className='requested-user-body'>
                {users.map((value, index) => {
                    return (
                        <span className='pulse-user-info' key={value._id + index}>
                            <img
                                src={value.userImageURL || require('Assets/images/defaultUser.png')}
                                className='pulse-user-image'
                                alt=''
                            />
                            &nbsp;
                            <span data-tip data-for={value.userName + index}>
                                {value?.userName?.length > 9 ? value.userName?.slice(0, 9) + '...' : value.userName}
                                {value?.userName?.length > 9 ? (
                                    <Tooltip arrow title={value?.userName}>
                                        {value?.userName}
                                    </Tooltip>
                                ) : null}
                            </span>
                        </span>
                    );
                })}
            </div>
        </div>
    );

    return (
        <div>
            <Modal open={open} onClose={() => setOpen(false)}>
                {content}
            </Modal>
        </div>
    );
};

Index.propTypes = {
    open: PropTypes.bool,
    setOpen: PropTypes.func,
    users: PropTypes.array,
};

export default Index;
