import CustomDateSelector from 'components/ReusableComponents/CustomDateSelector';
import { timeInterval, getTime, isSlack } from 'utils/HelperFunctions';
import { useBeforeunload } from 'react-beforeunload';
import { useToaster } from 'Context/SnackbarContext';
import React, { useState, useEffect } from 'react';
import { PULSE_FREQUENCY } from 'constants.js';
import { format, isSameDay, isValid, parseISO } from 'date-fns';
import './style.scss';
import FrequencyType from './schedulingComponents/SetFrequencyTemplate/FrequencyType';
import RollingPulse from './schedulingComponents/SetFrequencyTemplate/RollingPulse';
import DayResponse from './schedulingComponents/SetFrequencyTemplate/DayResponse';
import Reminders from './schedulingComponents/SetFrequencyTemplate/Reminders';
import DateDisclaimer from './schedulingComponents/SetFrequencyTemplate/DateDisclaimer';
import PulseCreationInfo from '../../Components/PulseCreationInfo';
import CustomTimePickerDropdown from 'components/ReusableComponents/CustomTimePickerDropdown';
import { shallowEqual, useSelector } from 'react-redux';
import Banner from './Banner';
import { ReactComponent as Warning } from 'Assets/images/warning-purple.svg';
import clsx from 'clsx';
import { EDIT_TYPE } from '../../constants';
import PropTypes from 'prop-types';

const Index = ({
    setQuesAdded,
    eNPS,
    pulseData,
    scheduling,
    setScheduling,
    setStep,
    questions,
    getScheduleInfo,
    duplicateId,
    isEditingOnetime,
    isEditingRolling,
    isEditingRecurring,
    editType,
    pulseAudienceData,
}) => {
    const currentTimeIndex = Math.ceil((new Date().getHours() * 60 + new Date().getMinutes()) / 15);
    const scheduledDate = format(new Date(scheduling?.onDate), 'dd MMMM yyyy');
    const pulseEndDate = isValid(new Date(pulseData?.endAt)) && format(new Date(pulseData?.endAt), 'dd MMMM yyyy');
    let currentTime;
    if (getScheduleInfo().sendInstantly || (scheduledDate === pulseEndDate && isEditingRecurring)) {
        currentTime = null;
    } else
        currentTime =
            isValid(new Date(getScheduleInfo()?.sendOn)) && format(new Date(getScheduleInfo()?.sendOn), 'hh:mm a');
    const { SetSnackbar } = useToaster();
    const { WEEKLY } = PULSE_FREQUENCY;

    const { platform, timezone } = useSelector(mapStateToProps, shallowEqual);
    const [openBanner, setOpenBanner] = useState(true);

    const getTimer = () => {
        let date = new Date();
        if (isValid(new Date(scheduling?.onDate))) {
            date = new Date(scheduling?.onDate);
        } else if (pulseData?.scheduleInfo?.sendOn) {
            date = new Date(pulseData.scheduleInfo.sendOn);
        }
        return timeInterval(date, true);
    };

    const [time, setTime] = useState(() => getTimer());
    const [error, setError] = useState(false);

    const handleChange = ({ name, value }) => {
        // eslint-disable-next-line default-case
        switch (name) {
            case 'frequencyType':
                setTime(timeInterval(new Date(), true));
                setScheduling({
                    ...scheduling,
                    onDate: new Date(),
                    onTime: time[0],
                    frequencyType: value,
                    onDays: value === WEEKLY ? format(new Date(), 'EEEE') : null,
                });
                break;
            case 'time':
                setScheduling({ ...scheduling, onTime: time[value] });
                break;
            case 'date':
                setTime(timeInterval(new Date(value), true));
                setScheduling({
                    ...scheduling,
                    onDate: value,
                    onTime: timeInterval(new Date(value), true)[0],
                    ...(scheduling?.frequencyType === WEEKLY && { onDays: format(new Date(value), 'EEEE') }),
                });
                break;
        }
    };

    const isEditingOngoing = () => {
        return isEditingOnetime || isEditingRolling || editType === EDIT_TYPE.ONGOING;
    };

    useEffect(() => {
        setQuesAdded(true);

        if (duplicateId && !scheduling?.onTime) {
            setScheduling({
                ...scheduling,
                onTime: time[currentTimeIndex] || time[0],
            });
        }
        if (pulseData?.scheduleInfo && !duplicateId) {
            if (isValid(new Date(pulseData?.scheduleInfo?.sendOn))) {
                const label = format(new Date(pulseData?.scheduleInfo?.sendOn), 'hh:mm a');
                const index = time.findIndex((slot) => slot.label === label);
                if (index > -1 && !scheduling?.onTime) {
                    setScheduling({
                        ...scheduling,
                        onTime: { label, value: index },
                    });
                }
            }
        }
        if (scheduledDate === pulseEndDate && isEditingRecurring) {
            const pulseEndInMin = getTime(format(new Date(pulseData?.endAt), 'hh:mm a'));
            const timeArray = time.filter((element) => {
                return getTime(element.label) >= pulseEndInMin;
            });
            setTime(timeArray);
        }
    }, [scheduling?.onDate, scheduling?.frequencyType]);

    useBeforeunload((event) => {
        event.preventDefault();
    });

    const formatDate = (date) => {
        if (date) {
            const parsedDate = parseISO(date);
            return format(parsedDate, 'd MMMM yyyy, h:mm a');
        }
    };
    const bannerHeading = `An instance of this pulse is running already and ends on ${formatDate(
        pulseData?.endAt
    )}. You can schedule next instance after ${formatDate(pulseData?.endAt)}`;

    const isBannerOpen = () => {
        if (isSameDay(new Date(scheduling?.onDate), new Date(pulseData?.endAt))) {
            const time1InMinutes = getTime(scheduling?.onTime?.label || '12:00 AM');
            const time2InMinutes = getTime(format(new Date(pulseData?.endAt), 'hh:mm a'));
            return time1InMinutes <= time2InMinutes;
        }
        return false;
    };

    return (
        <div className='pulse-scheduling-main '>
            {/* Section 1 */}
            {openBanner && isBannerOpen() && isEditingRecurring && editType === EDIT_TYPE.FUTURE && (
                <Banner
                    heading={bannerHeading}
                    image={<Warning className='feedback-banner' />}
                    onClose={() => setOpenBanner(!openBanner)}
                />
            )}
            <div className='schedule'>
                <div>
                    {!eNPS && isSlack(platform) && (
                        <RollingPulse
                            scheduling={scheduling}
                            setScheduling={setScheduling}
                            disabled={isEditingOnetime || isEditingRolling || isEditingRecurring}
                        />
                    )}

                    <h4 className='heading'>Schedule Pulse</h4>
                    {timezone && <p className='rp-desc'>{`Pulse will be sent in ${timezone || ''}`}</p>}

                    <FrequencyType
                        defaultFrequencyType={scheduling?.frequencyType}
                        selectedFrequencyType={handleChange}
                        selectedDay={scheduling?.onDays}
                        isRollingPulse={scheduling?.rollingPulse}
                        eNPS={eNPS}
                        error={error}
                        isEditingRolling={isEditingRolling}
                        editType={editType}
                        disabled={scheduling?.rollingPulse || isEditingOngoing()}
                    />

                    <div className={clsx({ 'pt-2': true })}>
                        <div className='d-flex'>
                            <div style={{ marginRight: 30 }}>
                                <CustomDateSelector
                                    title='When to start this pulse?'
                                    value={scheduling?.onDate || new Date()}
                                    placeholder='Select Date'
                                    handleChange={(date) => handleChange({ name: 'date', value: date })}
                                    format={'MMMM dd, yyyy'}
                                    disabled={isEditingOngoing()}
                                    editType={editType}
                                    isEditingRecurring={isEditingRecurring}
                                    pulseData={pulseData}
                                />
                            </div>
                            <div style={{ marginTop: 'auto' }}>
                                <CustomTimePickerDropdown
                                    label=''
                                    dropDownID='timeInput'
                                    classname='width-240 mr-5'
                                    name={currentTime || time[0]?.label}
                                    timeOptions={time}
                                    handleChange={(index) => handleChange({ name: 'time', value: index })}
                                    disabled={isEditingOngoing()}
                                />
                            </div>
                        </div>
                        {scheduling?.frequencyType?.toLowerCase() === WEEKLY && (
                            <span className='disclaimer-text'>{`Pulse will repeat every ${format(
                                new Date(scheduling?.onDate),
                                'EEEE'
                            )}`}</span>
                        )}
                        <DateDisclaimer scheduling={scheduling} time={getScheduleInfo()?.sendOn} />
                    </div>

                    <div style={{ margin: '20px 0 32px 0' }}>
                        <DayResponse
                            label='ALLOW RESPONSES FOR'
                            scheduling={scheduling}
                            setScheduling={setScheduling}
                            SetSnackbar={SetSnackbar}
                            eNPS={eNPS}
                            error={error}
                            setError={setError}
                            disabled={isEditingRolling}
                        />
                    </div>
                    <p className='divider-771'>
                        - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
                        - - - - - - - - - - - - - - - - - - -
                    </p>
                    <Reminders
                        scheduling={scheduling}
                        setScheduling={setScheduling}
                        isRollingPulse={scheduling?.rollingPulse}
                        responseDays={scheduling?.responseForDays}
                        numberOfQuestions={questions?.length}
                        disabled={isEditingRolling}
                    />
                </div>

                <div>
                    <PulseCreationInfo
                        eNPS={eNPS}
                        data={pulseAudienceData}
                        scheduled={getScheduleInfo}
                        setStep={setStep}
                        questions={questions}
                        fromScheduling
                        scheduling={scheduling}
                    />
                </div>
            </div>

            <br />
        </div>
    );
};

const mapStateToProps = ({ Workspace }) => ({
    platform: Workspace.platform,
    timezone: Workspace.timezone,
});

Index.propTypes = {
    setQuesAdded: PropTypes.func.isRequired,
    eNPS: PropTypes.bool,
    pulseData: PropTypes.object,
    scheduling: PropTypes.shape({
        onDate: PropTypes.string,
        onTime: PropTypes.object,
        frequencyType: PropTypes.string,
        onDays: PropTypes.string,
        rollingPulse: PropTypes.bool,
        responseForDays: PropTypes.number,
    }).isRequired,
    setScheduling: PropTypes.func.isRequired,
    setStep: PropTypes.func.isRequired,
    questions: PropTypes.array,
    getScheduleInfo: PropTypes.func.isRequired,
    duplicateId: PropTypes.string,
    isEditingOnetime: PropTypes.bool,
    isEditingRolling: PropTypes.bool,
    isEditingRecurring: PropTypes.bool,
    editType: PropTypes.bool,
    pulseAudienceData: PropTypes.object,
};

export default Index;
