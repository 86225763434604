import React, { useState } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import PropTypes from 'prop-types';
import Values from './Values';
import RecognitionConfiguration from './RecognitionConfiguration';
import RedemptionConfiguration from './RecognitionRedemptionConfiguration';
import Workspace from './Workspace';
import Notifications from './Notifications';
import TitleBar from 'components/ReusableComponents/TitleBar';
import { shallowEqual, useSelector } from 'react-redux';
import { PLATFORM } from 'constants.js';
import './style.scss';

const RECOGNITIONS = 'recognitions';

const Index = ({ history }) => {
    const { platform } = useSelector(mapStateToProps, shallowEqual);
    const [key, setKey] = useState(RECOGNITIONS);
    const isWebOnlyUser = platform === PLATFORM.WEB;

    const [modified, setModified] = useState(false);
    const [saveConfirm, setSaveConfirm] = useState(false);
    const [tempKey, setTempKey] = useState(RECOGNITIONS);

    const onSelect = (value) => {
        if (value !== RECOGNITIONS && modified) {
            setTempKey(value);
            setSaveConfirm(true);
            setKey(RECOGNITIONS);
        } else {
            setKey(value);
        }
    };

    const handleChange = (type, value) => {
        switch (type) {
            case 'modified':
                setModified(value);
                break;
            case 'saveConfirm':
                setSaveConfirm(value);
                break;
            case 'changeTab':
                setKey(tempKey);
                break;
        }
    };
    return (
        <div className='configuration-container navigatortabs'>
            <TitleBar title='Configurations' />
            <Tabs activeKey={key} onSelect={(k) => onSelect(k)}>
                <Tab eventKey={RECOGNITIONS} title='Recognition' className='pt-3'>
                    {key === RECOGNITIONS && (
                        <RecognitionConfiguration
                            history={history}
                            modified={modified}
                            saveConfirm={saveConfirm}
                            handleChange={handleChange}
                        />
                    )}
                </Tab>
                <Tab eventKey='redemption' title='Redemption' className='pt-3'>
                    {key === 'redemption' && <RedemptionConfiguration history={history} />}
                </Tab>
                {!isWebOnlyUser && (
                    <Tab eventKey='workspace' title='Workspace' className='pt-3'>
                        {key === 'workspace' && <Workspace history={history} />}
                    </Tab>
                )}
                <Tab eventKey='values' title='Values' className='pt-3'>
                    {key === 'values' && <Values />}
                </Tab>
                <Tab eventKey='notifications' title='Notifications' className='pt-3'>
                    {key === 'notifications' && <Notifications />}
                </Tab>
            </Tabs>
        </div>
    );
};

const mapStateToProps = (state) => ({
    platform: state.Workspace.platform,
});

Index.propTypes = {
    history: PropTypes.object,
};

export default Index;
