import React from 'react';
import PropTypes from 'prop-types';
import { BeatLoader } from 'react-spinners';
import CancelOutlined from '@material-ui/icons/CancelOutlined';
import { AdminBox } from 'utils/Stylesheet/style.js';
import './style.scss';

const Index = ({ data, isLoading, removeHandler, section }) => {
    if (isLoading) {
        return (
            <div className='text-center'>
                <BeatLoader data-testid={'Loader'} size={12} color={'#5D5CF5'} />
            </div>
        );
    }

    return (
        <div className='dashboard-user-container'>
            {data.map((item) => {
                return (
                    <AdminBox key={item._id}>
                        <img
                            src={item?.pictureURL || require('Assets/images/defaultUser.png')}
                            className='avatar-24'
                            alt='avatar'
                        />
                        <span className='name'>{item.userName}</span>
                        <button className='cross-icon cross' onClick={() => removeHandler(item, section)}>
                            <CancelOutlined data-testid={'Remove'} />
                        </button>
                    </AdminBox>
                );
            })}
        </div>
    );
};

Index.propTypes = {
    data: PropTypes.array,
    isLoading: PropTypes.bool,
    removeHandler: PropTypes.func,
    section: PropTypes.string,
};

export default Index;
