import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import CustomLoader from 'components/ReusableComponents/CustomLoader';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { toggleFullscreen } from 'redux/layout/actions';
import Header from './Header';
import CreateQuestion from './CreateQuestion';
import Audience from './Audience';
import Template from '../Template';
import SaveAsDraftModal from 'components/Modals/SaveAsDraftModal';
import UseSavedAsDraftModal from 'components/Modals/UseSavedAsDraftModal';
import { createPulse, editPulse, getPulse, getPulseTemplateQuestions } from 'Services/apiFunctions';
import { useToaster } from 'Context/SnackbarContext';
import Scheduling from './Scheduling';
import PulseCreatedModal from 'components/Modals/PulseCreatedModal';
import './style.scss';
import { eNPS_ROUTE } from '../../ENPS/constant';
import {
    KEY_PULSE_AUDIENCE_DATA,
    PULSE_TYPE,
    PULSE_STATUS,
    PULSE_SUB_TYPE,
    EDIT_TYPE,
} from '../../PulseSurvey/constants';
import { setDate, showSnackBarMessage, capitalizeFirstLetter, timeInterval } from 'utils/HelperFunctions';
import {
    getENPSQuestionList,
    scheduleDefaultValue,
    defaultAudience,
    getDefaultQuestion,
    useSavedAsDraftModalData,
    formattedEnpsAPIData,
} from './constants';
import { mapApiToQuestionState, validatePulse, getRequestedListData } from './createPulseFunctions';
import { format, isValid, isBefore } from 'date-fns';
import { useLocation } from 'react-router-dom';
import { updateWorkspaceDetails } from 'redux/workspace/actions';
import ConfirmModal from 'components/ReusableComponents/ConfirmModal';

/*** Step Definition
-1 = Templates listing | Component -> Template
0 = Editing questions | Component -> Questionnaire
1 = Editing audience | Component -> Audience
2 = Editing schedule | Component -> Scheduling
***/
const PULSE_ENDED = 'This pulse has already ended, you will not be able to save these changes anymore';
const PULSE_STARTED = 'This pulse has already started, you will not be able to save these changes anymore';
const NEXT_PULSE_STARTED =
    'The next instance of this pulse has already started, these changes will impact the future instances only.';

const Index = ({ history, eNPS }) => {
    const [surveyType, defaultFrequency] = eNPS ? ['eNPS', PULSE_TYPE.ONE_TIME] : ['Pulse', PULSE_TYPE.ONE_TIME];
    const { SetSnackbar } = useToaster();
    const dispatch = useDispatch();
    const location = useLocation();
    const isStepZeroVisited = useRef(false);
    const { duplicateId, editId, editType, isRecurringStopped, draftId, isOngoing, draftOrScheduled, isDraft } =
        location?.state || '';
    const { todo, companyName, isFullscreen } = useSelector(mapStateToProps, shallowEqual);
    const [quesAdded, setQuesAdded] = useState(false);
    const [step, setStep] = useState(0);
    const [pulseID, setPulseID] = useState(editId || draftId);
    const [pulseData, setPulseData] = useState(undefined);
    const [draftModal, setDraftModal] = useState(false);
    const [savedDraftModal, setSavedDraftModal] = useState(true);
    const [loading, setLoading] = useState(false);
    const [fetchLoading, setFetchLoading] = useState(false);
    const [pulseCreated, setPulseCreated] = useState(false);
    const [openQuestion, setOpenQuestion] = useState(Number(!!eNPS) * -1);
    const [questions, setQuestions] = useState(getDefaultQuestion(eNPS));
    const [deletedQuestions, setDeletedQuestions] = useState([]);
    const [audience, setAudience] = useState(defaultAudience);
    const [scheduling, setScheduling] = useState(scheduleDefaultValue);
    const [pulseTitle, setPulseTitle] = useState(eNPS ? 'eNPS' : 'Untitled Pulse');
    const [confirmModal, setConfirmModal] = useState({ open: false, heading: '' });
    const [eNPSQuestion, setENPSQuestion] = useState(getENPSQuestionList(dispatch));
    const [pulseAudienceData, setPulseAudienceData] = useState({});
    const stayDuration = useRef(0);
    const [isDraftPulse, setIsDraftPulse] = useState(isDraft);
    const AUTO_SAVE_DURATION = 5 * 60 * 1000;
    const timerRef = useRef(null);

    const [pulseSentData, setPulseSentData] = useState({
        title: 'Pulse sent successfully.',
        content: 'Your survey was sent to the audience.',
        buttonText: 'Go to dashboard',
    });
    const [questionWarn, setQuestionWarn] = useState({
        isInvalid: false,
        message: '',
    });

    const data = {
        heading: confirmModal.heading,
        confirm: 'Go to pulse homepage',
    };
    useEffect(() => {
        setENPSQuestion(getENPSQuestionList(dispatch, companyName));
    }, [companyName]);

    useEffect(() => {
        if (step > -1) {
            // browser back functionality handled here
            window.history.pushState(null, null, window.location.href);
            window.addEventListener('popstate', onClose);

            return () => window.removeEventListener('popstate', onClose);
        }
    }, [step]);

    useEffect(() => {
        if (eNPS) {
            toggleFullscreen(dispatch, true);
            setStep(0);
        }
        if (!duplicateId) {
            setScheduling({
                frequencyType: pulseData?.recurrenceType?.toLowerCase() || defaultFrequency,
                onDays: pulseData?.scheduleInfo?.repeatValue,
                onDate: isValid(new Date(pulseData?.startAt)) ? pulseData.startAt : new Date(),
                onTime: !(editId || draftId) ? time[0] : null,
                responseForDays: pulseData?.allowResponsesFor || 1,
                rollingPulse: pulseData?.subtype === PULSE_SUB_TYPE.ROLLING,
                remindersEnabled: pulseData?.reminder?.length > 0,
                reminderValue: pulseData?.reminder || [],
            });
        }
    }, [pulseData]);

    useEffect(() => {
        if (history.location.pathname.includes('/pulse/create')) {
            toggleFullscreen(dispatch, true);
            const data = queryString.parse(history.location.search);
            const tempPulseID = editId || duplicateId;
            if (data.list) {
                setStep(-1);
            } else if (tempPulseID && isFullscreen) {
                getPulseDetails(tempPulseID, editType, isOngoing);
            } else if (data.template && isFullscreen) {
                handleCreateFromTemplate(data);
            } else {
                sessionStorage.setItem(KEY_PULSE_AUDIENCE_DATA, null);
                isStepZeroVisited.current = true;
            }
        } else if (history.location.pathname.includes('/eNPS/create')) {
            if (editId && isFullscreen) {
                getPulseDetails(editId, editType, isOngoing);
            }
        }
    }, [history.location]);

    useEffect(() => {
        if (fetchLoading) {
            return;
        }
        if (step < 0 && isStepZeroVisited.current) {
            isStepZeroVisited.current = false;
        } else if (step > 0 && isStepZeroVisited.current) {
            if (
                validatePulse(
                    questions,
                    audience,
                    setOpenQuestion,
                    setQuestionWarn,
                    SetSnackbar,
                    true,
                    eNPS,
                    scheduling,
                    pulseAudienceData
                )
            ) {
                setQuestionWarn({
                    isInvalid: false,
                    message: '',
                });
            }
        } else if (step > 0) {
            isStepZeroVisited.current = true;
        }
    }, [step]);

    const startTimer = () => {
        timerRef.current = setInterval(() => {
            stayDuration.current = stayDuration.current + 1000;
        }, 1000);
    };

    const cleanupTimer = () => {
        clearInterval(timerRef.current);
    };

    useEffect(() => {
        startTimer();
        return cleanupTimer;
    }, []);

    useEffect(() => {
        // if it is new pulse or editing draft pulse, then only we auto-save it as draft
        const canAutoSave = !(
            pulseData?.status?.toLowerCase() === PULSE_STATUS.ACTIVE ||
            pulseData?.status?.toLowerCase() === PULSE_STATUS.SCHEDULED
        );
        if (canAutoSave && !loading && stayDuration.current >= AUTO_SAVE_DURATION) {
            createPulseData(true, true);
        }
    }, [stayDuration.current]);

    const time = timeInterval(pulseData?.startAt && !duplicateId ? new Date(pulseData.startAt) : new Date());

    /**
     * The function `setPulseScheduleData` sets the data for a successful pulse scheduling, including
     * the title, content, and button text.
     * @param date - The `date` parameter is a string representing the date and time of the pulse
     * schedule.
     */
    const setPulseScheduleData = (date) => {
        setQuesAdded(false);
        const schedule = new Date(date);
        const scheduleDateTime = `${format(schedule, 'MMMM dd, yyyy')} at ${format(schedule, 'h:mm a')} `;

        if (scheduling.frequencyType?.toLowerCase() !== PULSE_TYPE.ONE_TIME && !eNPS) {
            setPulseSentData({
                title: `Pulse ${getScheduleInfo().sendInstantly ? 'sent' : 'scheduled'} successfully.`,
                content: `First issue of the pulse will be sent to audience on ${scheduleDateTime} `,
                buttonText: 'Go to dashboard',
            });
        } else {
            setPulseSentData({
                title: `${eNPS ? 'eNPS' : 'Pulse'} ${
                    getScheduleInfo().sendInstantly ? 'sent' : 'scheduled'
                } successfully.`,
                content: `Your ${eNPS ? 'eNPS' : 'pulse'} will be sent to the audience on ${scheduleDateTime} `,
                buttonText: 'Go to dashboard',
            });
        }
        setPulseCreated(true);
    };

    const getPulseDetails = async (tempPulseID, editType, isOngoing) => {
        setFetchLoading(true);
        setPulseID(editId || draftId);
        try {
            const data = await getPulse({
                pulseId: tempPulseID,
                editType: editType,
                isOngoing: isOngoing,
            });
            setPulseData(data);
            setPulseTitle(`${duplicateId ? 'Copy of ' : ''}${data.name}`);
            setFetchLoading(false);
            setSavedDraftModal(false);
        } catch (err) {
            showSnackBarMessage(SetSnackbar, 'error', err?.message);
        }
    };

    const handleCreateFromTemplate = async (templateData) => {
        setFetchLoading(true);
        setPulseTitle(templateData.name);
        //  For quick create from templates
        setStep(templateData?.step ? parseInt(templateData.step) : 0);
        try {
            const data = await getPulseTemplateQuestions({ templateId: templateData.template });
            const questionsWithoutUnderscoreID = data.map(({ _id, ...question }) => ({
                ...question,
                id: _id,
            }));
            setPulseData({ questions: questionsWithoutUnderscoreID });
            setQuestions(mapApiToQuestionState(questionsWithoutUnderscoreID), () => {
                isStepZeroVisited.current = true;
            });
            setFetchLoading(false);
        } catch (err) {
            showSnackBarMessage(SetSnackbar, 'error', err?.message);
        }
    };

    const onConfirm = () => {
        setConfirmModal({ open: false });
        history.push('/dashboard/pulse/');
    };

    const onClose = () => {
        setQuesAdded(false);
        if (isDraft || pulseData?.status === PULSE_STATUS.DRAFT) {
            setIsDraftPulse(true);
            setDraftModal(true);
        } else if (!editId) {
            setDraftModal(true);
        } else {
            handleExit();
        }
    };

    const handleExit = () => {
        handleExitPulseCreation(pulseData?.status === PULSE_STATUS.DRAFT);
    };

    const handleDraft = () => {
        setDraftModal(false);
        createPulseData(true);
    };

    const handleExitPulseCreation = (draft) => {
        toggleFullscreen(dispatch, false);
        if (eNPS) {
            history.push(eNPS_ROUTE.HOME);
        } else if (draft) {
            history.push(`/dashboard/pulse/?tab=${PULSE_STATUS.DRAFT}`);
        } else {
            history.push('/dashboard/pulse');
        }
    };

    const handleContinue = () => {
        if (step < 2) {
            setStep(step + 1);
        }
        if (step + 1 === 3) {
            createPulseData(false);
        }
    };

    let ScheduledDateTime;
    if (scheduling?.onTime?.label === 'Send instantly') {
        ScheduledDateTime = new Date();
    } else if (scheduling?.onTime) {
        ScheduledDateTime = setDate(
            scheduling.onDate,
            scheduling?.onTime?.value - 1,
            scheduling?.frequencyType,
            scheduling.onDays
        );
    } else {
        ScheduledDateTime = pulseData?.startAt;
    }
    const getScheduleInfo = () => {
        return {
            schedule: true,
            remindersEnabled: scheduling.remindersEnabled,
            reminder: scheduling.reminderValue,
            rollingPulse: scheduling.rollingPulse,
            repeatValue: scheduling.onDays,
            allowedResponseDays: scheduling.responseForDays,
            recurring: scheduling?.frequencyType !== PULSE_TYPE.ONE_TIME,
            repeatFrequency: scheduling?.frequencyType,
            sendOn: ScheduledDateTime,
            sendInstantly: scheduling?.onTime?.label === 'Send instantly',
        };
    };

    //On editing the pulse and eNPS
    const patchPulseData = async (apiData, draft, autoSave) => {
        try {
            setLoading(true);
            await editPulse({ pulseID, apiData });
            setLoading(false);
            if (eNPS) {
                showSnackBarMessage(SetSnackbar, 'success', 'eNPS updated');
                history.push(eNPS_ROUTE.HOME);
            } else if (
                !eNPS &&
                pulseData?.status === PULSE_STATUS.SCHEDULED &&
                isBefore(new Date(pulseData?.startAt), new Date())
            ) {
                setConfirmModal({ open: true, heading: PULSE_STARTED, showIcon: true });
            } else {
                if (autoSave) {
                    showSnackBarMessage(SetSnackbar, 'success', 'Your pulse is auto-saved as draft');
                    stayDuration.current = 0;
                    return;
                }
                showSnackBarMessage(SetSnackbar, 'success', 'Pulse updated');
                if (draft) {
                    handleExitPulseCreation(true);
                } else {
                    setPulseScheduleData(apiData?.startAt);
                }
                history.push('/dashboard/pulse/');
            }
        } catch (err) {
            showSnackBarMessage(SetSnackbar, 'error', err?.message);
            setLoading(false);
        }
    };

    // On creating the pulse
    const postPulseData = async (apiData, draft, autoSave) => {
        try {
            setLoading(true);
            const data = await createPulse({ apiData });
            setLoading(false);
            // redux state update
            updateWorkspaceDetails(dispatch, { todo: { ...todo, isENPSFlowCompleted: true } });
            if (autoSave) {
                setPulseID(data.pulseId);
                showSnackBarMessage(SetSnackbar, 'success', 'Your pulse is auto-saved as draft');
                stayDuration.current = 0;
                return;
            }
            if (draft) {
                handleExitPulseCreation(draft);
            } else {
                setPulseScheduleData(apiData?.startAt);
            }
            showSnackBarMessage(SetSnackbar, 'success', draft ? 'Pulse saved as draft' : `${surveyType} created`);
        } catch (err) {
            setLoading(false);
            showSnackBarMessage(SetSnackbar, 'error', err?.message);
        }
    };

    const getMCQType = (multipleSelection) => (multipleSelection ? 'MULTIPLE_CHOICE' : 'SINGLE_CHOICE');

    const getPulseSubType = (scheduleInfo) => {
        if (scheduleInfo.rollingPulse) {
            return PULSE_SUB_TYPE.ROLLING;
        }
        return eNPS ? PULSE_SUB_TYPE.ENPS : PULSE_SUB_TYPE.STANDARD;
    };
    const addSecondsToCurrentDate = (seconds) => {
        let currentDate = new Date();
        currentDate.setSeconds(currentDate.getSeconds() + seconds);
        return currentDate;
    };

    const createPulseData = (draft, autoSave) => {
        if (pulseID && !draftOrScheduled && !draft) {
            if (pulseData?.type === PULSE_TYPE.RECURRING && isBefore(new Date(pulseData?.nextLaunchAt), new Date())) {
                setConfirmModal({ open: true, heading: NEXT_PULSE_STARTED, showIcon: true });
                return;
            } else if (!eNPS && isBefore(new Date(pulseData?.endAt), new Date())) {
                setConfirmModal({ open: true, heading: PULSE_ENDED, showIcon: true });
                return;
            }
        }
        const questionsList = questions.map((question) => ({
            title: question.question.name,
            type: question.questionType === 'MCQ' ? getMCQType(question.multipleSelection) : question.questionType,
            required: question.required,
            ...(question.questionType === 'MCQ' && { options: question.options.map((option) => option.name) }),
            ...(question._id && !duplicateId && { _id: question._id }),
        }));
        const [channelListData, userListData, userGroupData] = getRequestedListData(audience.userList);

        const userExcludedListData = audience.excludedUsers.map((user) => ({
            _id: user.id,
            memberID: user.memberID,
            userName: user.name,
        }));
        const scheduleInfo = getScheduleInfo();
        let formattedQuestions = [];
        if (eNPS) {
            formattedQuestions = editId
                ? [...eNPSQuestion, ...questionsList]
                : [...formattedEnpsAPIData(eNPSQuestion), ...questionsList];
        } else {
            formattedQuestions = [...questionsList, ...deletedQuestions];
        }
        const apiData = {
            ...{ name: pulseTitle },
            type: scheduleInfo.recurring ? PULSE_TYPE.RECURRING : PULSE_TYPE.ONE_TIME,
            subtype: getPulseSubType(scheduleInfo),
            ...(scheduleInfo?.recurring &&
                !scheduleInfo.rollingPulse && {
                    recurrenceType: capitalizeFirstLetter(scheduleInfo.repeatFrequency),
                }),
            questions: formattedQuestions,
            ...(channelListData.length > 0 && { channelList: channelListData }),
            ...(userGroupData.length > 0 && { userGroupList: userGroupData }),
            ...(userListData.length > 0 && { userList: userListData }),
            ...(userExcludedListData.length > 0 && { excludedUsers: userExcludedListData }),
            ...(eNPS && { enpsWorkspace: companyName }),
            ...{ anonymous: audience.anonymous },
            ...{
                startAt: scheduleInfo.sendInstantly ? addSecondsToCurrentDate(5) : scheduleInfo.sendOn,
                allowResponsesFor: scheduleInfo.allowedResponseDays,
            },
            ...(getScheduleInfo().sendInstantly && { sendInstantly: getScheduleInfo().sendInstantly }),
            ...(scheduleInfo.remindersEnabled && scheduleInfo?.reminder?.length && { reminder: scheduleInfo.reminder }),
            ...{ editType: editType },
            finalSubmit: !draft,
        };

        if (
            !draft &&
            !validatePulse(
                questions,
                audience,
                setOpenQuestion,
                setQuestionWarn,
                SetSnackbar,
                false,
                eNPS,
                scheduling.frequencyType,
                pulseAudienceData
            )
        ) {
            return;
        }

        if (pulseID) {
            patchPulseData(apiData, draft, autoSave);
        } else {
            postPulseData(apiData, draft, autoSave);
        }
    };

    const isEditingOnetime =
        pulseData?.status !== PULSE_STATUS.DRAFT &&
        !duplicateId &&
        pulseData?.type === PULSE_TYPE.ONE_TIME &&
        (pulseData?.subtype === PULSE_SUB_TYPE.STANDARD || pulseData?.subtype === PULSE_SUB_TYPE.ENPS) &&
        new Date(pulseData?.startAt) < new Date();
    const isEditingRolling =
        pulseData?.status !== PULSE_STATUS.DRAFT &&
        !duplicateId &&
        pulseData?.type === PULSE_TYPE.ONE_TIME &&
        pulseData?.subtype === PULSE_SUB_TYPE.ROLLING &&
        new Date(pulseData?.startAt) < new Date();
    const isEditingRecurring =
        pulseData?.status !== PULSE_STATUS.DRAFT && !duplicateId && pulseData?.type === PULSE_TYPE.RECURRING;

    const handlePulseCreatedModalSuccess = () => {
        let route;
        if (eNPS) {
            route = '/dashboard/eNPS';
        } else {
            const sendInstantly = getScheduleInfo().sendInstantly;
            route = sendInstantly
                ? `/dashboard/pulse/?tab=${PULSE_STATUS.ACTIVE}`
                : `/dashboard/pulse/?tab=${PULSE_STATUS.SCHEDULED}`;
        }
        history.push(route);
    };

    return (
        <div className='pulse-create-container'>
            {step > -1 && (
                <Header
                    eNPS={eNPS}
                    step={step}
                    setStep={setStep}
                    handleContinue={handleContinue}
                    history={history}
                    pulse={pulseData}
                    pulseTitle={pulseTitle}
                    setPulseTitle={setPulseTitle}
                    onClose={onClose}
                    loading={loading}
                    fetchLoading={fetchLoading}
                    questionWarn={questionWarn}
                    isEditingOnetime={isEditingOnetime}
                    isEditingRolling={isEditingRolling}
                    isEditingRecurring={isEditingRecurring}
                    editType={isRecurringStopped ? EDIT_TYPE.ONGOING : editType}
                />
            )}
            <div className='y-scroll pulse-create-data-container'>
                {fetchLoading && <CustomLoader />}
                {!fetchLoading && (
                    <>
                        {step === -1 && <Template history={history} />}
                        {step === 0 && (
                            <CreateQuestion
                                quesAdded={quesAdded}
                                pulseData={pulseData}
                                questions={questions}
                                eNPSQuestion={eNPSQuestion}
                                setENPSQuestion={setENPSQuestion}
                                setQuestions={setQuestions}
                                eNPS={eNPS}
                                editId={editId}
                                draftId={draftId}
                                questionWarn={questionWarn}
                                setQuestionWarn={setQuestionWarn}
                                openQuestion={openQuestion}
                                setOpenQuestion={setOpenQuestion}
                                isEditingOnetime={isEditingOnetime}
                                isEditingRolling={isEditingRolling}
                                isEditingRecurring={isEditingRecurring}
                                editType={isRecurringStopped ? EDIT_TYPE.ONGOING : editType}
                                setDeletedQuestions={setDeletedQuestions}
                            />
                        )}
                        {step === 1 && (
                            <Scheduling
                                setQuesAdded={setQuesAdded}
                                eNPS={eNPS}
                                history={history}
                                pulseID={pulseID}
                                pulseData={pulseData}
                                setStep={setStep}
                                scheduling={scheduling}
                                setScheduling={setScheduling}
                                questions={questions}
                                getScheduleInfo={getScheduleInfo}
                                duplicateId={duplicateId}
                                editId={editId}
                                isEditingOnetime={isEditingOnetime}
                                isEditingRolling={isEditingRolling}
                                isEditingRecurring={isEditingRecurring}
                                editType={isRecurringStopped ? EDIT_TYPE.ONGOING : editType}
                                pulseAudienceData={pulseAudienceData}
                            />
                        )}
                        {step === 2 && (
                            <Audience
                                setQuesAdded={setQuesAdded}
                                eNPS={eNPS}
                                pulseData={pulseData}
                                audience={audience}
                                setAudience={setAudience}
                                questions={questions}
                                setStep={setStep}
                                getScheduleInfo={getScheduleInfo}
                                isEditingOnetime={isEditingOnetime}
                                isEditingRolling={isEditingRolling}
                                isEditingRecurring={isEditingRecurring}
                                editType={isRecurringStopped ? EDIT_TYPE.ONGOING : editType}
                                scheduling={scheduling}
                                pulseAudienceData={pulseAudienceData}
                                setPulseAudienceData={setPulseAudienceData}
                            />
                        )}
                    </>
                )}
            </div>
            <SaveAsDraftModal
                open={draftModal}
                setOpen={setDraftModal}
                handleDraft={handleDraft}
                isDraftPulse={isDraftPulse}
                handleExit={handleExit}
            />
            {eNPS && draftId && (
                <UseSavedAsDraftModal
                    data={useSavedAsDraftModalData}
                    open={savedDraftModal}
                    setOpen={setSavedDraftModal}
                    handleDraft={() => getPulseDetails(draftId)}
                    handleExit={() => {
                        setSavedDraftModal(false);
                    }}
                />
            )}
            {pulseCreated && (
                <PulseCreatedModal
                    open={pulseCreated}
                    data={pulseSentData}
                    onConfirm={handlePulseCreatedModalSuccess}
                    onClose={() => setPulseCreated(false)}
                    showCloseButtton={false}
                />
            )}
            <ConfirmModal
                open={confirmModal.open}
                onClose={() => setConfirmModal({ open: false })}
                onConfirm={onConfirm}
                data={data}
                warningIcon={true}
                singleButton={true}
                showIcon={confirmModal.showIcon}
            />
        </div>
    );
};

const mapStateToProps = (state) => ({
    todo: state.Workspace.todo,
    companyName: state.Workspace.companyName,
    isFullscreen: state.LayoutSettings.isFullscreen,
});

Index.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func,
        location: PropTypes.object,
    }),
    eNPS: PropTypes.bool,
};
export default Index;
