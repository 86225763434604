import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import './style.scss';

const Index = ({ horizontal = false, dividerText = 'OR' }) => {
    const type = horizontal ? 'horizontal' : 'vertical';

    return (
        <div className={clsx('container-divider', type)}>
            <div className={clsx('divider-large', type)} />
            <p className='divider-text'>{dividerText}</p>
            <div className={clsx('divider-large', type)} />
        </div>
    );
};

Index.propTypes = {
    horizontal: PropTypes.bool,
    dividerText: PropTypes.string,
};

export default Index;
