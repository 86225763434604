import React, { useState, useEffect } from 'react';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useMutation } from 'react-query';
import { updateWorkspaceDetails } from 'redux/workspace/actions';
import WelcomeBanner from '../../../Components/WelcomeBanner';
import InputField from 'components/ReusableComponents/InputField';
import CustomFilterDropdown from 'components/ReusableComponents/CustomFilterDropdown';
import { getTimeZoneList } from 'utils/timezoneUtil';
import { showSnackBarMessage } from 'utils/HelperFunctions';
import { configureGlobalSettings, registerOnboardingModules } from 'Services/apiFunctions';
import { useToaster } from 'Context/SnackbarContext';
import EWButton from 'components/ReusableComponents/EWButton';
import { UPDATE_TYPE, NUMBER_OF_EMPLOYEES, ROLES_IN_ORGANISATION } from 'constants.js';
import { getCountries } from 'utils/countryUtils';
import { moduleItems } from '../../ModuleSelection/constants';
import { find } from 'lodash';
import jwt_decode from 'jwt-decode';
import Cookies from 'js-cookie';
import { userLogout } from 'utils/SessionUtils/sessionUtils';
import { setter } from 'utils/CacheManager';
import { ROUTES_ONBOARDING } from '../../../constants';
import './style.scss';

const countryOptions = getCountries(false);

const Index = ({ history }) => {
    const dispatch = useDispatch();
    const { SetSnackbar } = useToaster();
    const location = useLocation();
    const tokenData = queryString.parse(location?.search);

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [teamName, setTeamName] = useState('');
    const [phone, setPhone] = useState('');
    const [employeeCount, setEmployeeCount] = useState('');
    const [role, setRole] = useState('');

    // country + timezone states
    const [country, setCountry] = useState('');
    const [timezoneList, setTimezoneList] = useState([]);
    const [timezone, setTimezone] = useState('');

    // error state
    const [loading, setLoading] = useState(false);

    const { mutate: configureSettings } = useMutation(configureGlobalSettings, {
        onSuccess: () => {
            updateWorkspaceDetails(dispatch, {
                workspaceCountry: find(getCountries(), (item) => item.country === country?.country),
            });
            registerModules([moduleItems[0].moduleKey]);
        },
        onError: (error) => {
            showSnackBarMessage(SetSnackbar, 'error', error?.message);
            setLoading(false);
        },
    });

    const { mutate: registerModules } = useMutation(registerOnboardingModules, {
        onSuccess: () => history.replace(ROUTES_ONBOARDING.RNR_INTRO),
        onError: (error) => showSnackBarMessage(SetSnackbar, 'error', error.message),
    });

    const handleSubmit = () => {
        const bodyData = {
            userName: `${firstName.trim()} ${lastName.trim()}`,
            teamName: teamName.trim(),
            phoneNumber: phone.trim(),
            country: country.country,
            timezone: timezone?.label,
            employeeCount: employeeCount,
            ...(role && { role }),
        };
        setLoading(true);
        configureSettings(bodyData);
    };

    const handleChange = (type, value) => {
        switch (type) {
            case UPDATE_TYPE.FIRST_NAME:
                setFirstName(value);
                break;
            case UPDATE_TYPE.LAST_NAME:
                setLastName(value);
                break;
            case UPDATE_TYPE.WORKSPACE:
                setTeamName(value);
                break;
            case UPDATE_TYPE.PHONE:
                setPhone(!value || isNaN(value) ? '' : value);
                break;
            case UPDATE_TYPE.COUNTRY:
                setCountry(value);
                setTimezone({ label: '', value: '' });
                break;
            case UPDATE_TYPE.TIMEZONE:
                setTimezone(value);
                break;
            case UPDATE_TYPE.EMPLOYEE_COUNT:
                setEmployeeCount(value);
                break;
            case UPDATE_TYPE.ROLE:
                setRole(value);
                break;
            default:
                break;
        }
    };

    useEffect(() => {
        let userToken = tokenData?.token;
        if (userToken) {
            let decode;
            try {
                decode = jwt_decode(userToken);
                Cookies.set('AuthToken', userToken, { expires: 30, path: '/' });
                const user = {
                    team: decode['team-id'],
                    adminId: decode['admin-id'],
                    platform: decode['platform'],
                };
                setter('user', user);
                updateWorkspaceDetails(dispatch, { email: decode['email'], platform: decode.platform });
            } catch (e) {
                userLogout();
                return null;
            }
        }
    }, []);

    useEffect(() => {
        if (country?.value) {
            let timezoneList = getTimeZoneList(country?.value);
            if (timezoneList.length === 1) {
                setTimezone(timezoneList[0]);
            }
            setTimezoneList(timezoneList);
        }
    }, [country]);

    const validate = () => {
        return (
            firstName?.trim().length > 0 &&
            lastName?.trim().length > 0 &&
            teamName?.trim().length > 0 &&
            phone?.trim().length >= 7 &&
            phone?.trim().length <= 15 &&
            country &&
            timezone &&
            employeeCount
        );
    };

    const disabled = !validate();

    return (
        <div className='d-flex height-100-vh bg-white'>
            <WelcomeBanner />
            <div className='module-right-container'>
                <p className='title'>Setup your organization account</p>
                <p className='subtitle'>Help us with some general information to start with </p>

                <div className='details-container'>
                    <InputField
                        inputID='firstName'
                        label='First Name '
                        labelClass='input-label'
                        placeholder='Enter First Name'
                        inputType='text'
                        inputClass='mr-3'
                        width='280px'
                        isRequired
                        value={firstName}
                        handleChange={(_id, value) => handleChange(UPDATE_TYPE.FIRST_NAME, value)}
                    />
                    <InputField
                        inputID='lastName'
                        label='Last Name '
                        labelClass='input-label'
                        placeholder='Enter Last Name'
                        inputType='text'
                        width='280px'
                        isRequired
                        value={lastName}
                        handleChange={(_id, value) => handleChange(UPDATE_TYPE.LAST_NAME, value)}
                    />
                </div>
                <div className='details-container'>
                    <InputField
                        inputID='companyName'
                        label='Company Name '
                        placeholder='Enter Company Name'
                        labelClass='input-label'
                        width='280px'
                        inputType='text'
                        inputClass='mr-3'
                        value={teamName}
                        isRequired
                        handleChange={(_id, value) => handleChange(UPDATE_TYPE.WORKSPACE, value)}
                    />
                    <InputField
                        inputID='phoneNumber'
                        label='Phone Number '
                        placeholder='Enter Phone Number'
                        labelClass='input-label'
                        width='280px'
                        inputType='text'
                        value={phone}
                        isRequired
                        handleChange={(_id, value) => handleChange(UPDATE_TYPE.PHONE, value)}
                    />
                </div>
                <div className='details-container'>
                    <CustomFilterDropdown
                        search
                        title='Select Country '
                        titleStyleClass='title-dropdown'
                        filterOptions={countryOptions}
                        selectedName={country?.label || 'Select Country'}
                        optionsSelected
                        handleSelection={(index) => handleChange(UPDATE_TYPE.COUNTRY, countryOptions[index])}
                        buttonStyleClass='width-dropdown'
                        dropDownID='Country'
                        info='Employees added to EngageWith will be assigned this country by default.'
                        singleSelect
                        isRequired
                    />
                    <CustomFilterDropdown
                        title='TIME ZONE '
                        titleStyleClass='title-dropdown'
                        filterOptions={timezoneList}
                        selectedName={timezone?.label || 'Select Timezone'}
                        optionsSelected
                        handleSelection={(index) =>
                            handleChange(UPDATE_TYPE.TIMEZONE, getTimeZoneList(country?.value)[index])
                        }
                        buttonStyleClass='width-dropdown'
                        dropDownID='Timezone'
                        singleSelect
                        info='Communications from EngageWith will be scheduled based on this time zone'
                        isRequired
                    />
                </div>
                <div className='details-container'>
                    <CustomFilterDropdown
                        title='Number of Employees '
                        filterOptions={NUMBER_OF_EMPLOYEES}
                        selectedName={employeeCount || 'Choose no. of employees'}
                        optionsSelected
                        titleStyleClass='title-dropdown'
                        buttonStyleClass='width-dropdown'
                        dropDownID='NumberOfEmployees'
                        singleSelect
                        isRequired
                        handleSelection={(index) =>
                            handleChange(UPDATE_TYPE.EMPLOYEE_COUNT, NUMBER_OF_EMPLOYEES[index])
                        }
                    />
                    <CustomFilterDropdown
                        title='Your role in organisation '
                        filterOptions={ROLES_IN_ORGANISATION}
                        selectedName={role || 'Choose role'}
                        optionsSelected
                        titleStyleClass='title-dropdown'
                        buttonStyleClass='width-dropdown'
                        dropDownID='RoleInOrganisation'
                        singleSelect
                        handleSelection={(index) => handleChange(UPDATE_TYPE.ROLE, ROLES_IN_ORGANISATION[index])}
                    />
                </div>
                <EWButton
                    buttonStyleClass='proceed-button'
                    buttonText='Proceed'
                    width='320px'
                    onClick={handleSubmit}
                    loading={loading}
                    disabled={loading || disabled}
                />
            </div>
        </div>
    );
};

Index.propTypes = {
    history: PropTypes.object,
};

export default Index;
