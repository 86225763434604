import React from 'react';
import './style.scss';

const Index = () => {
    return (
        <div className='horizontal-divider' data-testid='horizontal-divider'>
            <div className='horizontal-divider-left' data-testid='horizontal-divider-left' />
            <span className='horizontal-divider-content'>OR</span>
            <div className='horizontal-divider-right' data-testid='horizontal-divider-right' />
        </div>
    );
};

export default Index;
