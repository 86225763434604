import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Table from 'components/ReusableComponents/Table';
import CustomFilterDropdown from 'components/ReusableComponents/CustomFilterDropdown';
import { format } from 'date-fns';
import { dateRanges } from 'constants.js';
import CustomSearchBox from 'components/Styles/CustomSearchBox';
import { AWARD_TABLE_PROPERTIES, AWARD_TYPE_LIST } from './constants';
import { getAllActivityAwards } from '../../../EmployeeAwards/Services/APIFunctions';
import { GET_EMPLOYEE_AWARDS_LIST } from 'Services/apiKeys';
import { showSnackBarMessage } from 'utils/HelperFunctions';
import { useToaster } from 'Context/SnackbarContext';
import { getSortHandler, rowFormatter } from './componentUtil';
import { debounce } from 'lodash';
import CustomDateRangeModal from 'components/Modals/CustomDateRangeModal';
import { dateRangeHandler } from '../../../EmployeeAwards/componentUtils';
import { useQuery } from 'react-query';

const Index = ({ history }) => {
    const [total, setTotal] = useState(0);
    const [pageNumber, setPageNumber] = useState(1);
    const [searchTerm, setSearchTerm] = useState('');
    const [showDatePicker, setShowDatePicker] = useState(false);
    const [selectedDateRange, setSelectedDateRange] = useState();
    const [selectedDateRangeDisplayLabel, setSelectedDateRangeDisplayLabel] = useState();
    const [awardType, setAwardType] = useState(AWARD_TYPE_LIST[0].value);
    const [rowData, setRowData] = useState([]);
    const [sort, setSort] = useState({ key: undefined, direction: undefined });
    const sortHandler = getSortHandler(sort, setSort, AWARD_TABLE_PROPERTIES);
    const handleSearchChange = debounce((value) => setSearchTerm(value), 300);
    const searchHandler = (event) => {
        handleSearchChange(event.target.value);
        setPageNumber(1);
    };
    const dateRangeSelectionHandler = (index) => {
        dateRangeHandler({
            index,
            setSelectedDateRangeDisplayLabel,
            setShowDatePicker,
            setSelectedDateRange,
        });
        setPageNumber(1);
    };
    const onCloseDateModalHandler = () => setShowDatePicker(false);
    const onConfirmDateModalHandler = (selectedDate) => {
        setShowDatePicker(false);
        setSelectedDateRange({
            start: format(selectedDate.startDate, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"),
            end: format(selectedDate.endDate, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"),
        });
        setPageNumber(1);
    };
    const paginationProps = {
        fixedBottom: true,
        currentPage: pageNumber,
        totalCount: total,
        setCurrentPage: setPageNumber,
    };
    const { SetSnackbar } = useToaster();
    const onError = (err) => showSnackBarMessage(SetSnackbar, 'error', err.message);
    const onSuccess = (data) => {
        const { awards, total } = data;
        setTotal(total);
        setRowData(awards.map(rowFormatter(history)));
    };

    const { isLoading, isFetching } = useQuery(
        [GET_EMPLOYEE_AWARDS_LIST, pageNumber, sort.key, sort.direction, selectedDateRange, searchTerm, awardType],
        getAllActivityAwards,
        {
            onSuccess,
            onError,
        }
    );

    return (
        <div className='ac-main-container'>
            <div className='ac-filter-container'>
                <div className='d-flex'>
                    <CustomFilterDropdown
                        title='Date Range'
                        filterOptions={dateRanges}
                        selectedName={selectedDateRangeDisplayLabel || 'All'}
                        optionsSelected={selectedDateRangeDisplayLabel !== 'Select'}
                        handleSelection={dateRangeSelectionHandler}
                        dropDownID='awardDateDropdown'
                        buttonStyleClass='width-188'
                        singleSelect
                    />
                    <CustomDateRangeModal
                        open={showDatePicker}
                        onClose={onCloseDateModalHandler}
                        onConfirm={onConfirmDateModalHandler}
                    />
                    <CustomFilterDropdown
                        singleSelect
                        title='Award Type'
                        filterOptions={AWARD_TYPE_LIST}
                        selectedName={awardType || 'All'}
                        optionsSelected={awardType !== 'All'}
                        handleSelection={(index) => setAwardType(AWARD_TYPE_LIST[index].value)}
                        dropDownID='awardDropdown'
                        buttonStyleClass='width-188'
                    />
                </div>

                <div className='mt-3'>
                    <CustomSearchBox
                        width={'320px'}
                        handleSearch={searchHandler}
                        placeholder={'Search by award name'}
                    />
                </div>
            </div>

            <div className='ac-table-container'>
                <Table
                    header={AWARD_TABLE_PROPERTIES.HEADERS}
                    noSortColumns={AWARD_TABLE_PROPERTIES.NO_SORT}
                    columnWidth={AWARD_TABLE_PROPERTIES.COLUMN_WIDTH}
                    data={rowData}
                    paginationProps={paginationProps}
                    loading={isLoading || isFetching}
                    noShadow={true}
                    handleSort={sortHandler}
                />
            </div>
        </div>
    );
};

Index.propTypes = {
    history: PropTypes.object,
};

export default Index;
