import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { getData } from 'Services/apiCall';
import { debounce, filter, find, uniqBy } from 'lodash';
import CustomAutoComplete from 'components/ReusableComponents/CustomAutoComplete';
import queryString from 'query-string';

const DEFAULT_SEARCH_TEXT = '^.*$';

const SearchUser = ({
    autoCompleteId,
    width,
    onChange,
    filterChannels,
    selectedData,
    placeholder,
    listBoxProps,
    selectedOptions,
    maxSelections,
    multiple,
}) => {
    const [open, setOpen] = useState(false);
    const [options, setOptions] = useState([]);
    const [loading, setLoading] = useState(false);
    const page = useRef(1);
    const [hasMore, setHasMore] = useState(false);
    const [inputValue, setInputValue] = useState('');

    const getUsers = debounce((pageNumber, setLoading, setOptions, searchValue, filterChannels, selectedData) => {
        if (!searchValue || searchValue.trim().length <= 0) {
            return;
        }
        setLoading(true);
        let selectedChannels = [];
        let selectedGroups = [];
        if (filterChannels) {
            selectedChannels = filter(selectedData, (item) => item.channel).map((item) => item.channelID);
            selectedGroups = filter(selectedData, (item) => item.groupID).map((item) => item.groupID);
        }

        const queryParams = {
            skip: (pageNumber - 1) * 10,
            limit: 10,
            ...(searchValue && searchValue.trim().length > 0 && { name: searchValue.trim() }),
            ...(selectedChannels.length > 0 && { channels: selectedChannels.join() }),
            ...(selectedGroups.length > 0 && { userGroups: selectedGroups.join() }),
        };

        const queryStringParams = queryString.stringify(queryParams);
        let url = `users/search-user-channel-group?${queryStringParams}`;
        getData(url)
            .then((res) => {
                const response = res.data;
                let combinedOptions = response.users.data.map((user) => ({
                    id: user._id,
                    memberID: user.memberID,
                    name: user.userName,
                    picUrl: user.pictureURL || require('Assets/images/defaultUser.png'),
                    channel: false,
                    isEditingOnetime: false,
                }));

                if (filterChannels) {
                    // filter already selected users from excluded options
                    combinedOptions = combinedOptions.filter((value) => !find(selectedData, value));
                } else {
                    const channels = response.channels.map((user) => ({
                        id: user._id,
                        channelID: user.channelID,
                        channelName: user.channelName,
                        name: user.channelName,
                        picUrl: '',
                        channel: true,
                        memberCount: 0,
                        isEditingOnetime: false,
                    }));
                    const userGroups = response.userGroups.map((userGroup) => ({
                        id: userGroup._id,
                        groupID: userGroup.groupID,
                        name: userGroup.groupName,
                        picUrl: '',
                        memberCount: 0,
                        isEditingOnetime: false,
                    }));
                    combinedOptions = [...channels, ...combinedOptions, ...userGroups];
                }
                const allUsers = uniqBy([...options, ...combinedOptions], 'id');
                allUsers.sort((a, b) => (a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1));
                setOptions(allUsers);
                setHasMore(response.users.hasMore);
                setLoading(false);
            })
            .catch(() => {
                setLoading(false);
            });
    }, 500);

    const onChangeValue = (value) => {
        onChange(value);
    };

    const handleInputChange = useCallback(
        debounce(async (newInputValue) => {
            getUsersList(1, newInputValue.trim().length > 0 ? newInputValue : DEFAULT_SEARCH_TEXT);
        }, 300),
        []
    );

    const loadMoreOptions = () => {
        if (hasMore) {
            const tempPage = page.current + 1;
            getUsersList(tempPage, inputValue || DEFAULT_SEARCH_TEXT);
        }
    };

    const getUsersList = (pageNumber, value) => {
        page.current = pageNumber;
        setLoading(true);
        getUsers(page.current, setLoading, setOptions, value, filterChannels, selectedData);
    };

    useEffect(() => {
        if (open) {
            getUsersList(1, DEFAULT_SEARCH_TEXT);
        }
    }, [open]);

    return (
        <div>
            <CustomAutoComplete
                autoCompleteId={autoCompleteId}
                open={open}
                setOpen={setOpen}
                loading={loading}
                width={width}
                options={options}
                onChange={onChangeValue}
                placeholder={placeholder}
                listBoxProps={listBoxProps}
                selectedOptions={selectedOptions}
                maxSelections={maxSelections}
                multiple={multiple}
                loadMoreOptions={loadMoreOptions}
                handleInputChange={handleInputChange}
                inputValue={inputValue}
                setInputValue={setInputValue}
            />
        </div>
    );
};

SearchUser.propTypes = {
    autoCompleteId: PropTypes.string,
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    onChange: PropTypes.func,
    filterChannels: PropTypes.bool,
    selectedData: PropTypes.array,
    placeholder: PropTypes.string,
    listBoxProps: PropTypes.object,
    selectedOptions: PropTypes.array,
    maxSelections: PropTypes.number,
    multiple: PropTypes.bool,
};

export default SearchUser;
