import { GET_ADMIN_LIST, GET_USERS_DATA } from 'Services/apiKeys';
import { useMutation, useQueryClient } from 'react-query';
import { useToaster } from 'Context/SnackbarContext';
import { modifyAdmins } from 'Services/apiFunctions';
import OrgModal from 'components/Modals/OrgModal';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { showSnackBarMessage } from 'utils/HelperFunctions';

const Index = ({ open, setOpen, initialAdminCount }) => {
    const [selectedList, setSelectedList] = useState({});

    const { SetSnackbar } = useToaster();
    const queryClient = useQueryClient();

    const onSuccess = () => {
        showSnackBarMessage(SetSnackbar, 'success', 'Updated successfully.');
        queryClient.setQueryData([GET_ADMIN_LIST], (oldData) => {
            const tempOldData = { ...oldData };
            Object.keys(selectedList).forEach((selectedKey) => {
                tempOldData.data.push({
                    pictureURL: selectedList[selectedKey].pictureURL,
                    memberID: selectedList[selectedKey].memberID,
                    userName: selectedList[selectedKey].userName,
                    _id: selectedList[selectedKey]._id,
                });
            });
            return tempOldData;
        });
        queryClient.invalidateQueries(GET_USERS_DATA);
        setOpen(false);
    };

    const { mutate: addAdmin, isLoading } = useMutation(modifyAdmins, {
        onSuccess,
        onError: (err) => showSnackBarMessage(SetSnackbar, 'error', err?.message),
    });

    const addAdminData = {
        heading: 'Add Admin',
        buttonLeft: 'Close',
        buttonRight: 'Add',
        setOpenForClose: true,
        actionNote: 'Select Members to add them as admin',
        handleClick: (member) => {
            const id = member._id;
            const tempList = { ...selectedList };
            if (tempList[id]) {
                delete tempList[id];
            } else if (initialAdminCount + selectedList.length > 9) {
                showSnackBarMessage(SetSnackbar, 'warning', 'A maximum of 10 admins allowed');
            } else {
                tempList[id] = member;
            }
            setSelectedList(tempList);
        },
        handleLeftButton: () => setOpen(false),
        handleRightButton: async () => {
            if (Object.keys(selectedList).length < 1) {
                showSnackBarMessage(SetSnackbar, 'warning', 'User must be present');
                return;
            } else if (Object.keys(selectedList).length + initialAdminCount > 10) {
                showSnackBarMessage(SetSnackbar, 'warning', 'There can be a maximum of 10 admins.');
                return;
            }
            const apiData = {
                userId: Object.keys(selectedList),
                markAdmin: true,
            };
            addAdmin({ apiData });
        },
    };

    return (
        <OrgModal
            open={open}
            setOpen={setOpen}
            loading={isLoading}
            skipAdmin
            isActive
            selectedList={selectedList}
            {...addAdminData}
        />
    );
};

Index.propTypes = {
    open: PropTypes.bool,
    setOpen: PropTypes.func,
    initialAdminCount: PropTypes.number,
};

export default Index;
