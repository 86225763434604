import React, { useState, useEffect } from 'react';
import { CustomSwitch } from 'components';
import './style.scss';
import PropTypes from 'prop-types';
import EWButton from 'components/ReusableComponents/EWButton';
import { useMutation, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import { GET_REWARDS_DATA } from 'Services/apiKeys';
import { redemptionSubHeading, pointsConversionSubHeading } from '../constants';
import PointsConversionModal from '../../Recognitions/Configuration/RecognitionRedemptionConfiguration/EnableRewards/PointsConversionModal';
import { updateOrganisationWideRedemption } from 'Services/apiFunctions';
import { showSnackBarMessage } from 'utils/HelperFunctions';
import { useToaster } from 'Context/SnackbarContext';
import { updateWorkspaceDetails } from 'redux/workspace/actions';

const Index = ({ history, conversions, config, enableRedemptions, setEnableRedemptions, nameForPoints }) => {
    const dispatch = useDispatch();
    const queryClient = useQueryClient();
    const [openRewardsModal, setOpenRewardsModal] = useState(false);
    const [conversionModalData, setConversionModalData] = useState([]);
    const { SetSnackbar } = useToaster();

    const { mutateAsync: handleModalSave } = useMutation(updateOrganisationWideRedemption);

    const setModalData = (data) => {
        const result = data.map((item) => ({
            modified: false,
            originalValue: item.value,
            originalStatus: item.redemptionEnabled,
            ...item,
        }));
        setConversionModalData(result);
    };

    useEffect(() => {
        if (conversions?.length) {
            setModalData(conversions);
        }
    }, [conversions]);

    const handleEditOrgWideRedemption =
        ({ enableRedemptions, setEnableRedemptions }) =>
        async () => {
            try {
                const apiData = { redemption: !enableRedemptions };
                setEnableRedemptions(!enableRedemptions);
                updateWorkspaceDetails(dispatch, { config: { ...config, redemption: !enableRedemptions } });
                await handleModalSave({ apiData });
                queryClient.invalidateQueries(GET_REWARDS_DATA);
            } catch (error) {
                showSnackBarMessage(SetSnackbar, 'error', error?.message);
                setEnableRedemptions(enableRedemptions);
                updateWorkspaceDetails(dispatch, { config: { ...config, redemption: enableRedemptions } });
            }
        };

    return (
        <div className='enable-redemptions-config-container'>
            <div className='d-flex justify-content-between align-item-center separator'>
                <div className='d-flex flex-column'>
                    <h3 className='header-redemption'>Workspace wide redemption</h3>
                    <span className='sub-header-redemption'>{redemptionSubHeading}</span>
                </div>
                <CustomSwitch
                    checked={enableRedemptions}
                    onClick={handleEditOrgWideRedemption({
                        enableRedemptions,
                        setEnableRedemptions,
                    })}
                    name='editRedemption'
                />
            </div>
            <div>
                <div className='d-flex flex-column'>
                    <h3 className='header-redemption'>Recognitions Points Conversion Rate</h3>
                    <span className='sub-header-redemption'>{pointsConversionSubHeading}</span>
                </div>
                <EWButton
                    buttonText={'Manage points conversion rate'}
                    primary={false}
                    plainTextButton
                    onClick={() => {
                        setOpenRewardsModal(true);
                    }}
                />
            </div>

            <PointsConversionModal
                open={openRewardsModal}
                onClose={() => {
                    setOpenRewardsModal(false);
                    setModalData(conversions);
                }}
                setOpenRewardsModal={setOpenRewardsModal}
                config={config}
                conversionModalData={conversionModalData}
                setConversionModalData={setConversionModalData}
                nameForPoints={nameForPoints}
                history={history}
            />
        </div>
    );
};
Index.propTypes = {
    history: PropTypes.object,
    enableRedemptions: PropTypes.bool,
    setEnableRedemptions: PropTypes.func,
    conversions: PropTypes.array,
    config: PropTypes.object,
    nameForPoints: PropTypes.string,
};
export default Index;
