import React from 'react';
import { CustomSwitch } from 'components';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import './style.scss';

const Index = ({ index, option, toggleNotification, customClassName, showDivider = false }) => {
    return (
        <div className={clsx({ [customClassName]: !!customClassName })} style={{ backgroundColor: '#FFFFFF' }}>
            <div className='notification-op-container'>
                <div className='notification-heading-container'>
                    <div className='notification-new-badge'>
                        {option?.heading}
                        {option?.isNew && <div className='badge'>New</div>}
                    </div>
                    <p>{option?.subtext}</p>
                </div>
                <div className='notification-switch-container'>
                    <CustomSwitch
                        checked={!!option?.isEnabled}
                        onClick={() => toggleNotification(index)}
                        name={`notifcheck${option?.heading}`}
                    />
                </div>
            </div>
            {showDivider && <div className='notification-config-divider' />}
        </div>
    );
};

Index.propTypes = {
    index: PropTypes.number,
    option: PropTypes.object,
    toggleNotification: PropTypes.func,
    customClassName: PropTypes.string,
    showDivider: PropTypes.bool,
};

export default Index;
