import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import './style.scss';

const Index = ({ authType, text, icon, handleAuth, width = '220px', itemsCenter = false }) => {
    return (
        <button
            style={{ width }}
            className={clsx('container-auth-button', { 'items-center': itemsCenter })}
            key={authType}
            onClick={() => handleAuth(authType)}
        >
            <img src={require(`Assets/images/${icon}`)} alt='auth-type' />
            {text || ''}
        </button>
    );
};

Index.propTypes = {
    authType: PropTypes.string,
    text: PropTypes.string,
    icon: PropTypes.string,
    handleAuth: PropTypes.func,
    width: PropTypes.string,
    itemsCenter: PropTypes.bool,
};

export default Index;
