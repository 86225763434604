import { ReactComponent as RnRLogo } from 'Assets/images/rewards_recognitions_logo.svg';
import { ReactComponent as FeedbackLogo } from 'Assets/images/feedback-logo.svg';
import { ReactComponent as SpecialRewardsLogo } from 'Assets/images/special-rewards-logo.svg';
import { ReactComponent as OrgHealthLogo } from 'Assets/images/org-health-logo.svg';
import { ReactComponent as EmployeeAwardsLogo } from 'Assets/images/employee_award_splash_logo.svg';
import { ReactComponent as SuggestionBox } from 'Assets/images/suggestion-box.svg';

export const ONBOARDING_MODULES_MAP = {
    RnR: 'R&R',
    ORG_HEALTH: 'ORG_HEALTH',
    CELEBRATIONS: 'CELEBRATIONS',
    FEEDBACK: 'FEEDBACK',
    SUGGESTION_BOX: 'SUGGESTION_BOX',
    EMPLOYEE_AWARDS: 'EMPLOYEE_AWARDS',
    NEW_HIRE_ONBOARDING: 'NEW_HIRE_ONBOARDING',
};
export const moduleItems = [
    {
        id: 0,
        heading: 'Rewards and Recognitions',
        logo: RnRLogo,
        selected: true,
        moduleKey: ONBOARDING_MODULES_MAP.RnR,
    },
    {
        id: 1,
        heading: 'Birthdays and Work\nAnniversaries',
        logo: SpecialRewardsLogo,
        selected: true,
        moduleKey: ONBOARDING_MODULES_MAP.CELEBRATIONS,
    },
    {
        id: 2,
        heading: 'Engagement',
        logo: OrgHealthLogo,
        selected: true,
        moduleKey: ONBOARDING_MODULES_MAP.ORG_HEALTH,
    },
    {
        id: 3,
        heading: '360º Feedback',
        logo: FeedbackLogo,
        selected: true,
        moduleKey: ONBOARDING_MODULES_MAP.FEEDBACK,
    },
    {
        id: 4,
        heading: 'Suggestion Box',
        logo: SuggestionBox,
        selected: true,
        moduleKey: ONBOARDING_MODULES_MAP.SUGGESTION_BOX,
    },

    {
        id: 5,
        heading: 'Employee Awards',
        logo: EmployeeAwardsLogo,
        selected: true,
        moduleKey: ONBOARDING_MODULES_MAP.EMPLOYEE_AWARDS,
    },
];
