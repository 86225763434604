import { AUTH_TYPES } from 'constants.js';

export const AUTH_BUTTONS = [
    {
        type: AUTH_TYPES.SLACK,
        icon: 'icon-slack.svg',
        name: 'Slack',
    },
    {
        type: AUTH_TYPES.MSTEAMS,
        icon: 'icon-ms-teams.svg',
        name: 'Teams',
    },
];
