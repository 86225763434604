import React from 'react';
import AuthButton from '../AuthButton';
import { AUTH_BUTTONS } from '../constants';
import PropTypes from 'prop-types';
import './style.scss';

const Index = ({ history, login, handleAuth }) => {
    return (
        <div className='container-auth-options'>
            <p className='ls-auth-title'>If you want to use EngageWith on Slack or Teams platform</p>
            {AUTH_BUTTONS.map((button) => {
                return (
                    <AuthButton
                        key={button.type}
                        authType={button.type}
                        icon={button.icon}
                        text={`${login ? 'Sign in' : 'Sign up'} with ${button.name}`}
                        handleAuth={handleAuth}
                    />
                );
            })}
        </div>
    );
};

Index.propTypes = {
    history: PropTypes.object,
    login: PropTypes.bool,
    handleAuth: PropTypes.func,
};

export default Index;
