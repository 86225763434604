import React, { useState } from 'react';
import { useInfiniteQuery } from 'react-query';
import PropTypes from 'prop-types';
import InfiniteScroll from 'react-infinite-scroll-component';
import { debounce, findIndex } from 'lodash';
import EllipsisText from 'components/ReusableComponents/EllipsisText';
import clsx from 'clsx';
import Modal from '@material-ui/core/Modal';
import { BeatLoader } from 'react-spinners';
import CloseIcon from '@material-ui/icons/Close';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CustomSearchBox from 'components/Styles/CustomSearchBox';
import CustomLoader from 'components/ReusableComponents/CustomLoader';
import { getUsers } from 'Services/apiFunctions';
import { GET_USERS_DATA } from 'Services/apiKeys';
import './style.scss';

const Index = ({
    open,
    setOpen,
    heading,
    subHeading,
    setOpenForClose,
    buttonLeft = 'Cancel',
    buttonRight = 'Save',
    handleLeftButton,
    handleRightButton,
    handleClick,
    loading,
    isActive,
    actionNote,
    selectedList = {},
    skipAdmin,
}) => {
    const [searchInput, setSearchInput] = useState('');

    const { data, isLoading, fetchNextPage, hasNextPage } = useInfiniteQuery(
        [GET_USERS_DATA, { search: searchInput, isActive }],
        getUsers,
        {
            getNextPageParam: (lastPage, pages) => {
                return lastPage?.data?.users?.length > 9 ? pages.length + 1 : undefined;
            },
            refetchOnWindowFocus: false,
            keepPreviousData: true,
            retry: 0,
        }
    );

    const handleSearchChange = debounce((value) => {
        setSearchInput(value);
    }, 300);

    if (!open) {
        return null;
    }

    let isEmpty = data?.pages?.[0]?.data?.users?.length < 1;
    if (skipAdmin && !isEmpty && data?.pages?.[0]?.data?.users?.length < 11) {
        isEmpty = findIndex(data.pages[0].data.users, (user) => !user.isAdmin) < 0;
    }

    return (
        <Modal open={open} onClose={() => setOpen(false)}>
            <div className='org-modal-container'>
                <div className='org-modal-heading'>
                    <h3 className='header-3'>{heading}</h3>
                    <CloseIcon
                        className='pointer-cursor'
                        onClick={() => (setOpenForClose ? setOpen(false) : handleLeftButton())}
                    />
                </div>
                <p className='org-modal-subheading'>{subHeading}</p>
                <CustomSearchBox
                    width='100%'
                    placeholder='Search name'
                    handleSearch={(e) => handleSearchChange(e.target.value)}
                />
                {!isEmpty && <p className='org-action-note'>{actionNote}</p>}
                {isEmpty && !isLoading && (
                    <div className='response-empty'>
                        <div className='response-empty-image'></div>
                        <p className='no-response-text'>No results found</p>
                    </div>
                )}
                {isEmpty && isLoading && <CustomLoader />}
                {!isLoading && (
                    <InfiniteScroll
                        dataLength={data?.pages?.length * 10 || 0}
                        next={fetchNextPage}
                        hasMore={hasNextPage}
                        height={'100%'}
                        scrollThreshold={0.8}
                        loader={<CustomLoader />}
                    >
                        <div className='org-member-list-container'>
                            {data?.pages?.map((pageData) =>
                                pageData.data.users.map((user) =>
                                    skipAdmin && user?.isAdmin ? null : (
                                        <button
                                            className={clsx({
                                                'org-member-card': true,
                                                'selected-org-member-card': !!selectedList[user._id],
                                            })}
                                            key={user._id}
                                            onClick={() => handleClick(user)}
                                        >
                                            <img
                                                src={user.pictureURL || require('Assets/images/defaultUser.png')}
                                                className='avatar-32'
                                                alt={user.userName}
                                            />
                                            <EllipsisText text={user.userName} />
                                            <CheckCircleIcon />
                                        </button>
                                    )
                                )
                            )}
                        </div>
                    </InfiniteScroll>
                )}
                {!isEmpty && !isLoading && (
                    <div className='org-footer'>
                        <button className='ew-btn tb-btn mr-4' onClick={handleLeftButton} disabled={loading}>
                            {buttonLeft}
                        </button>
                        <button className='ew-btn pb-btn' onClick={handleRightButton} disabled={loading}>
                            {loading ? <BeatLoader size={10} color={'#fff'} /> : buttonRight}
                        </button>
                    </div>
                )}
            </div>
        </Modal>
    );
};

Index.propTypes = {
    open: PropTypes.bool.isRequired,
    heading: PropTypes.string.isRequired,
    subHeading: PropTypes.string,
    buttonLeft: PropTypes.string,
    buttonRight: PropTypes.string,
    handleLeftButton: PropTypes.func.isRequired,
    handleRightButton: PropTypes.func.isRequired,
    handleClick: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    isActive: PropTypes.bool.isRequired,
    actionNote: PropTypes.string.isRequired,
    skipAdmin: PropTypes.bool.isRequired,
    selectedList: PropTypes.object.isRequired,
    setOpen: PropTypes.func.isRequired,
    setOpenForClose: PropTypes.func.isRequired,
};

export default Index;
