import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
    TRANSACTION_STATES,
    MYACTIVITIES_TRANSACT_STATES_ADMIN,
    MYACTIVITIES_TRANSACT_STATES_NON_ADMIN,
} from './constant';
import './style.scss';

const getChips = (isAdmin, isManager, type) => {
    if (type === 'MyActivities') {
        if (isAdmin) {
            return MYACTIVITIES_TRANSACT_STATES_ADMIN;
        } else if (isManager) {
            return MYACTIVITIES_TRANSACT_STATES_NON_ADMIN;
        } else {
            return MYACTIVITIES_TRANSACT_STATES_NON_ADMIN.slice(0, 2);
        }
    } else {
        return TRANSACTION_STATES;
    }
};

const Index = ({ transactionState, handleTransactionSelection, type, isAdmin, isManager }) => {
    const temp = getChips(isAdmin, isManager, type);
    return (
        <div className='transaction-state'>
            {temp.map((trxState) => (
                <button
                    key={trxState?.value}
                    className={clsx('d-inline-block  multi-select-chip ', {
                        'selected-multi-select-chip': trxState?.value === transactionState,
                    })}
                    onClick={() => {
                        handleTransactionSelection(trxState?.value);
                    }}
                >
                    {trxState?.label}
                </button>
            ))}
        </div>
    );
};

Index.propTypes = {
    transactionState: PropTypes.string,
    handleTransactionSelection: PropTypes.func,
    type: PropTypes.string,
    isAdmin: PropTypes.bool,
    isManager: PropTypes.bool,
};

export default Index;
