import React from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import EWButton from 'components/ReusableComponents/EWButton';
import { ReactComponent as CopyIcon } from 'Assets/images/ic-copy.svg';
import { showSnackBarMessage } from 'utils/HelperFunctions';
import { useToaster } from 'Context/SnackbarContext';
import EllipsisText from 'components/ReusableComponents/EllipsisText';
import PropTypes from 'prop-types';
import './style.scss';

const InviteViaURL = ({ joiningLink }) => {
    const { SetSnackbar } = useToaster();

    const onCopy = () => showSnackBarMessage(SetSnackbar, 'success', 'Copied to clipboard');

    return (
        <div className='invite-users-url'>
            <p className='invite-users-url-title'>URL to invite users</p>
            <p className='invite-users-url-sub-title'>
                Share the URL with team members who should join the organization
            </p>
            <div className='d-flex'>
                <EllipsisText text={joiningLink} white maxWidth='272px' customClassName='invite-users-link' />
                <CopyToClipboard text={joiningLink} data-tip data-for='copy' onCopy={onCopy}>
                    <EWButton width='132px' plainTextButton={true} border={true}>
                        <CopyIcon /> Copy link
                    </EWButton>
                </CopyToClipboard>
            </div>
        </div>
    );
};

InviteViaURL.propTypes = {
    joiningLink: PropTypes.bool,
};

export default InviteViaURL;
