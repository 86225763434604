import React from 'react';

import './style.scss';
import { userLogout } from '../../utils/SessionUtils/sessionUtils';

const Index = () => {
    return (
        <div className='onboardingWrapper'>
            <div style={{ width: '100%' }}>
                <div className='page-content text-center'>
                    <div className='container'>
                        <div className='middle-elements'>
                            <img src={require('Assets/images/tape.svg')} alt='' />
                            <h2>Caution! This Page is Cordoned Off</h2>
                            <h4>
                                Only workspace admins from your organization can proceed with the on-boarding process
                            </h4>
                            <button className='btn' onClick={() => userLogout()}>
                                Return to homepage
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Index;
