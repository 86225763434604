import React, { useState, useEffect } from 'react';
import WelcomeBanner from '../OnboardingV2/Components/WelcomeBanner';
import InputField from 'components/ReusableComponents/InputField';
import EWButton from 'components/ReusableComponents/EWButton';
import ErrorField from 'components/ReusableComponents/ErrorField';
import Divider from './Divider';
import AuthOptions from './AuthOptions';
import AuthButton from './AuthButton';
import Account from './Account';
import { signup } from './Services/apiFunctions';
import { useMutation } from 'react-query';
import { useToaster } from 'Context/SnackbarContext';
import { showSnackBarMessage, validateEmail, handleUserLogin } from 'utils/HelperFunctions';
import { ROUTES, termsAndConditionsURL, privacyPolicyURL, AUTH_TYPES, EVENT_STORAGE } from 'constants.js';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import './style.scss';

const Index = ({ history }) => {
    const location = useLocation();
    const { SetSnackbar } = useToaster();
    const [name, setName] = useState();
    const [email, setEmail] = useState();
    const [error, setError] = useState(false);
    const [errorText, setErrorText] = useState('');

    const path = location?.pathname?.match(/\/signup\/(.+)/);
    const workspaceId = path ? path[1] : null;

    const { mutateAsync: signupUser, isLoading } = useMutation(signup);

    const handleAuth = async (authType) => {
        try {
            const queryParams = {
                platform: authType,
                ...((authType === AUTH_TYPES.GOOGLE || authType === AUTH_TYPES.MICROSOFT) && { workspaceId }),
            };
            const queryStringParams = queryString.stringify(queryParams);
            window.open(`${process.env.REACT_APP_API_URL}install?${queryStringParams}`, '_self');
        } catch (error) {
            showSnackBarMessage(SetSnackbar, 'error', error?.message);
        }
    };

    const handleSignUp = async () => {
        if (!validateEmail(email)) {
            setError(true);
            setErrorText('Please enter a valid email');
            return;
        }

        try {
            const apiData = {
                name,
                email,
                ...(workspaceId && { workspaceId }),
            };
            await signupUser({ apiData });
            history.push(ROUTES.SIGNUP_CONFIRMATION, { email, signup: true });
        } catch (error) {
            setError(true);
            setErrorText(error?.message);
        }
    };

    useEffect(() => {
        handleUserLogin(history);
        const handleStorageChange = (_event) => {
            handleUserLogin(history);
        };
        window.addEventListener(EVENT_STORAGE, handleStorageChange);
        return () => {
            window.removeEventListener(EVENT_STORAGE, handleStorageChange);
        };
    }, []);

    return (
        <div className='container-sign-up'>
            <WelcomeBanner />
            <div className='container-sign-up-content'>
                <h4 className='container-sign-up-title'>Sign Up on EngageWith</h4>
                <div className='d-flex'>
                    <div className='d-flex flex-column'>
                        <p className='ls-auth-title'>Signup via Google or Microsoft (For Web only users)</p>
                        <div className='ls-auth-others'>
                            <AuthButton
                                authType={AUTH_TYPES.GOOGLE}
                                icon='icon-google.svg'
                                text='Google'
                                itemsCenter
                                handleAuth={() => handleAuth(AUTH_TYPES.GOOGLE)}
                                width='180px'
                            />
                            <AuthButton
                                authType={AUTH_TYPES.MICROSOFT}
                                icon='icon-microsoft.svg'
                                text='Microsoft'
                                itemsCenter
                                handleAuth={() => handleAuth(AUTH_TYPES.MICROSOFT)}
                                width='180px'
                            />
                        </div>
                        <Divider horizontal dividerText='OR signup with email' />
                        <InputField
                            inputID='name'
                            label='NAME'
                            placeholder='Enter your name'
                            labelClass='input-label'
                            inputType='text'
                            width='320px'
                            value={name}
                            handleChange={(_id, value) => setName(value)}
                        />
                        <InputField
                            inputID='emailId'
                            label='EMAIL ID'
                            placeholder='Enter your Email Id'
                            labelClass='input-label'
                            inputType='text'
                            width='320px'
                            value={email}
                            handleChange={(_id, value) => setEmail(value)}
                        />
                        {error && <ErrorField errorText={errorText} lightErrorText className='signup-error-field' />}
                        <div className='tnc'>
                            <p className='tnc-heading'>Terms and conditions</p>
                            <p className='tnc-text'>
                                By clicking on proceed you agree on our Terms and conditions <br></br> and Privacy
                                policy
                            </p>
                            <div className='tnc-links'>
                                <button
                                    className='tnc-link'
                                    onClick={() => window.open(termsAndConditionsURL, '_blank')}
                                >
                                    Terms and Conditions
                                </button>
                                <div className='vertical-line'></div>
                                <button className='tnc-link' onClick={() => window.open(privacyPolicyURL, '_blank')}>
                                    Privacy Policy
                                </button>
                            </div>
                        </div>
                        <EWButton
                            buttonStyleClass='container-sign-up-proceed-button'
                            width='320px'
                            buttonText='Proceed to Sign up'
                            onClick={handleSignUp}
                            loading={isLoading}
                            disabled={!name || !email}
                        />
                        <Account login={false} onClick={() => history.push(ROUTES.LOGIN)} />
                    </div>
                    <Divider />
                    <AuthOptions history={history} handleAuth={handleAuth} />
                </div>
            </div>
        </div>
    );
};

Index.propTypes = {
    history: PropTypes.object,
};

export default Index;
