import React, { useState, useRef, createRef } from 'react';
import PropTypes from 'prop-types';
import DonutChart from 'components/ReusableComponents/DoughnutChart';
import CustomLoader from 'components/ReusableComponents/CustomLoader';
import { CustomTag } from 'utils/Stylesheet/style';
import { REQUIRED_TAG } from '../../constants';
import ResultBox from './ResultBox';
import ChoiceResult from './ChoiceResult';
import QuestionTitle from './QuestionTitle';
import clsx from 'clsx';

const Index = ({ summary, setPulseSummary, setKey, response, enps, translateRating, loading, respondentTotal }) => {
    const [selected, setSelected] = useState(0);
    const questionRef = useRef([]);
    if (questionRef.current.length !== summary?.summary?.length) {
        questionRef.current = Array(summary?.summary?.length)
            .fill()
            .map((_, i) => questionRef.current[i] || createRef());
    }
    const executeScroll = (index) => questionRef.current[index].current.scrollIntoView();
    const handleResponseView = (index) => {
        const tempSummary = { ...summary };
        tempSummary.summary[index].viewAll = summary.summary[index].viewAll ? !summary.summary[index].viewAll : true;
        setPulseSummary(tempSummary);
    };

    const doughnutChartIncreasedBy = !isNaN(+summary?.enpsData?.previousScore)
        ? Math.round(summary?.enpsData?.eNPSScore - summary?.enpsData?.previousScore)
        : 0;

    if (loading) {
        return <CustomLoader />;
    }

    return (
        <div className='summary-response mt-3'>
            <div className='d-flex justify-content-start'>
                <div className='summary-question'>
                    <div className='toggle-summary-responses'>
                        <button className='btn ob-btn active-btn'>Summary</button>
                        <button className='btn ob-btn inactive-btn' onClick={() => setKey('Responses')}>
                            Responses({response?.participants?.length || 0})
                        </button>
                    </div>
                    {summary?.summary?.length > 0 && (
                        <ul>
                            {summary?.summary.map((element, i) => (
                                <li className={clsx({ selected: selected === i })} key={element?._id}>
                                    <button
                                        className='summary-btn'
                                        onClick={() => {
                                            setSelected(i);
                                            executeScroll(i);
                                        }}
                                    >
                                        <QuestionTitle title={element?.question?.title} />
                                    </button>
                                </li>
                            ))}
                        </ul>
                    )}
                </div>
                <div className='content-scroll-summary pl-2'>
                    <div className='d-flex justify-content-between m-3' style={{ margin: '1rem 1rem -16px 1rem' }}>
                        <h5 className='pb-0'>Summary</h5>
                    </div>
                    {enps && summary?.enpsData && (
                        <div className='ml-3'>
                            <h5 className='pt-0'>eNPS score</h5>
                            <DonutChart
                                right={true}
                                score={summary?.enpsData?.eNPSScore?.toFixed(1)}
                                increasedBy={doughnutChartIncreasedBy}
                                promoters={summary?.enpsData?.Promoter}
                                detractors={summary?.enpsData?.Detractor}
                                passives={summary?.enpsData?.Passive}
                                respondents={summary?.enpsData?.totalRespondents}
                                respondentTotal={respondentTotal}
                                previousScore={summary?.enpsData?.previousScore}
                            />
                        </div>
                    )}
                    {summary?.summary?.length > 0 ? (
                        <div>
                            {summary.summary.map((element, i) => (
                                <React.Fragment key={element._id}>
                                    <>
                                        {' '}
                                        {enps && i === 0 && (
                                            <div className='ml-3 mt-5'>
                                                <h6>Standard Questions</h6>
                                                <p className='text-muted'>
                                                    Responses for the below questions are associated with the final eNPS
                                                </p>
                                            </div>
                                        )}
                                        {enps && i === 2 && (
                                            <div className='ml-3'>
                                                <h6>Added Questions</h6>
                                                <p className='text-muted'>
                                                    Responses to these questions are not considered in the calculation
                                                    of eNPS score
                                                </p>
                                            </div>
                                        )}{' '}
                                    </>

                                    <button
                                        className='make-relative summary-question-short-text'
                                        onMouseOver={() => (selected !== i ? setSelected(i) : null)}
                                        onFocus={() => (selected !== i ? setSelected(i) : null)}
                                    >
                                        <p className='count-question'>{i + 1}</p>
                                        <div
                                            ref={questionRef.current[i]}
                                            className={clsx('question-container', {
                                                'width-100':
                                                    ['SHORT_TEXT', 'LONG_TEXT'].includes(element.question.type) ||
                                                    element.viewAll ||
                                                    enps,
                                                'height-200':
                                                    !['SHORT_TEXT', 'LONG_TEXT'].includes(element.question.type) &&
                                                    !element.viewAll &&
                                                    !enps,
                                            })}
                                        >
                                            <h6 className='d-inline-block'>{element?.question?.title}</h6>
                                            {element?.question?.required && (
                                                <div className='d-flex justify-content-start'>
                                                    <CustomTag
                                                        color={REQUIRED_TAG?.color}
                                                        background={REQUIRED_TAG?.background}
                                                        fontWeight={REQUIRED_TAG?.fontWeight}
                                                    >
                                                        Required
                                                    </CustomTag>
                                                </div>
                                            )}
                                            <div className='my-3'>
                                                {element?.pending ? (
                                                    <span className='question-metadata-chip'>pending</span>
                                                ) : (
                                                    <div>
                                                        <span className='question-metadata-chip'>
                                                            {element?.totalResponsesToThisQues || 0} responded
                                                        </span>
                                                        &nbsp;&nbsp;
                                                        {element?.skipped >= 0 && (
                                                            <span className='question-metadata-chip'>
                                                                {element?.skipped} Skipped
                                                            </span>
                                                        )}
                                                    </div>
                                                )}
                                            </div>
                                            {element?.responses.length !== 0 && (
                                                <>
                                                    {['SHORT_TEXT', 'LONG_TEXT'].includes(element.question.type) && (
                                                        <ul
                                                            className={clsx({
                                                                'height-115': !element.viewAll && !enps,
                                                                // for enps, when viewAll is false and number of responses are less than 4, we need to show the full height
                                                                // else we fix the height to 150px, and show the view all response button to show all responses
                                                                'height-150':
                                                                    !element.viewAll &&
                                                                    enps &&
                                                                    element?.responses.length > 3,
                                                            })}
                                                            style={{ padding: '20px 20px 20px 40px', marginBottom: 0 }}
                                                        >
                                                            {element?.responses?.map((answer) => (
                                                                <li key={answer.ans}>
                                                                    <div className='align-items-center d-flex justify-content-between mb-2'>
                                                                        <div>{answer.ans}</div>
                                                                        <div
                                                                            className='emoji'
                                                                            data-toggle='tooltip'
                                                                            data-placement='top'
                                                                            title={translateRating(answer.emoji)}
                                                                        ></div>
                                                                    </div>
                                                                </li>
                                                            ))}
                                                        </ul>
                                                    )}
                                                    {['BINARY', 'RATING', 'RATING_5', 'LIKERT'].includes(
                                                        element?.question?.type
                                                    ) && <ResultBox element={element} />}

                                                    {['MULTIPLE_CHOICE', 'SINGLE_CHOICE'].includes(
                                                        element?.question?.type
                                                    ) && <ChoiceResult element={element} />}
                                                    {['SHORT_TEXT', 'LONG_TEXT'].includes(element.question.type) &&
                                                        element?.responses.length > 3 && (
                                                            <button
                                                                className='all-responses'
                                                                onClick={() => handleResponseView(i)}
                                                            >
                                                                {element.viewAll
                                                                    ? 'Close responses'
                                                                    : 'View all Responses'}{' '}
                                                            </button>
                                                        )}
                                                </>
                                            )}
                                        </div>
                                    </button>
                                </React.Fragment>
                            ))}
                        </div>
                    ) : (
                        <div className='response-empty'>
                            <div className='response-empty-image'></div>
                            <p className='no-response-text'>No results found</p>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

Index.propTypes = {
    summary: PropTypes.object,
    setPulseSummary: PropTypes.func,
    setKey: PropTypes.func,
    response: PropTypes.object,
    enps: PropTypes.object,
    translateRating: PropTypes.func,
    loading: PropTypes.bool,
    respondentTotal: PropTypes.number,
};

export default Index;
