import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { SUGGESTION } from './constants';
import Configuration from './Configuration';
import AllSuggestions from './AllSuggestions';
import SetupSuggesstion from './SetupSuggesstion';

const Index = () => {
    return (
        <div>
            <Switch>
                <Route exact path={SUGGESTION.SETUP_SUGGESTION_BOX} component={SetupSuggesstion} />
                <Route exact path={SUGGESTION.ALL_SUGGESTION} component={AllSuggestions} />
                <Route exact path={SUGGESTION.CONFIGURATION} component={Configuration} />
            </Switch>
        </div>
    );
};

export default Index;
