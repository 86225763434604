import React from 'react';
import EWButton from 'components/ReusableComponents/EWButton';
import { ReactComponent as ClappingHandsIcon } from 'Assets/images/ic-clapping-hands.svg';
import PropTypes from 'prop-types';
import './style.scss';

const Index = ({ handleSendRecognition }) => {
    return (
        <div className='container-recognition-feed-banner'>
            <ClappingHandsIcon />
            <p>{'See someone doing great things? Let them\nknow! Recognize someone now.'}</p>
            <EWButton buttonText='Recognize someone' onClick={handleSendRecognition} />
        </div>
    );
};

Index.propTypes = {
    handleSendRecognition: PropTypes.func.isRequired,
};

export default Index;
