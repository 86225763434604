import React from 'react';
import ConfirmModal from 'components/ReusableComponents/ConfirmModal';
import PropTypes from 'prop-types';

const Index = ({ open, setOpen, handleManualUpdate, handleSendDM }) => {
    const onClose = (isCancel) => {
        if (isCancel) handleManualUpdate();
        setOpen(false);
    };

    const data = {
        confirm: 'Send DM to update',
        cancel: 'Bulk Update',
        heading: 'Update missing information',
        subHeading:
            'You can bulk upload birthday and anniversary dates via CSV, or you can send a DM to employees to update the information',
    };

    return (
        <div className='update-missing-info-modal-container'>
            <ConfirmModal open={open} data={data} onClose={onClose} onConfirm={handleSendDM} height={'230px'} />
        </div>
    );
};

Index.propTypes = {
    open: PropTypes.bool,
    setOpen: PropTypes.func,
    handleManualUpdate: PropTypes.func,
    handleSendDM: PropTypes.func,
};

export default Index;
