import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import PropTypes from 'prop-types';

const theme = ({ color }) => ({
    root: {
        height: 10,
        width: '100%',
        borderRadius: 5,
    },
    colorPrimary: {
        backgroundColor: 'rgba(217, 219, 233, 0.4)',
    },
    bar: {
        borderRadius: 5,
        backgroundColor: color || '#5e61ec',
    },
});

const Index = ({ value, color }) => {
    const BorderLinearProgress = withStyles(theme({ color }))(LinearProgress);
    return <BorderLinearProgress variant='determinate' value={value} data-testid={'linear-progress'} />;
};

Index.propTypes = {
    value: PropTypes.number.isRequired,
    color: PropTypes.string,
};

export default Index;
