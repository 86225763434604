import React from 'react';
import Organization from '../Organization';
import PropTypes from 'prop-types';
import { PLATFORM } from 'constants.js';
import './style.scss';

const Index = ({ organizations, selectedOrganization, handleSelectOrganization, platform, showAlternateTitle }) => {
    const getTitle = () => {
        if (showAlternateTitle) {
            return `We found an account linked with your ${platform.toLowerCase()} account. Want to sign in via ${platform.toLowerCase()} instead?`;
        } else {
            return `Log into organisation linked with your ${
                platform.toLowerCase() === PLATFORM.WEB.toLowerCase() ? 'email' : `${platform.toLowerCase()} account`
            }`;
        }
    };

    return (
        <div className='organization-list-container'>
            <p className='container-select-organization-sub-title'>{getTitle()}</p>
            <div className='org-list'>
                {organizations.map((organization) => (
                    <Organization
                        key={organization._id}
                        organization={organization}
                        selectedOrganization={selectedOrganization}
                        handleSelectOrganization={(organization) => handleSelectOrganization(organization)}
                        platform={platform}
                    />
                ))}
            </div>
        </div>
    );
};

Index.propTypes = {
    organizations: PropTypes.array,
    selectedOrganization: PropTypes.object,
    handleSelectOrganization: PropTypes.func,
    platform: PropTypes.string,
    showAlternateTitle: PropTypes.bool,
};

export default Index;
