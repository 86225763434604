import React from 'react';
import NotificationWrapper from '../../NotificationWrapper';
import PropTypes from 'prop-types';
import { ReactComponent as WalletIcon } from 'Assets/images/Notification-Payment-icon.svg';
import EWButton from 'components/ReusableComponents/EWButton';
import { ROUTES } from 'constants.js';
import '../styles.scss';

const Index = ({ data, history }) => {
    return (
        <div className='notification-event-container'>
            <div className='notification-auto-reload-enabled-container'>
                <div className='notification-auto-reload-enabled-text-top-container'>
                    <WalletIcon width={23} height={23} />
                    <p className='notification-module-text'>Payments:</p>
                    <p className='notification-auto-reload-text'>Auto reload settings enabled and active now.</p>
                </div>
                <p className='notification-auto-reload-data-text'>
                    👉 Your wallet will be automatically topped up with{' '}
                    <span className='auto-reload-currency-text'>{data.metadata.currency}</span>{' '}
                    <span className=' auto-reload-reloaded-text'>{data.metadata.autoReloadAmount}</span> when your
                    balances drops below <span className='auto-reload-currency-text'>{data.metadata.currency}</span>{' '}
                    <span className=' auto-reload-reloaded-text'>{data.metadata.thresholdAmount}</span>.
                </p>
            </div>
            <EWButton
                buttonStyleClass='notification-cta-button'
                width='180px'
                onClick={() => {
                    history.push(ROUTES.PAYMENTS_WALLET);
                }}
            >
                Manage preferences
            </EWButton>
        </div>
    );
};

Index.propTypes = {
    data: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
};

export default NotificationWrapper(Index);
