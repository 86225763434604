import React, { useState } from 'react';
import EWModal from 'components/ReusableComponents/EWModal';
import { useToaster } from 'Context/SnackbarContext';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { updateUserProfile } from 'redux/user/actions';
import { useMutation, useQueryClient, useQuery } from 'react-query';
import { GET_RECOGNITION_POSTS, GET_INTEGRATED_CHANNELS } from 'Services/apiKeys';
import { ReactComponent as ClappingHandsIcon } from 'Assets/images/ic-clapping-hands.svg';
import CustomFilterDropdown from 'components/ReusableComponents/CustomFilterDropdown';
import InputField from 'components/ReusableComponents/InputField';
import UserSearchDropdown from 'components/ReusableComponents/UserSearchDropdown';
import { ReactComponent as InfoIcon } from 'Assets/images/info-grey-filled.svg';
import { default as Tooltip } from 'components/Styles/Tooltip';
import CustomUserTags from 'components/ReusableComponents/CustomUserTags';
import {
    showSnackBarMessage,
    getChannelOptionsByPlatform,
    getIntegratedChannelName,
    isSlack,
    isSubscriptionCancelled,
} from 'utils/HelperFunctions';
import SubscriptionCancelledModal from 'components/Modals/SubscriptionCancelledModal';
import { postRecognition } from '../../../Services/apiFunctions';
import { getIntegratedChannelsList } from 'Services/apiFunctions';
import PropTypes from 'prop-types';
import './style.scss';
import { find } from 'lodash';
import EnhanceWithAI from 'components/ReusableComponents/EnhanceWithAI';
import { HISTORY_FROM, PLATFORM } from 'constants.js';

const RECOGNITION_CHAR_LIMIT = 2900;
const RESTRICTED_USER_NOTE = 'This user has been restricted from receiving recognitions by the admin.';

const Index = ({
    open,
    onClose,
    addonParentId,
    isAddOnRecognition = false,
    recognizedUsers = [],
    defaultRecognitionChannel,
    handleSuccess,
    addOnRewardType,
    values,
    recognitionTypes,
    addOnRecognitionChannel,
    history,
}) => {
    const dispatch = useDispatch();
    const queryClient = useQueryClient();
    const { SetSnackbar } = useToaster();
    const [subscriptionCancelled, setSubscriptionCancelled] = useState(false);

    const { nameForPoints, userId, creditBalance, platform, subscription, isCompanyValuesRequired } = useSelector(
        mapStateToProps,
        shallowEqual
    );
    const isWebOnlyUser = platform === PLATFORM.WEB;
    const [recognitionMessage, setRecognitionMessage] = useState();
    const [channelList, setChannelList] = useState([]);
    const [isAiEnhanced, setIsAiEnhanced] = useState(false);
    const [selectedUsers, setSelectedUsers] = useState(recognizedUsers);
    const [selectedValue, setSelectedValue] = useState();

    /**
     * Returns default recognition channel details if it is set
     * In case of add on recognition returns the channel details where parent post was posted
     * If default recognition channel is not set --> if there is only one channel then set it by default else
     * return empty so that user needs to select the channel
     * @returns channel object if channel is selected
     */
    const rewardTypeHandler = () => {
        const type = addOnRewardType || 'kudos';
        const selectedAddOnRewardType =
            find(recognitionTypes, (recognition) => recognition.name.toLowerCase() === type) || recognitionTypes[0];

        return selectedAddOnRewardType;
    };

    const [selectedRecognitionType, setSelectedRecognitionType] = useState(rewardTypeHandler());

    const getSelectedChannel = () => {
        if (defaultRecognitionChannel?.enabled) {
            return defaultRecognitionChannel?.channel;
        } else if (isAddOnRecognition) {
            return {
                channelID: addOnRecognitionChannel.id,
                channelName: addOnRecognitionChannel.name,
                isPrivate: addOnRecognitionChannel.isPrivate,
                ...(!isSlack(platform) && {
                    groupId: addOnRecognitionChannel.groupId,
                    label: addOnRecognitionChannel.name,
                }),
            };
        } else if (channelList?.length === 1) {
            return channelList[0];
        }
        return '';
    };

    const [selectedChannel, setSelectedChannel] = useState(getSelectedChannel());

    const onError = (error) => showSnackBarMessage(SetSnackbar, 'error', error?.message);

    useQuery([GET_INTEGRATED_CHANNELS], getIntegratedChannelsList, {
        onSuccess: (data) => {
            if (data?.length) {
                setChannelList(getChannelOptionsByPlatform(data, platform));
            }
        },
        onError,
    });

    const { mutateAsync: sendRecognitionPost, isLoading: isSendingRecognition } = useMutation(postRecognition);

    const handleSendRecognition = async () => {
        if (find(selectedUsers, (user) => user._id === userId)) {
            showSnackBarMessage(SetSnackbar, 'error', `You can't give a ${selectedRecognitionType?.name} to yourself`);
            return;
        }

        if (!isAddOnRecognition && recognitionMessage?.length < 10) {
            showSnackBarMessage(SetSnackbar, 'error', 'Recognition message must be at least 10 characters long.');
            return;
        }

        if (selectedUsers.length * (selectedRecognitionType?.points || 0) > creditBalance) {
            showSnackBarMessage(
                SetSnackbar,
                'error',
                'You do not have enough points to recognize everyone in the list'
            );
            return;
        }

        try {
            if (isSubscriptionCancelled(subscription)) {
                setSubscriptionCancelled(true);
                return;
            }
            const apiData = {
                rewardId: selectedRecognitionType?._id,
                receivers: selectedUsers.map((user) => user._id),
                message: recognitionMessage,
                isAIEnhanced: isAiEnhanced,
                isAddOn: isAddOnRecognition,
                ...(selectedValue && { value: selectedValue.id }),
                ...(isAddOnRecognition && { parentId: addonParentId }),
                //label and groupid is sent only for teams
                ...(platform === PLATFORM.TEAMS && {
                    displayName: selectedChannel?.label,
                    groupId: selectedChannel?.groupId,
                }),
                channel:
                    platform === PLATFORM.WEB
                        ? 'recognition_feed'
                        : selectedChannel?.channelID ?? selectedChannel?.channelId,
            };

            const recognitionPostData = await sendRecognitionPost({ apiData });
            updateUserProfile(dispatch, {
                creditBalance: creditBalance - selectedUsers.length * selectedRecognitionType?.points,
            });
            queryClient.invalidateQueries(GET_RECOGNITION_POSTS);
            handleSuccess(recognitionPostData?.data);
        } catch (err) {
            showSnackBarMessage(SetSnackbar, 'error', err?.message);
        }
    };

    const shouldDisableRecognition = () => {
        return (
            !selectedRecognitionType ||
            selectedUsers.length < 1 ||
            (recognitionMessage && recognitionMessage?.length > RECOGNITION_CHAR_LIMIT) ||
            (!isAddOnRecognition && !recognitionMessage) ||
            !selectedChannel ||
            (isCompanyValuesRequired && !selectedValue)
        );
    };

    const modalData = {
        heading: (
            <span style={{ fontFamily: 'Montserrat' }}>
                <ClappingHandsIcon style={{ width: '24px', height: '24px', margin: '-5px 8px 0px 0px' }} />{' '}
                {isAddOnRecognition ? 'Add-on recognition' : 'Recognize someone'}
            </span>
        ),
        rightButtonText: 'Recognize',
        handleRightButtonClick: handleSendRecognition,
        leftButtonText: 'Cancel',
        handleLeftButtonClick: onClose,
        loading: isSendingRecognition,
        disabled: shouldDisableRecognition(),
    };

    // callback on selecting users
    const handleUserSelection = (user) => {
        if (find(selectedUsers, (selectedUser) => selectedUser?._id === user?._id)) {
            setSelectedUsers(selectedUsers.filter((item) => item?._id !== user?._id));
        } else {
            setSelectedUsers([...selectedUsers, user]);
        }
    };

    const isOptionDisabledHandler = (user) => {
        return user.permissions?.recognition?.restricted;
    };
    const userNameHandler = (user) => {
        const isRestricted = user.permissions?.recognition?.restricted;
        return (
            <>
                {user.userName} {isRestricted && <span style={{ fontStyle: 'italic' }}>{` (Restricted) `}</span>}
                {isRestricted && (
                    <Tooltip arrow title={RESTRICTED_USER_NOTE}>
                        <InfoIcon className='info-icon' />
                    </Tooltip>
                )}
            </>
        );
    };

    return (
        <EWModal
            open={open}
            onClose={onClose}
            width='580px'
            height='auto'
            modalData={modalData}
            customClassName='csr-modal-padding'
        >
            <div className='container-send-recognition-modal'>
                <div className='csr-modal-balance'>
                    <span className='csr-modal-balance-text'>Current balance: </span>
                    <span className='csr-modal-balance-value'>
                        {creditBalance} {nameForPoints}
                    </span>
                </div>
                <CustomFilterDropdown
                    title='Type of recognition'
                    titleStyleClass='csr-modal-filter-label'
                    search
                    dropDownID='recognition-type'
                    filterOptions={recognitionTypes}
                    selectedName={selectedRecognitionType?.label}
                    handleSelection={(_index, value) => setSelectedRecognitionType(value)}
                    singleSelect
                    optionsSelected
                    buttonStyleClass='width-540'
                    dropdownWidth={'540px'}
                    dropdownGap={25}
                />
                <div className='csr-modal-search-users'>
                    <span className='csr-modal-filter-label'>Whom do you want to recognize?</span>
                    <UserSearchDropdown
                        dropDownID='search-user'
                        dropdownWidth={540}
                        dropdownHeight={200}
                        selectedUsers={selectedUsers}
                        handleUserSelection={handleUserSelection}
                        placeholder='Type or select someone'
                        customAddSearch={false}
                        isRestrictedOptionsAllowed={true}
                        isOptionDisabledHandler={isOptionDisabledHandler}
                        userNameHandler={userNameHandler}
                    />
                    <div className='csr-modal-search-users-selected'>
                        <CustomUserTags users={selectedUsers} onRemove={(users) => setSelectedUsers(users)} />
                    </div>
                </div>

                <InputField
                    inputID='csr-modal-recognition-message'
                    label='Why do you want to recognize them?'
                    labelClass='csr-modal-filter-label'
                    inputClass='csr-modal-recogniton-message'
                    value={recognitionMessage}
                    placeholder='Write your message here&#10;Eg: Congratulation on completing a difficult campaign'
                    width='540px'
                    height='86px'
                    handleChange={(_id, value) => setRecognitionMessage(value)}
                    textArea
                    optional={isAddOnRecognition}
                />
                {!isAddOnRecognition && recognitionMessage?.length < 10 && (
                    <span className='csr-modal-text-error'>You must enter at least 10 characters.</span>
                )}

                {recognitionMessage?.length > RECOGNITION_CHAR_LIMIT && (
                    <span className='csr-modal-text-error'>{`Maximum character limit ${RECOGNITION_CHAR_LIMIT}`}</span>
                )}

                <EnhanceWithAI
                    textMessage={recognitionMessage}
                    onMessageEnhanced={(message) => {
                        setRecognitionMessage(message);
                        setIsAiEnhanced(true);
                    }}
                    handleRevertoriginalMessage={(message) => {
                        setRecognitionMessage(message);
                        setIsAiEnhanced(false);
                    }}
                    isAiEnhanced={isAiEnhanced}
                />

                {values.length > 0 && (
                    <CustomFilterDropdown
                        title='Company Value Highlighted'
                        titleStyleClass='csr-modal-filter-label'
                        search
                        dropDownID='company-value'
                        filterOptions={values}
                        selectedName={selectedValue?.value || 'Select company value'}
                        handleSelection={(_index, value) => setSelectedValue(value)}
                        singleSelect
                        optionsSelected={!!selectedValue}
                        buttonStyleClass='width-540'
                        dropdownWidth={'540px'}
                        dropdownGap={25}
                        optional={!isCompanyValuesRequired}
                    />
                )}

                {!isAddOnRecognition && !defaultRecognitionChannel?.enabled && (
                    <div>
                        <CustomFilterDropdown
                            title='Where do you want to post your recognition?'
                            titleStyleClass='csr-modal-filter-label'
                            search
                            dropDownID='channel'
                            filterOptions={channelList}
                            selectedName={
                                selectedChannel?.channelName
                                    ? getIntegratedChannelName(selectedChannel, platform)
                                    : 'Select channel'
                            }
                            handleSelection={(_index, value) => setSelectedChannel(value)}
                            singleSelect
                            optionsSelected
                            buttonStyleClass='width-540'
                            dropdownWidth={'540px'}
                            dropdownGap={25}
                        />
                        {selectedChannel && (
                            <span className='csr-modal-channel-note'>
                                Public channels results in posting on recognition feed as well
                            </span>
                        )}
                    </div>
                )}
                {!isAddOnRecognition && defaultRecognitionChannel?.enabled && (
                    <span className='csr-modal-channel-note mt-1'>
                        Your recognition will be posted on{' '}
                        {!isWebOnlyUser && (
                            <span>
                                <strong>
                                    {getIntegratedChannelName(defaultRecognitionChannel?.channel, platform)}
                                </strong>{' '}
                                and on
                            </span>
                        )}
                        the recognition feed
                    </span>
                )}

                <SubscriptionCancelledModal
                    open={subscriptionCancelled}
                    onClose={() => setSubscriptionCancelled(false)}
                    history={history}
                    currentRoute={HISTORY_FROM.RECOGNITIONS}
                />
            </div>
        </EWModal>
    );
};

const mapStateToProps = ({ Workspace, User, Payments }) => ({
    nameForPoints: Workspace.nameForPoints,
    userId: User._id,
    isAdmin: User.isAdmin,
    creditBalance: User.creditBalance || 0,
    platform: Workspace.platform,
    subscription: Payments?.subscription,
    isCompanyValuesRequired: Workspace?.companyValue?.isRequired || false,
});

Index.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    addonParentId: PropTypes.string,
    isAddOnRecognition: PropTypes.bool,
    addOnRewardType: PropTypes.string,
    defaultRecognitionChannel: PropTypes.object,
    handleSuccess: PropTypes.func,
    recognizedUsers: PropTypes.array,
    values: PropTypes.array,
    recognitionTypes: PropTypes.array,
    addOnRecognitionChannel: PropTypes.object,
    history: PropTypes.object,
};

export default Index;
