import { useQuery, useMutation, useQueryClient } from 'react-query';
import { GET_NOTIFICATION, GET_CORE_VALUES } from 'Services/apiKeys';
import { getCoreValues, addCoreValues, updateCoreValues, saveNotification } from 'Services/apiFunctions';
import { handleAddEditValueClick, getAllNotifications } from './componentsUtil';
import { showSnackBarMessage } from 'utils/HelperFunctions';

/*--------------- Queries and mutations for "Notification" tab ---------------*/

export const useGetNotificationsQuery = ({ showError, platform, nameForPoints }) => {
    return useQuery(GET_NOTIFICATION, () => getAllNotifications(platform, nameForPoints), {
        onError: showError,
    });
};

export const useToggleNotificationMutation = ({ showError, notifications, SetSnackbar }) => {
    const queryClient = useQueryClient();
    const { mutateAsync, isLoading: loading } = useMutation(saveNotification);

    const toggleNotification = async (index) => {
        try {
            if (loading) {
                return;
            }
            let nudgeNames = [
                'allowEmailNotifications',
                'summaryNudge',
                'top3Nudge',
                'recognitionNudge',
                'redeemNudge',
            ];
            await mutateAsync({
                apiData: { [nudgeNames[index]]: !notifications[index].isEnabled },
            });
            queryClient.setQueryData(GET_NOTIFICATION, (oldData) => {
                const tempData = [...oldData];
                tempData[index].isEnabled = !notifications[index].isEnabled;
                return tempData;
            });
            showSnackBarMessage(
                SetSnackbar,
                'success',
                `${notifications[index].heading} has been ${notifications[index].isEnabled ? 'Enabled' : 'Disabled'}`
            );
        } catch (error) {
            showError(error);
        }
    };
    return toggleNotification;
};

/*--------------- Queries and mutations for "Values" tab ---------------*/
export const useGetCoreValuesQuery = ({ SetSnackbar }) => {
    return useQuery([GET_CORE_VALUES], getCoreValues, {
        onError: (err) => showSnackBarMessage(SetSnackbar, 'error', err?.message),
    });
};

export const useAddEditValuesMutation = ({ addModal, value, values, onClose, onError, SetSnackbar }) => {
    const queryClient = useQueryClient();
    const { mutateAsync: addValue, isLoading: isAddingValue } = useMutation(addCoreValues, {
        onSuccess: async (valuesData) => {
            values.push(valuesData.data);
            onClose();
        },
        onError,
    });

    const { mutateAsync: updateValue, isLoading: isUpdatingValue } = useMutation(updateCoreValues, {
        onError,
    });

    const handleClick = () => {
        return handleAddEditValueClick({
            onClose,
            value,
            values,
            addModal,
            addValue,
            updateValue,
            queryClient,
            SetSnackbar,
        });
    };

    return { handleClick, isAddingValue, isUpdatingValue };
};
