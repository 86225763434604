import React, { useRef, useImperativeHandle, forwardRef } from 'react';
import { nanoid } from 'nanoid';
import { findIndex } from 'lodash';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import CloseIcon from '@material-ui/icons/Close';
import Editable from './Editable';
import { ReactComponent as WarningIcon } from 'Assets/images/warning.svg';
import { EDIT_TYPE, QUESTION_TYPE } from '../../constants';
import { ReactComponent as MagicWand } from 'Assets/images/enhanceAI-blue.svg';
import PropTypes from 'prop-types';

const Questions = forwardRef(
    (
        {
            questions,
            setQuestions,
            addedLibraryQuestion,
            setAddedLibraryQuestion,
            eNPS,
            questionWarn,
            setQuestionWarn,
            openQuestion,
            setOpenQuestion,
            isEditingOnetime,
            isEditingRolling,
            isEditingRecurring,
            editType,
            setDeletedQuestions,
            setENPSQuestion,
            eNPSQuestion,
            editId,
            setUpdatePromptModalOpen,
            isAIGenerated,
            aiUserPrompt,
        },
        addQuestionButtonRef
    ) => {
        const addQuestion = useRef();
        const removeWarning = () =>
            setQuestionWarn({
                isInvalid: false,
                message: '',
            });

        /**
         * The function `handleAddQuestion` adds a new question to an array of questions in a form, with
         * default values for the question and options.
         */
        const handleAddQuestion = () => {
            const tempQuestions = [...questions];
            if (openQuestion > -1 && tempQuestions.length > 0 && tempQuestions[openQuestion]) {
                tempQuestions[openQuestion].question.name =
                    tempQuestions[openQuestion].question.name || 'Untitled question';
                if (tempQuestions[openQuestion].questionType === 'MCQ') {
                    tempQuestions[openQuestion].options.forEach((option, index) => {
                        option.name = option.name || `Choice ${index + 1}`;
                    });
                }
            }
            setOpenQuestion(questions.length);
            setQuestions([
                ...tempQuestions,
                {
                    id: nanoid(),
                    question: { name: '', error: false },
                    questionType: 'MCQ',
                    questionLabel: 'Multiple choice',
                    required: false,
                    multipleSelection: false,
                    options: [
                        { name: '', error: false, id: nanoid() },
                        { name: '', error: false, id: nanoid() },
                    ],
                },
            ]);
        };

        const handleOnDragEnd = (result) => {
            const { destination, source } = result;
            if (!destination || destination.index === source.index) {
                return;
            }
            const tempQuestionArray = [...questions];
            const openQuestionID = tempQuestionArray[openQuestion]?.id;
            tempQuestionArray.splice(destination.index, 0, tempQuestionArray.splice(source.index, 1)[0]);
            if (openQuestion > -1) {
                setOpenQuestion(findIndex(tempQuestionArray, (question) => question.id === openQuestionID));
            }
            setQuestions(tempQuestionArray);
        };

        useImperativeHandle(addQuestionButtonRef, () => ({
            scrollIntoView: () => {
                addQuestion.current.scrollIntoView({ behavior: 'smooth' });
            },
        }));

        return (
            <DragDropContext onDragEnd={handleOnDragEnd}>
                <div
                    style={eNPS || isEditingOnetime || isEditingRolling || isEditingRecurring ? { width: '100%' } : {}}
                    className='create-ques-list-container'
                >
                    {isAIGenerated && (
                        <div className='ai-generated-container'>
                            <p className='ai-gen-title-text'>
                                <MagicWand />
                                This is an AI generated Pulse questions based the below prompt:
                            </p>
                            <p className='ai-prompt-text'>"{aiUserPrompt}"</p>
                            <p className='ai-prompt-update-text'>
                                Not happy with the results? Try updating the prompt again!{' '}
                                <button onClick={() => setUpdatePromptModalOpen(true)}>Update My prompt</button>
                            </p>
                        </div>
                    )}
                    {questionWarn.isInvalid && (
                        <div className='pulse-warning-snackbar'>
                            <div>
                                <WarningIcon /> {questionWarn.message}
                            </div>
                            <CloseIcon onClick={removeWarning} />
                        </div>
                    )}
                    {eNPS && (
                        <>
                            <div className='eNPS-ques-heading-conainer'>
                                <h5 className='eNPS-ques-type-heading'>Standard Questions </h5>
                                <p className='enps-question-subtext'>
                                    Questions added below are associated with the final eNPS and cannot be edited
                                </p>
                            </div>

                            <Editable
                                question={eNPSQuestion[0]}
                                setENPSQuestion={setENPSQuestion}
                                questions={eNPSQuestion}
                                backgroundColor={'#39af37'}
                                questionIndex={0}
                                questionType={QUESTION_TYPE.STANDARD}
                                isEditingOngoing={isEditingOnetime || editType === EDIT_TYPE.ONGOING}
                                eNPS
                            />
                            <Editable
                                question={eNPSQuestion[1]}
                                setENPSQuestion={setENPSQuestion}
                                questions={eNPSQuestion}
                                questionIndex={1}
                                backgroundColor={'#39af37'}
                                questionType={QUESTION_TYPE.STANDARD}
                                isEditingOngoing={isEditingOnetime || editType === EDIT_TYPE.ONGOING}
                                eNPS
                            />
                            {questions.length > 0 && (
                                <div className='eNPS-ques-heading-conainer'>
                                    <h5 className='eNPS-ques-type-heading'>Added Questions </h5>
                                    <p className='enps-question-subtext'>
                                        Questions added below will not be considered for eNPS score calculation
                                    </p>
                                </div>
                            )}
                        </>
                    )}

                    <Droppable droppableId={'pulse-create-droppable-column-1'}>
                        {(provided) => (
                            <div ref={provided.innerRef} {...provided.droppableProps}>
                                {questions.map((question, index) => (
                                    <Draggable
                                        draggableId={question._id || question.id}
                                        key={question._id || question.id}
                                        index={index}
                                        isDragDisabled={isEditingOnetime || isEditingRolling || isEditingRecurring}
                                    >
                                        {(innerProvided) => (
                                            <Editable
                                                provided={innerProvided}
                                                question={question}
                                                editing={index === openQuestion}
                                                setOpenQuestion={setOpenQuestion}
                                                questionIndex={index}
                                                questions={questions}
                                                setQuestions={setQuestions}
                                                addedLibraryQuestion={addedLibraryQuestion}
                                                setAddedLibraryQuestion={setAddedLibraryQuestion}
                                                openQuestion={openQuestion}
                                                isEditingOnetime={isEditingOnetime}
                                                eNPS={eNPS}
                                                backgroundColor={'#5d5cf5'}
                                                isEditingOngoing={
                                                    isEditingOnetime ||
                                                    isEditingRolling ||
                                                    editType === EDIT_TYPE.ONGOING
                                                }
                                                setDeletedQuestions={setDeletedQuestions}
                                            />
                                        )}
                                    </Draggable>
                                ))}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>

                    <div className='text-center mt-4' ref={addQuestion}>
                        {!(isEditingOnetime || isEditingRolling || editType === EDIT_TYPE.ONGOING) && (
                            <button className='ew-btn ob-btn' onClick={handleAddQuestion}>
                                + Add new question
                            </button>
                        )}
                        {eNPS && (questions.length > 0 || !editId) && (
                            <p className='enps-question-subtext'>
                                Questions added below will not be considered for eNPS score calculation
                            </p>
                        )}
                    </div>
                </div>
            </DragDropContext>
        );
    }
);
Questions.propTypes = {
    questions: PropTypes.array,
    setQuestions: PropTypes.func,
    addedLibraryQuestion: PropTypes.object,
    setAddedLibraryQuestion: PropTypes.func,
    eNPS: PropTypes.bool,
    questionWarn: PropTypes.object,
    setQuestionWarn: PropTypes.func,
    openQuestion: PropTypes.number,
    setOpenQuestion: PropTypes.func,
    isEditingOnetime: PropTypes.bool,
    isEditingRecurring: PropTypes.bool,
    isEditingRolling: PropTypes.bool,
    setDeletedQuestions: PropTypes.func,
    setENPSQuestion: PropTypes.func,
    eNPSQuestion: PropTypes.array,
    editId: PropTypes.string,
    editType: PropTypes.string,
    isAIGenerated: PropTypes.bool,
    aiUserPrompt: PropTypes.string,
};
export default Questions;
