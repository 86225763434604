import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import WelcomeBanner from '../OnboardingV2/Components/WelcomeBanner';
import InputField from 'components/ReusableComponents/InputField';
import EWButton from 'components/ReusableComponents/EWButton';
import ErrorField from 'components/ReusableComponents/ErrorField';
import Divider from '../Signup/Divider';
import { useToaster } from 'Context/SnackbarContext';
import { useMutation } from 'react-query';
import { login } from './apiFunctions';
import { showSnackBarMessage, validateEmail, handleUserLogin } from 'utils/HelperFunctions';
import AuthButton from '../Signup/AuthButton';
import Account from '../Signup/Account';
import { ROUTES, AUTH_TYPES, EVENT_STORAGE } from 'constants.js';
import './style.scss';

const Index = ({ history }) => {
    const { SetSnackbar } = useToaster();
    const [email, setEmail] = useState();
    const [error, setError] = useState(false);
    const [errorText, setErrorText] = useState('');

    const { mutateAsync: loginUser, isLoading } = useMutation(login);

    const handleAuth = (authType) => {
        try {
            window.location.replace(`${process.env.REACT_APP_API_URL}login?platform=${authType}`, '_self');
        } catch (error) {
            showSnackBarMessage(SetSnackbar, 'error', error?.message);
        }
    };

    const handleSignIn = async () => {
        if (!validateEmail(email)) {
            setError(true);
            setErrorText('Please enter a valid email.');
            return;
        }
        try {
            const apiData = {
                platform: AUTH_TYPES.EMAIL,
                email,
            };
            await loginUser(apiData);
            history.push(ROUTES.SIGNUP_CONFIRMATION, { email, signup: false });
        } catch (error) {
            setError(true);
            setErrorText(error?.message);
        }
    };

    useEffect(() => {
        handleUserLogin(history);
        const handleStorageChange = (_event) => {
            handleUserLogin(history);
        };
        window.addEventListener(EVENT_STORAGE, handleStorageChange);
        return () => {
            window.removeEventListener(EVENT_STORAGE, handleStorageChange);
        };
    }, []);

    return (
        <div className='login-screen'>
            <WelcomeBanner />
            <div className='login-screen-content'>
                <h4 className='container-sign-in-title'>Sign In</h4>
                <div className='d-flex'>
                    <div className='d-flex flex-column align-items-left'>
                        <p className='ls-auth-title'>Sign in via Google or Microsoft (For Web only users) </p>
                        <div className='ls-auth-others'>
                            <AuthButton
                                authType={AUTH_TYPES.GOOGLE}
                                icon='icon-google.svg'
                                text='Google'
                                itemsCenter
                                handleAuth={() => handleAuth(AUTH_TYPES.GOOGLE)}
                                width='180px'
                            />
                            <AuthButton
                                authType={AUTH_TYPES.MICROSOFT}
                                icon='icon-microsoft.svg'
                                text='Microsoft'
                                itemsCenter
                                handleAuth={() => handleAuth(AUTH_TYPES.MICROSOFT)}
                                width='180px'
                            />
                        </div>
                        <Divider horizontal dividerText='OR signin with email' />
                        <InputField
                            inputID='email'
                            label='Email Address'
                            placeholder='Enter email address'
                            labelClass='input-label'
                            inputType='text'
                            width='320px'
                            value={email}
                            handleChange={(_id, value) => setEmail(value)}
                        />
                        {error && <ErrorField errorText={errorText} lightErrorText className='login-error-field' />}
                        <EWButton
                            buttonStyleClass='container-sign-up-proceed-button'
                            width='320px'
                            buttonText='Proceed'
                            onClick={handleSignIn}
                            loading={isLoading}
                            disabled={!email}
                        />
                        <Account login={true} onClick={() => history.push(ROUTES.SIGNUP)} />
                    </div>
                    <Divider />
                    <div className='login-screen-auth'>
                        <p className='ls-auth-title'>If you are using EngageWith on Slack or Teams</p>
                        <AuthButton
                            authType={AUTH_TYPES.SLACK}
                            icon='icon-slack.svg'
                            text='Sign in with Slack'
                            handleAuth={() => handleAuth(AUTH_TYPES.SLACK)}
                        />
                        <AuthButton
                            authType={AUTH_TYPES.MSTEAMS}
                            icon='icon-ms-teams.svg'
                            text='Sign in with Teams'
                            handleAuth={() => handleAuth(AUTH_TYPES.MSTEAMS)}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

Index.propTypes = {
    history: PropTypes.object,
};

export default Index;
