import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { ANALYTICS } from '../constants';
import CustomSearchBox from 'components/Styles/CustomSearchBox';
import CustomDropdown from 'components/ReusableComponents/CustomDropdown';
import DropdownList from './DropdownList';
import Tabs from './Tabs';
import './style.scss';

const Index = ({ cohortData, handleCohortSelection, selectedCohort, blockSelection }) => {
    const TAB_SEARCH_PLACEHOLDER = [
        'Search for department, manager',
        'Search for department',
        'Search for manager name',
    ];
    const hideManager = window.location.pathname === ANALYTICS.ANALYTICS_MANAGERS;
    const [selectedTab, setSelectedTab] = useState(0);
    const [search, setSearch] = useState('');
    const [selectedCohortId, setSelectedCohortId] = useState('');

    const getListData = () => {
        return [
            ...(cohortData?.organisation || []),
            ...((cohortData?.departments?.length && [{ name: 'Departments' }]) || []),
            ...(cohortData?.departments || []),
            ...(hideManager || !cohortData?.managers?.length ? [] : [{ name: 'Managers' }]),
            ...(!hideManager && cohortData?.managers?.length ? cohortData.managers : []),
        ];
    };

    const [tabListData, setTabListData] = useState(getListData());
    const [dropdownOpen, setDropdownOpen] = useState(false);

    const handleTabChange = (tabIndex) => {
        setSelectedTab(tabIndex);
        if (tabIndex === 0) {
            setTabListData(getListData());
        } else {
            const key = tabIndex === 1 ? 'departments' : 'managers';
            setTabListData(cohortData[key]);
        }
        search && handleSearch(search, tabIndex);
    };

    const handleSearch = (value, tabIndex) => {
        setSearch(value);
        const key = tabIndex === 1 ? 'departments' : 'managers';
        if (value && String(value).trim().length) {
            const dataToSearch = tabIndex === 0 ? getListData() : cohortData[key];
            const searchData = dataToSearch.filter((data) => data.name.toLowerCase().includes(value.toLowerCase()));
            setTabListData(searchData);
        } else {
            setTabListData(tabIndex === 0 ? getListData() : cohortData[key]);
        }
    };

    const handleSelection = (item) => {
        handleCohortSelection(item);
        setDropdownOpen(false);
    };

    useEffect(() => {
        selectedCohort?._id && setSelectedCohortId(selectedCohort?._id);
    }, [selectedCohort]);

    return (
        <CustomDropdown
            title={'Cohort'}
            selectedName={`${selectedCohort?.name}${selectedCohort?.type === 'manager' ? "'s team" : ''}`}
            buttonWidth={236}
            dropdownWidth={357}
            dropdownHeight={311}
            blockSelection={blockSelection}
            open={dropdownOpen}
            setOpen={setDropdownOpen}
        >
            <div className='cohort-dropdown-container'>
                <Tabs handleTabChange={handleTabChange} hideManager={hideManager} selectedTab={selectedTab} />
                <CustomSearchBox
                    containerClassName='dropdown-search'
                    width='100%'
                    handleSearch={(e) => handleSearch(e.target.value, selectedTab)}
                    placeholder={TAB_SEARCH_PLACEHOLDER[selectedTab]}
                />
                <div className='dropdown-list-container'>
                    <DropdownList
                        data={tabListData}
                        selectedCohort={selectedCohort}
                        selectedCohortId={selectedCohortId}
                        selectedTab={selectedTab}
                        handleSelection={handleSelection}
                    />
                </div>
            </div>
        </CustomDropdown>
    );
};

Index.propTypes = {
    cohortData: PropTypes.object,
    handleCohortSelection: PropTypes.func,
    selectedCohort: PropTypes.object,
    blockSelection: PropTypes.bool,
};

export default Index;
