import React from 'react';
import { ReactComponent as RewardRecognitionLogo } from 'Assets/images/rewards-recognitions-logo.svg';
import { ReactComponent as LeaderboardLogo } from 'Assets/images/leaderboard-logo.svg';
import { ReactComponent as BonusLogo } from 'Assets/images/bonus-logo.svg';
import { ReactComponent as CatalogLogo } from 'Assets/images/catalog-logo.svg';

export const bannerItems = [
    {
        heading: 'Rewards and Recognitions',
        subHeading: 'Send Kudos and Shoutout instantly\nto your teammates to recognize\nawesome work',
        image: <RewardRecognitionLogo className='banner-image' />,
    },
    {
        heading: 'Leaderboard',
        subHeading:
            'Track your employee recognition\nmetrics. Know the most recognized\nand recognizers in the organization',
        image: <LeaderboardLogo className='banner-image' />,
    },
    {
        heading: 'Bonus',
        subHeading: 'Award a bonus to your top\nperforming employees for acing\ntheir work like a pro',
        image: <BonusLogo className='banner-image' />,
    },
    {
        heading: 'Catalog',
        subHeading:
            'Offer employees the rewards they\nwant. From popular gift cards,\nbranded company swag, to awesome\nbrands, we have it covered',
        image: <CatalogLogo className='banner-image' />,
    },
];

export const rnrIntroDetails = {
    heading: 'Rewards and Recognitions',
    subHeading:
        'Build a culture of appreciation that translates into a great workplace experience\n and improved organizational performance.',
    buttonText: 'Set up Rewards and Recognitions',
};
