import React from 'react';
import './style.scss';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { ReactComponent as EngageWithLogo } from 'Assets/images/EngageWith_Logo.svg';
import RnRStepper from '../RNRStepper';
import { STEPS, SIDEPANEL } from '../../Services/constant';
import EWButton from 'components/ReusableComponents/EWButton';

const Index = ({ children, activeStep, stepStatus, handleStep, checkCompleted, loading }) => {
    const currentStep = STEPS[activeStep - 1];
    const sidePanel = SIDEPANEL();
    return (
        <div className='rnr-wrapper'>
            {/* Logo */}
            <div className='logo'>
                <EngageWithLogo />
            </div>
            {/* Left Part */}
            <div className='body-left'>
                <div className=' rnr-container'>
                    {/* Stepper */}
                    <RnRStepper steps={STEPS} stepStatus={stepStatus} activeStep={activeStep} handleStep={handleStep} />

                    {/* Header */}
                    <div className='recognition-setup'>
                        <p className='title'>{currentStep?.title}</p>
                        <p className='subtitle'>{currentStep?.subtitle}</p>
                    </div>

                    {/* RnR Setup - Main */}
                    {children}

                    {/* Action Buttons */}
                    <div className='rnr-actions'>
                        <EWButton
                            buttonText={activeStep === 3 ? 'Finish Setup' : 'Next'}
                            onClick={() => handleStep(currentStep?.next)}
                            disabled={!checkCompleted[activeStep - 1] || loading}
                            loading={loading}
                        />
                    </div>
                </div>
            </div>

            {/* Right Part */}
            <div className='body-right'>
                <div className='info-container'>
                    {sidePanel?.[activeStep - 1]?.info?.map((info) => (
                        <div className='side-info' key={info.question}>
                            <p>{info?.question}</p>
                            <p>{info?.description}</p>
                        </div>
                    ))}
                </div>
                <div className={clsx('img-container ', { 'reward-img': activeStep === 2 })}>
                    <span className='rnr-img'>{sidePanel?.[activeStep - 1]?.imageSrc}</span>
                </div>
            </div>
        </div>
    );
};

Index.propTypes = {
    children: PropTypes.any,
    activeStep: PropTypes.number,
    stepStatus: PropTypes.array,
    handleStep: PropTypes.func,
    checkCompleted: PropTypes.array,
    loading: PropTypes.bool,
};

export default Index;
