import React from 'react';
import EllipsisText from 'components/ReusableComponents/EllipsisText';
import EWButton from 'components/ReusableComponents/EWButton';
import PropTypes from 'prop-types';
import { PLATFORM } from 'constants.js';
import './style.scss';

const Index = ({ organization, selectedOrganization, handleSelectOrganization, platform }) => {
    const getName = (organization) => {
        if (platform.toLowerCase() === PLATFORM.SLACK.toLowerCase()) {
            return 'Slack organizations';
        } else if (platform.toLowerCase() === PLATFORM.TEAMS.toLowerCase()) {
            return 'Teams organizations';
        } else {
            return organization.name;
        }
    };

    return (
        <div className='container-organization'>
            <EWButton
                plainTextButton={true}
                primary={false}
                buttonStyleClass={`organization-list ${
                    selectedOrganization?._id === organization._id ? 'selected' : ''
                }`}
                whiteBorder={true}
                width='378px'
                height='42px'
                onClick={() => handleSelectOrganization(organization)}
            >
                {platform.toLowerCase() === PLATFORM.WEB.toLowerCase() && (
                    <div className='organization-profile-icon ml-1'>{organization.name.charAt(0).toUpperCase()}</div>
                )}

                {platform.toLowerCase() === PLATFORM.SLACK.toLowerCase() && (
                    <div className='organization-profile-pic'>
                        <img src={require(`Assets/images/icon-slack.svg`)} alt='org' />
                    </div>
                )}
                {platform.toLowerCase() === PLATFORM.TEAMS.toLowerCase() && (
                    <div className='organization-profile-pic'>
                        <img src={require(`Assets/images/icon-ms-teams.svg`)} alt='org' />
                    </div>
                )}
                <EllipsisText text={getName(organization)} white maxWidth='320px' customClassName='organization-name' />
            </EWButton>
        </div>
    );
};

Index.propTypes = {
    organization: PropTypes.array,
    selectedOrganization: PropTypes.object,
    handleSelectOrganization: PropTypes.func,
    platform: PropTypes.string,
};

export default Index;
