import { getData, postData, patchData, deleteData } from 'Services/apiCall';
import { format } from 'date-fns';

export const getEmployeeAwardsTemplates = async () => {
    try {
        const { data } = await getData('employee-awards/template');
        return data;
    } catch (err) {
        throw new Error(err?.response?.data?.message);
    }
};

export const createCustomAward = async ({ awardData }) => {
    try {
        return await postData('employee-awards/template', awardData);
    } catch (err) {
        throw new Error(err?.response?.data?.message);
    }
};

export const editCustomAward = async ({ awardData }) => {
    try {
        return await patchData('employee-awards/template', awardData);
    } catch (err) {
        throw new Error(err?.response?.data?.message);
    }
};

export const deleteAwardTemplate = async (id) => {
    try {
        return await deleteData(`employee-awards/template/${id}`);
    } catch (err) {
        throw new Error(err?.response?.data?.message);
    }
};

export const getAwardDetails = async ({ queryKey: [_key, id] }) => {
    try {
        const response = await getData(`employee-awards/details/${id}`);
        return response.data;
    } catch (err) {
        throw new Error(err?.response?.data?.message);
    }
};

export const launchAward = async ({ awardData }) => {
    try {
        return await postData('employee-awards', awardData);
    } catch (err) {
        throw new Error(err?.response?.data?.message);
    }
};

export const editLaunchedAward = async ({ awardData }) => {
    try {
        return await patchData('employee-awards', awardData);
    } catch (err) {
        throw new Error(err?.response?.data?.message);
    }
};

export const deleteAward = async (awardId) => {
    try {
        return await deleteData(`employee-awards/${awardId}`);
    } catch (err) {
        throw new Error(err?.response?.data?.message);
    }
};

export const getReadyToLaunchAwards = async () => {
    try {
        const { data } = await getData('employee-awards/ready-to-launch-awards');
        return data;
    } catch (err) {
        throw new Error(err?.response?.data?.message);
    }
};

export const extendVotingDeadline = async (awardId) => {
    try {
        return await postData('employee-awards/extend-deadline', { awardId });
    } catch (err) {
        throw new Error(err?.response?.data?.message);
    }
};

export const getDataToPickAwardee = async ({ queryKey: [_key, awardId] }) => {
    try {
        const data = await getData(`employee-awards/winners-list/${awardId}`);
        return data?.data?.[0] || {};
    } catch (err) {
        throw new Error(err?.response?.data?.message);
    }
};

export const announceWinner = async (awardId, winnerId, awardType) => {
    try {
        return await patchData('employee-awards/announce-winner', { winnerId, awardId, awardType });
    } catch (err) {
        throw new Error(err?.response?.data?.message);
    }
};

export const sendVotingReminder = async (awardId) => {
    try {
        return await postData('employee-awards/send-reminder', { awardId });
    } catch (err) {
        throw new Error(err?.response?.data?.message);
    }
};

export const getRecentAwardees = async () => {
    try {
        const { data } = await getData('employee-awards/leaderboard?page=1&dateRange=yearly');
        return data || [];
    } catch (err) {
        throw new Error(err?.response?.data?.message);
    }
};

export const getEmployeeAwardsCount = async ({ queryKey: [_key] }) => {
    try {
        const { data } = await getData('employee-awards/status-count-based-on-type');
        return data || {};
    } catch (err) {
        throw new Error(err?.response?.data?.message);
    }
};

export const getMyActivityAwards = async ({ queryKey: [_key, page, sortBy, sortOrder, dateRange, search = ''] }) => {
    try {
        const response = await postData('employee-awards/activities/me', {
            page,
            sortBy,
            sortOrder,
            ...(dateRange && { dateRange }),
            ...(search && { search }),
        });
        return response.data;
    } catch (err) {
        throw new Error(err?.response?.data?.message);
    }
};

export const getAllActivityAwards = async ({
    queryKey: [_key, page, sortBy, sortOrder, dateRange, search = '', awardType],
}) => {
    try {
        const response = await postData('employee-awards/activities/all', {
            page,
            sortBy,
            sortOrder,
            ...(dateRange && { dateRange }),
            ...(search && { search }),
            ...(awardType && { awardType }),
        });
        return response.data;
    } catch (err) {
        throw new Error(err?.response?.data?.message);
    }
};

export const getEmployeeAwardsList = async ({
    queryKey: [_key, { searchTerm, awardType, status, dateRange, dateType, pageNumber, limit, sort }],
}) => {
    try {
        if (!limit) limit = 10;
        if (dateRange?.startDate && !dateRange?.endDate) {
            dateRange = {
                endDate: format(new Date(), 'dd-MM-yyyy'),
                ...dateRange,
            };
        }
        let path = `employee-awards/award-list?limit=${limit}&`;
        if (searchTerm) path += `search=${searchTerm}&`;
        if (awardType) path += `awardType=${awardType}&`;
        if (status) path += `awardStatus=${status}&`;
        if (dateRange?.startDate) path += `startDate=${dateRange.startDate}&`;
        if (dateRange?.endDate) path += `endDate=${dateRange.endDate}&`;
        if (dateType) path += `dateType=${dateType}&`;
        if (sort?.key) path += `sortBy=${sort.key}&`;
        if (sort?.direction) path += `sortOrder=${sort.direction}&`;
        if (pageNumber) path += `page=${pageNumber}`;
        const data = await getData(path);
        return data?.data || [];
    } catch (error) {
        throw new Error(error?.response?.data?.message);
    }
};
