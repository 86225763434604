import React from 'react';
import CloseIcon from '@material-ui/icons/Close';
import Modal from '@material-ui/core/Modal';
import { BeatLoader } from 'react-spinners';
import CustomFilterDropdown from 'components/ReusableComponents/CustomFilterDropdown';
import './style.scss';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import RadioComponent from '../RadioComponent';
import { ReactComponent as Warning } from 'Assets/images/warning.svg';

const Index = ({
    open,
    onClose,
    onCancel,
    onConfirm,
    data,
    red,
    blue,
    loading,
    singleButton,
    smallHeadingFont,
    height = '196px',
    width = '528px',
    showDropDown,
    handleDropdownSelection,
    dropdownData = [],
    selectedDropdownOption,
    disableButton = false,
    customClassname,
    textArea,
    reason,
    handleChange,
    showRadioButtons,
    instances,
    selectedOption,
    setSelectedOption,
    isRollingPulse,
    questionsToBeRolled,
    totalQuestions,
    showIcon,
    subHeadingColor,
}) => {
    if (!open) return null;

    return (
        <Modal
            open={open}
            onClose={() => onClose(false)}
            className={clsx({
                [customClassname]: customClassname,
            })}
        >
            <div style={{ height: textArea ? '258px' : height, width }} className={'confirm-container'}>
                <CloseIcon data-testid='btn-close' className='btn-close' onClick={() => onClose(false)} />
                <div className='heading-container'>
                    {showIcon && <Warning className='warning-icon' />}
                    <h3 className={clsx({ heading: true, 'font-size-16': smallHeadingFont })}>{data?.heading}</h3>
                </div>
                {data?.subHeading && (
                    <p
                        className='subHeading'
                        style={{ color: subHeadingColor }}
                        dangerouslySetInnerHTML={{ __html: data.subHeading }}
                    ></p>
                )}
                {data?.image && <img className='confirm-modal-image' src={data.image} alt='dataImage' />}
                {showDropDown && (
                    <CustomFilterDropdown
                        filterOptions={dropdownData}
                        optionsSelected
                        handleSelection={handleDropdownSelection}
                        selectedName={selectedDropdownOption}
                        dropDownID='selectChannelDropdown'
                        buttonStyleClass='width-292 mt-3'
                        singleSelect
                        search
                        dropdownHeight={170}
                    />
                )}
                {textArea && (
                    <textarea
                        className='text-area'
                        value={reason}
                        placeholder='Type your reason here.'
                        onChange={handleChange}
                    />
                )}
                {showRadioButtons && (
                    <div>
                        {instances.map((instance) => (
                            <RadioComponent
                                key={instance.value}
                                heading={instance.HEADING}
                                subHeading={instance.SUBHEADING}
                                selectedOption={selectedOption}
                                setSelectedOption={setSelectedOption}
                                value={instance.value}
                            />
                        ))}
                    </div>
                )}
                {isRollingPulse && (
                    <div>
                        <p className='font-weight-600 mt-4'>
                            {questionsToBeRolled || totalQuestions} questions yet to be sent
                        </p>
                    </div>
                )}
                <div className='button-container'>
                    {!singleButton && (
                        <button
                            className={`${blue ? 'cancel-blue-btn' : 'cancel-btn'}`}
                            onClick={() => (onCancel ? onCancel() : onClose(true))}
                            disabled={loading}
                        >
                            {data?.cancel || 'Cancel'}
                        </button>
                    )}

                    <button
                        className={`ew-btn ${red ? 'pr-btn' : 'pb-btn'}`}
                        onClick={onConfirm}
                        disabled={loading || disableButton}
                        data-testid={loading ? 'loading' : 'not-loading'}
                    >
                        {loading ? (
                            <BeatLoader size={10} color={'#fff'} loading={loading} />
                        ) : (
                            data?.confirm || 'Delete'
                        )}
                    </button>
                </div>
            </div>
        </Modal>
    );
};

Index.propTypes = {
    open: PropTypes.bool,
    onCancel: PropTypes.func,
    onClose: PropTypes.func,
    onConfirm: PropTypes.func,
    data: PropTypes.object,
    height: PropTypes.string,
    width: PropTypes.string,
    red: PropTypes.bool,
    blue: PropTypes.bool,
    loading: PropTypes.bool,
    singleButton: PropTypes.bool,
    smallHeadingFont: PropTypes.bool,
    disableButton: PropTypes.bool,
    showDropDown: PropTypes.bool,
    handleDropdownSelection: PropTypes.func,
    dropdownData: PropTypes.array,
    selectedDropdownOption: PropTypes.string,
    customClassname: PropTypes.string,
    textArea: PropTypes.bool,
    reason: PropTypes.string,
    handleChange: PropTypes.func,
    showRadioButtons: PropTypes.bool,
    HEADING: PropTypes.shape({
        thisInstance: PropTypes.string,
        futureInstance: PropTypes.string,
        allInstances: PropTypes.string,
    }),
    SUBHEADING: PropTypes.shape({
        thisInstance: PropTypes.string,
        futureInstance: PropTypes.string,
        allInstances: PropTypes.string,
    }),
    selectedOption: PropTypes.string,
    setSelectedOption: PropTypes.func,
    isRollingPulse: PropTypes.bool,
    questionsToBeRolled: PropTypes.number,
    totalQuestions: PropTypes.number,
    showIcon: PropTypes.bool,
    instances: PropTypes.array,
    subHeadingColor: PropTypes.string,
};

export default Index;
