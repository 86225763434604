import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Switch, Route } from 'react-router-dom';
import { useToaster } from 'Context/SnackbarContext';
import { useQuery } from 'react-query';
import { GET_ANALYTICS_COHORT_DATA } from 'Services/apiKeys';
import { getAnalyticsCohortData } from 'Services/apiFunctions';
import { ANALYTICS_GROUPBY } from 'constants.js';
import Analytics from './Analytics';
import { ANALYTICS } from './constants';
import AnalyticsTables from './AnalyticsTables';
import { getDefaultDateRange, showSnackBarMessage } from 'utils/HelperFunctions';
import './style.scss';

export default function Index({ history }) {
    const [selectedCohort, setSelectedCohort] = useState();
    const [selectedDateRange, setSelectedDateRange] = useState(getDefaultDateRange());
    const [analyticsGroupBy, setAnalyticsGroupBy] = useState(ANALYTICS_GROUPBY.RECOGNITIONS);
    // view for the line chart - weekly, monthly or yearly
    const [view, setView] = useState('weekly');

    const { SetSnackbar } = useToaster();

    const { data: cohortData, isLoading } = useQuery([GET_ANALYTICS_COHORT_DATA], getAnalyticsCohortData, {
        onSuccess: (analyticsCohortData) => setSelectedCohort(analyticsCohortData?.organisation?.[0]),
        onError: (err) => showSnackBarMessage(SetSnackbar, 'error', err?.message),
    });

    return (
        <div>
            <Switch>
                <Route
                    exact
                    path={ANALYTICS.ANALYTICS_DASHBOARD}
                    render={() => (
                        <Analytics
                            history={history}
                            cohortData={cohortData}
                            isLoading={isLoading}
                            selectedCohort={selectedCohort}
                            setSelectedCohort={setSelectedCohort}
                            selectedDateRange={selectedDateRange}
                            setSelectedDateRange={setSelectedDateRange}
                            view={view}
                            setView={setView}
                            analyticsGroupBy={analyticsGroupBy}
                            setAnalyticsGroupBy={setAnalyticsGroupBy}
                        />
                    )}
                />
                <Route
                    exact
                    path={ANALYTICS.ANALYTICS_USERS}
                    render={() => (
                        <AnalyticsTables
                            history={history}
                            title={'Users'}
                            cohortData={cohortData}
                            isLoading={isLoading}
                            selectedCohort={selectedCohort}
                            setSelectedCohort={setSelectedCohort}
                            selectedDateRange={selectedDateRange}
                            setSelectedDateRange={setSelectedDateRange}
                            users
                            setView={setView}
                        />
                    )}
                />
                <Route
                    exact
                    path={ANALYTICS.ANALYTICS_MANAGERS}
                    render={() => (
                        <AnalyticsTables
                            history={history}
                            title={'Manager Insights'}
                            cohortData={cohortData}
                            isLoading={isLoading}
                            selectedCohort={selectedCohort}
                            setSelectedCohort={setSelectedCohort}
                            selectedDateRange={selectedDateRange}
                            setSelectedDateRange={setSelectedDateRange}
                            managers
                            setView={setView}
                        />
                    )}
                />
                <Route
                    exact
                    path={ANALYTICS.ANALYTICS_DEPARTMENTS}
                    render={() => (
                        <AnalyticsTables
                            history={history}
                            title={'Departments'}
                            cohortData={cohortData}
                            isLoading={isLoading}
                            selectedCohort={selectedCohort}
                            setSelectedCohort={setSelectedCohort}
                            selectedDateRange={selectedDateRange}
                            setSelectedDateRange={setSelectedDateRange}
                            department
                            setView={setView}
                        />
                    )}
                />
            </Switch>
        </div>
    );
}

Index.propTypes = {
    history: PropTypes.object,
};
