import Cookies from 'js-cookie';
import { LOCALSTORAGE_KEYS } from 'utils/localStorageKeys';
import { ROUTES } from 'constants.js';
import store from 'redux/store';

const getWorkSpaceId = () => {
    const data = store.getState();
    return data.Workspace.team;
};

export const userLogout = () => {
    try {
        clearStorage();
        Cookies.remove('AuthToken');
        window.location.href = ROUTES.LOGIN;
        return true;
    } catch (error) {
        return false;
    }
};

export function setTimestamp(timestamp) {
    const workspaceId = getWorkSpaceId();
    let timestamps = JSON.parse(localStorage.getItem('timestamps')) || {};
    timestamps[workspaceId] = timestamp;
    localStorage.setItem('timestamps', JSON.stringify(timestamps));
}

export function getTimestamp() {
    const workspaceId = getWorkSpaceId();
    const timestamps = JSON.parse(localStorage.getItem('timestamps')) || {};
    return timestamps[workspaceId];
}

function clearStorage() {
    let timestamps = JSON.parse(localStorage.getItem('timestamps')) || {};

    Object.keys(localStorage).forEach((key) => {
        if (key !== 'timestamps') {
            localStorage.removeItem(key);
        }
    });

    localStorage.setItem('timestamps', JSON.stringify(timestamps));
}

export const inactiveRedirect = () => {
    try {
        clearStorage();
        Cookies.remove('AuthToken');
        window.open(process.env.REACT_APP_BASE_URL + 'inactive', '_self');
        return true;
    } catch (error) {
        return false;
    }
};

export function getUserFromLocalStorage() {
    try {
        return JSON.parse(localStorage.getItem('user') || null);
    } catch (error) {
        return null;
    }
}

/**
 * Method redirects to target location while maintaining an active state in nav bar.
 * @param {Object} history
 * @param {String} redirectionURL
 * @param {String} navMenuCode - Fixed codes defines in HISTORY_FROM
 */
export const handlePageRedirectionWithinSubMenu =
    ({ history, redirectionURL, navMenuCode }) =>
    () => {
        localStorage.setItem(LOCALSTORAGE_KEYS.NAVIGATION_FROM, navMenuCode);
        history.push(redirectionURL, { from: navMenuCode });
    };
