import { countryMap, timezones } from '../constants';

// default fallback is India as timezone
export const getTimeZoneList = (country) => {
    let timezoneOptions;
    if (country === 'United States of America') {
        timezoneOptions = timezones.filter((item) => item.countryCode === 'US');
    } else {
        timezoneOptions = timezones.filter((item) => item.countryCode === countryMap[country]) || [];
    }

    return timezoneOptions?.length
        ? timezoneOptions.map((item) => ({ label: item.timezone, value: item.timezone }))
        : [{ label: 'Asia/Kolkata', value: 'Asia/Kolkata' }];
};
