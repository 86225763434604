import React, { useState } from 'react';
import Header from '../CustomTableContainer/Header';
import List from '../CustomTableContainer/List';
import NewOrganisationModal from 'components/Modals/NewOrganisationModal';
import { useToaster } from 'Context/SnackbarContext';
import { debounce } from 'lodash';
import ConfirmModal from '../CustomTableContainer/ConfirmModal';
import { getDepartments, deleteDepartment } from 'Services/apiFunctions';
import { EDIT, DELETE, ORG_MENU_OPTION } from '../constants';
import CustomLoader from 'components/ReusableComponents/CustomLoader';
import { useQuery, useQueryClient, useMutation } from 'react-query';
import { GET_PAGINATED_DEPARTMENT } from 'Services/apiKeys';
import { showSnackBarMessage } from 'utils/HelperFunctions';
import './style.scss';

export default function Index() {
    const queryClient = useQueryClient();
    const btnName = '+Add new department';
    const searchPlaceholder = 'Search departments';
    const noSortColumns = [0, 1, 2];
    const columnWidth = ['33%', '66%', '1%'];
    const listHeader = ['department', 'active members'];
    const { SetSnackbar } = useToaster();
    const [open, setOpen] = useState(false);
    const DEPARTMENT = 'department';
    const [editData, setEditData] = useState(undefined);
    const [currentPage, setCurrentPage] = useState(1);
    const [confirmModal, setConfirmModal] = useState({ open: false, type: '', department: {} });
    const [search, setSearch] = useState('');
    const limit = 10;

    const {
        data: departmentData,
        isLoading,
        isFetching,
    } = useQuery([GET_PAGINATED_DEPARTMENT, currentPage, limit, search], getDepartments, {
        keepPreviousData: true,
        refetchOnWindowFocus: false,
        retry: 0,
    });

    const handleDeleteDepartment = () => {
        setCurrentPage(1);
        showSnackBarMessage(SetSnackbar, 'success', `${confirmModal.department.name} deleted Successfully!`);
        queryClient.invalidateQueries(GET_PAGINATED_DEPARTMENT);
        setConfirmModal({ open: false, type: '', department: {} });
    };

    const { mutate: removeDepartment, isLoading: deletingDepartment } = useMutation(deleteDepartment, {
        onSuccess: handleDeleteDepartment,
        onError: () => setConfirmModal({ open: false, type: '', department: {} }),
    });

    const handleConfimModalOK = async () => {
        if (confirmModal.type === DEPARTMENT) {
            removeDepartment(confirmModal.department.id);
        }
    };

    // Menu Operations
    const handleMenuOption = (option, department) => {
        switch (option.value) {
            case EDIT:
                setEditData(department);
                setOpen(!open);
                break;
            case DELETE:
                setConfirmModal({ open: true, type: DEPARTMENT, department });
                break;
            default:
                break;
        }
    };

    const addDepartment = () => {
        setEditData(undefined);
        setOpen(!open);
    };

    const handleSearchChange = debounce((value) => {
        setCurrentPage(1);
        setSearch(value);
    }, 300);

    if (isLoading) {
        return <CustomLoader marginTop />;
    }

    if (!search && departmentData?.totalCount < 1) {
        return (
            <div>
                <div className='empty-department'>
                    <img src={require('Assets/images/empty-department.svg')} alt='empty-department' className='mt-5' />
                    <br />
                    <button className='ew-btn pb-btn mt-3 ' onClick={addDepartment}>
                        +Add new Department
                    </button>
                </div>
                {open && (
                    <NewOrganisationModal
                        organisationType={DEPARTMENT}
                        setOrgModal={setOpen}
                        setCurrentPage={setCurrentPage}
                        editData={editData}
                    />
                )}
            </div>
        );
    }

    return (
        <>
            <div className='mt-2 overall-dept-container'>
                <Header
                    title={`${departmentData?.totalCount || 0} Departments`}
                    btnName={btnName}
                    handleSearch={handleSearchChange}
                    searchPlaceholder={searchPlaceholder}
                    handleAddButton={addDepartment}
                />
                {departmentData?.departments?.length > 0 ? (
                    <List
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                        noSortColumns={noSortColumns}
                        columnWidth={columnWidth}
                        listHeader={listHeader}
                        totalCount={departmentData?.totalCount}
                        listData={departmentData?.departments}
                        menuOption={ORG_MENU_OPTION}
                        location={false}
                        loading={isLoading}
                        fetching={isFetching}
                        handleMenuOption={handleMenuOption}
                        handlePagination={setCurrentPage}
                        className='shadow-none'
                    />
                ) : (
                    <div className='empty-department'>
                        <img src={require('Assets/images/empty-flag.svg')} alt='empty-flag' className='mt-5' />
                        <p className='empty-state-content mt-4'>No results found</p>
                    </div>
                )}
            </div>
            <ConfirmModal
                open={confirmModal.open}
                type={confirmModal.type}
                name={confirmModal.department?.name || ''}
                onConfirm={handleConfimModalOK}
                onClose={() => setConfirmModal({ open: false })}
                loading={deletingDepartment}
            />
            {open && (
                <NewOrganisationModal
                    organisationType={DEPARTMENT}
                    setOrgModal={setOpen}
                    setCurrentPage={setCurrentPage}
                    editData={editData}
                />
            )}
        </>
    );
}
