import React from 'react';
import { ReactComponent as DeleteIcon } from 'Assets/images/deleteIcon.svg';
import { ReactComponent as EditIcon } from 'Assets/images/edit-blue.svg';
import VertMenu from 'components/ReusableComponents/VertMenu';
import EWButton from 'components/ReusableComponents/EWButton';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import PropTypes from 'prop-types';
import './style.scss';

const Index = ({ handleAction, recognitionTypeData, removeDeleteOption }) => {
    const menuOptions = [
        {
            label: (
                <div>
                    <EditIcon className='mr-3 ml-0 pointer-cursor' />
                    <EWButton
                        buttonText='Edit'
                        primary={false}
                        plainTextButton={true}
                        height={24}
                        buttonStyleClass='dropdown-items'
                    />
                </div>
            ),
            value: 'edit',
        },
        {
            label: (
                <div>
                    <DeleteIcon className='dropdown-delete-icon' />
                    <EWButton
                        buttonText='Delete'
                        primary={false}
                        plainTextButton={true}
                        height={24}
                        buttonStyleClass='dropdown-items'
                    />
                </div>
            ),
            value: 'delete',
        },
    ];

    const filteredMenuOptions = removeDeleteOption
        ? menuOptions.filter((option) => option.value !== 'delete')
        : menuOptions;

    return (
        <div style={{ width: '50px', height: '40px' }}>
            <VertMenu
                optionMenu={filteredMenuOptions}
                className='mr-2 pointer-cursor'
                placement={'bottom-end'}
                menuWidth='180px'
                getSelected={(optionIndex) => handleAction(filteredMenuOptions[optionIndex].value, recognitionTypeData)}
            >
                <MoreHorizIcon />
            </VertMenu>
        </div>
    );
};

Index.propTypes = {
    handleAction: PropTypes.func,
    recognitionTypeData: PropTypes.object,
    removeDeleteOption: PropTypes.bool,
};

export default Index;
