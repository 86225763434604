import React from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import jwt_decode from 'jwt-decode';
import { userLogout } from 'utils/SessionUtils/sessionUtils';
import './style.scss';

const Index = ({ location }) => {
    const param = queryString.parse(location.search);
    if (!param.token || param.token === 'undefined' || param.token === null || param.token === '') {
        userLogout();
        return null;
    }
    const adminList = jwt_decode(param.token).adminList || [];
    return (
        <div className='middle-element-inactive'>
            <img
                src={require('Assets/images/inactive.svg')}
                alt='Your profile is currently inactive, please contact your admin for access.'
            />
            <p className='text-center'>
                Your EngageWith profile is inactive. Ask your EngageWith admin to activate your profile. <br />
                {adminList?.length > 0 && (
                    <>
                        You can reach out to{' '}
                        {adminList.map((admin, i) => admin.name + (i === adminList.length - 1 ? '' : ', '))}
                    </>
                )}
            </p>
        </div>
    );
};

Index.propTypes = {
    location: PropTypes.object,
};

export default Index;
