import styled from 'styled-components';

export const PageWrapper = styled.div`
    margin: 0 auto 0;
    .content {
        margin: 0 auto 0;
        width: 688px;
    }
    .loadingContent {
        display: flex;
        justify-content: center;
        margin: 0 auto 0;
    }
`;

export const BlockWrapper = styled.div`
    padding: 20px 25px;
    width: 688px;
    margin: 20px auto;
    box-shadow: 0 0 8px rgba(57, 68, 105, 0.05);
    background: #ffffff;
    border-radius: 8px;
`;

export const CentredBox = styled(BlockWrapper)`
    display: flex;
    justify-content: center;
    align-items: center;
`;
