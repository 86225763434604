import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import EWModal from 'components/ReusableComponents/EWModal';
import InputField from 'components/ReusableComponents/InputField';
import { CustomCheckbox } from 'components';
import { shallowEqual, useSelector } from 'react-redux';
import { useMutation } from 'react-query';
import { showSnackBarMessage, getChannelNameByPlatform } from 'utils/HelperFunctions';
import { useToaster } from 'Context/SnackbarContext';
import { sendSuggestion } from '../../Services/apiFunctions';
import { FEEDBACK_TEXT_LENGTH_LIMIT } from 'constants.js';
import './style.scss';
import EnhanceWithAI from 'components/ReusableComponents/EnhanceWithAI';

const Index = ({ open, onSuccess, onClose, history }) => {
    const { SetSnackbar } = useToaster();
    const { suggestionConfigs, platform } = useSelector(mapStateToProps, shallowEqual);
    const [suggestionMessage, setSuggestionMessage] = useState('');
    const [anonymous, setAnonymous] = useState(false);
    const [isAiEnhanced, setIsAiEnhanced] = useState(false);
    const [postPublicly, setPostPublicly] = useState(false);

    const { mutate: sendSuggestionMessage, isLoading: isSendingMessage } = useMutation(sendSuggestion, {
        onSuccess: (data) => {
            onSuccess(data?.messageUrl, data?.receiver, data?.anonymous);
        },
        onError: (err) => showSnackBarMessage(SetSnackbar, 'error', err?.message),
    });

    useEffect(() => {
        if (suggestionConfigs?.allowAnonymous) {
            setAnonymous(suggestionConfigs?.allowAnonymous);
        }
    }, [suggestionConfigs]);

    const handleSendSuggestion = () => {
        if (suggestionMessage.length > FEEDBACK_TEXT_LENGTH_LIMIT) {
            showSnackBarMessage(
                SetSnackbar,
                'error',
                `Message length should be less than ${FEEDBACK_TEXT_LENGTH_LIMIT} characters`
            );
            return;
        }
        const apiData = {
            message: suggestionMessage,
            anonymous: anonymous,
            postPublicly: postPublicly,
            isAIEnhanced: isAiEnhanced,
        };

        sendSuggestionMessage({ apiData });
    };

    // Data for send suggestion modal
    const modalData = {
        heading: 'Suggestion Box',
        subHeading:
            'Give suggestions and start discussions with your organisation admin on policies, work culture, management etc.',
        rightButtonText: anonymous ? 'Send anonymously' : 'Send',
        handleRightButtonClick: handleSendSuggestion,
        leftButtonText: 'Cancel',
        handleLeftButtonClick: onClose,
        loading: isSendingMessage,
        disabled: !suggestionMessage,
    };

    return (
        <div>
            <EWModal open={open} onClose={onClose} width='528px' modalData={modalData}>
                <div className='container-send-suggestion'>
                    <InputField
                        inputID='suggestion_message'
                        label='Suggestion message'
                        labelClass='csf-dropdown-heading'
                        value={suggestionMessage}
                        placeholder='Enter your suggestion message here'
                        width='480px'
                        height='72px'
                        textArea
                        handleChange={(_id, value) => setSuggestionMessage(value)}
                    />
                    <EnhanceWithAI
                        textMessage={suggestionMessage}
                        isAiEnhanced={isAiEnhanced}
                        onMessageEnhanced={(message) => {
                            setSuggestionMessage(message);
                            setIsAiEnhanced(true);
                        }}
                        handleRevertoriginalMessage={(message) => {
                            setSuggestionMessage(message);
                            setIsAiEnhanced(false);
                        }}
                    />

                    {(suggestionConfigs?.postPublicly || suggestionConfigs?.allowAnonymous) && (
                        <div className='csf-config'>
                            <div className='csf-config-header'>Configurations (optional)</div>
                            {suggestionConfigs?.allowAnonymous && (
                                <>
                                    <div className='csf-checkbox'>
                                        <CustomCheckbox
                                            label='Anonymously'
                                            checked={anonymous}
                                            onClick={() => setAnonymous(!anonymous)}
                                        />
                                    </div>
                                    <div className='csf-config-subtext'>
                                        Your name will not be shared with the admin.
                                    </div>
                                </>
                            )}
                            {suggestionConfigs?.postPublicly && suggestionConfigs?.suggestionChannel && (
                                <>
                                    <div className='csf-checkbox'>
                                        <CustomCheckbox
                                            checked={postPublicly}
                                            onClick={() => setPostPublicly(!postPublicly)}
                                            label={'Post publicly'}
                                        />
                                    </div>
                                    <div className='csf-config-subtext'>
                                        Your suggestion will be posted in{' '}
                                        <a
                                            href={suggestionConfigs?.suggestionChannel?.url}
                                            target='_blank'
                                            className='channel-link'
                                        >
                                            {getChannelNameByPlatform(
                                                suggestionConfigs?.suggestionChannel.name,
                                                platform
                                            )}
                                        </a>
                                    </div>
                                </>
                            )}
                        </div>
                    )}
                </div>
            </EWModal>
        </div>
    );
};

const mapStateToProps = ({ Workspace }) => ({
    platform: Workspace.platform,
    suggestionConfigs: Workspace.suggestionBox,
});

Index.propTypes = {
    open: PropTypes.bool,
    onSuccess: PropTypes.func,
    onClose: PropTypes.func,
    history: PropTypes.object,
};

export default Index;
