import { actions } from './actionEvents';
import { postData } from 'Services/apiCall';
import { getRewardsCatalog } from 'Services/apiFunctions';
import { showSnackBarMessage } from 'utils/HelperFunctions';

export const SetXoxoday = (dispatch, data) => {
    dispatch({ type: actions.SET_XOXODAY, payload: data });
};

const showError = (error, SetSnackbar) => showSnackBarMessage(SetSnackbar, 'error', error?.response?.data?.message);

export const GetXoxoday = async (dispatch, payload, SetSnackbar) => {
    try {
        const { data } = await getRewardsCatalog({ apiData: payload });
        dispatch({ type: actions.SET_XOXODAY, payload: data });
    } catch (error) {
        showError(error, SetSnackbar);
    }
};

export const HandleXoxodayCountry = (dispatch, payload, conversionRate, currencySymbol, SetSnackbar) => {
    postData(`xoxoday/user/getVouchers`, payload)
        .then(({ data }) => {
            dispatch({
                type: actions.SET_XOXODAY,
                payload: { ...data, conversionRate: conversionRate, currencySymbol: currencySymbol },
            });
            return data;
        })
        .catch((error) => showError(error, SetSnackbar));
};
