import React from 'react';
import PropTypes from 'prop-types';
import UserButton from 'components/ReusableComponents/UserButton';
import { handlePageRedirectionWithinSubMenu } from 'utils/SessionUtils/sessionUtils';
import './style.scss';
import { HISTORY_FROM } from 'constants.js';

const LogItem = ({ history, date, userDetails, text }) => {
    const { _id, userName, pictureURL } = userDetails;

    const openUserProfile = () => {
        handlePageRedirectionWithinSubMenu({
            history,
            redirectionURL: `/dashboard/profile/${_id}`,
            navMenuCode: HISTORY_FROM.AUDIT_LOGS,
        })();
    };

    const processedText = text.replace(userName, '').trim();

    return (
        <div className='log-item'>
            <span className='log-date'>{date}</span>
            <div className='log-content'>
                <UserButton
                    senderName={userName}
                    showImage
                    onClick={openUserProfile}
                    imageURL={pictureURL}
                    isAnonymous={false}
                    clickableUser={true}
                />
                &nbsp;
                <span className='log-text' dangerouslySetInnerHTML={{ __html: processedText }} />
            </div>
        </div>
    );
};

LogItem.propTypes = {
    date: PropTypes.string,
    userDetails: PropTypes.object,
    text: PropTypes.string,
    history: PropTypes.object,
};

export default LogItem;
