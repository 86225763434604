import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from 'components/Styles/Tooltip';
import { ReactComponent as InfoIcon } from 'Assets/images/info-purple.svg';
import { formatDateRange } from 'utils/HelperFunctions';
import './style.scss';

const Index = ({ details, responseCount, eNPS, responseDetails, instanceNumber, status }) => {
    const { questions, requestedUsers, startAt, endAt } = details || {};
    const { completeResponseCount, partialResponseCount } = responseDetails || 0;
    if (!questions) {
        return null;
    }

    return (
        <div className='instance-detail-wrapper'>
            <span className='count-respondents'>
                {eNPS ? 'eNPS' : 'Pulse'} instance:<strong> {instanceNumber}</strong>
                <span className='status ml-2 capitalize'>{status}</span>&nbsp;&nbsp;&bull;&nbsp;&nbsp; No. of questions:{' '}
                <strong> {questions.length}</strong> &nbsp;&nbsp;&bull;&nbsp;&nbsp; Total Respondents:{' '}
                <strong>
                    {responseCount}/{requestedUsers?.length || 0}
                </strong>{' '}
                {/* need to update */}
                <Tooltip
                    arrow
                    title={`${completeResponseCount || 0} responded & ${partialResponseCount || 0} partially responded`}
                >
                    <InfoIcon className='info-icon' />
                </Tooltip>{' '}
                &nbsp;&nbsp;&bull;&nbsp;&nbsp; Duration: <strong>{formatDateRange(startAt, endAt)}</strong>
            </span>
        </div>
    );
};

Index.propTypes = {
    details: PropTypes.shape({
        questions: PropTypes.array,
        requestedUsers: PropTypes.array,
        startAt: PropTypes.string,
    }),
    responseCount: PropTypes.number,
    eNPS: PropTypes.bool,
    responseDetails: PropTypes.object,
    instanceNumber: PropTypes.string,
    status: PropTypes.string,
};

export default Index;
