import React from 'react';
import { ReactComponent as OpenChannelIcon } from 'Assets/images/icon-open-channel.svg';
import { ReactComponent as AddOnIcon } from 'Assets/images/ic_round-plus.svg';
import EWButton from 'components/ReusableComponents/EWButton';
import TextWithViewMore from 'components/ReusableComponents/TextWithViewMore';
import { Tooltip } from 'components';
import { shallowEqual, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import './style.scss';
import { PLATFORM } from 'constants.js';

const Index = ({
    recognitionData,
    showMessageTitle = true,
    showGiveAddOnButton = true,
    showAddOnValue = false,
    showRecognitionImage = true,
    showViewMoreButtonForMessage = false,
    showChannelIcon = false,
    handleGiveAddOnRecognition,
}) => {
    const { platform } = useSelector(mapStateToProps, shallowEqual);
    const isWebOnlyUser = platform === PLATFORM.WEB;

    return (
        <div className='container-recognition-message'>
            {showMessageTitle && (
                <div className='recognition-message-title'>
                    <p>
                        Here's what <strong>{recognitionData.sender.userName}</strong> has to say:
                    </p>
                    {!isWebOnlyUser && (
                        <EWButton
                            plainTextButton
                            primary={false}
                            buttonStyleClass='recognition-message-channel'
                            height='24px'
                            onClick={() => window.open(recognitionData.messageLink, '_blank')}
                        >
                            <span className='recognition-message-channel-content'>
                                {!showChannelIcon && (
                                    <>
                                        Sent on&nbsp;<strong>{recognitionData.channel.name}</strong>&nbsp;
                                    </>
                                )}
                                <Tooltip title={showChannelIcon ? `Sent on ${recognitionData.channel.name}` : ''}>
                                    <OpenChannelIcon />
                                </Tooltip>
                            </span>
                        </EWButton>
                    )}
                </div>
            )}
            {recognitionData?.message && (
                <div className='recognition-message'>
                    <div className='recognition-message-divider' />
                    <div className='d-flex flex-column'>
                        {showViewMoreButtonForMessage ? (
                            <TextWithViewMore text={recognitionData.message} maxLines={2} />
                        ) : (
                            <p className='recognition-message-content'>{recognitionData.message}</p>
                        )}
                        {showAddOnValue && recognitionData?.value && (
                            <p className='recognized-users-value'>
                                Value highlighted: <strong>{recognitionData.value}</strong>
                            </p>
                        )}
                    </div>
                </div>
            )}
            {showRecognitionImage && (
                <img className='recognition-image' src={recognitionData.gifLink} alt='recognition-message' />
            )}
            {showGiveAddOnButton && (
                <EWButton
                    plainTextButton
                    border
                    primary={false}
                    height='36px'
                    width='181px'
                    buttonStyleClass='recognition-btn-add-on'
                    onClick={() => handleGiveAddOnRecognition(recognitionData)}
                >
                    <span>
                        <AddOnIcon />
                        &nbsp; Add on recognition
                    </span>
                </EWButton>
            )}
            {recognitionData?.addOnRecognitions?.length > 0 && <div className='recognition-divider' />}
        </div>
    );
};

const mapStateToProps = (state) => ({
    platform: state.Workspace.platform,
});

Index.propTypes = {
    recognitionData: PropTypes.object,
    showMessageTitle: PropTypes.bool,
    showGiveAddOnButton: PropTypes.bool,
    showAddOnValue: PropTypes.bool,
    showRecognitionImage: PropTypes.bool,
    showViewMoreButtonForMessage: PropTypes.bool,
    showChannelIcon: PropTypes.bool,
    handleGiveAddOnRecognition: PropTypes.func,
};

export default Index;
