import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from 'react-query';
import { useToaster } from 'Context/SnackbarContext';
import CustomLoader from 'components/ReusableComponents/CustomLoader';
import Table from 'components/ReusableComponents/Table';
import CustomFilterDropdown from 'components/ReusableComponents/CustomFilterDropdown';
import EmptyContent from 'components/ReusableComponents/EmptyScreen';
import { ReactComponent as NoFeedbackImage } from 'Assets/images/no-feedback.svg';
import { shallowEqual, useSelector } from 'react-redux';
import { handlePageRedirectionWithinSubMenu } from 'utils/SessionUtils/sessionUtils';
import { HISTORY_FROM } from 'constants.js';
import { ReactComponent as Eye } from 'Assets/images/eye.svg';
import { ReactComponent as RedirectToSlack } from 'Assets/images/redirect-slack.svg';
import ReadFeedbackModal from '../../SuggestionBox/AllSuggestions/ReadSuggestionModal';
import UserButton from 'components/ReusableComponents/UserButton';
import { GET_FEEDBACK_LIST } from 'Services/apiKeys';
import { getFeedbackList } from '../Services/apiFunctions';
import { showSnackBarMessage } from 'utils/HelperFunctions';

const FeedbackTable = ({ history, feedbackGiven, data, setData, requestFeedbackHandler, sendFeebackHandler }) => {
    const { SetSnackbar } = useToaster();
    const { isAdmin } = useSelector(mapStateToProps, shallowEqual);

    const [page, setPage] = useState(1);
    const [sortOrder, setSortOrder] = useState('descending');
    const [selectedUser, setSelectedUser] = useState();
    const [readFeedbackModal, setReadFeedbackModal] = useState(false);
    const currentFeedback = useRef();

    const fromOptions = useRef([]);
    const selectedUserName = useRef();
    const [loading, setLoading] = useState(true);

    const type = feedbackGiven ? 'sent' : 'received';

    useQuery([GET_FEEDBACK_LIST, page, type, sortOrder, selectedUser], getFeedbackList, {
        onSuccess: (data) => {
            setFromDropdownOptions(data?.users);
            setData(data);
            setLoading(false);
        },
        onError: (error) => {
            showSnackBarMessage(SetSnackbar, 'error', error?.message);
            setFromDropdownOptions(data?.users);
            setLoading(false);
        },
    });

    const setFromDropdownOptions = (optionsData) => {
        const allOption = { userName: 'ALL', label: 'ALL' };
        const options =
            optionsData?.map((option) => ({
                ...option,
                label: option?.userName,
            })) || [];
        fromOptions.current = [allOption, ...options];
    };

    const handleSort = (_index) => setSortOrder(sortOrder === 'descending' ? 'ascending' : 'descending');

    const handleFromSelection = (index) => {
        setLoading(true);
        selectedUserName.current = fromOptions.current[index]?.userName;
        setSelectedUser(fromOptions.current[index]?._id);
        setPage(1);
    };

    const header = ['Date', feedbackGiven ? 'To' : 'From', 'Strength', 'Needs Improvement', 'Message'];
    const noSortColumns = [1, 2, 3, 4, 5, 6];
    const paginationProps = {
        fixedBottom: true,
        currentPage: page,
        totalCount: data?.total,
        setCurrentPage: setPage,
        onChange: () => setLoading(true),
    };
    const columnWidth = ['12%', '20%', '20%', '20%', '23%', '5%'];

    const openUserProfile = (item, key) => {
        let user;
        switch (key) {
            case 'sender':
                user = item.sender;
                break;

            case 'receiver':
                user = item.receiver;
                break;
            default:
                user = feedbackGiven ? item.receiver : item.sender;
                break;
        }
        if (isAdmin && user._id) {
            handlePageRedirectionWithinSubMenu({
                history,
                redirectionURL: `/dashboard/profile/${user._id}`,
                navMenuCode: HISTORY_FROM.FEEDBACK,
            })();
        }
    };

    const getSenderImageURL = (item, key) => {
        let url;
        switch (key) {
            case 'sender':
                url = item.sender.pictureURL;
                break;

            case 'receiver':
                url = item.receiver.pictureURL;
                break;
            default:
                url = feedbackGiven ? item.receiver.pictureURL : item.sender.pictureURL;
                break;
        }
        return url || require('Assets/images/defaultUser.png');
    };

    /**
     * Get the sender name from the sender object.
     * If sender is anonymous then sender will be string else object
     * @param {object} item
     * @returns userName
     */
    const getSenderName = (item, key) => {
        switch (key) {
            case 'sender':
                return typeof item.sender === 'object' ? item.sender.userName : item.sender;
            case 'receiver':
                return item.receiver.userName;
            default:
                if (!feedbackGiven) {
                    return typeof item.sender === 'object' ? item.sender.userName : item.sender;
                }
                return item.receiver.userName;
        }
    };

    const userButtonHandler = (item, key) => {
        // Don't show image when sender of the feedback is anonymous
        const showImage = feedbackGiven ? true : typeof item?.sender === 'object';
        const isAnonymous = item.sender === 'Anonymous' && type === 'received';
        return (
            <UserButton
                key={item._id}
                id={item._id}
                item={item}
                isAnonymous={isAnonymous}
                showImage={showImage}
                onClick={() => openUserProfile(item, key)}
                imageURL={getSenderImageURL(item, key)}
                senderName={getSenderName(item, key)}
                pointerEvents={isAdmin && !isAnonymous}
                clickableUser={!isAnonymous}
            />
        );
    };

    const feedbackTableData = data?.data?.map((item) => {
        return {
            id: item,
            row: [
                item.date,
                userButtonHandler(item),
                item.strength.join(', ') || '-',
                item.improvement.join(', ') || '-',
                <p key={item._id} className='my-feedback-message'>
                    {item?.message}
                </p>,
                <div className='vert-menu-wrapper' key={item._id}>
                    <Eye
                        key={item._id}
                        onClick={() => {
                            currentFeedback.current = item;
                            setReadFeedbackModal(true);
                        }}
                        style={{ cursor: 'pointer' }}
                    />
                    <RedirectToSlack
                        key={item._id}
                        onClick={(_index) => window.open(item.messageUrl, '_blank')}
                        style={{ cursor: 'pointer' }}
                    />
                </div>,
            ],
        };
    });

    if (loading) {
        return <CustomLoader />;
    }

    if (!loading && !feedbackTableData?.length) {
        return (
            <EmptyContent
                image={<NoFeedbackImage />}
                headingText={`No Feedback ${feedbackGiven ? 'given' : 'received'} as of now.`}
                btnName='Send feedback'
                action={sendFeebackHandler}
                leftBtn='Request feedback'
                leftOnClick={requestFeedbackHandler}
            />
        );
    }

    return (
        <div>
            <CustomFilterDropdown
                dropDownID={'filterFeedbackReceived'}
                title={feedbackGiven ? 'To' : 'From'}
                selectedName={selectedUserName?.current || 'ALL'}
                optionsSelected
                buttonStyleClass='width-188 mb-2'
                dropdownWidth={188}
                filterOptions={fromOptions.current}
                handleSelection={handleFromSelection}
                search
                singleSelect
            />
            <Table
                header={header}
                data={feedbackTableData}
                noSortColumns={noSortColumns}
                paginationProps={paginationProps}
                columnWidth={columnWidth}
                noShadow
                loading={loading}
                handleSort={handleSort}
            />
            {readFeedbackModal && (
                <ReadFeedbackModal
                    open={readFeedbackModal}
                    onClose={() => setReadFeedbackModal(false)}
                    data={currentFeedback.current}
                    openUserProfile={openUserProfile}
                    userButtonHandler={userButtonHandler}
                    isFeedbackReadableModal={true}
                />
            )}
        </div>
    );
};

const mapStateToProps = (state) => ({ isAdmin: state.User.isAdmin });

FeedbackTable.propTypes = {
    history: PropTypes.object,
    feedbackGiven: PropTypes.bool,
    data: PropTypes.object,
    setData: PropTypes.func,
    requestFeedbackHandler: PropTypes.func,
    sendFeebackHandler: PropTypes.func,
};

export default FeedbackTable;
