import React from 'react';
import PropTypes from 'prop-types';
import CustomSearchBox from 'components/Styles/CustomSearchBox';
import './style.scss';

export default function Index({ title, btnName, searchPlaceholder, handleSearch, handleAddButton }) {
    return (
        <div className='dept-header'>
            <h2 className='header-3'>{title}</h2>
            <CustomSearchBox
                placeholder={searchPlaceholder}
                width='315px'
                handleSearch={(event) => handleSearch(event.target.value)}
                containerClassName='dept-header-search'
            />
            <button className='header-btn ob-btn' onClick={handleAddButton}>
                {btnName}
            </button>
        </div>
    );
}

Index.propTypes = {
    title: PropTypes.string,
    btnName: PropTypes.string,
    searchPlaceholder: PropTypes.string,
    handleSearch: PropTypes.func,
    handleAddButton: PropTypes.func,
};
