import React from 'react';
import NotificationWrapper from '../../NotificationWrapper';
import PropTypes from 'prop-types';
import { ReactComponent as WalletIcon } from 'Assets/images/Notification-Payment-icon.svg';
import EWButton from 'components/ReusableComponents/EWButton';
import '../styles.scss';
import { ROUTES } from 'constants.js';

const Index = ({ history }) => {
    return (
        <div className='notification-event-container'>
            <div className=' auto-reload-disabled'>
                <div className='notification-auto-reload-enabled-container'>
                    <div className='notification-auto-reload-enabled-text-top-container'>
                        <WalletIcon width={23} height={23} />
                        <p className='notification-module-text'>Payments:</p>
                        <p className='notification-auto-reload-text'>Auto reload settings disabled and inactive now.</p>
                    </div>
                    <p className='notification-auto-reload-data-text'>
                        👉 Since the Auto-reload is disabled, we'll automatically charge the saved card for any
                        redemptions by your users when there are insufficient funds in your wallet.
                    </p>
                </div>
                <EWButton
                    buttonStyleClass='notification-cta-button'
                    width='180px'
                    onClick={() => {
                        history.push(ROUTES.PAYMENTS_WALLET);
                    }}
                >
                    Manage preferences
                </EWButton>
            </div>
        </div>
    );
};

Index.propTypes = {
    history: PropTypes.object.isRequired,
};

export default NotificationWrapper(Index);
