import React, { useState } from 'react';
import clsx from 'clsx';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import './style.scss';
import PropTypes from 'prop-types';

// If any item in ItemList has label empty, it is replaced with "Others" because label is always expected to be there and showing empty state in label will be bad UI
const Index = ({ title, titleStyleClass, itemList = [], selectedIndices = [], onClick, className = '' }) => {
    const [open, setOpen] = useState(false);

    const handleClose = () => {
        setOpen(false);
    };

    const selectedLength = Object.keys(selectedIndices).length;

    return (
        <ClickAwayListener onClickAway={handleClose}>
            <div className={`multi-select ${className}`}>
                {title && (
                    <p className={clsx({ 'filter-title': !titleStyleClass, [titleStyleClass]: titleStyleClass })}>
                        {title}
                    </p>
                )}
                <button
                    className={clsx('multi-select-button', {
                        'multi-select-button-active': selectedLength > 0,
                        'multi-select-button-open': open,
                    })}
                    onClick={() => setOpen(!open)}
                >
                    {selectedLength > 0 ? `Selected (${selectedLength})` : 'ALL'}
                    {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </button>
                <div className={clsx({ 'multi-select-item-container': true, 'display-none': !open })}>
                    {itemList.map((item, index) => (
                        <button
                            key={item.value + index}
                            className={clsx({
                                'multi-select-chip': true,
                                'selected-multi-select-chip': selectedIndices[index] !== undefined,
                            })}
                            onClick={(event) => onClick(event, index)}
                        >
                            {item.label || 'Others'}
                        </button>
                    ))}
                </div>
            </div>
        </ClickAwayListener>
    );
};

Index.propTypes = {
    title: PropTypes.string,
    titleStyleClass: PropTypes.string,
    itemList: PropTypes.array,
    selectedIndices: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    onClick: PropTypes.func,
    className: PropTypes.string,
};

export default Index;
