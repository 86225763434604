import React from 'react';
import PropTypes from 'prop-types';
import ImageWithLabel from 'components/ReusableComponents/ImageWithLabel';

const Activity = ({ data }) => {
    const activities = [
        {
            label: 'Total Recognitions received',
            value: data?.totalRecognitionReceived || '-',
        },
        {
            label: 'Total Recognitions sent',
            value: data?.totalRecognitionSent || '-',
        },
        {
            label: 'Most Recognitions rewarded to',
            value: data?.mostRewardedTo?.userName || '-',
            imageUrl: data?.mostRewardedTo?.imageUrl,
            image: true,
        },
        {
            label: 'Most Recognitions received from',
            value: data?.mostReceivedFrom?.userName || '-',
            imageUrl: data?.mostReceivedFrom?.imageUrl,
            image: true,
        },
    ];

    return (
        <div className='cp-activity'>
            <h4 className='title'>Activity</h4>
            <div className='cp-activities'>
                {activities.map((activity) => (
                    <div className='d-flex flex-column' key={activity.label}>
                        <p className='cp-activities-label'>{activity?.label}</p>
                        <ImageWithLabel
                            {...(activity?.image &&
                                activity?.value !== '-' && {
                                    imageUrl: activity?.imageUrl || require('Assets/images/defaultUser.png'),
                                })}
                            text={activity.value}
                            textWidth='auto'
                            width={22}
                            height={22}
                            textMarginLeft={8}
                            textStyleClass='cp-activities-value'
                        />
                    </div>
                ))}
            </div>
        </div>
    );
};

Activity.propTypes = {
    data: PropTypes.object,
};

export default Activity;
