import React, { useRef, useState } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { getRecognitions, getRecognitionById } from '../../Services/apiFunctions';
import { getWorkspaceConfig, getCoreValues, getRecognitonTypes } from 'Services/apiFunctions';
import {
    GET_RECOGNITION_POSTS,
    GET_CONFIG_WORKSPACE,
    GET_PENDING_RECOGNITIONS,
    GET_CORE_VALUES,
    GET_RECOGNITION_TYPES,
    GET_RECOGNITION_POST_BY_ID,
} from 'Services/apiKeys';
import PropTypes from 'prop-types';
import { useInfiniteQuery, useQuery, useQueryClient } from 'react-query';
import InfiniteScroll from 'react-infinite-scroll-component';
import CustomLoader from 'components/ReusableComponents/CustomLoader';
import RecognitionItem from './RecognitionItem';
import RecognitionBanner from './RecognitionBanner';
import RecognitionByUserFilter from './RecognitionByUserFilter';
import SendRecognitionModal from './SendRecognitionModal';
import RecognitionSuccessModal from './RecognitionSuccessModal';
import RecognitionNotConfiguredModal from '../../Configuration/RecognitionConfiguration/RecognitionNotConfiguredModal';
import { useToaster } from 'Context/SnackbarContext';
import { showSnackBarMessage, capitalizeFirstLetter, getIntegratedChannelName, isSlack } from 'utils/HelperFunctions';
import { updateWorkspaceDetails } from 'redux/workspace/actions';
import EmptyFeed from '../RecognitionFeed/EmptyFeed';
import { find } from 'lodash';
import { HISTORY_FROM } from 'constants.js';
import { ACCESS_FOR } from '../../Configuration/RecognitionConfiguration/constants';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import ViewAddOnRecognitionModal from '../RecognitionFeed/ViewAddOnRecognitionModal';
import './style.scss';

const Index = ({ history }) => {
    const { SetSnackbar } = useToaster();
    const { nameForPoints, isAdmin, platform, isManager } = useSelector(mapStateToProps, shallowEqual);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [values, setValues] = useState([]);
    const [recognitionTypes, setRecognitionTypes] = useState([]);
    const [showSendRecognitionModal, setShowSendRecognitionModal] = useState(false);
    const [showRecognitionSuccessModal, setShowRecognitionSuccessModal] = useState(false);
    const defaultRecognitionChannel = useRef({ enabled: false, channel: {} });
    const sentRecognitionPostData = useRef();
    const isAddOnRecognition = useRef(false);
    const selectedRecognitionData = useRef();
    const queryClient = useQueryClient();
    const dispatch = useDispatch();
    const location = useLocation();
    const data = queryString.parse(location.search);

    const [addOnModal, setAddOnModal] = useState({ data: null, show: false });

    useQuery([GET_RECOGNITION_POST_BY_ID, data.id], getRecognitionById, {
        onSuccess: (data) => {
            setAddOnModal({ data: data.data.recognitions[0], show: true });
        },
        onError: (error) => showSnackBarMessage(SetSnackbar, 'error', error?.message),
        enabled: !!data?.id,
    });

    const {
        data: recognitionPosts,
        fetchNextPage,
        hasNextPage,
        isLoading,
        isFetching,
    } = useInfiniteQuery([GET_RECOGNITION_POSTS, null, selectedUsers?.map((user) => user?._id)], getRecognitions, {
        getNextPageParam: (lastPage, pages) => {
            return lastPage?.data?.data?.hasMore ? pages.length + 1 : undefined;
        },
        refetchOnWindowFocus: false,
        keepPreviousData: true,
        onError: (err) => showSnackBarMessage(SetSnackbar, 'error', err?.message),
        retry: 0,
    });
    useQuery([GET_CONFIG_WORKSPACE], getWorkspaceConfig, {
        onSuccess: (data) => {
            updateWorkspaceDetails(dispatch, { companyValue: data?.companyValue });
            defaultRecognitionChannel.current = {
                enabled: data?.defaultRecognitionChannel?.enabled,
                channel: {
                    ...(isSlack(platform)
                        ? { channelID: data?.defaultRecognitionChannel?.channel?.id }
                        : {
                              channelId: data?.defaultRecognitionChannel?.channel?.id,
                              groupName: data?.defaultRecognitionChannel?.channel?.groupName,
                              groupId: data?.defaultRecognitionChannel?.channel?.groupId,
                              label: getIntegratedChannelName(data?.defaultRecognitionChannel?.channel, platform, true),
                          }),
                    channelName: data?.defaultRecognitionChannel?.channel?.name,
                    isPrivate: data?.defaultRecognitionChannel?.channel?.isPrivate,
                },
            };
            return defaultRecognitionChannel.current;
        },
    });

    useQuery([GET_CORE_VALUES], getCoreValues, {
        onSuccess: (data) => {
            const tempValues = data.values.map((value) => ({
                ...value,
                label: value.value,
                value: value.value,
            }));
            setValues(tempValues);
        },
        onError: (err) => showSnackBarMessage(SetSnackbar, 'error', err?.message),
        keepPreviousData: true,
    });

    useQuery([GET_RECOGNITION_TYPES], getRecognitonTypes, {
        onSuccess: (data) => {
            const tempValues = data?.recognitionTypes
                ?.filter((recognition) => recognition?.points >= 0)
                .map((recognition) => ({
                    ...recognition,
                    label: `${capitalizeFirstLetter(recognition.name)} (${recognition.points} ${nameForPoints})`,
                }));
            let filteredValues;
            if (isAdmin) {
                filteredValues = tempValues;
            } else if (isManager) {
                filteredValues = tempValues.filter(
                    (recognition) =>
                        recognition.allowRecognitionFor.includes(ACCESS_FOR.MANAGER) ||
                        recognition.allowRecognitionFor.includes(ACCESS_FOR.USER)
                );
            } else {
                filteredValues = tempValues.filter((recognition) =>
                    recognition.allowRecognitionFor.includes(ACCESS_FOR.USER)
                );
            }
            setRecognitionTypes(filteredValues);
        },
        onError: (error) => showSnackBarMessage(SetSnackbar, 'error', error?.message),
        keepPreviousData: true,
    });

    const showFeed = recognitionPosts?.pages?.[0]?.data?.data?.recognitions?.length > 0 || selectedUsers?.length > 0;

    const handleUserSelection = (user) => {
        if (find(selectedUsers, (selectedUser) => selectedUser?._id === user?._id)) {
            setSelectedUsers(selectedUsers.filter((item) => item?._id !== user?._id));
        } else {
            setSelectedUsers([...selectedUsers, user]);
        }
    };

    const handleSuccessModalClose = () => {
        selectedRecognitionData.current = null;
        queryClient.invalidateQueries(GET_PENDING_RECOGNITIONS);
        setShowRecognitionSuccessModal(false);
    };

    const handleSendRecognitionModalClose = () => {
        selectedRecognitionData.current = null;
        setShowSendRecognitionModal(false);
    };

    const handleSendRecognitionSuccess = (recognitionData) => {
        sentRecognitionPostData.current = recognitionData;
        setShowSendRecognitionModal(false);
        setShowRecognitionSuccessModal(true);
    };

    const handleAddOnRecognition = (recognitionData) => {
        selectedRecognitionData.current = recognitionData;
        isAddOnRecognition.current = true;
        setShowSendRecognitionModal(true);
    };

    return (
        <div className='container-recognitions-feed'>
            <RecognitionBanner
                handleSendRecognition={() => {
                    isAddOnRecognition.current = false;
                    setShowSendRecognitionModal(true);
                }}
            />
            {isLoading && <CustomLoader />}
            {!showFeed && !isLoading && !isFetching && <EmptyFeed />}
            {showFeed && (
                <RecognitionByUserFilter
                    selectedUsers={selectedUsers}
                    handleUserSelection={(user) => handleUserSelection(user)}
                />
            )}
            {recognitionPosts?.pages[0]?.data?.data?.recognitions?.length > 0 && (
                <InfiniteScroll
                    dataLength={recognitionPosts?.pages?.length * 9}
                    next={fetchNextPage}
                    hasMore={hasNextPage}
                    height={820}
                    loader={<CustomLoader size={10} />}
                >
                    {recognitionPosts?.pages?.map((pageData) =>
                        pageData?.data?.data?.recognitions?.map((recognition) => (
                            <RecognitionItem
                                key={recognition?._id}
                                recognitionData={recognition}
                                handleGiveAddOnRecognition={handleAddOnRecognition}
                            />
                        ))
                    )}
                </InfiniteScroll>
            )}
            {showSendRecognitionModal && recognitionTypes?.length >= 1 && (
                <SendRecognitionModal
                    open={showSendRecognitionModal}
                    onClose={handleSendRecognitionModalClose}
                    isAddOnRecognition={isAddOnRecognition.current}
                    defaultRecognitionChannel={defaultRecognitionChannel.current}
                    handleSuccess={(recognitionData) => handleSendRecognitionSuccess(recognitionData)}
                    values={values}
                    history={history}
                    recognitionTypes={recognitionTypes}
                    {...(isAddOnRecognition.current && {
                        recognizedUsers: selectedRecognitionData.current.receivers,
                        addonParentId: selectedRecognitionData.current._id,
                        addOnRecognitionChannel: selectedRecognitionData.current.channel,
                        addOnRewardType: selectedRecognitionData.current.type,
                    })}
                />
            )}
            {showSendRecognitionModal && recognitionTypes?.length <= 0 && (
                <RecognitionNotConfiguredModal
                    open={showSendRecognitionModal}
                    onClose={handleSendRecognitionModalClose}
                    currentRoute={HISTORY_FROM.RECOGNITIONS}
                    history={history}
                />
            )}
            {showRecognitionSuccessModal && (
                <RecognitionSuccessModal
                    open={showRecognitionSuccessModal}
                    onClose={handleSuccessModalClose}
                    recognitionPostData={sentRecognitionPostData.current}
                    platform={platform}
                />
            )}
            {addOnModal.show && (
                <ViewAddOnRecognitionModal
                    open={addOnModal.show}
                    onClose={() => setAddOnModal({ data: null, show: false })}
                    recognitionData={addOnModal.data}
                    handleGiveAddOnRecognition={handleAddOnRecognition}
                />
            )}
        </div>
    );
};
Index.propTypes = {
    history: PropTypes.object,
};

const mapStateToProps = ({ Workspace, User }) => ({
    nameForPoints: Workspace.nameForPoints,
    isAdmin: User.isAdmin,
    userId: User._id,
    isManager: User.isManager,
    platform: Workspace.platform,
});

export default Index;
