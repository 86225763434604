import React from 'react';
import PropTypes from 'prop-types';
import ClearIcon from '@material-ui/icons/Clear';
import './style.scss';

const Index = ({ image, heading, onClose }) => {
    return (
        <div className='banner-container'>
            <div className='banner-image'>{image}</div>
            <div className='banner-heading'>{heading}</div>
            <div className='banner-close'>
                <ClearIcon onClick={() => onClose()} />
            </div>
        </div>
    );
};

Index.propTypes = {
    image: PropTypes.element,
    heading: PropTypes.string,
    onClose: PropTypes.func,
};

export default Index;
