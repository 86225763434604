import React from 'react';
import PropTypes from 'prop-types';
import { shallowEqual, useSelector } from 'react-redux';
import InfoScreen from 'components/ReusableComponents/InfoScreen';
import { PLATFORM, ROUTES } from 'constants.js';
import { RECOGNITIONS } from '../../../Dashboard/Content/Recognitions/constants';

const BANNER_IMG = {
    slack: 'https://assets-springengage.s3.amazonaws.com/production/icons/RnR-setup-complete-slack.svg',
    teams: 'https://assets-springengage.s3.amazonaws.com/production/icons/RnR-setup-complete-msteams.svg',
};

const Index = ({ history }) => {
    const { platform, team } = useSelector(mapStateToProps, shallowEqual);
    const redirectionURL = `slack://app?team=${team}&id=${process.env.REACT_APP_SLACK_APP_ID}&tab=home`;
    const redirectionURLTeams = `https://teams.microsoft.com/l/entity/${process.env.REACT_APP_TEAMS_APP_ID}/home`;

    return (
        <InfoScreen
            heading='Yayy! Setup complete!'
            subHeading='You can start giving recognitions to your teammates'
            bannerImage={
                <img src={platform?.toLowerCase() === 'slack' ? BANNER_IMG.slack : BANNER_IMG.teams} alt='banner' />
            }
            primaryButtonDetails={{
                text: 'Send your first recognition',
                onClick: () => {
                    if (platform === PLATFORM.SLACK) {
                        window.open(redirectionURL, '_blank');
                    } else if (platform === PLATFORM.TEAMS) {
                        window.open(redirectionURLTeams, '_blank');
                    } else {
                        history.replace(RECOGNITIONS.FEED);
                    }
                },
            }}
            secondaryButtonDetails={{
                text: 'Explore dashboard',
                onClick: () => history.replace(ROUTES.USERS_ONBOARDING_REDIRECTION),
            }}
            isSetupComplete={true}
        />
    );
};

const mapStateToProps = ({ Workspace }) => ({
    platform: Workspace.platform,
    team: Workspace.team,
});

Index.propTypes = {
    history: PropTypes.object,
};

export default Index;
