import React from 'react';
import { AdminBox } from 'utils/Stylesheet/style.js';
import './style.scss';
import { shallowEqual, useSelector } from 'react-redux';

const Index = () => {
    const { approver } = useSelector(mapStateToProps, shallowEqual);

    return (
        <div className='mt-4'>
            <AdminBox>
                <img
                    className='avatar-24'
                    src={approver?.approverImageURL || require('Assets/images/defaultUser.png')}
                    alt='img'
                />
                <span>{approver?.approverName}</span>
            </AdminBox>
        </div>
    );
};

const mapStateToProps = (state) => ({ approver: state.Workspace.approver });

export default Index;
