import React, { useCallback, useMemo, useRef, useState } from 'react';
import Modal from '@material-ui/core/Modal';
import CloseIcon from '@material-ui/icons/Close';
import './style.scss';
import { DateRangePicker } from 'react-date-range';
import PropTypes from 'prop-types';

/*
 * CUSTOM DATE RANGE PICKER
 * ss: https://prnt.sc/1v7x6t8
 * design: https://www.figma.com/file/i7J3vAW2srnObSfhd4Baff/EW-Dashboard---Revamp?node-id=4819%3A141899
 *
 * */

const ModalComponent = ({ onClose, onConfirm, startDate = null, endDate = null, ...props }) => {
    const now = useRef(new Date());
    const [to, setTo] = useState(endDate || now.current);
    const [from, setFrom] = useState(startDate || now.current);

    const ranges = useMemo(() => {
        return [
            {
                startDate: from,
                endDate: to,
                key: 'selection',
            },
        ];
    }, [from, to]);

    const handleSelect = useCallback(({ selection: { startDate, endDate } }) => {
        setFrom(startDate);
        setTo(endDate);
    });

    const handleConfirm = () => {
        onConfirm({ startDate: from, endDate: to });
    };

    return (
        <Modal open>
            <div className='custom-date-picker-modal'>
                <div className='modal-header _modal-header'>
                    <div>Custom - Select date range</div>
                    <CloseIcon onClick={onClose} />
                </div>

                <div className='ranger-heading'>
                    <div style={{ width: '50%' }}>FROM</div>
                    <div>TO</div>
                </div>
                <DateRangePicker
                    onChange={handleSelect}
                    dateDisplayFormat='dd/MM/yyyy'
                    staticRanges={[]}
                    inputRanges={[]}
                    direction='horizontal'
                    months={2}
                    weekStartsOn={1}
                    ranges={ranges}
                    maxDate={new Date()}
                    rangeColors={['#5D5CF5']}
                />

                <div className='_modal-footer'>
                    <button className='ew-btn ob-btn' onClick={onClose}>
                        Cancel
                    </button>

                    <button className='ew-btn pb-btn margin-left-25' onClick={handleConfirm}>
                        Done
                    </button>
                </div>
            </div>
        </Modal>
    );
};

// This enables the modal to be mounted only when open is true.
const Index = ({ open, ...props }) => {
    if (open) return <ModalComponent {...props} />;
    return null;
};

Index.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,
};

ModalComponent.propTypes = {
    onClose: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,
    startDate: PropTypes.object,
    endDate: PropTypes.object,
};

export default Index;
