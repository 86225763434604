import React from 'react';
import PropTypes from 'prop-types';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import { handleValidity } from '../constant';
import './style.scss';

const Index = ({ product, handlePurchase, disabled }) => {
    const validity = handleValidity(product?.expiryAndValidity);
    return (
        <button disabled={disabled} className='reward-item-container' onClick={() => handlePurchase(product)}>
            <div className='item-top-container'>
                <div className='image'>
                    {product?.imageUrl?.includes('http') ? (
                        <img src={product?.imageUrl} alt='' />
                    ) : (
                        <div className='logo-pic'>{product?.imageUrl}</div>
                    )}
                </div>
                <div className='reward-details-container'>
                    <p>{product?.name}</p>
                    <p> {product?.categories || 'Others'}</p>
                </div>
            </div>

            {validity && (
                <div className='validity'>
                    <img src={require('Assets/images/validity.svg')} alt='' />
                    <p>{validity}</p>
                </div>
            )}
            <div className='checkout-btn'>
                <KeyboardArrowRightIcon />
            </div>
        </button>
    );
};

Index.propTypes = {
    product: PropTypes.object,
    handlePurchase: PropTypes.func,
    disabled: PropTypes.bool,
};

export default Index;
