import React from 'react';
import PropTypes from 'prop-types';
import { CustomSwitch } from 'components';
import { YellowTagDark } from 'utils/Stylesheet/style';

const ROLLING_PULSE_DESCRIPTION =
    'This feature lets you distribute your questions over a\n period of time, instead of sending all together.';

const RollingPulse = ({ scheduling, setScheduling, disabled }) => (
    <div>
        <div className='d-flex'>
            <h4 className='heading'>
                Rolling pulse <YellowTagDark>Recommended</YellowTagDark>
            </h4>
            <div className='d-flex justify-content-start align-items-center rolling-toggle-container'>
                <CustomSwitch
                    checked={scheduling.rollingPulse}
                    onChange={() =>
                        setScheduling({
                            ...scheduling,
                            rollingPulse: !scheduling.rollingPulse,
                            reminderValue: [],
                            remindersEnabled: false,
                        })
                    }
                    disabled={disabled}
                />
                {scheduling.rollingPulse ? 'Enabled' : 'Disabled'}
            </div>
        </div>
        <p className='rp-desc'>{ROLLING_PULSE_DESCRIPTION}</p>
        <p className='divider-771'>
            - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
            - - - - - - - - - - - - -
        </p>
    </div>
);

RollingPulse.propTypes = {
    scheduling: PropTypes.object,
    setScheduling: PropTypes.func,
    disabled: PropTypes.bool,
};

export default RollingPulse;
