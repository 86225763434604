import React, { useState } from 'react';
import { useInfiniteQuery } from 'react-query';
import { getAuditLogs } from 'Services/apiFunctions';
import { GET_AUDIT_LOGS } from 'Services/apiKeys';
import { useToaster } from 'Context/SnackbarContext';
import LogsFilter from '../Components/Filters';
import CustomLoader from 'components/ReusableComponents/CustomLoader';
import InfiniteScroll from 'react-infinite-scroll-component';
import LogList from '../Components/AuditLogsList';
import PropTypes from 'prop-types';
import { showSnackBarMessage } from 'utils/HelperFunctions';

const Index = ({ history }) => {
    const { SetSnackbar } = useToaster();
    const [actionByDropdownOptions, setActionByDropdownOptions] = useState([]);
    const [settingNameDropdown, setSettingNameDropdown] = useState([]);
    const [filterOption, setFilterOption] = useState({
        search: '',
        date: '',
        actionBy: '',
        settingName: '',
    });

    const onError = (error) => {
        showSnackBarMessage(SetSnackbar, 'error', error?.message || 'Some error occurred');
    };

    const {
        data: auditLogsData,
        fetchNextPage,
        hasNextPage,
    } = useInfiniteQuery(
        [GET_AUDIT_LOGS, filterOption.actionBy, filterOption.search, filterOption.settingName, filterOption.date],
        getAuditLogs,
        {
            getNextPageParam: (lastPage, pages) => {
                return lastPage?.data?.hasMore ? pages.length + 1 : undefined;
            },
            onSuccess: (data) => {
                // Check if the current page is 0
                if (data.pageParams[0] === undefined) {
                    const filterData = data.pages[0].data?.filterData[0];
                    const actionBy =
                        filterData.actionBy?.map((item) => ({
                            label: item.userName,
                            value: item._id,
                            pictureUrl: item.pictureURL,
                        })) || [];
                    const settings =
                        filterData.setting?.map((item) => ({
                            label: item.charAt(0).toUpperCase() + item.slice(1),
                            value: item,
                        })) || [];
                    // Adding default option at the top
                    actionBy.unshift({ label: 'All', value: '' });
                    settings.unshift({ label: 'All', value: '' });
                    setActionByDropdownOptions(actionBy);
                    setSettingNameDropdown(settings);
                }
            },
            refetchOnWindowFocus: false,
            keepPreviousData: true,
            onError,
            retry: 0,
        }
    );

    return (
        <div className='user-wrapper'>
            <div className='mt-2 overall-user-container'>
                <LogsFilter
                    setFilterOption={setFilterOption}
                    actionByDropdownOptions={actionByDropdownOptions}
                    settingNameDropdown={settingNameDropdown}
                />
                {auditLogsData?.pages[0]?.data?.logs?.length > 0 && (
                    <div className='log-list'>
                        <InfiniteScroll
                            dataLength={auditLogsData?.pages?.length * 9}
                            next={fetchNextPage}
                            hasMore={hasNextPage}
                            height={480}
                            loader={<CustomLoader size={10} />}
                        >
                            {auditLogsData?.pages?.map((pageData) =>
                                pageData?.data?.logs?.map((log) => <LogList history={history} key={log._id} {...log} />)
                            )}
                        </InfiniteScroll>
                    </div>
                )}
            </div>
        </div>
    );
};

Index.propTypes = {
    history: PropTypes.object,
};

export default Index;
