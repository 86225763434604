// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../Assets/images/RnR-setup-complete-bg.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".setup-complete-background {\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat center; }\n\n.info-screen .header {\n  padding: 20px 0 10px 20px;\n  display: flex;\n  justify-content: space-between;\n  flex-direction: row;\n  align-items: flex-start; }\n  .info-screen .header .header-details {\n    display: flex;\n    justify-content: center;\n    flex-direction: row;\n    align-items: center;\n    font-family: Montserrat;\n    font-size: 0.875rem;\n    font-weight: 600;\n    line-height: 20px;\n    text-align: center;\n    margin-right: 20px; }\n\n.info-screen .content .content-heading {\n  font-family: Montserrat;\n  font-size: 1.75rem;\n  font-weight: 600;\n  line-height: 40px;\n  text-align: center;\n  margin-bottom: 10px; }\n\n.info-screen .content .content-sub-heading {\n  font-family: Montserrat;\n  font-size: 1.125rem;\n  font-weight: 400;\n  line-height: 28px;\n  text-align: center;\n  margin-bottom: 30px;\n  white-space: pre-wrap; }\n", ""]);
// Exports
module.exports = exports;
