export const dateRanges = [
    {
        value: '',
        label: 'All time',
    },
    {
        value: 'currentMonth',
        label: 'This month',
    },
    {
        value: 'lastMonth',
        label: 'Last month',
    },
    {
        value: 'halfYear',
        label: 'Last 6 months',
    },
    {
        value: 'currentYear',
        label: 'Current year',
    },
];
