import React from 'react';
import PropTypes from 'prop-types';
import EWButton from 'components/ReusableComponents/EWButton';
import './style.scss';
import clsx from 'clsx';

const Index = ({ setOpen, handleChange, reason, handleReject, isLoading }) => {
    const disabled = reason.length === 0;

    return (
        <div className={'cta-modal-background'}>
            <div className='cta-modal-container'>
                <h2 className='title'>Reason for Rejecting</h2>
                <textarea
                    className='text-area'
                    value={reason}
                    placeholder='Type your reason here.'
                    onChange={(e) => handleChange(e)}
                />
                <div className=' button-container'>
                    <EWButton
                        buttonText='Go Back'
                        primary={false}
                        plainTextButton={true}
                        onClick={() => setOpen(false)}
                    />
                    <EWButton
                        buttonText='Reject'
                        buttonStyleClass={clsx({ 'reject-btn': true, 'btn-disabled': disabled })}
                        disabled={disabled}
                        primary={false}
                        redBackground={true}
                        loading={isLoading}
                        onClick={handleReject}
                    />
                </div>
            </div>
        </div>
    );
};

Index.propTypes = {
    setOpen: PropTypes.func,
    handleChange: PropTypes.func,
    reason: PropTypes.string,
    handleReject: PropTypes.func,
    isLoading: PropTypes.bool,
};

export default Index;
