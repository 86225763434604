import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useMutation, useQueryClient } from 'react-query';
import AdminSelectionModal from 'components/ReusableComponents/AdminSelectionModal';
import { useToaster } from 'Context/SnackbarContext';
import { changeApprover } from 'Services/apiFunctions';
import { GET_ADMIN_LIST } from 'Services/apiKeys';
import { updateWorkspaceDetails } from 'redux/workspace/actions';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { showSnackBarMessage } from 'utils/HelperFunctions';

const Index = ({ open, setOpen, initialAdminCount }) => {
    const { approver } = useSelector(mapStateToProps, shallowEqual);
    const { SetSnackbar } = useToaster();
    const queryClient = useQueryClient();
    const dispatch = useDispatch();

    const [selectedUser, setSelectedUser] = useState({
        name: approver.approverName,
        pictureURL: approver.approverImageURL,
        _id: approver.approverID,
        isAdmin: true,
    });

    const { mutateAsync, isLoading } = useMutation(changeApprover, {
        onError: (error) => showSnackBarMessage(SetSnackbar, 'error', error?.message),
    });

    const handleAddApprover = async () => {
        if (!selectedUser.isAdmin && initialAdminCount > 9) {
            showSnackBarMessage(
                SetSnackbar,
                'warning',
                'There are already 10 admins. Cannot make a non-admin the approver'
            );
        } else {
            try {
                await mutateAsync({ apiData: { orgWideApproverId: selectedUser._id } });
                // update approver
                updateWorkspaceDetails(dispatch, {
                    approver: {
                        approverName: selectedUser.name,
                        approverImageURL: selectedUser.pictureURL,
                        approverId: selectedUser._id,
                    },
                });
                queryClient.setQueryData([GET_ADMIN_LIST], (oldData) => {
                    if (selectedUser.isAdmin) {
                        return oldData;
                    }
                    const tempOldData = { ...oldData };
                    tempOldData.data.push({
                        pictureURL: selectedUser.pictureURL,
                        userName: selectedUser.name,
                        _id: selectedUser._id,
                    });
                    return tempOldData;
                });
                showSnackBarMessage(SetSnackbar, 'success', 'Updated successfully.');
            } catch (err) {
                showSnackBarMessage(SetSnackbar, 'error', err?.message);
            } finally {
                setOpen(false);
            }
        }
    };

    const changeApproverData = {
        heading: 'Change Approver',
        confirm: 'Save',
    };

    return (
        <AdminSelectionModal
            open={open}
            onClose={() => setOpen(false)}
            data={changeApproverData}
            onConfirm={handleAddApprover}
            loading={isLoading}
            selectedUser={selectedUser}
            setSelectedUser={setSelectedUser}
            customClassname='mt-3'
            buttonWidth={480}
            dropdownWidth={480}
            width='528px'
        />
    );
};

const mapStateToProps = ({ Workspace }) => ({ approver: Workspace.approver || {} });

Index.propTypes = {
    open: PropTypes.bool,
    setOpen: PropTypes.func,
    initialAdminCount: PropTypes.number,
};

export default Index;
