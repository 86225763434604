export const GET_CORE_VALUES = 'get-core-values';
export const GET_LEADERBOARD_DATA = 'get-leaderboard-data';
export const GET_ADMIN_LIST = 'get-admin-list';
export const GET_AUDIT_LOGS = 'get-audit-logs';
export const GET_PROFILE_DATA = 'get-profile-data';
export const GET_USERS_DATA = 'get-users-data';
export const GET_MIN_USERS_DATA = 'get-min-users-data';
export const GET_DEPARTMENT_LIST = 'get-department-data';
export const GET_LOCATION_LIST = 'get-location-data';
export const GET_SUBSCRIPTION_DETAILS = 'get-subscription-details';
export const GET_REWARDS_DATA = 'get-rewards-data';
export const GET_AWARDS_DATA = 'get-awards-data';
export const GET_QUESTION_LIBRARY = 'GET_QUESTION_LIBRARY';
export const GET_PAGINATED_USERS = 'GET_PAGINATED_USERS';
export const GET_PAGINATED_DEPARTMENT = 'GET_PAGINATED_DEPARTMENT';
export const GET_PAGINATED_COUNTRY = 'GET_PAGINATED_COUNTRY';
export const GET_NOTIFICATION = 'get-notification';
export const GET_USERS_OLD = 'get-users-old';
export const GET_RECOGNITION_RESTRICTED_USER = 'get-recognition_restricted_user';
export const GET_REWARD_STATS = 'get-reward-stats';
export const GET_COMPANY_VALUES_STATS = 'get-company-values-stats';
export const GET_RNR_LINE_DATA = 'get-randr-linechart-data';
export const GET_ANALYTICS_COHORT_DATA = 'get-analytics-cohort-data';
export const GET_DEPT_ANALYTICS_TABLE_DATA = 'get-dept-analytics-table-data';
export const GET_USER_ANALYTICS_TABLE_DATA = 'get-user-analytics-table-data';
export const GET_MANGER_ANALYTICS_TABLE_DATA = 'get-manager-analytics-table-data';
export const GET_MISSING_SPECIAL_DATE_USER_COUNT = 'get-missing-special-date-user-count';
export const GET_INTEGRATED_CHANNELS = 'get-integrated-channels';
export const GET_PUBLIC_CHANNELS = 'get-public-channels';
export const GET_GIFT_VALUES = 'get-gift-values';
export const GET_CELEBRATION_CONFIGS = 'get-celebration-configs';
export const GET_ALL_USERS_LIST = 'get-all-users-list';
export const GET_COUNTRY_LIST = 'get-country-list';
export const GET_MANAGERS_LIST = 'get-managers-list';
export const GET_USERS_TEAM_DATA = 'get-users-team-data';

//Redemption
export const GET_ORG_WIDE_REDEMPTION_CONFIG = 'get-org-wide-redemption-config';

// Pulse
export const GET_PULSE_TEMPLATES = 'get-pulse-templates';
export const GET_ORG_DATA_FOR_PULSE = 'get-org-data-for-pulse';
export const GET_PULSE_DETAILS_DATA = 'get-pulse-details-data';
export const GET_PULSE_RESPONSE_DATA = 'get-pulse-response-data';
export const GET_PULSE_SUMMARY_DATA = 'get-pulse-summary-data';

// Employee Awards
export const GET_EMPLOYEE_AWARDS_COUNT = 'get-employee-awards-count';
export const GET_EMPLOYEE_AWARDS_LIST = 'get-employee-awards-list';
export const GET_EMPLOYEE_AWARDS_TEMPLATES = 'get-employee-awards-templates';
export const GET_READY_TO_LAUNCH_AWARDS = 'get-ready-to-launch-awards';
export const GET_AWARD_DETAILS = 'get-award-details';
export const GET_PICK_AWARDEE_DATA = 'get-pick-awardee-data';
export const GET_RECENT_AWARDEES = 'get-recent-awardees';
// Refactored new API's
export const GET_CONFIG_WORKSPACE = 'get-config-workspace';
export const GET_CONFIG_POINTS = 'get-config-points';
export const GET_RECOGNITION_TYPES = 'get-recognition-types';
// Onboarding
export const GET_GLOBAL_SETTINGS = 'get-global-settings';
export const GET_WORKSPACE_DETAILS = 'get-workspace-details';

export const GET_REDEEM_HISTORY = 'get-redeem-history';
// Feedback
export const GET_FEEDBACK_LIST = 'get-feedback-list';
export const GET_FEEDBACK_CONFIGURATION = 'get-feedback-configuration';

//Suggestion box
export const GET_SUGGESTION_BOX_LIST = 'get-suggestion-box-list';
export const GET_SUGGESTION_BOX_CONFIGURATION = 'get-suggestion-box-configuration';
export const GET_FEEDBACK_CATEGORIES_LIST = 'get-feedback-categories';

// Redemptions
export const FETCH_REDEMPTIONS_GIVEN = 'fetch-redemptions-given';
export const FETCH_REDEMPTIONS_RECEIVED = 'fetch-redemptions-received';
export const FETCH_REDEMPTIONS_REQUESTED = 'fetch-redemptions-requested';

// All Activities
export const GET_REDEEM_REWARDS = 'get-redeem-rewards';
export const GET_COMPANY_VALUES = 'get-company-values';
export const GET_TRANSACTION_SUMMARY = 'get-transaction-summary';

// Recognitions
export const GET_RECOGNITION_POSTS = 'get-recognition-posts';
export const GET_ADD_ON_RECOGNITION_POSTS = 'get-add-on-recognition-posts';
export const GET_PENDING_RECOGNITIONS = 'get-pending-recognitions';
export const GET_RECOGNITION_POST_BY_ID = 'get-recognition-post-id';

// Tasks
export const GET_TASKS = 'get-tasks';
export const SET_TASK_STATUS = 'set-task-status';

// Celebrations
export const GET_EMPLOYEES_WITHOUT_DATES = 'get-employees-without-dates';

// Notifications
export const GET_INAPP_NOTIFICATIONS = 'get-notifications';
export const GET_INAPP_NOTIFICATIONS_COUNT = 'get-notification-count';
