export const ACCESS_FOR = {
    ADMINS: 'Admins',
    USERS: 'Users',
    MANAGERS: 'Managers',
    ALL_USERS: 'All Users',
    ADMIN: 'admin',
    USER: 'user',
    MANAGER: 'manager',
};

export const RECOGNITION_TYPE_LIMIT_MESSAGE =
    'Only 10 recognition types are allowed. Delete an existing type to add a new one.';
