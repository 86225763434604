import React, { useState } from 'react';
import PropTypes from 'prop-types';
import TermsModal from '../../../RedeemPoints/TermsModal';
import RedeemInstructionModal from '../../../RedeemPoints/RedeemInstructionModal';
import { fetchRedeemHistory } from 'Services/apiFunctions';
import { GET_REDEEM_HISTORY } from 'Services/apiKeys';
import { useQuery } from 'react-query';
import { format } from 'date-fns';
import { useToaster } from 'Context/SnackbarContext';
import Pagination from 'components/ReusableComponents/Pagination';
import CustomLoader from 'components/ReusableComponents/CustomLoader';
import GiftCardCouponModal from 'components/Modals/GiftCardCouponModal';
import CustomDateRangeModal from 'components/Modals/CustomDateRangeModal';
import CustomSearchBox from 'components/Styles/CustomSearchBox';
import CustomFilterDropdown from 'components/ReusableComponents/CustomFilterDropdown';
import { dateRanges } from 'constants.js';
import { debounce } from 'lodash';
import RedemptionRow from './RedemptionRow';
import { showSnackBarMessage, getLastNDays } from 'utils/HelperFunctions';
import './style.scss';

const EmptyState = ({ noResults }) => {
    const noResultsText = <p>Adjust filter for results!</p>;
    const noOrdersText = (
        <p>
            Currently there are no order purchases to <br />
            showcase. Once you start redeeming gift <br /> cards, you will find them here.
        </p>
    );
    const emptyText = noResults ? noResultsText : noOrdersText;
    return (
        <div className='empty-state'>
            <img src={require('Assets/images/empty-filter.svg')} alt='empty-state' />
            {emptyText}
        </div>
    );
};

const Index = ({ showDateFilter = true }) => {
    const [currentIndex, setCurrentIndex] = useState(-1);
    const [couponOpen, setCouponOpen] = useState(false);
    const [termsOpen, setTermsOpen] = useState(false);
    const [instructionOpen, setInstructionOpen] = useState(false);
    const [tnc, setTnc] = useState('');
    const [ri, setRi] = useState('');
    const [redeemData, setRedeemData] = useState([]);
    const [page, setPage] = useState(1);
    const [showDatePicker, setShowDatePicker] = useState(false);
    const { SetSnackbar } = useToaster();
    const [dateFilter, setDateFilter] = useState({ date: { label: 'ALL', value: '' } });
    const [search, setSearch] = useState('');
    const [rewardList, setRewardList] = useState([]);
    const [rewardCardFilter, setRewardCardFilter] = useState([]);
    const CUSTOM_DATE = 'Custom Date';

    const {
        data: redeemHistoryData,
        isLoading,
        isFetching,
    } = useQuery(
        [
            GET_REDEEM_HISTORY,
            {
                page,
                search,
                startDate: dateFilter?.dateStart,
                endDate: dateFilter?.dateEnd,
                productId: rewardCardFilter,
            },
        ],
        fetchRedeemHistory,
        {
            onSuccess: (data) => {
                setRedeemData(data?.redeemHistory || []);
                if (rewardList.length === 0) {
                    const tempRewards = data.allCards.map((rewardCard) => {
                        return {
                            ...rewardCard,
                            label: rewardCard.name,
                            value: rewardCard.productId,
                            isChecked: false,
                        };
                    });
                    setRewardList(tempRewards);
                }
            },
            onError: (err) => showSnackBarMessage(SetSnackbar, 'error', err?.message),
        }
    );

    const toggleCoupon = (index) => {
        setCurrentIndex(index);
        setCouponOpen(true);
    };
    const handleTnc = (content) => {
        setTnc(content);
        setTermsOpen(true);
    };
    const handleRI = (content) => {
        setRi(content);
        setInstructionOpen(true);
    };

    const handleSearchText = debounce((value) => {
        setSearch(value);
        setPage(1);
    }, 300);

    const handleCustomDate = (selectedDate) => {
        const fromDate = format(selectedDate?.startDate, 'yyyy-MM-dd');
        const toDate = format(selectedDate?.endDate, 'yyyy-MM-dd');
        setDateFilter({
            ...dateFilter,
            date: { label: CUSTOM_DATE, value: CUSTOM_DATE },
            dateStart: fromDate,
            dateEnd: toDate,
        });
        setPage(1);
        setShowDatePicker(false);
    };

    const handleFilter = (value) => {
        if (value?.value === CUSTOM_DATE) {
            setShowDatePicker(true);
        } else {
            let startDate;
            switch (value.value) {
                case 'today':
                    startDate = getLastNDays(0).startDate;
                    break;
                case 'last7':
                    startDate = getLastNDays(7).startDate;
                    break;
                case 'last30':
                    startDate = getLastNDays(30).startDate;
                    break;
                case 'last60':
                    startDate = getLastNDays(60).startDate;
                    break;
                case 'last90':
                    startDate = getLastNDays(90).startDate;
                    break;
                default:
                    break;
            }
            setDateFilter({
                ...dateFilter,
                date: value,
                dateStart: startDate ? format(startDate, 'yyyy-MM-dd') : null,
                dateEnd: startDate ? format(new Date(), 'yyyy-MM-dd') : null,
            });
            setPage(1);
        }
    };

    const handleCardSelection = (index) => {
        const temp = [...rewardList];
        temp[index].isChecked = !temp[index].isChecked;
        const selectedProductIds = temp.filter((product) => product.isChecked).map((product) => product.value);
        setRewardCardFilter(selectedProductIds);
        setPage(1);
        setRewardList(temp);
    };

    if (isLoading && !search) {
        return <CustomLoader />;
    }

    return (
        <div className='my-recognitions-container purchased-rewards-container'>
            <div className='my-recognitions-filters'>
                {showDateFilter && (
                    <CustomFilterDropdown
                        dropDownID={'redemptionDateDropdown'}
                        title='DATE RANGE'
                        selectedName={dateFilter?.date?.label || 'ALL'}
                        optionsSelected
                        buttonStyleClass='width-188'
                        dropdownHeight={202}
                        filterOptions={dateRanges}
                        handleSelection={(index) => handleFilter(dateRanges[index])}
                        singleSelect
                    />
                )}
                <CustomFilterDropdown
                    title='Reward Card'
                    filterOptions={rewardList}
                    selectedName={rewardCardFilter.length > 0 ? `Selected (${rewardCardFilter.length})` : 'ALL'}
                    optionsSelected={rewardCardFilter.length > 0}
                    handleSelection={(index) => handleCardSelection(index)}
                    dropDownID='rewardPurchaseDropdown'
                    search
                    buttonStyleClass='width-188'
                    dropdownWidth={278}
                    dropdownHeight={202}
                />
                <div className='ml-auto mt-auto'>
                    <CustomSearchBox
                        width={'320px'}
                        handleSearch={(e) => handleSearchText(e.target.value)}
                        placeholder='Search by gift card name'
                    />
                </div>
            </div>

            <div className='voucher-container-redeem mt-2'>
                {redeemData?.map((item, index) => (
                    <RedemptionRow
                        key={item?._id}
                        rowData={item}
                        index={index}
                        handleTnc={handleTnc}
                        handleRI={handleRI}
                        toggleCoupon={toggleCoupon}
                    />
                ))}
                {redeemData.length < 1 && !isFetching && (
                    <EmptyState noResults={rewardCardFilter.length > 0 || search || dateFilter?.date?.value} />
                )}
            </div>
            <CustomDateRangeModal
                open={showDatePicker}
                onClose={() => setShowDatePicker(false)}
                onConfirm={(selectedDate) => handleCustomDate(selectedDate)}
            />
            <GiftCardCouponModal
                open={couponOpen}
                setOpen={setCouponOpen}
                voucherCodes={redeemData?.[currentIndex]}
                tnc={tnc}
                redeemInstructions={redeemData?.[currentIndex]?.redemptionInstructions}
                redeemCoupon={false}
                backButtonText='Back to Redeem History'
            />
            <TermsModal termsOpen={termsOpen} setTermsOpen={setTermsOpen} termsData={tnc} />
            <RedeemInstructionModal
                instructionOpen={instructionOpen}
                setInstructionOpen={setInstructionOpen}
                instructionData={ri}
            />
            {redeemHistoryData?.totalCount > 0 && (
                <Pagination currentPage={page} setCurrentPage={setPage} totalCount={redeemHistoryData?.totalCount} />
            )}
        </div>
    );
};

EmptyState.propTypes = {
    noResults: PropTypes.bool,
};

Index.propTypes = {
    showDateFilter: PropTypes.bool,
};

export default Index;
