import { createGlobalStyle } from 'styled-components';

export const GlobalStyles = createGlobalStyle`
  .dashboard-nav-panel{
    .dashboard-nav-list{
      .active{
        .nav-item{
          color: ${({ theme }) => theme.colourCode};
          background: ${({ theme }) => theme.hover};
        }
      }
    }
  }
  .container-dropdown{ .more-button{
    &:hover{
      border-color:${({ theme }) => theme.colourCode};
      color:${({ theme }) => theme.colourCode};
    }
  }
  .more-button-selected{
      border-color:${({ theme }) => theme.colourCode};
      color:${({ theme }) => theme.colourCode};
  }
  .dropdown-content{
    border-color:${({ theme }) => theme.colourCode};
    ul{li{
      .options-text{
        &:hover{
          color: ${({ theme }) => theme.colourCode};
        }
      }
      &:hover{
        color: ${({ theme }) => theme.colourCode};
          background: ${({ theme }) => theme.hover};
      }
    }}
  }
}
  .navigatortabs{ .nav-tabs{
    .active{
    color:${({ theme }) => theme.colourCode} !important;
    border-bottom-color:${({ theme }) => theme.colourCode} !important;
  }}}
  .analytics-main-container {.analytics-points-tab {.analytics-view-all{color:${({ theme }) => theme.colourCode};}}}

  .my-recognitions-container {.my-recog-name{color:${({ theme }) => theme.colourCode}}}
  .redeem-main-container {.redeem-topbar{
    background: ${({ theme }) => theme.hover};
  }}
  .container-member-manager {.new-members-button {span{color:${({ theme }) => theme.colourCode}}}}
  .more-button-selected{color:${({ theme }) => theme.colourCode}; border: '1px solid ${({ theme }) =>
    theme.colourCode} !important';}
  .ob-btn {
    color: ${({ theme }) => theme.colourCode};
    border: 1px solid ${({ theme }) => theme.colourCode};
    background-color: #ffffff;
    &:hover {
        color: ${({ theme }) => theme.colourCode};
        border-color: ${({ theme }) => theme.colourCode};
    }
    &:disabled {
        color: #5e61ec;
        border: 1px solid #5e61ec;
    }
}
  .leaderboard-tab-container{ .leaderboard-switch-container{ .checked-item{
    color:${({ theme }) => theme.colourCode};
  }}}

  .pb-btn {
    color: #f7f7fc;
    background-color: ${({ theme }) => theme.colourCode};
    &:hover {
      background-color: ${({ theme }) => theme?.buttonHover?.backgroundColor || theme.colourCode};
    }
    &:disabled {
        background-color: ${({ theme }) => theme.colourCode}80;
    }
}
.tb-btn {
  padding: 0;
  color: ${({ theme }) => theme.colourCode};
  background-color: transparent;
}
.bulk-reassign{
  color: ${({ theme }) => theme.colourCode};
  svg{
    path{
         fill:${({ theme }) => theme.colourCode};
    }
  }
}
.setup-steps{
  .MuiLinearProgress-bar1Determinate{
    background: ${({ theme }) => theme.colourCode};
  }
  span{
    color: ${({ theme }) => theme.colourCode};
  }
}
.Component-root-11.Component-checked-12{
  color: ${({ theme }) => theme.colourCode};
}
.ew-pagination .MuiPaginationItem-page.Mui-selected{
  color: ${({ theme }) => theme.colourCode};
  background: ${({ theme }) => theme.hover};
}
.ew-pagination .MuiPaginationItem-root{
  color: ${({ theme }) => theme.colourCode};
}
.redeem-main-container .redeem-topbar .redeem-history{
  color: ${({ theme }) => theme.colourCode};
}
.member .username{
  color: ${({ theme }) => theme.colourCode};
}
.css-0{
  color: ${({ theme }) => theme.colourCode};
}
`;
