import React from 'react';
import { useLocation } from 'react-router-dom';
import WelcomeBanner from '../OnboardingV2/Components/WelcomeBanner';
import { ReactComponent as IconEmail } from 'Assets/images/icon-email-rounded.svg';
import './style.scss';

const Index = () => {
    const location = useLocation();
    const signUp = location?.state?.signup || false;
    const email = location?.state.email;

    return (
        <div className='container-confirmation'>
            <WelcomeBanner />
            <div className='confirmation-content'>
                <h4 className='confirmation-title'>{signUp ? 'Sign Up on EngageWith' : 'Sign In'}</h4>
                <IconEmail />
                <div className='confirmation-link-text'>{`Link sent to ${email}`}</div>
                <div className='confirmation-subtext'>
                    Please check your inbox for a confirmation link, open the link to verify your Email ID
                </div>
            </div>
        </div>
    );
};

export default Index;
