import React, { useRef, useEffect, useState } from 'react';
import './style.scss';
import CustomCheckbox from '../../Styles/CustomCheckbox';
import CustomRadioButton from 'components/Styles/CustomRadioButton';
import ExpandLessOutlinedIcon from '@material-ui/icons/ExpandLessOutlined';
import ExpandMoreOutlinedIcon from '@material-ui/icons/ExpandMoreOutlined';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import CloseIcon from '@material-ui/icons/Close';
import PropTypes from 'prop-types';
import { ReactComponent as SearchIcon } from 'Assets/images/dropdown-search.svg';
import clsx from 'clsx';

const useOutsideAlerter = (ref, toggleCloseOnOutsideClick) => {
    useEffect(() => {
        /**
         * Alert if clicked on outside of element
         */
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                toggleCloseOnOutsideClick();
            }
        }

        // Bind the event listener
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [ref, toggleCloseOnOutsideClick]);
};

useOutsideAlerter.propTypes = {
    wrapperRef: PropTypes.element,
    toggleCloseOnOutsideClick: PropTypes.func,
};

const ExpandedIcon = (state) =>
    state ? (
        <ExpandLessOutlinedIcon style={{ fontSize: '1rem' }} />
    ) : (
        <ExpandMoreOutlinedIcon style={{ fontSize: '1rem' }} />
    );

const Index = ({
    // title of the dropdown | <p> tag used (mentioning for css purposes)
    title,
    // for giving class to the dropdown title
    titleStyleClass,
    moreFilters,
    handleMoreCheck,
    name,
    dropDownID,
    blackText,
    className,
    singleSelect,
    search,
    dropdownClass,
    disabled,
    top,
    value,
    setValue,
    clock,
    showRadioButton = false,
    selectedIndexRadioButton,
}) => {
    const searchInput = useRef('');
    const wrapperRef = useRef(null);
    const [state, setState] = useState(false);
    const [close, setClose] = useState(false);

    const toggleCloseOnOutsideClick = () => {
        setState(false);
    };

    const buttonClassName = className || '',
        divDropDownClass = dropdownClass || '';

    const buttonStyle = top ? { borderTop: '0px' } : { borderBottom: '0px' };

    useOutsideAlerter(wrapperRef, toggleCloseOnOutsideClick);

    const filterFunction = () => {
        let filter, ul, a, i, txtValue, div;
        filter = searchInput.current.value.toUpperCase();
        div = document.getElementById(dropDownID);
        ul = div.getElementsByTagName('ul');
        a = div.getElementsByTagName('li');
        let flag = true;

        if (ul[0].lastChild.textContent === 'Try adjusting your search') {
            ul[0].removeChild(ul[0].lastChild);
            ul[0].removeChild(ul[0].lastChild);
        }

        for (i = 0; i < a.length; i++) {
            txtValue = a[i].textContent || a[i].innerText;
            if (txtValue.toUpperCase().indexOf(filter) > -1) {
                a[i].style.display = '';
                flag = false;
            } else {
                a[i].style.display = 'none';
            }
            if (txtValue.toUpperCase().indexOf('SORRY') > -1) {
                ul[0].removeChild(ul[0].lastChild);
                ul[0].removeChild(ul[0].lastChild);
            }
        }

        if (flag) {
            let li1 = document.createElement('li');
            li1.appendChild(document.createTextNode(`No results found`));
            li1.classList.add('mt-4', 'text-center', 'pt-3');
            let li2 = document.createElement('li');
            li2.appendChild(document.createTextNode(`Try adjusting your search`));
            li2.classList.add('text-center', 'font-size-10', 'color-normal', 'pt-0');
            ul[0].appendChild(li1);
            ul[0].appendChild(li2);
        }
    };

    const handleEvent = (index) => {
        handleMoreCheck(index);
        if (singleSelect) {
            toggleCloseOnOutsideClick();
        }
    };

    const dropdownStyle = {
        zIndex: '3',
        display: state ? 'block' : 'none',
        ...(top && { bottom: '40px' }),
    };

    const handleSearchInput = (searchValue) => {
        if (!close) {
            setClose(true);
        }

        if (value && searchValue.length === 0) {
            setValue('');
            setClose(false);
        } else if (searchValue.length === 0) {
            searchInput.current.value = '';
            setClose(false);
            filterFunction();
        }
    };

    return (
        <div>
            {title && (
                <p className={clsx({ 'dropdown-title': !titleStyleClass, [titleStyleClass]: titleStyleClass })}>
                    {title}
                </p>
            )}
            <div ref={wrapperRef} className='container-dropdown'>
                <div className='dropdown make-relative'>
                    <button
                        onClick={() => setState(!state)}
                        className={`${state ? 'more-button-selected' : 'more-button'} ${buttonClassName}`}
                        disabled={disabled}
                        style={state ? buttonStyle : null}
                    >
                        <div
                            className='d-flex justify-content-between align-items-center p-2'
                            style={{ height: '30px' }}
                        >
                            {name}
                            {clock ? (
                                <div className='clock-design'>
                                    <AccessTimeIcon style={{ height: '16px', width: '16px' }} data-testid={'clock'} />{' '}
                                </div>
                            ) : (
                                <ExpandedIcon state={state} />
                            )}
                        </div>
                    </button>
                    <div
                        id={dropDownID}
                        style={dropdownStyle}
                        className={`dropdown-content ${divDropDownClass !== '' ? divDropDownClass : buttonClassName}`}
                    >
                        {search && !top && (
                            <div>
                                <SearchIcon />
                                <input
                                    type='text'
                                    style={{ paddingLeft: '30px', paddingRight: '30px' }}
                                    placeholder='SEARCH'
                                    ref={searchInput}
                                    onChange={(e) => {
                                        filterFunction();
                                        handleSearchInput(e.target.value);
                                    }}
                                    value={value}
                                />
                                {close && (
                                    <CloseIcon
                                        className='team-close-icon'
                                        onClick={() => handleSearchInput('')}
                                        style={{ top: '15.4px', height: '15px', color: '#4A4A4A' }}
                                        data-testid={'close'}
                                    />
                                )}
                            </div>
                        )}
                        <ul className='mb-0'>
                            {moreFilters?.map((filter, i) => (
                                <button
                                    onClick={() => !showRadioButton && handleEvent(i)}
                                    key={typeof filter === 'object' ? filter.label + i : filter + i}
                                >
                                    <div className='d-flex justify-content-start'>
                                        {!singleSelect && (
                                            <CustomCheckbox
                                                checked={filter.isChecked ? filter.isChecked : false}
                                                className='drop-down-check'
                                                data-testid={'custom-checkbox'}
                                            />
                                        )}
                                        {singleSelect && showRadioButton && (
                                            <CustomRadioButton
                                                checked={selectedIndexRadioButton === i}
                                                title={typeof filter === 'object' ? filter.label : filter}
                                                name={`cr${i}`}
                                                size={24}
                                                onChange={() => {
                                                    handleEvent(i);
                                                }}
                                            />
                                        )}
                                        {!showRadioButton && (
                                            <span
                                                className={`width-100 options-text ${!singleSelect ? '' : 'ml-2'} ${
                                                    blackText ? 'options-text-color' : ''
                                                } ${!blackText && filter.isChecked ? 'font-color-primaryCTA' : ''}`}
                                                data-testid={blackText ? 'black-text' : 'blue-text'}
                                            >
                                                {typeof filter === 'object' ? filter.label : filter}
                                            </span>
                                        )}
                                    </div>
                                </button>
                            ))}
                        </ul>
                        {search && top && (
                            <div data-testid={'top'}>
                                <SearchIcon />
                                <input
                                    type='text'
                                    style={{ paddingLeft: '30px' }}
                                    placeholder='SEARCH'
                                    ref={searchInput}
                                    onChange={filterFunction}
                                />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

Index.propTypes = {
    moreFilters: PropTypes.array,
    handleMoreCheck: PropTypes.func,
    name: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    dropDownID: PropTypes.string,
    blackText: PropTypes.bool,
    className: PropTypes.string,
    singleSelect: PropTypes.bool,
    search: PropTypes.bool,
    dropdownClass: PropTypes.string,
    disabled: PropTypes.bool,
    top: PropTypes.bool,
    value: PropTypes.string,
    setValue: PropTypes.func,
    clock: PropTypes.bool,
    title: PropTypes.string,
    titleStyleClass: PropTypes.string,
    showRadioButton: PropTypes.bool,
    selectedIndexRadioButton: PropTypes.number,
};

export default Index;
