import { actions } from './actionEvents';

const initState = {};

export default function UserReducer(state = initState, action) {
    const { payload, type } = action;

    switch (type) {
        case actions.SET_USER:
            return payload;

        case actions.UPDATE_USER:
            return { ...state, ...payload };

        case actions.SET_ADMIN_ACCESS:
            return { ...state, isAdmin: true };

        case actions.SET_USER_ACCESS:
            return { ...state, isAdmin: false };

        default:
            return state;
    }
}
