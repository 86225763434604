import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import InputField from 'components/ReusableComponents/InputField';
import ErrorField from 'components/ReusableComponents/ErrorField';
import './style.scss';

const RewardEnable = ({ countrySelected, conversionRate, kudos, shoutout, handleChange }) => {
    const error = conversionRate && conversionRate < 1;

    return (
        <div className='setup-container'>
            <div className={clsx({ 'conversion-rate': countrySelected?.label })}>
                {countrySelected?.label && <span className='country-code'>{countrySelected?.currency}</span>}
                <InputField
                    inputID='conversionRate'
                    label='CONVERSION RATE | 1 POINT'
                    placeholder='Enter amount for 1 Point'
                    value={conversionRate}
                    handleChange={handleChange}
                />
            </div>
            <p className='points-info'>
                1 Kudos :{' '}
                <strong>
                    {kudos} Points :&nbsp;
                    {conversionRate && `${countrySelected?.currency || ''} ${kudos * conversionRate}`}
                </strong>
            </p>
            <p className='points-info'>
                1 Shoutout :{' '}
                <strong>
                    {shoutout} Points :&nbsp;
                    {conversionRate && `${countrySelected?.currency || ''} ${shoutout * conversionRate}`}
                </strong>
            </p>

            {!!error && <ErrorField errorText={'Please enter a numeric value greater than 0 to continue.'} />}
        </div>
    );
};

RewardEnable.propTypes = {
    countrySelected: PropTypes.object,
    conversionRate: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    kudos: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    shoutout: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    handleChange: PropTypes.func,
};

export default RewardEnable;
