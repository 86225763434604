import React from 'react';
import PropTypes from 'prop-types';
import CustomRadioButton from 'components/Styles/CustomRadioButton';

const AnonymousSection = ({ audience, setAudience, disabled, onlyAnonymous }) => {
    return (
        <div className='pa-anonymous-box'>
            <h5 className='pa-an-header'>Configure Anonymity</h5>
            <div>
                <div className='pa-anonimity'>
                    <CustomRadioButton
                        checked={audience.anonymous}
                        onChange={() => setAudience({ ...audience, anonymous: true })}
                        title='Anonymous'
                        name='aud-anonymous'
                        size={24}
                        bold={audience.anonymous}
                        disabled={disabled}
                    />
                    <p className='subtitle'>
                        No user information will be associated <br /> with responses
                    </p>
                </div>
                {!onlyAnonymous && (
                    <div className='pa-anonimity'>
                        <CustomRadioButton
                            checked={!audience.anonymous}
                            onChange={() => setAudience({ ...audience, anonymous: false })}
                            title='Non-Anonymous'
                            name='aud-non-anonymous'
                            size={24}
                            bold={!audience.anonymous}
                            disabled={disabled}
                        />
                        <p className='subtitle'>
                            Username is associated with <br />
                            each response
                        </p>
                    </div>
                )}
            </div>
        </div>
    );
};

AnonymousSection.propTypes = {
    audience: PropTypes.object,
    setAudience: PropTypes.func,
    onlyAnonymous: PropTypes.bool,
    disabled: PropTypes.bool,
};

export default AnonymousSection;
