// This NOTIFICATIONS_REDIRECTS_PATHS object handles the path for the redirects when the notification clicked,
// it is needed for the notifications without a CTA

import { ROUTES } from 'constants.js';

export const NOTIFICATIONS_REDIRECTS = {
    userAdded: ROUTES.USERS,
    userDeactivated: ROUTES.USERS,
    subscriptionExpired: ROUTES.PAYMENTS,
};
