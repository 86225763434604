import React from 'react';
import './style.scss';
import { useToaster } from 'Context/SnackbarContext';
import { useQuery } from 'react-query';
import { GET_REWARD_STATS } from 'Services/apiKeys';
import { getRewardStatsAnalytics } from 'Services/apiFunctions';
import { shallowEqual, useSelector } from 'react-redux';
import RewardsStatItem from './RewardsStatItem';
import { showSnackBarMessage, isSlack } from 'utils/HelperFunctions';
import PropTypes from 'prop-types';

const Index = ({ cohort, startDate, endDate }) => {
    const { SetSnackbar } = useToaster();

    const { nameForPoints, platform } = useSelector(mapStateToProps, shallowEqual);

    const { data } = useQuery([GET_REWARD_STATS, cohort, startDate, endDate], getRewardStatsAnalytics, {
        keepPreviousData: false,
        onError: (err) => showSnackBarMessage(SetSnackbar, 'error', err?.message),
    });

    const totals = [
        {
            name: `${nameForPoints} rewarded`,
            count: data?.totalPointsGiven || 0,
            imageIcon: require('Assets/images/badge.svg'),
            percentageChange: data?.pointGivenChange || 0,
            info: `These many ${nameForPoints} were rewarded to teammates when they were recognized`,
        },
        {
            name: 'Recognitions made',
            count: data?.totalAppreciation || 0,
            imageIcon: require('Assets/images/clap.svg'),
            percentageChange: data?.appreciationGivenChange || 0,
            info: 'These many recognitions were made',
        },
        {
            name: 'Rewards redeemed',
            count: data?.totalRedemptions || 0,
            imageIcon: require('Assets/images/gift-money.svg'),
            percentageChange: data?.redemptionChange || 0,
            info: 'These many rewards were redeemed',
        },
        {
            name: 'Engagement rate',
            count: data?.participationRate || 0,
            imageIcon: require('Assets/images/engagement-rate.svg'),
            percentageChange: data?.participationRateChange || 0,
            info: 'This is the percentage of activated users who have made or received recognitions',
        },
        ...(isSlack(platform)
            ? [
                  {
                      name: '360º Feedback shared',
                      count: data?.feedbacksGiven || 0,
                      imageIcon: require('Assets/images/feedback-shared.svg'),
                      percentageChange: data?.feedbacksGivenChange || 0,
                      info: 'These many 360º feedbacks were sent',
                  },
              ]
            : []),
        ...(isSlack(platform)
            ? [
                  {
                      name: 'Suggestions shared',
                      count: data?.suggestionGiven || 0,
                      imageIcon: require('Assets/images/suggestion-analytics.svg'),
                      percentageChange: data?.suggestionGivenChange || 0,
                      info: 'These many suggestions were shared',
                  },
              ]
            : []),
    ];

    return (
        <div className='stats-container'>
            {totals.map((item) => (
                <RewardsStatItem key={item?.name} {...item} />
            ))}
        </div>
    );
};

const mapStateToProps = ({ Workspace }) => ({
    nameForPoints: Workspace.nameForPoints,
    platform: Workspace.platform,
});
Index.propTypes = {
    cohort: PropTypes.object,
    startDate: PropTypes.object,
    endDate: PropTypes.object,
};

export default Index;
