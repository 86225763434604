import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CustomDatePicker from 'components/ReusableComponents/CustomDatePicker';
import PropTypes from 'prop-types';

const useStyles = makeStyles({
    text: {
        fontFamily: 'Open Sans',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '0.75rem',
        lineHeight: '1.5em',
        color: '#82878d !important',
        marginBottom: 8,
        textTransform: 'uppercase',
    },
});

const Index = ({
    placeholder,
    title = 'Date',
    format,
    value,
    handleChange,
    titleClassName,
    className = 'width-240',
    disablePast = true,
    disableFuture = false,
    disableWeekends,
    disabled,
    editType,
    isEditingRecurring,
    pulseData,
}) => {
    const classes = useStyles();

    return (
        <div>
            <div className={titleClassName || classes.text}>{title}</div>
            <CustomDatePicker
                className={className}
                value={value}
                placeholder={placeholder}
                onChange={handleChange}
                format={format}
                disablePast={disablePast}
                disableFuture={disableFuture}
                disableWeekends={disableWeekends}
                disabled={disabled}
                editType={editType}
                isEditingRecurring={isEditingRecurring}
                pulseData={pulseData}
            />
        </div>
    );
};

Index.propTypes = {
    title: PropTypes.string,
    placeholder: PropTypes.string,
    format: PropTypes.string,
    value: PropTypes.instanceOf(Date),
    handleChange: PropTypes.func,
    className: PropTypes.string,
    titleClassName: PropTypes.string,
    disabled: PropTypes.bool,
    editType: PropTypes.bool,
    disablePast: PropTypes.bool,
    disableFuture: PropTypes.bool,
    disableWeekends: PropTypes.bool,
    isEditingRecurring: PropTypes.bool,
    pulseData: PropTypes.object,
};

export default Index;
