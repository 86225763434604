export const isQuestionTypeBinary = (element) => {
    return element?.question?.type === 'BINARY';
};

export const isApplicableForQuestionType = (element) => {
    return (
        element?.question?.type === 'BINARY' ||
        element?.question?.type === 'SINGLE_CHOICE' ||
        element?.question?.type === 'MULTIPLE_CHOICE'
    );
};

export const getBarFillColor = (questionType) => {
    switch (questionType) {
        case 'BINARY':
        case 'LIKERT':
            return '#A7D5EF';
        case 'RATING_5':
            return '#FBCDB3';
        default:
            return '#FFDA55';
    }
};
