import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { format, addDays, isValid, parseISO, isBefore } from 'date-fns';
import { showSnackBarMessage } from 'utils/HelperFunctions';
import { frequencyType, eNPSFrequency } from 'constants.js';
import clsx from 'clsx';

const NOTE_MIN_DAYS =
    '💡 Note: If you have team members in other timezones, ensure you allow\n them sufficient time to respond. Scheduling pulse just before the weekend\n might have lower response rates.';

const DayResponse = ({ label, scheduling, setScheduling, SetSnackbar, eNPS, error, setError, disabled }) => {
    const scheduleDate = isValid(scheduling?.onDate) ? new Date(scheduling?.onDate) : null;
    const isFriday = scheduleDate && scheduleDate.getDay() === 5;
    const [responseDeadlineError, setResponseDeadlineError] = useState(false);
    const currentDate = scheduling?.onDate;

    const selectedFrequency = eNPS
        ? eNPSFrequency?.find((freq) => freq?.value === scheduling?.frequencyType.toLowerCase())
        : frequencyType?.find((freq) => freq?.value === scheduling?.frequencyType.toLowerCase());

    const handleDaysChange = (event) => {
        let value = event.target.value;
        const regName = /^[0-9\b]*$/;
        if (regName.test(value) || value === '') {
            if (isBefore(addDays(parseISO(currentDate), parseInt(value)), new Date())) {
                setResponseDeadlineError(true);
            } else {
                setResponseDeadlineError(false);
            }
            if (value && parseInt(value) > 999) {
                showSnackBarMessage(SetSnackbar, 'warning', 'Maximum limit for days is 999');
            } else {
                value = Number(value) ? Number(value) : '';
                setScheduling({ ...scheduling, responseForDays: value });
            }
        }
    };

    const onDate = format(
        addDays(
            isValid(new Date(scheduling?.onDate)) ? new Date(scheduling.onDate) : new Date(),
            scheduling.responseForDays
        ),
        'MMM dd, yyyy'
    );

    useEffect(() => {
        setError(
            selectedFrequency?.label?.toLowerCase() !== 'one-time' &&
                scheduling?.responseForDays > selectedFrequency?.days
        );
    }, [scheduling]);

    return (
        <div>
            <div>
                <p className='sub-heading'>{label}</p>
                <div
                    className={clsx({
                        'container-allow-response': true,
                        'container-allow-response-error': error,
                    })}
                >
                    <input
                        type='text'
                        placeholder='Select no. of days'
                        name='days'
                        value={scheduling?.responseForDays}
                        onChange={handleDaysChange}
                        disabled={disabled}
                    />
                    <div className='allow-response-days'>Days</div>
                </div>
            </div>
            {responseDeadlineError && (
                <p className='mt-2' style={{ color: 'red' }} data-testid='reponse-validity-note'>
                    Responses will be accepted till{' '}
                    {format(addDays(parseISO(currentDate), scheduling?.responseForDays), 'MMM dd, yyyy hh:mm a')} which
                    is in the past
                </p>
            )}
            {!error && !responseDeadlineError && scheduling.responseForDays && (
                <p className='mt-2' data-testid='reponse-validity-note'>
                    Pulse will accept responses till {onDate}
                </p>
            )}
            {error && (
                <p className='allow-responses-error-text'>{`You can allow responses for up to ${selectedFrequency.days} days if the frequency is ${selectedFrequency.value}.`}</p>
            )}
            {isFriday && scheduling?.responseForDays <= 3 && (
                <div className='pulse-date-disclaimer'>
                    <span className='disclaimer-text'>{NOTE_MIN_DAYS}</span>
                </div>
            )}
        </div>
    );
};

DayResponse.propTypes = {
    label: PropTypes.string,
    scheduling: PropTypes.object,
    setScheduling: PropTypes.func,
    SetSnackbar: PropTypes.func,
    eNPS: PropTypes.object,
    error: PropTypes.bool,
    setError: PropTypes.func,
    disabled: PropTypes.bool,
};

export default DayResponse;
