import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import './style.scss';

const Index = ({ login, onClick }) => {
    return (
        <div className={clsx({ 'container-account-text': true })}>
            {login ? 'Don’t have an account?' : 'Already have an account?'}&nbsp;{''}
            <button className='button-login' onClick={onClick}>
                {login ? 'Sign up' : 'Sign in'}
            </button>
        </div>
    );
};

Index.propTypes = {
    login: PropTypes.bool,
    onClick: PropTypes.func,
};

export default Index;
