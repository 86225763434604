import React, { useState } from 'react';
import { PlanInfoBox } from '../styles';
import { handlePlanAction, PLAN_TYPES, PLAN_STATUS, SUMMARY_PLAN_BOX_DATA, CONTACT_US } from '../../constants';
import { shallowEqual, useSelector } from 'react-redux';
import EWButton from 'components/ReusableComponents/EWButton';
import { useToaster } from 'Context/SnackbarContext';
import { showSnackBarMessage } from 'utils/HelperFunctions';

const evaluatePlanType = (subscription) => {
    // Right now its only one plan on the platform
    return PLAN_TYPES.STANDARD;
};

export const Plans = () => {
    const [redirectLoading, setRedirectLoading] = useState(false);
    const { paymentsData } = useSelector(mapStateToProps, shallowEqual);
    const { SetSnackbar } = useToaster();

    const planType = evaluatePlanType(paymentsData?.subscription);

    const onError = (err) => {
        showSnackBarMessage(SetSnackbar, 'error', err?.message);
        setRedirectLoading(false);
    };

    const handleOnClick = () => {
        setRedirectLoading(true);
        handlePlanAction(onError);
    };

    const handleOpenTransactions = () => handlePlanAction(onError);

    if (paymentsData.loading) return null;

    return (
        <div>
            <div className='plan-section'>
                <div className='plan-data'>{SUMMARY_PLAN_BOX_DATA[planType].line1(paymentsData?.subscription)}</div>
                <div className='d-flex align-items-center'>
                    {paymentsData?.subscription?.status === PLAN_STATUS.CANCELLED && (
                        <EWButton
                            buttonStyleClass='mr-2'
                            buttonText='Contact Us'
                            primary={false}
                            plainTextButton
                            onClick={() => window.open(CONTACT_US, '_blank')}
                        />
                    )}
                    <EWButton
                        buttonStyleClass='ew-btn-slim'
                        buttonText={SUMMARY_PLAN_BOX_DATA[planType].button}
                        onClick={handleOnClick}
                        loading={redirectLoading}
                        disabled={redirectLoading}
                    />
                </div>
            </div>
            {paymentsData?.subscription?.status === PLAN_STATUS.CANCELLED && (
                <div className='plan-note'>{SUMMARY_PLAN_BOX_DATA[planType].line2(paymentsData)}</div>
            )}
            <p className='plan-note'>
                To access and download your transaction history and invoices, please{' '}
                <button className='btn-portal' onClick={handleOpenTransactions}>
                    click here.
                </button>
            </p>
        </div>
    );
};

const PlanInfo = () => {
    return (
        <PlanInfoBox>
            <Plans />
        </PlanInfoBox>
    );
};

const mapStateToProps = (state) => ({ paymentsData: state.Payments || {} });

Plans.propTypes = {};
PlanInfo.propTypes = {};

export default PlanInfo;
