import React from 'react';
import NotificationWrapper from '../../NotificationWrapper';
import PropTypes from 'prop-types';
import { ReactComponent as RedemptionFailedUserIcon } from 'Assets/images/redemption-failed-user.svg';
import '../styles.scss';

const Index = ({ data }) => {
    const giftCard = data.metadata.voucher.name;
    return (
        <div className='notification-event-container'>
            <div className=' auto-reload-disabled'>
                <div className='notification-redemption-failed-container'>
                    <div className='notification-redemption-failed-to-user-text-top-container'>
                        <RedemptionFailedUserIcon width={23} height={23} />
                        <p className='notification-module-text'>Redemption: </p>
                        <p>
                            Your <b>{giftCard}</b> Gift card redemption was unsuccessful, no funds have been deducted
                            from your balance.
                        </p>
                    </div>
                    <p className='notification-event-data-text'>
                        <b>Reason:</b> There was an issue processing your transaction and the gift card was not issued
                        by the provider. .
                    </p>
                </div>
            </div>
        </div>
    );
};

Index.propTypes = {
    data: PropTypes.object,
};

export default NotificationWrapper(Index);
