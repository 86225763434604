import React, { useState } from 'react';
import RecognitionHeader from './RecognitionHeader';
import RecognizedUsers from './RecognizedUsers';
import RecognitionMessage from './RecognitionMessage';
import ViewAddOnRecognitionModal from '../../RecognitionFeed/ViewAddOnRecognitionModal';
import ImageStack from 'components/ReusableComponents/ImageStack';
import ArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import EWButton from 'components/ReusableComponents/EWButton';
import { uniqBy } from 'lodash';
import PropTypes from 'prop-types';
import './style.scss';

const Index = ({ recognitionData, handleGiveAddOnRecognition }) => {
    const [viewAddOnRecognitionsModal, setViewAddOnRecognitionsModal] = useState(false);

    // if same user gives multiple add on recognitions then show user's name and pic only once
    const addOnRecognitions = uniqBy(recognitionData.addOnRecognitions, 'senderObjectId');

    const getUsersWithAddOnRecognitions = (addOnRecognitions) => {
        const userNames = addOnRecognitions.map((addOnRecognition) => addOnRecognition.userName);
        let names = '';
        if (userNames.length === 1) {
            names = userNames[0];
        } else if (userNames.length === 2) {
            names = userNames.join(' and ');
        } else if (userNames.length > 2) {
            names = `${userNames.slice(0, 2).join(',')}  and ${userNames.length - 2} more`;
        }
        return `${names} gave Add on ${userNames.length === 1 ? 'recognition' : 'recognitions'} `;
    };

    return (
        <div className='container-recognition-item'>
            <RecognitionHeader recognitionData={recognitionData} />
            <RecognizedUsers users={recognitionData?.receivers} value={recognitionData?.values} />
            <RecognitionMessage
                recognitionData={recognitionData}
                handleGiveAddOnRecognition={() => handleGiveAddOnRecognition(recognitionData)}
            />
            {addOnRecognitions.length > 0 && (
                <EWButton plainTextButton primary={false} onClick={() => setViewAddOnRecognitionsModal(true)}>
                    <div className='recognition-message-add-ons'>
                        <ImageStack members={addOnRecognitions} width={21} height={21} />
                        <p>
                            {`${getUsersWithAddOnRecognitions(addOnRecognitions)}`} <ArrowRightIcon />
                        </p>
                    </div>
                </EWButton>
            )}
            {viewAddOnRecognitionsModal && (
                <ViewAddOnRecognitionModal
                    open={viewAddOnRecognitionsModal}
                    onClose={() => setViewAddOnRecognitionsModal(false)}
                    recognitionData={recognitionData}
                    handleGiveAddOnRecognition={() => handleGiveAddOnRecognition(recognitionData)}
                />
            )}
        </div>
    );
};

Index.propTypes = {
    recognitionData: PropTypes.object,
    handleGiveAddOnRecognition: PropTypes.func,
};

export default Index;
