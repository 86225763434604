import React, { useState } from 'react';
import { useMutation, useQueryClient, useQuery } from 'react-query';
import PropTypes from 'prop-types';
import RewardPoint from 'components/ReusableComponents/RewardPoint';
import { ReactComponent as InfoIcon } from 'Assets/images/info-grey-filled.svg';
import AddOrEditRecognitionTypeModal from '../AddOrEditRecognitionTypeModal';
import ConfirmModal from 'components/ReusableComponents/ConfirmModal';
import CustomLoader from 'components/ReusableComponents/CustomLoader';
import { recogSetupHeaderReward, recogSetupSubHeader } from '../../constants';
import { GET_RECOGNITION_TYPES } from 'Services/apiKeys';
import { getRecognitionTypes, deleteRecognitionType } from '../../../Services/apiFunctions';
import clsx from 'clsx';
import EWButton from 'components/ReusableComponents/EWButton';
import './style.scss';
import { useToaster } from 'Context/SnackbarContext';
import { showSnackBarMessage } from 'utils/HelperFunctions';
import { RECOGNITION_TYPE_LIMIT_MESSAGE } from '../constants';

const Index = ({ nameForPoints }) => {
    const [recognitionTypes, setRecognitionTypes] = useState([]);
    const { SetSnackbar } = useToaster();
    const queryClient = useQueryClient();
    const [confirmModal, setConfirmModal] = useState({ open: false, type: '', id: '' });
    const [showAddOrEditRecognitionTypeModal, setShowAddOrEditRecognitionTypeModal] = useState(false);
    const [addRecognitionType, setAddRecognitionType] = useState(true);
    const [item, setItem] = useState('');
    const RECOGNITION_TYPES_LIMIT = 10;

    const getConfirmModalsData = (recognitionName) => ({
        DELETE_RECOGNITION: {
            name: 'DELETE_RECOGNITION',
            heading: `Are you sure you want to delete ${recognitionName} recognition type?`,
            subHeading: 'Once deleted, it cannot be recovered.',
            confirm: 'Delete',
        },
    });

    const { isFetching } = useQuery([GET_RECOGNITION_TYPES], getRecognitionTypes, {
        onSuccess: (data) => setRecognitionTypes(data.types),
        onError: (error) => showSnackBarMessage(SetSnackbar, 'error', error?.message),
    });

    const handleVertMenuOption = (option, item) => {
        /* eslint-disable default-case */
        switch (option) {
            case 'delete':
                setConfirmModal({
                    open: true,
                    type: item.name,
                    id: item._id,
                });
                setItem(item);
                break;
            case 'edit':
                setItem(item);
                setShowAddOrEditRecognitionTypeModal(true);
                setAddRecognitionType(false);
            // skip default case
        }
    };

    const { mutateAsync: deleteRecognitionTypeAsync, isLoading } = useMutation(deleteRecognitionType);

    const onConfirmDeleteRecognitionType = async () => {
        try {
            await deleteRecognitionTypeAsync(item._id);
            setItem('');
            queryClient.invalidateQueries([GET_RECOGNITION_TYPES]);
            setConfirmModal({ open: false });
            const message = `${item.name} recognition type deleted`;
            showSnackBarMessage(SetSnackbar, 'success', message);
        } catch (err) {
            showSnackBarMessage(SetSnackbar, 'error', err?.message);
        }
    };

    const handleAddRecognitionType = () => {
        if (recognitionTypes?.length >= RECOGNITION_TYPES_LIMIT) {
            showSnackBarMessage(SetSnackbar, 'error', RECOGNITION_TYPE_LIMIT_MESSAGE);
        } else {
            setShowAddOrEditRecognitionTypeModal(true);
            setAddRecognitionType(true);
        }
    };

    const handleOnClose = () => {
        setConfirmModal({ open: false });
    };

    return (
        <div className='recognition-config-container'>
            <div className='container-main-recognitions'>
                <div className='cr-container-header'>
                    <h3 className='header-reward'>{recogSetupHeaderReward}</h3>
                    <EWButton
                        buttonText={'+Add new Recognition type'}
                        primary={false}
                        plainTextButton
                        buttonStyleClass='cr-container-header-button'
                        onClick={() => handleAddRecognitionType()}
                    />
                </div>
                <p className='sub-header-reward'>{recogSetupSubHeader(nameForPoints)}</p>
                <div
                    className={clsx({
                        'container-recognitions': 1,
                        'justify-content-between': nameForPoints?.length > 9,
                    })}
                >
                    {isFetching ? (
                        <CustomLoader />
                    ) : (
                        recognitionTypes?.map((reward, index) => (
                            <div className='reward-item' key={reward.name}>
                                <RewardPoint
                                    index={index}
                                    recognitionTypeData={reward}
                                    rewardName={reward.name}
                                    recogniser={reward.allowRecognitionFor}
                                    isApprovalRequired={reward.isApprovalRequired}
                                    points={reward.points}
                                    nameForPoints={nameForPoints}
                                    handleAction={handleVertMenuOption}
                                    removeDeleteOption={recognitionTypes.length === 1}
                                />
                            </div>
                        ))
                    )}
                </div>
                <div className='cr-info'>
                    <InfoIcon className='mt-1' />
                    <p className='cr-info-message ml-1'>
                        If 'Approval required' is checked, respective manager approval is required to send the
                        recognition
                    </p>
                </div>
            </div>
            {showAddOrEditRecognitionTypeModal && (
                <AddOrEditRecognitionTypeModal
                    open={showAddOrEditRecognitionTypeModal}
                    addRecognition={addRecognitionType}
                    recognitionData={item}
                    setShowAddOrEditRecognitionTypeModal={setShowAddOrEditRecognitionTypeModal}
                    setAddRecognitionType={setAddRecognitionType}
                />
            )}
            <ConfirmModal
                open={confirmModal.open}
                onClose={handleOnClose}
                onConfirm={onConfirmDeleteRecognitionType}
                data={getConfirmModalsData(confirmModal.type).DELETE_RECOGNITION}
                red={true}
                loading={isLoading}
            />
        </div>
    );
};

Index.propTypes = {
    nameForPoints: PropTypes.string,
};

export default Index;
