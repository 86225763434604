import React, { useState } from 'react';
import PropTypes from 'prop-types';
import EWModal from 'components/ReusableComponents/EWModal';
import InputField from 'components/ReusableComponents/InputField';
import { useMutation, useQueryClient } from 'react-query';
import { showSnackBarMessage, isSubscriptionCancelled } from 'utils/HelperFunctions';
import { useToaster } from 'Context/SnackbarContext';
import { updateSuggestion } from '../../Services/apiFunctions';
import { FEEDBACK_TEXT_LENGTH_LIMIT, HISTORY_FROM } from 'constants.js';
import { GET_SUGGESTION_BOX_LIST } from 'Services/apiKeys';
import { shallowEqual, useSelector } from 'react-redux';
import SubscriptionCancelledModal from 'components/Modals/SubscriptionCancelledModal';
import EnhanceWithAI from 'components/ReusableComponents/EnhanceWithAI';
import './style.scss';

const Index = ({ open, onClose, editSuggestionData, history }) => {
    const queryClient = useQueryClient();
    const { SetSnackbar } = useToaster();
    const { subscription } = useSelector(mapStateToProps, shallowEqual);
    const [subscriptionCancelled, setSubscriptionCancelled] = useState(false);
    const [isAiEnhanced, setIsAiEnhanced] = useState(false);
    const [suggestionMessage, setSuggestionMessage] = useState(editSuggestionData.message);

    const { mutate: sendUpdateSuggestionMessage, isLoading: isSendingMessage } = useMutation(updateSuggestion, {
        onSuccess: (data) => {
            queryClient.invalidateQueries(GET_SUGGESTION_BOX_LIST);
            onClose();
        },
        onError: (err) => showSnackBarMessage(SetSnackbar, 'error', err.message),
    });
    const handleSendSuggestion = () => {
        if (suggestionMessage.length > FEEDBACK_TEXT_LENGTH_LIMIT) {
            showSnackBarMessage(
                SetSnackbar,
                'error',
                `Message length should be less than ${FEEDBACK_TEXT_LENGTH_LIMIT} characters`
            );
            return;
        }
        if (isSubscriptionCancelled(subscription)) {
            setSubscriptionCancelled(true);
            return;
        }
        const apiData = {
            message: suggestionMessage,
            isAIEnhanced: isAiEnhanced,
            suggestionId: editSuggestionData._id,
        };

        sendUpdateSuggestionMessage({ apiData });
    };

    // Data for send suggestion modal
    const modalData = {
        heading: 'Edit Suggestion',
        rightButtonText: 'Update',
        handleRightButtonClick: handleSendSuggestion,
        leftButtonText: 'Cancel',
        handleLeftButtonClick: onClose,
        loading: isSendingMessage,
        disabled: suggestionMessage === editSuggestionData.message,
    };

    return (
        <div>
            <EWModal open={open} onClose={onClose} width='528px' modalData={modalData}>
                <div className='container-send-suggestion'>
                    <InputField
                        inputID='suggestion_message'
                        label='Suggestion message'
                        labelClass='csf-dropdown-heading'
                        value={suggestionMessage}
                        placeholder='Enter your suggestion message here'
                        width='480px'
                        height='92px'
                        textArea
                        handleChange={(_id, value) => setSuggestionMessage(value)}
                    />
                    {suggestionMessage !== editSuggestionData.message && (
                        <EnhanceWithAI
                            textMessage={suggestionMessage}
                            isAiEnhanced={isAiEnhanced}
                            onMessageEnhanced={(message) => {
                                setSuggestionMessage(message);
                                setIsAiEnhanced(true);
                            }}
                            handleRevertoriginalMessage={(message) => {
                                setSuggestionMessage(message);
                                setIsAiEnhanced(false);
                            }}
                        />
                    )}
                </div>
                <SubscriptionCancelledModal
                    open={subscriptionCancelled}
                    onClose={() => setSubscriptionCancelled(false)}
                    history={history}
                    currentRoute={HISTORY_FROM.SUGGESTION_BOX}
                />
            </EWModal>
        </div>
    );
};
const mapStateToProps = ({ Payments }) => ({
    subscription: Payments?.subscription,
});

Index.propTypes = {
    open: PropTypes.bool,
    editSuggestionData: PropTypes.object,
    onClose: PropTypes.func,
    history: PropTypes.object,
};

export default Index;
