import React, { useState, useEffect } from 'react';
import EmptyScreen from 'components/ReusableComponents/EmptyScreen';
import { ReactComponent as ENPSImage } from 'Assets/images/eNPS.svg';
import { useDispatch, shallowEqual, useSelector } from 'react-redux';
import { toggleFullscreen } from 'redux/layout/actions';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { eNPS_ROUTE } from '../constant';
import { HISTORY_FROM } from 'constants.js';
import SubscriptionCancelledModal from 'components/Modals/SubscriptionCancelledModal';
import { isSubscriptionCancelled } from 'utils/HelperFunctions';

const Index = ({ history }) => {
    const dispatch = useDispatch();
    const location = useLocation();
    const [subscriptionCancelled, setSubscriptionCancelled] = useState(false);
    const { subscription } = useSelector(mapStateToProps, shallowEqual);

    const { draftId } = location?.state || '';

    const emptyScreenContent = {
        image: <ENPSImage />,
        description:
            'Employee NPS is a scoring system designed to help employers measure employee engagement within the organizations.',
        btnName: 'Setup eNPS',
        action: () => {
            if (isSubscriptionCancelled(subscription)) {
                setSubscriptionCancelled(true);
                return;
            }
            history.push(
                draftId
                    ? {
                          pathname: eNPS_ROUTE.CREATE_TEMPLATE,
                          state: { draftId },
                      }
                    : eNPS_ROUTE.eNPS_CREATE
            );
        },
    };
    useEffect(() => {
        toggleFullscreen(dispatch, false);
    }, []);
    return (
        <div className='enps-empty'>
            <EmptyScreen {...emptyScreenContent} />
            <SubscriptionCancelledModal
                open={subscriptionCancelled}
                onClose={() => setSubscriptionCancelled(false)}
                history={history}
                currentRoute={HISTORY_FROM.eNPS}
            />
        </div>
    );
};
const mapStateToProps = ({ Payments }) => ({
    subscription: Payments?.subscription,
});
Index.propTypes = {
    history: PropTypes.object,
};

export default Index;
