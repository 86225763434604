import React from 'react';
import Recognizer from '../Recognizer';
import RecognitionDate from '../RecognitionDate';
import PropTypes from 'prop-types';
import './style.scss';

const Index = ({ recognitionData }) => {
    return (
        <div className='recognition-header'>
            <Recognizer
                userName={recognitionData?.sender?.userName}
                pictureURL={recognitionData?.sender?.pictureURL}
                recognitionType={recognitionData?.type}
            />
            <RecognitionDate date={recognitionData?.timestamp} />
        </div>
    );
};

Index.propTypes = {
    recognitionData: PropTypes.object,
};

export default Index;
