import React from 'react';
import EllipsisText from 'components/ReusableComponents/EllipsisText';
import { handlePageRedirectionWithinSubMenu } from 'utils/SessionUtils/sessionUtils';
import PropTypes from 'prop-types';
import './styles.scss';

export const Index = ({ history, memberID, memberPhoto, memberName, navMenuCode, deactivated }) => {
    return (
        <button
            className='member-tag'
            onClick={() =>
                memberID
                    ? handlePageRedirectionWithinSubMenu({
                          history,
                          redirectionURL: `/dashboard/profile/${memberID}`,
                          navMenuCode: navMenuCode,
                      })()
                    : null
            }
        >
            <img src={memberPhoto || require('Assets/images/defaultUser.png')} alt='' />

            <EllipsisText
                text={memberName}
                white
                customClassName={`mb-0 ${deactivated ? 'member-tag-name-deactivated' : 'member-tag-name'}`}
            />
        </button>
    );
};
Index.propTypes = {
    history: PropTypes.object,
    memberID: PropTypes.string,
    memberPhoto: PropTypes.string,
    memberName: PropTypes.string,
    navMenuCode: PropTypes.string,
    deactivated: PropTypes.bool,
};

export default Index;
