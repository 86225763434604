import React from 'react';
import Pagination from '@material-ui/lab/Pagination';
import './style.scss';
import PropTypes from 'prop-types';
import clsx from 'clsx';

//Sample screenshot https://user-images.githubusercontent.com/38394102/129188142-a787f355-73ad-42db-bd5b-dd7b087ce1c7.png

const Index = ({ limit, currentPage, totalCount, setCurrentPage, onChange, pageItem, fixedBottom, rounded = true }) => {
    const handleChange = (_event, value) => {
        setCurrentPage(value);
        if (onChange) onChange(value);
    };

    const pageItemCount = 10 * currentPage;
    const pageItemCountLimit = pageItemCount > totalCount ? totalCount : pageItemCount;

    return (
        <>
            {pageItem && (
                <p className='pagination-info '>
                    Showing{' '}
                    <strong>
                        {pageItemCount - 9}-{pageItemCountLimit}
                    </strong>{' '}
                    of <strong>{totalCount}</strong> {pageItem}
                </p>
            )}
            <div className={clsx({ 'ew-pagination': true, stickyBottom: fixedBottom, 'pagination-radius': rounded })}>
                <Pagination
                    page={currentPage}
                    count={Math.ceil(totalCount / (limit || 10))}
                    onChange={handleChange}
                    variant='outlined'
                    shape='rounded'
                />
            </div>
        </>
    );
};

Index.propTypes = {
    currentPage: PropTypes.number,
    totalCount: PropTypes.number,
    setCurrentPage: PropTypes.func,
    onChange: PropTypes.func,
    fixedBottom: PropTypes.bool,
    rounded: PropTypes.bool,
    limit: PropTypes.number,
    pageItem: PropTypes.string,
};

export default Index;
