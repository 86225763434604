import React from 'react';
import PropTypes from 'prop-types';
import ConfirmModal from 'components/ReusableComponents/ConfirmModal';
import { ReactComponent as Warning } from 'Assets/images/warning-yellow.svg';

const Index = ({ channel, onConfirm }) => {
    const text = {
        heading: (
            <p>
                <Warning style={{ width: '18px', height: '14px' }} /> Unable to Remove
            </p>
        ),
        subHeading: (
            <span>
                We are unable to remove EngageWith from #{channel?.label} because it is a private channel. If you would
                like to remove this channel, you can do so directly from Slack.
            </span>
        ),
        confirm: 'Close',
    };
    return <ConfirmModal open onClose={onConfirm} onConfirm={onConfirm} data={text} height='230px' singleButton />;
};

Index.propTypes = {
    channel: PropTypes.object,
    onConfirm: PropTypes.func,
};

export default Index;
