import React from 'react';
import PropTypes from 'prop-types';
import { Route, useLocation } from 'react-router-dom';
import { getUserFromLocalStorage } from 'utils/SessionUtils/sessionUtils';

const Index = ({ component, ...rest }) => {
    const admin = getUserFromLocalStorage();
    const location = useLocation();
    const isLoggedIn =
        location.search.includes('success') || (admin?.['team'] && admin?.['adminId'] && admin?.['platform']) || false;

    if (!isLoggedIn && !location.pathname.includes('/login') && !location.pathname.includes('/signup')) {
        window.open(process.env.REACT_APP_LANDER_URL, '_self');
        return null;
    }

    return <Route component={component} {...rest} />;
};

Index.propTypes = {
    component: PropTypes.any,
};

export default Index;
