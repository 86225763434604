import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './style.scss';
import clsx from 'clsx';

const Index = ({ option1 = 'Yes', option2 = 'No', firstOptionSelected = true, handleSelection, height = 32 }) => {
    const [firstOption, setFirstOption] = useState(firstOptionSelected);

    const handleClick = (value) => {
        setFirstOption(!value);
        handleSelection(value);
    };

    return (
        <div className='custom-toggle-container' style={{ height }}>
            <button
                className={clsx({ 'toggle-filled': firstOption, 'toggle-outlined': !firstOption })}
                onClick={() => handleClick(0)}
            >
                {option1}
            </button>
            <button
                className={clsx({ 'toggle-filled': !firstOption, 'toggle-outlined': firstOption })}
                onClick={() => handleClick(1)}
            >
                {option2}
            </button>
        </div>
    );
};

Index.propTypes = {
    option1: PropTypes.string,
    option2: PropTypes.string,
    firstOptionSelected: PropTypes.bool,
    handleSelection: PropTypes.func,
    height: PropTypes.number,
};

export default Index;
