const cardData = {
    brand: 'visa',
    country: 'US',
    exp_month: 4,
    exp_year: 2024,
    last4: '4242',
};

export const paymentsData = [
    {
        cardList: [
            {
                ...cardData,
                paymentMethodId: 'src_1KUCsRKWprTQqvZyI4BlYyh8',
                funding: 'credit',
            },
            {
                ...cardData,
                paymentMethodId: 'pm_1KUCsBKWprTQqvZymO9av6zW',
                funding: 'credit',
                isDefault: true,
            },
        ],
        product: 'Pro',
        pricings: 2,
        activeUsers: 101,
        walletBalance: 0,
        status: 'canceled',
        currency: 'USD',
        currencySymbol: '$',
        upcomingBillingAmount: 0,
        autoReloadSettings: null,
        analytics: {
            totalPointsGiven: 0,
            totalAppreciations: 0,
            totalSenderCount: 0,
        },
        periodEndUnix: 1644965810,
        cardDetails: {
            ...cardData,
            paymentMethodId: 'pm_1KUCsBKWprTQqvZymO9av6zW',
            logo: 'https://www.credit-card-logos.com/images/visa_credit-card-logos/visa_logo_2.gif',
        },
    },
];

export const customerCardsData = [
    {
        paymentMethodId: 'src_1KURtmKWprTQqvZyG4MveJDe',
        ...cardData,
        funding: 'credit',
    },
    {
        paymentMethodId: 'pm_1KUCsBKWprTQqvZymO9av6zW',
        ...cardData,
        funding: 'credit',
        isDefault: true,
    },
];

export const addWalletBalance = [
    (schema, request) => {
        const { result, data } = JSON.parse(request.requestBody);
        // if valid value exists then modify in db
        return { result, data };
    },
];
