import Modal from '@material-ui/core/Modal';
import PropTypes from 'prop-types';
import React from 'react';
import { ReactComponent as SurveySentImg } from 'Assets/images/survey-sent.svg';
import EWButton from 'components/ReusableComponents/EWButton';
import './style.scss';

/**
 * Receives the data object in the following for the modal
 * {
 * title: 'title',
 * content: 'content',
 * buttonText: 'text'
 * }
 */

const Index = ({ open, data, onConfirm, onClose, showCloseButtton = true }) => {
    if (!open) {
        return null;
    }

    return (
        <Modal open={open}>
            <div className='pulse-created-modal absolute-center'>
                <div className='content d-flex flex-column justify-content-center align-items-center'>
                    <SurveySentImg />
                    <div className='pc-title my-3'>{data?.title}</div>
                    <p>{data?.content}</p>
                    {data?.buttonText && (
                        <EWButton buttonStyleClass='mt-2' buttonText={data?.buttonText} onClick={onConfirm} />
                    )}
                    {showCloseButtton && (
                        <EWButton
                            buttonStyleClass='mt-2'
                            primary={false}
                            plainTextButton
                            buttonText='Close'
                            onClick={onClose}
                        />
                    )}
                </div>
            </div>
        </Modal>
    );
};

Index.propTypes = {
    open: PropTypes.bool,
    data: PropTypes.shape({
        title: PropTypes.string,
        content: PropTypes.string,
        buttonText: PropTypes.string,
    }),
    onConfirm: PropTypes.func,
    onClose: PropTypes.func,
    showCloseButtton: PropTypes.bool,
};

export default Index;
