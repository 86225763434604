import React from 'react';
import { Draggable } from 'react-beautiful-dnd';
import PropTypes from 'prop-types';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import CloseIcon from '@material-ui/icons/Close';

const DraggableOption = ({ option, index, isEditingOngoing, handleOptionChange, handleDeleteOption, optionLength }) => {
    return (
        <Draggable draggableId={option.id} key={option.id} index={index}>
            {(innerProvided) => (
                <div
                    className='d-flex align-items-center mb-4'
                    ref={innerProvided.innerRef}
                    {...innerProvided.draggableProps}
                >
                    {!isEditingOngoing && (
                        <span {...innerProvided.dragHandleProps}>
                            <DragIndicatorIcon style={{ color: '#c4c4c4' }} />
                        </span>
                    )}
                    <input
                        type='text'
                        value={option.name}
                        onChange={(event) => handleOptionChange(event, index)}
                        placeholder={`Choice ${index + 1}`}
                        disabled={isEditingOngoing}
                    />
                    {optionLength > 2 && !isEditingOngoing && <CloseIcon onClick={() => handleDeleteOption(index)} />}
                </div>
            )}
        </Draggable>
    );
};

DraggableOption.propTypes = {
    option: PropTypes.object,
    index: PropTypes.number,
    isEditingOngoing: PropTypes.bool,
    handleOptionChange: PropTypes.func,
    handleDeleteOption: PropTypes.func,
    optionLength: PropTypes.number,
};

export default DraggableOption;
