import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { useToaster } from 'Context/SnackbarContext';
import { isEmptyObject, showSnackBarMessage } from 'utils/HelperFunctions';
import { GET_CONFIG_POINTS } from 'Services/apiKeys';
import { getPointsConfig } from 'Services/apiFunctions';
import PropTypes from 'prop-types';
import EnableRewards from './EnableRewards';

const Index = ({ history }) => {
    const { SetSnackbar } = useToaster();
    const [enableRewards, setEnableRewards] = useState(false);

    useQuery([GET_CONFIG_POINTS], getPointsConfig, {
        onSuccess: (data) => {
            if (!isEmptyObject(data)) {
                setEnableRewards(data?.redemption);
            }
        },
        onError: (error) => showSnackBarMessage(SetSnackbar, 'error', error?.message),
    });

    return (
        <div>
            <EnableRewards history={history} enableRewards={enableRewards} setEnableRewards={setEnableRewards} />
        </div>
    );
};

Index.propTypes = {
    history: PropTypes.object,
};

export default Index;
