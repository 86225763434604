import React from 'react';
import PropTypes from 'prop-types';
import Modal from '@material-ui/core/Modal';
import CloseIcon from '@material-ui/icons/Close';
import './style.scss';

const Index = ({ open, setOpen, channels }) => {
    const content = (
        <div className='requested-channels-container'>
            <div className='requested-channels-header'>
                <p>Requested Channels</p>
                <CloseIcon className='close-icon' onClick={() => setOpen(false)} />
            </div>
            <div className='requested-channels-body'>
                {channels?.map((value, index) => {
                    return (
                        <p className='pulse-channels-info' key={value?.channelID + index}>
                            #{value?.channelName}
                        </p>
                    );
                })}
            </div>
        </div>
    );

    return (
        <div>
            <Modal open={open} onClose={() => setOpen(false)}>
                {content}
            </Modal>
        </div>
    );
};

Index.propTypes = {
    open: PropTypes.bool,
    setOpen: PropTypes.func,
    channels: PropTypes.array,
};

export default Index;
