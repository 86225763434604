import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as GroupIcon } from 'Assets/images/group.svg';
import clsx from 'clsx';
import './style.scss';

const Index = ({ count, handleClick, showPointerCursor = false }) => {
    return (
        <button
            className={clsx({ 'team-count-span': true, 'pointer-cursor': showPointerCursor })}
            onClick={handleClick}
        >
            <GroupIcon /> {count}
        </button>
    );
};

Index.propTypes = {
    count: PropTypes.number,
    handleClick: PropTypes.func,
    showPointerCursor: PropTypes.bool,
};

export default Index;
