import React, { useRef, useState } from 'react';
import { useQueryClient, useMutation } from 'react-query';
import { GET_PAGINATED_USERS } from 'Services/apiKeys';
import { modifyUser } from 'Services/apiFunctions';
import { useToaster } from 'Context/SnackbarContext';
import { showSnackBarMessage } from 'utils/HelperFunctions';
import EWModal from 'components/ReusableComponents/EWModal';
import MemberCard from 'components/ReusableComponents/MemberDetailsCard';
import EWButton from 'components/ReusableComponents/EWButton';
import UserDetailsCard from 'components/ReusableComponents/UserDetailsCard';
import PropTypes from 'prop-types';
import './style.scss';
import { find } from 'lodash';

const Index = ({ open, onClose, managerData, handleChangeTeamManager, userDetails }) => {
    const queryClient = useQueryClient();
    const { SetSnackbar } = useToaster();
    const [removedUsers, setRemovedUsers] = useState([]);

    const getTeamDetails = () => {
        if (managerData?.team?.length > 0 && userDetails?.length > 0) {
            // since team field does not have users dept info we get it from user details
            return managerData.team.map((teamMember) => {
                const teamUser = find(userDetails, (user) => user._id === teamMember._id);
                return teamUser ? { ...teamMember, department: teamUser?.department } : teamMember;
            });
        }
        return [];
    };

    const selectedUsers = useRef(getTeamDetails());

    const { mutateAsync: updateManager, isLoading } = useMutation(modifyUser);

    const handleSave = async () => {
        try {
            const apiData = {
                managerId: managerData?._id,
                removedUserIds: removedUsers?.map((removedUser) => removedUser?._id),
            };
            await updateManager({ apiData });
            queryClient.invalidateQueries(GET_PAGINATED_USERS);
            onClose();
        } catch (error) {
            showSnackBarMessage(SetSnackbar, 'error', error?.message);
        }
    };

    const handleUserRemoval = (user) => {
        // handle removal of users from team
        selectedUsers.current = selectedUsers.current.filter((item) => item?._id !== user?._id);
        setRemovedUsers([...removedUsers, user]);
    };

    const modalData = {
        heading: 'Manage Team',
        rightButtonText: 'Save',
        handleRightButtonClick: handleSave,
        leftButtonText: 'Cancel',
        handleLeftButtonClick: onClose,
        loading: isLoading,
        disabled: isLoading || removedUsers?.length < 1,
    };

    return (
        <EWModal open={open} onClose={() => onClose(false)} width='528px' height='510px' modalData={modalData}>
            <div className='container-view-team-modal'>
                <UserDetailsCard
                    userName={managerData?.userName}
                    pictureURL={managerData?.pictureURL}
                    department={managerData?.department}
                    tag='Manager'
                />
                <div className='cv-container-selection'>
                    <span className='cv-direct-reports'>{`Users under this Manager (${selectedUsers.current?.length})`}</span>
                    <div className='cv-change-manager'>
                        <EWButton
                            buttonText='Change manager for this team'
                            plainTextButton
                            primary={false}
                            onClick={handleChangeTeamManager}
                        />
                        <img src={require('Assets/images/change-manager-for-team.svg')} alt='change-manager' />
                    </div>
                </div>
                <div className='cv-selection-list'>
                    {selectedUsers.current.map((member) => (
                        <MemberCard
                            key={member._id}
                            member={member}
                            selected={true}
                            handleChange={() => handleUserRemoval(member)}
                        />
                    ))}
                </div>
            </div>
        </EWModal>
    );
};

Index.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    managerData: PropTypes.object,
    handleChangeTeamManager: PropTypes.func,
    userDetails: PropTypes.array,
};

export default Index;
