import React from 'react';
import { DisclaimerNoteBox } from './Components/styles';
import { STRIPE_FEES } from './constants';
import PropTypes from 'prop-types';

export const DisclaimerNote = ({ currency }) => (
    <DisclaimerNoteBox>
        <p className=''>
            <b>{currency === 'USD' ? STRIPE_FEES.USD : STRIPE_FEES.OTHER}%</b> fee will be added to every transaction
            via cards. This fee is added to offset the fee charged to us. Reach out to customer support at{' '}
            <a
                href='mailto:engagewith@springworks.in'
                target='_blank'
                rel='noopener noreferrer'
                className='underline-text'
            >
                engagewith@springworks.in
            </a>{' '}
            to do a bank transfer to avoid the fee.
        </p>
    </DisclaimerNoteBox>
);

DisclaimerNote.propTypes = {
    currency: PropTypes.string,
};
