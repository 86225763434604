import React from 'react';
import CustomSwitch from 'components/Styles/CustomSwitch';
import ConfirmModal from 'components/ReusableComponents/ConfirmModal';
import PropTypes from 'prop-types';

const Index = ({ checked, handleChange, modalOpen, setModalOpen, data, type }) => (
    <div className='ob-toggle'>
        <CustomSwitch
            checked={checked}
            onClick={() => (!checked ? handleChange(true, type) : setModalOpen(true))}
            aria-checked={checked}
            data-testid='custom-switch'
        />{' '}
        <ConfirmModal
            open={modalOpen}
            onConfirm={() => {
                handleChange(false, type);
                setModalOpen(false);
            }}
            onClose={() => setModalOpen(false)}
            data={{
                heading: data.heading,
                subHeading: data.subHeading,
                confirm: 'Disable',
            }}
            data-testid='custom-modal'
            red
        />
    </div>
);

Index.propTypes = {
    checked: PropTypes.bool,
    handleChange: PropTypes.func,
    modalOpen: PropTypes.bool,
    setModalOpen: PropTypes.func,
    data: PropTypes.object,
    type: PropTypes.string,
};

export default Index;
