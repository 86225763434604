import { actions } from './actionEvents';

const initState = {
    loading: true,
    cardList: [],
};

export default function PaymentsReducer(state = initState, action) {
    const { payload, type } = action;

    switch (type) {
        case actions.SET_DATA:
            return { ...state, ...payload.data, loading: false };

        case actions.SET_CARD_DATA: {
            // added braces to resolve codesmell - lexical declaration
            const { cardData } = payload;
            const cardList = payload.cardList ? [...payload.cardList, cardData] : [cardData];
            if (state.card) {
                return { ...state, loading: false, cardList };
            }
            return { ...state, loading: false, card: cardData, cardList };
        }

        case actions.UPDATE_DATA:
            return { ...state, ...payload };

        case actions.ADD_WALLET_BALANCE: {
            // added braces to resolve codesmell - lexical declaration
            const balanceData = { ...state.workspaceWallet, balance: (state.workspaceWallet.balance || 0) + payload };
            return { ...state, workspaceWallet: { ...balanceData } };
        }

        default:
            return state;
    }
}
