import React from 'react';
import PropTypes from 'prop-types';
import ConfirmModal from 'components/ReusableComponents/ConfirmModal';

const Index = ({ type, name, open, onConfirm, onClose, loading, userCount = 0 }) => {
    if (!open) {
        return null;
    }
    const deleteCountryMessage =
        `${userCount} user${userCount > 1 ? 's' : ''} will no longer have any country associated with them.\n` +
        'This will allow them to redeem rewards from all enabled countries.';
    const data = {
        heading: `Are you sure want to delete ${type === 'country' ? "'" : ''}${name}${
            type === 'country' ? "'" : ''
        } ${type} ?`,
        ...(type === 'country' && { subHeading: deleteCountryMessage }),
        confirm: 'Delete',
    };
    return (
        <ConfirmModal open onClose={onClose} onConfirm={onConfirm} data={data} red loading={loading} height='204px' />
    );
};

Index.propTypes = {
    type: PropTypes.string,
    name: PropTypes.string,
    open: PropTypes.bool,
    onConfirm: PropTypes.func,
    onClose: PropTypes.func,
    loading: PropTypes.bool,
    userCount: PropTypes.number,
};

export default Index;
