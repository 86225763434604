import React from 'react';
import InputField from 'components/ReusableComponents/InputField';
import PropTypes from 'prop-types';
import './style.scss';

const InviteViaEmail = ({ email, setEmail }) => {
    return (
        <div className='invite-users-email'>
            <div className='invite-users-email-title'>
                <span>Enter email address&nbsp;</span>
                <span>(For multiple users, enter email addresses separated with commas)</span>
            </div>
            <InputField
                inputID='email'
                placeholder='Enter email address'
                inputType='text'
                width='392px'
                value={email}
                handleChange={(_id, value) => setEmail(value)}
            />
        </div>
    );
};

InviteViaEmail.propTypes = {
    email: PropTypes.bool,
    setEmail: PropTypes.func,
};

export default InviteViaEmail;
