import React, { useState } from 'react';
import ConfirmModal from 'components/ReusableComponents/ConfirmModal';
import { selectChannelModalData } from '../constants';
import PropTypes from 'prop-types';
import { getIntegratedChannelName } from 'utils/HelperFunctions';

import './style.scss';

const Index = ({ open, handleSave, onClose, channelsData, selectedChannel, platform, disableButton = false }) => {
    const [channel, setChannel] = useState(selectedChannel);

    return (
        <div>
            <ConfirmModal
                open={open}
                data={selectChannelModalData}
                onClose={onClose}
                onConfirm={() => handleSave(channel)}
                height={'219px'}
                dropdownData={channelsData}
                showDropDown
                handleDropdownSelection={(_index, value) => setChannel(value)}
                singleButton
                disableButton={disableButton}
                selectedDropdownOption={getIntegratedChannelName(channel, platform)}
            />
        </div>
    );
};

Index.propTypes = {
    open: PropTypes.bool,
    handleSave: PropTypes.func,
    onClose: PropTypes.func,
    channelsData: PropTypes.array,
    selectedChannel: PropTypes.string,
    platform: PropTypes.string,
    disableButton: PropTypes.bool,
};

export default Index;
