import React from 'react';
import PropTypes from 'prop-types';
import Redemption from 'components/Dashboard/Content/Activities/MyActivities/Redemptions';
import TitleBar from 'components/ReusableComponents/TitleBar';

const Index = ({ history }) => {
    return (
        <div className='redeem-points-container'>
            <div className='title-container'>
                <TitleBar title={`Redeem History`} back onBack={() => history.push('/dashboard/redeem/list')} />
            </div>
            <Redemption />
        </div>
    );
};

Index.propTypes = {
    history: PropTypes.object,
};

export default Index;
