import React from 'react';
import { default as Tooltip } from 'components/Styles/Tooltip';
import { Ellipsis } from 'utils/Stylesheet/style';
import PropTypes from 'prop-types';

/** EllipseText
 *  Resuable component to show ellipsis text
 *
 * @param {string} text - text to be displayed
 * @param {string} children - children to be displayed in ellipse
 * @param {string} maxWidth - max width of the ellipse
 * @param {boolean} white - white tooltip
 *
 * @returns {JSX} - Ellipse Text with ToolTip
 *
 * @example
 * <EllipseText>{'This is a text'}</EllipsisText>
 * @example
 * <EllipseText white maxWidth='200px' text='This is a text' />
 */

const Index = ({ children, text, maxWidth = '124px', white = false, customClassName }) => {
    const textContent = text || children;
    return (
        <Tooltip white={white} arrow={!white} title={textContent} hide={false} placement='bottom-end'>
            <Ellipsis {...(customClassName && { className: customClassName })} maxWidth={maxWidth}>
                {textContent}
            </Ellipsis>
        </Tooltip>
    );
};

Index.propTypes = {
    children: PropTypes.string,
    text: PropTypes.string,
    maxWidth: PropTypes.string,
    white: PropTypes.bool,
    customClassName: PropTypes.string,
};

export default Index;
