import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';
import { TRANSACTION_STATUS } from './constants';
import { Tooltip } from 'components';
import { ReactComponent as InfoIcon } from 'Assets/images/info-grey-filled.svg';

const StatusChips = ({ status, rejectedReason = '' }) => {
    const reason = (
        <p className='rejected-reason'>
            <span className=' rejected-reason-lable-text'>Reject Reason: </span>"{rejectedReason}"
        </p>
    );
    return (
        <div className=' status-text-container'>
            <p className='status-text' style={{ color: `${TRANSACTION_STATUS[status]['color']}` }}>
                {TRANSACTION_STATUS[status]['label']}
            </p>
            {status.toLowerCase() === TRANSACTION_STATUS['Rejected']['value'] && (
                <Tooltip title={reason} white>
                    <InfoIcon />
                </Tooltip>
            )}
        </div>
    );
};

StatusChips.propTypes = {
    status: PropTypes.string,
    rejectedReason: PropTypes.string,
};

export default StatusChips;
