import React from 'react';
import PropTypes from 'prop-types';
import RecognitionFeed from './RecognitionFeed';
import PendingRecognitions from './PendingRecognitions';
import TitleBar from 'components/ReusableComponents/TitleBar';
import './style.scss';

const Index = ({ history }) => {
    return (
        <div>
            <TitleBar title='Recognitions' />
            <div className='container-recognitions'>
                <RecognitionFeed history={history} />
                <PendingRecognitions history={history} />
            </div>
        </div>
    );
};

Index.propTypes = {
    history: PropTypes.object,
};

export default Index;
