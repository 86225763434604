import React from 'react';
import clsx from 'clsx';
import { find } from 'lodash';
import { CustomCheckbox } from 'components';
import PropTypes from 'prop-types';
import './style.scss';

const Index = ({ user, selectedUsers, handleUserSelection }) => {
    return (
        <button className={clsx('dropdown-list')} key={user._id} onClick={() => handleUserSelection(user)}>
            <CustomCheckbox checked={!!find(selectedUsers, (selectedUser) => selectedUser._id === user._id)} />
            <img
                className='dropdown-list-user-image'
                src={user.pictureURL || require('Assets/images/defaultUser.png')}
                alt='user'
            />
            <span className='dropdown-list-username'>{user.userName}</span>
        </button>
    );
};

Index.propTypes = {
    user: PropTypes.object,
    selectedUsers: PropTypes.array,
    handleUserSelection: PropTypes.func,
};

export default Index;
