import React from 'react';
import { Tooltip } from 'components';
import { ReactComponent as InfoIcon } from 'Assets/images/info-grey-filled.svg';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import './style.scss';

const Index = ({ text, tooltipText, textStyleClass }) => {
    return (
        <div className='container-label-tooltip'>
            <span className={clsx({ 'clt-label-text': !textStyleClass, [textStyleClass]: textStyleClass })}>
                {text}
                {tooltipText && (
                    <Tooltip arrow title={tooltipText}>
                        <InfoIcon className='info-icon' />
                    </Tooltip>
                )}
            </span>
        </div>
    );
};

Index.propTypes = {
    text: PropTypes.string.isRequired,
    tooltipText: PropTypes.string,
    textStyleClass: PropTypes.string,
};

export default Index;
