import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import RestrictedUser from './RestrictedUsers';
import { OrgSettings } from 'components/ReusableComponents/Settings';
import MultiSelectionUserModal from 'components/Modals/MultiSelectUserModal';
import { useMutation, useQueryClient } from 'react-query';
import { postRecognitionRestrictedUser } from '../../../Services/apiFunctions';
import { GET_RECOGNITION_RESTRICTED_USER } from 'Services/apiKeys';
import { showSnackBarMessage } from 'utils/HelperFunctions';
import { useToaster } from 'Context/SnackbarContext';
import './style.scss';

const Index = ({ data, dataLoading }) => {
    const queryClient = useQueryClient();
    const { SetSnackbar } = useToaster();
    const [addPermissionOpen, setAddPermissionOpen] = useState(false);
    const addPermissionModalData = useRef();
    const { mutateAsync: sendRecognitionPermission } = useMutation(postRecognitionRestrictedUser);

    const removeRestrictedUserHandler = async (item, key) => {
        const apiData = {
            recognition: {
                [key]: {
                    removed: [item._id],
                },
            },
        };
        try {
            const data = await sendRecognitionPermission({ apiData });
            showSnackBarMessage(SetSnackbar, 'success', data.message);
            queryClient.invalidateQueries(GET_RECOGNITION_RESTRICTED_USER);
        } catch (error) {
            showSnackBarMessage(SetSnackbar, 'error', error.message);
        }
    };

    const permissionSection = [
        {
            heading: 'Ability to send a recognition but not be recognised',
            subtext: 'Users who have this permission will not be shown in the user list while sending recognitions.',
            button: '+Add Users',
            component: (
                <RestrictedUser
                    data={data?.recognition.restricted || []}
                    isLoading={dataLoading}
                    removeHandler={removeRestrictedUserHandler}
                    section={'restricted'}
                />
            ),
        },
        {
            heading: `Ability to send a recognition without approvals`,
            subtext: `Users who have this permission will be able to send recognition without any approvals`,
            button: `+Add Users`,
            component: (
                <RestrictedUser
                    data={data?.recognition.bypassApproval || []}
                    isLoading={dataLoading}
                    removeHandler={removeRestrictedUserHandler}
                    section={'bypassApproval'}
                />
            ),
        },
    ];

    const onClose = () => {
        setAddPermissionOpen(false);
    };

    const onSave = async (selectedUser, removedUser) => {
        const selectedIds = selectedUser.map((item) => item._id);
        const removedIds = removedUser.map((item) => item._id);
        const key = addPermissionModalData.current.key;
        const apiData = {
            recognition: {
                [key]: {
                    added: selectedIds,
                    removed: removedIds,
                },
            },
        };
        try {
            const data = await sendRecognitionPermission({ apiData });
            showSnackBarMessage(SetSnackbar, 'success', data.message);
            queryClient.invalidateQueries(GET_RECOGNITION_RESTRICTED_USER);
            onClose();
        } catch (error) {
            showSnackBarMessage(SetSnackbar, 'error', error.message);
        }
    };
    const handleOpenModal = (index) => {
        if (index === 0) {
            addPermissionModalData.current = {
                key: 'restricted',
                heading: 'Can give recognition but cannot receive it',
                subHeading:
                    'Users who have this permission will not be shown in the user list while sending recognitions.',
                leftButtonText: 'Cancel',
                handleLeftButtonClick: onClose,
                rightButtonText: 'Save',
                selectedUser: data?.recognition.restricted || [],
            };

            setAddPermissionOpen(true);
        } else if (index === 1) {
            addPermissionModalData.current = {
                key: 'bypassApproval',
                heading: 'Can send recognitions without approvals',
                subHeading: 'Users who have this permission will be able to send recognition without any approvals',
                leftButtonText: 'Cancel',
                handleLeftButtonClick: onClose,
                rightButtonText: 'Save',
                selectedUser: data?.recognition.bypassApproval || [],
            };
            setAddPermissionOpen(true);
        }
    };

    return (
        <div className='permission-section-container'>
            <h3 className='header-reward'>Permissions</h3>
            <p className='sub-header-reward'>Provide special permission to your users</p>
            <div className='sub-section-container'>
                {permissionSection.map((blockData, index) => (
                    <OrgSettings
                        blockData={blockData}
                        handleOpenModal={handleOpenModal}
                        index={index}
                        key={blockData.heading}
                        isOrgBlock={false}
                    />
                ))}
            </div>
            {addPermissionOpen && (
                <MultiSelectionUserModal
                    open={addPermissionOpen}
                    onSave={onSave}
                    modalData={addPermissionModalData.current}
                />
            )}
        </div>
    );
};

Index.propTypes = {
    data: PropTypes.object,
    dataLoading: PropTypes.bool,
};

export default Index;
