import React from 'react';
import PropTypes from 'prop-types';
import CloseIcon from '@material-ui/icons/Close';
import Modal from '@material-ui/core/Modal';
import Table from 'components/ReusableComponents/Table';
import { ReactComponent as InfoIcon } from 'Assets/images/info-grey-filled.svg';
import { BeatLoader } from 'react-spinners';
import './style.scss';

const Index = ({
    open,
    onClose,
    header,
    data,
    noSortColumns,
    columnWidth,
    heading,
    onSave,
    loading,
    disableSave,
    width,
    history,
}) => {
    return (
        <Modal open={open} onClose={() => !loading && onClose(false)}>
            <div className='country-rewards-modal-container' style={{ width }}>
                <CloseIcon className='btn-close' onClick={() => onClose(false)} />
                <h3 className='heading'>{heading}</h3>
                <div className='country-table'>
                    <Table
                        header={header}
                        data={data}
                        noSortColumns={noSortColumns}
                        loading={false}
                        columnWidth={columnWidth}
                        noShadow
                    />
                </div>
                <div className='gift-value-bottom-bar'>
                    <div className='d-flex'>
                        <InfoIcon className='info-icon' />
                        <span>
                            To add more countries, please add the country to your workspace from the{' '}
                            <button
                                className='org-nav-button'
                                style={{ color: '#5d5cf5' }}
                                onClick={() => history.push(`/dashboard/users?type=Country`)}
                            >
                                Organization Page
                            </button>
                        </span>
                    </div>
                    <button className='ew-btn pb-btn' onClick={onSave} disabled={loading || disableSave}>
                        {loading ? <BeatLoader size={10} color={'#fff'} loading={loading} /> : 'Save'}
                    </button>
                </div>
            </div>
        </Modal>
    );
};

Index.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    header: PropTypes.array,
    data: PropTypes.array,
    noSortColumns: PropTypes.array,
    columnWidth: PropTypes.array,
    heading: PropTypes.string,
    onSave: PropTypes.func,
    loading: PropTypes.bool,
    disableSave: PropTypes.bool,
    width: PropTypes.string,
    history: PropTypes.object,
};

export default Index;
