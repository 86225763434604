import React from 'react';
import NotificationWrapper from '../../NotificationWrapper';
import PropTypes from 'prop-types';
import { ReactComponent as WalletIcon } from 'Assets/images/Notification-Payment-icon.svg';
import EWButton from 'components/ReusableComponents/EWButton';
import '../styles.scss';
import { ROUTES } from 'constants.js';

const Index = ({ history }) => {
    return (
        <div className='notification-event-container'>
            <div className=' auto-reload-disabled'>
                <div className='notification-redemption-failed-container'>
                    <div className='notification-redemption-failed-text-top-container'>
                        <WalletIcon width={23} height={23} />
                        <p className='notification-module-text'>Payments:</p>
                        <p className='notification-event-text'>Redemption failed</p>
                    </div>
                    <p className='notification-event-data-text'>
                        ⚠️ Uh-Oh! Someone tried to redeem a gift card but it failed because of{' '}
                        <span className='notification-event-reason-text'>insufficient wallet balance. </span>Please add
                        a card or add funds to your wallet.
                    </p>
                </div>
                <EWButton
                    buttonStyleClass='notification-cta-button'
                    width='180px'
                    onClick={() => {
                        history.push(ROUTES.PAYMENTS_WALLET);
                    }}
                >
                    Add Funds
                </EWButton>
            </div>
        </div>
    );
};

Index.propTypes = {
    history: PropTypes.object.isRequired,
};

export default NotificationWrapper(Index);
