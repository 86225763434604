import React, { useState } from 'react';
import PreviewModal from 'components/Modals/PreviewModal';
import PropTypes from 'prop-types';
import { BASE_URL_S3 } from 'constants.js';
import { isSlack } from 'utils/HelperFunctions';

const Index = ({ platform }) => {
    const [showPreview, setShowPreview] = useState(false);
    const imageUrl = `${BASE_URL_S3}${
        isSlack(platform) ? 'celebration-preview-slack.svg' : 'celebration-preview-mst.svg'
    }`;

    return (
        <div className='preview-card my-3 d-flex justify-content-between'>
            <h6 className='m-0'>See how your wishes would look like</h6>
            <button className='link-btn' onClick={() => setShowPreview(!showPreview)}>
                Show preview
            </button>

            {showPreview && (
                <PreviewModal
                    open={showPreview}
                    onClose={setShowPreview}
                    data={{
                        heading: 'Celebration preview',
                        img: imageUrl,
                    }}
                    width='712px'
                    height='590px'
                />
            )}
        </div>
    );
};

Index.propTypes = {
    platform: PropTypes.string,
};

export default Index;
