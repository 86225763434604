import { actions } from './actionEvents';

const initialState = {
    isFullscreen: false,
};

export default function LayoutReducer(state = initialState, action) {
    switch (action.type) {
        case actions.SET_FULLSCREEN:
            return { ...state, isFullscreen: true };

        case actions.REMOVE_FULLSCREEN:
            return { ...state, isFullscreen: false };

        default:
            return state;
    }
}
