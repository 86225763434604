import React from 'react';
import PropTypes from 'prop-types';
import Choice from '../../Dashboard/Content/PulseSurvey/CreatePulse/CreateQuestion/Choice';
import './style.scss';

const likertOptions = [
    { name: 'Strongly disagree', error: false },
    { name: 'Disagree', error: false },
    { name: 'Neutral', error: false },
    { name: 'Agree', error: false },
    { name: 'Strongly agree', error: false },
];

export const CollapsedOptions = ({ multiple, options = likertOptions }) => {
    const iconStyle = {
        borderRadius: multiple ? '4px' : '100%',
    };

    return (
        <>
            {options.map((option) => (
                <div className='mcq-options' key={option.id}>
                    <div style={iconStyle} />
                    {option.name}
                </div>
            ))}
        </>
    );
};

export const Text = ({ long }) => {
    const style = {
        width: '601px',
        ...(long && { height: '71px', paddingBottom: '40px' }),
    };
    return (
        <input
            type='text'
            className='pq-text-input'
            style={style}
            placeholder={`${long ? 'Long' : 'Short'} text`}
            disabled
        />
    );
};

export const Binary = () => {
    return (
        <div className='binary-options'>
            <div>Yes</div>
            <div>No</div>
        </div>
    );
};

export const Rating = ({ max = 10 }) => {
    const countArray = Array.from({ length: max }, (_value, index) => index + 1);

    return (
        <div className='rating-icons'>
            {countArray.map((value) => (
                <div key={value}>{value}</div>
            ))}
        </div>
    );
};

export const QuestionTypeComponent = ({
    question,
    questionIndex,
    questions,
    setQuestions,
    isEditingOngoing,
    editing,
    hideComponent = false, // this is used for newboarding pulse
}) => {
    switch (question.questionType) {
        case 'SHORT_TEXT':
            return hideComponent ? null : <Text />;
        case 'LONG_TEXT':
            return hideComponent ? null : <Text long />;
        case 'BINARY':
            return <Binary />;
        case 'RATING_5':
            return <Rating max={5} />;
        case 'RATING':
            return <Rating />;
        case 'LIKERT':
            return <CollapsedOptions />;
        case 'MCQ':
            return editing ? (
                <Choice
                    questionIndex={questionIndex}
                    questions={questions}
                    setQuestions={setQuestions}
                    isEditingOngoing={isEditingOngoing}
                />
            ) : (
                <CollapsedOptions multiple={question.multipleSelection} options={question.options} />
            );
        default:
            return null;
    }
};

CollapsedOptions.propTypes = {
    multiple: PropTypes.bool,
    options: PropTypes.array,
};

Text.propTypes = {
    long: PropTypes.bool,
};

Rating.propTypes = {
    max: PropTypes.number,
};

QuestionTypeComponent.propTypes = {
    question: PropTypes.object,
    questionIndex: PropTypes.number,
    questions: PropTypes.array,
    setQuestions: PropTypes.func,
    isEditingOngoing: PropTypes.bool,
    editing: PropTypes.bool,
    hideComponent: PropTypes.bool,
};
