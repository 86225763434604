import React, { useState } from 'react';
import ConfirmModal from 'components/ReusableComponents/ConfirmModal';
import PropTypes from 'prop-types';
import { isSlack } from 'utils/HelperFunctions';

const Index = ({ open, modalData, handleSave, onClose, dropdownData, selectedOption, platform }) => {
    const [selectedValue, setSelectedValue] = useState(selectedOption);
    const getSelectedOption = () => {
        if (selectedValue?.name) {
            return `${isSlack(platform) ? '#' : ''}${selectedValue.name}`;
        }
        return 'Select';
    };
    const handleButtonDisabled = () => {
        return !dropdownData?.length || !selectedValue;
    };

    return (
        <div>
            <ConfirmModal
                open={open}
                data={modalData}
                onClose={onClose}
                onConfirm={() => handleSave(selectedValue)}
                height={'219px'}
                dropdownData={dropdownData}
                showDropDown
                handleDropdownSelection={(_index, value) =>
                    setSelectedValue({ name: value.channelName, id: value.channelID })
                }
                singleButton
                disableButton={handleButtonDisabled()}
                selectedDropdownOption={getSelectedOption()}
            />
        </div>
    );
};

Index.propTypes = {
    open: PropTypes.bool,
    handleSave: PropTypes.func,
    onClose: PropTypes.func,
    modalData: PropTypes.array,
    selectedOption: PropTypes.object,
    dropdownData: PropTypes.array,
    platform: PropTypes.string,
};
export default Index;
