import React from 'react';
import PropTypes from 'prop-types';
import ConfirmModal from 'components/ReusableComponents/ConfirmModal';

const Index = ({ open, onClose, data, setManager, setDepartment }) => {
    const confirmProps = {
        open,
        onClose,
        data: {
            heading: `${data.count} of the selected users already have a ${data.type} assigned to them`,
            subHeading: `Are you sure you want to re-assign ${data.type}s to them?`,
            confirm: 'Yes, Assign',
            cancel: 'Go back',
        },
        onConfirm: () => {
            if (data.type === 'manager') {
                setManager(true);
            } else {
                setDepartment(true);
            }
            onClose();
        },
    };
    return (
        <div>
            <ConfirmModal {...confirmProps} />
        </div>
    );
};

Index.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    data: PropTypes.object,
    setManager: PropTypes.func,
    setDepartment: PropTypes.func,
};

export default Index;
