import React from 'react';
import { BeatLoader } from 'react-spinners';
import { css } from '@emotion/core';
import { useCommonContext } from '../../Context/CommonContext';

const CustomLoader = () => {
    const { loading } = useCommonContext();

    const override = css`
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;

        -webkit-box-align: center;
        -moz-box-align: center;
        -ms-flex-align: center;
        -webkit-align-items: center;
        align-items: center;

        -webkit-box-pack: center;
        -moz-box-pack: center;
        -ms-flex-pack: center;
        -webkit-justify-content: center;
        justify-content: center;
        height: 100vh;
        background: rgba(255, 255, 255, 0.8);
    `;
    return (
        <div>
            <BeatLoader css={override} size={20} color={'#5D5CF5'} loading={loading} />
        </div>
    );
};

export default CustomLoader;
