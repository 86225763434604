import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from 'react-query';
import { Tab, Tabs } from 'react-bootstrap';
import CustomLoader from 'components/ReusableComponents/CustomLoader';
import TitleBar from 'components/ReusableComponents/TitleBar';
import PointsTable from './PointsTable';
import { getLeaderboardData } from 'Services/apiFunctions';
import { GET_LEADERBOARD_DATA, GET_RECENT_AWARDEES } from 'Services/apiKeys';
import './style.scss';
import { shallowEqual, useSelector } from 'react-redux';
import { useToaster } from 'Context/SnackbarContext';
import RecentAwardees from 'components/Dashboard/Content/EmployeeAwards/RecentAwardees';
import { getRecentAwardees } from '../EmployeeAwards/Services/APIFunctions';
import { showSnackBarMessage } from 'utils/HelperFunctions';
import clsx from 'clsx';
import { PLATFORM } from 'constants.js';

const MyRank = ({ data, history }) => {
    if (!data) {
        return null;
    }
    return (
        <div>
            <p className='rank-tag'>YOUR RANK</p>
            <div className='rank-container'>
                <PointsTable data={Array.from([data])} showMyRank={true} history={history} />
            </div>
        </div>
    );
};

const Index = ({ history }) => {
    const DATE_RANGES = { MONTHLY: 'monthly', YEARLY: 'yearly' };
    const { nameForPoints, loggedUserID, isAdmin, platform } = useSelector(mapStateToProps, shallowEqual);
    const isWebOnly = platform === PLATFORM.WEB;
    const [groupBy, setGroupBy] = useState('received');
    const [dateRange, setDateRange] = useState(DATE_RANGES.MONTHLY);
    const [page, setPage] = useState(1);
    const [recentAwardees, setRecentAwardees] = useState();
    const { SetSnackbar } = useToaster();

    const onError = (err) => showSnackBarMessage(SetSnackbar, 'error', err.message);

    const { data, isLoading, isFetching } = useQuery(
        [GET_LEADERBOARD_DATA, page, groupBy, dateRange],
        getLeaderboardData,
        {
            keepPreviousData: true,
            refetchOnWindowFocus: false,
            onError,
        }
    );

    const { isLoading: isLoadingRecentAwards, isFetching: isFetchingrecentRewards } = useQuery(
        [GET_RECENT_AWARDEES],
        getRecentAwardees,
        {
            onSuccess: (awardeesData) => setRecentAwardees(awardeesData),
            onError,
        }
    );

    const showAwards = !isWebOnly && (isAdmin || recentAwardees?.length > 0);

    if (isLoading || !data) {
        return <CustomLoader />;
    }

    return (
        <div>
            <TitleBar title='Leaderboard' />
            <div className={clsx({ 'd-flex': showAwards, 'd-flex justify-content-center': !showAwards })}>
                <div className='leaderboard-tab-container navigatortabs'>
                    <Tabs
                        id='leaderboard-controlled-tab'
                        activeKey={groupBy}
                        onSelect={(key) => {
                            setGroupBy(key);
                            setPage(1);
                        }}
                    >
                        <Tab eventKey='received' title={`Most ${nameForPoints} received`} className='padding-top-28'>
                            {groupBy === 'received' && (
                                <div>
                                    <MyRank data={data?.me} history={history} />
                                    <PointsTable
                                        nameForPoints={nameForPoints}
                                        data={data.leaderboard}
                                        currentPage={page}
                                        setCurrentPage={setPage}
                                        totalCount={data.totalCount}
                                        isFetching={isFetching}
                                        history={history}
                                    />
                                </div>
                            )}
                        </Tab>
                        <Tab eventKey='sent' title={`Most ${nameForPoints} given`} className='padding-top-28'>
                            {groupBy === 'sent' && (
                                <div>
                                    <MyRank data={data?.me} history={history} />
                                    <PointsTable
                                        nameForPoints={nameForPoints}
                                        data={data.leaderboard}
                                        currentPage={page}
                                        setCurrentPage={setPage}
                                        totalCount={data.totalCount}
                                        isFetching={isFetching}
                                        history={history}
                                    />
                                </div>
                            )}
                        </Tab>
                    </Tabs>
                    <div className='leaderboard-monthly-toggle'>
                        <button
                            className={dateRange === DATE_RANGES.MONTHLY ? 'toggle-filled' : 'toggle-outlined'}
                            onClick={() => setDateRange(DATE_RANGES.MONTHLY)}
                        >
                            Monthly
                        </button>
                        <button
                            className={dateRange === DATE_RANGES.YEARLY ? 'toggle-filled' : 'toggle-outlined'}
                            onClick={() => setDateRange(DATE_RANGES.YEARLY)}
                        >
                            Yearly
                        </button>
                    </div>
                </div>
                {showAwards && (
                    <RecentAwardees
                        loading={isLoadingRecentAwards || isFetchingrecentRewards}
                        history={history}
                        awardees={recentAwardees}
                        userId={loggedUserID}
                    />
                )}
            </div>
        </div>
    );
};

const mapStateToProps = (state) => ({
    nameForPoints: state.Workspace.nameForPoints,
    isAdmin: state.User.isAdmin,
    loggedUserID: state.User._id,
    platform: state.Workspace.platform,
});

Index.propTypes = {
    history: PropTypes.object,
};

MyRank.propTypes = {
    history: PropTypes.object,
    data: PropTypes.object,
};

export default Index;
