import React, { useEffect, useState } from 'react';
import { Switch, Route, Redirect, useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import jwt_decode from 'jwt-decode';
import Cookies from 'js-cookie';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import Lander from './Components/Lander';
import WelcomeScreen from './Pages/WelcomeScreen';
import AccountSetup from './Pages/AccountSetup';
import WebOnlySetup from './Pages/AccountSetup/WebOnlySetup';
import ModuleSelection from './Pages/ModuleSelection';
import { getOnboardingModules } from 'Services/apiFunctions';
import RNRSetup from './Pages/RNRSetup';
import RNRSetupComplete from './Pages/RNRSetupComplete';
import RNRIntro from './Pages/RNRIntro';
import { updateUserProfile } from 'redux/user/actions';
import { updateWorkspaceDetails } from 'redux/workspace/actions';
import { computeRedirectionPath, showSnackBarMessage } from 'utils/HelperFunctions';
import { userLogout } from 'utils/SessionUtils/sessionUtils';
import { useToaster } from 'Context/SnackbarContext';
import { useCommonContext } from 'Context/CommonContext';
import { find } from 'lodash';
import { ROUTES_ONBOARDING } from './constants';

const Index = () => {
    const location = useLocation();
    const tokenData = queryString.parse(location?.search);
    const { isAdmin } = useSelector(mapStateToProps, shallowEqual);
    const history = useHistory();
    const { SetSnackbar } = useToaster();
    const { onboardingInfo, setOnboardingInfo } = useCommonContext();
    const dispatch = useDispatch();
    const [platform, setPlatform] = useState('');

    useEffect(() => {
        if (!tokenData?.token) {
            let savedTokenDecode;
            try {
                savedTokenDecode = jwt_decode(Cookies.get('AuthToken'));
            } catch (error) {
                userLogout();
                return null;
            }
            if (!savedTokenDecode) {
                userLogout();
                return null;
            }
            setPlatform(savedTokenDecode['platform']);
            updateUserProfile(dispatch, { adminId: savedTokenDecode['admin-id'] });
            updateWorkspaceDetails(dispatch, {
                team: savedTokenDecode['team-id'],
                platform: savedTokenDecode.platform,
            });
        }
    }, []);

    const getOnboardingInfo = async () => {
        try {
            const onboardingData = await getOnboardingModules();
            if (onboardingData) {
                setOnboardingInfo(onboardingData);
                const selectedModules = !!find(onboardingData, (item) => item.completed);
                if (!selectedModules) {
                    history.push(ROUTES_ONBOARDING.ONBOARDING);
                } else {
                    const path = computeRedirectionPath(onboardingData, isAdmin);
                    history.push(path);
                }
            }
        } catch (error) {
            showSnackBarMessage(SetSnackbar, 'error', error.message || 'Error fetching Onboarding Modules');
        }
    };

    useEffect(() => {
        if (!tokenData?.token && !onboardingInfo) {
            getOnboardingInfo();
        }
    }, [onboardingInfo]);

    return (
        <Switch>
            <Route exact path={ROUTES_ONBOARDING.ONBOARDING} render={() => <Lander history={history} />} />
            <Route
                exact
                path={ROUTES_ONBOARDING.WELCOME}
                render={() => <WelcomeScreen history={history} platform={platform} />}
            />
            <Route exact path={ROUTES_ONBOARDING.SETUP_WEB} render={() => <WebOnlySetup history={history} />} />
            <Route exact path={ROUTES_ONBOARDING.SETUP} render={() => <AccountSetup history={history} />} />
            <Route
                exact
                path={ROUTES_ONBOARDING.MODULE_SELECTION}
                render={() => <ModuleSelection history={history} />}
            />
            <Route exact path={ROUTES_ONBOARDING.RNR_INTRO} render={() => <RNRIntro history={history} />} />
            <Route exact path={ROUTES_ONBOARDING.RNR_START_SETUP} render={() => <RNRSetup history={history} />} />
            <Route
                exact
                path={ROUTES_ONBOARDING.RNR_SETUP_COMPLETE}
                render={() => <RNRSetupComplete history={history} />}
            />
            <Redirect to={ROUTES_ONBOARDING.ONBOARDING} />
        </Switch>
    );
};

const mapStateToProps = (state) => ({ isAdmin: state.User.isAdmin });

export default Index;
