import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as User } from 'Assets/images/default-user.svg';
import { CustomSwitch } from 'components';
import { useMutation, useQueryClient } from 'react-query';
import { useDispatch, shallowEqual, useSelector } from 'react-redux';
import ImageWithLabel from 'components/ReusableComponents/ImageWithLabel';
import GiftValue from 'components/ReusableComponents/GiftValue';
import CountryRewardsModal from 'components/Modals/CountryRewardsModal';
import { rewardsNoSortColumns, rewardsColumnWidth, pointsConversionsHeading } from '../../../constants';
import { handleEditRewards, hasValidData, findAlterData } from '../../../componentsUtil';
import { updateWorkspaceDetails } from 'redux/workspace/actions';
import { countryDetails } from 'utils/countryUtils';
import { showSnackBarMessage } from 'utils/HelperFunctions';
import { find } from 'lodash';
import { updateOrganisationWideRedemption } from 'Services/apiFunctions';
import { GET_ORG_WIDE_REDEMPTION_CONFIG } from 'Services/apiKeys';
import { useToaster } from 'Context/SnackbarContext';
import './style.scss';

const Column = ({ name }) => <span className='column-heading'>{name}</span>;

const getTableData = ({ conversionModalData, setTableData }) => {
    const tempData = conversionModalData.map(
        (
            { _id, country, countryFlag, users, currency, redemptionEnabled, value = 0, originalValue, originalStatus },
            index
        ) => {
            return {
                id: _id,
                index: index,
                row: [
                    <ImageWithLabel key={_id} imageUrl={countryFlag} text={country} width={24} height={16} />,
                    <span key={_id} className='user-count'>
                        <User />
                        {users}
                    </span>,
                    <GiftValue
                        key={_id}
                        index={index}
                        currency={currency}
                        value={value}
                        selected={redemptionEnabled}
                        keyName='value'
                        handleEditValue={(index, value, keyName) =>
                            handleEditRewards({
                                index,
                                value,
                                conversions: conversionModalData,
                                keyName,
                                getTableData,
                                setTableData,
                                originalValue,
                                originalStatus,
                            })
                        }
                    />,
                    <CustomSwitch
                        key={_id}
                        checked={redemptionEnabled}
                        onClick={() =>
                            handleEditRewards({
                                index,
                                value: !redemptionEnabled,
                                conversions: conversionModalData,
                                getTableData,
                                setTableData,
                                originalValue,
                                originalStatus,
                                keyName: 'redemptionEnabled',
                            })
                        }
                        name='redemption'
                    />,
                ],
            };
        }
    );
    setTableData(tempData);
};

const Index = ({
    open,
    onClose,
    config,
    conversionModalData,
    setOpenRewardsModal,
    history,
    nameForPoints = 'Points',
}) => {
    const queryClient = useQueryClient();
    const { SetSnackbar } = useToaster();
    const dispatch = useDispatch();
    const { userCountry } = useSelector(mapStateToProps, shallowEqual);
    const [tableData, setTableData] = useState([]);

    const header = [
        <Column key={0} name='COUNTRY' />,
        <Column key={1} name='USERS' />,
        <Column key={2} name='POINT CONVERSION' />,
        <Column key={3} name='REDEMPTION' />,
    ];

    const { mutateAsync: handleModalSave, isLoading: isSavingModalValues } = useMutation(
        updateOrganisationWideRedemption
    );

    const disableSave = !find(conversionModalData, (data) => data.modified);

    useEffect(() => {
        if (conversionModalData) {
            getTableData({ conversionModalData, setTableData });
        }
    }, [conversionModalData]);

    const handleFinalSave = async () => {
        try {
            const validData = hasValidData(conversionModalData);
            if (validData.status) {
                const apiData = findAlterData(conversionModalData);
                if (apiData) {
                    const configConversions = [...config.conversion];
                    const alterConversions = apiData?.conversions;
                    for (const altConversion of alterConversions) {
                        const index = configConversions?.findIndex(
                            (conversion) => conversion._id === altConversion.conversionId
                        );
                        if (index >= 0) {
                            configConversions[index] = { ...configConversions[index], ...altConversion };
                        }
                    }
                    updateWorkspaceDetails(dispatch, { config: { ...config, conversion: configConversions } });
                    await handleModalSave({ apiData });
                    // if user's country's redemption is enabled/disabled update the config value
                    // this is required in redeem points screen to show warning banner
                    const userCountryCode = find(countryDetails, (item) => item.country === userCountry).countryCode;
                    updateWorkspaceDetails(dispatch, {
                        config: {
                            ...config,
                            userCountryRedemption: !!find(alterConversions, (item) => item.country === userCountryCode)
                                ?.redemptionEnabled,
                        },
                    });
                    setOpenRewardsModal(false);
                    SetSnackbar({ open: true, variant: 'success', message: 'Rewards settings updated.' });
                    queryClient.invalidateQueries(GET_ORG_WIDE_REDEMPTION_CONFIG);
                }
            } else {
                SetSnackbar({ open: true, variant: 'error', message: validData.message });
            }
        } catch (error) {
            showSnackBarMessage(SetSnackbar, 'error', error?.message);
        }
    };

    return (
        <div className='points-conversion-modal-container'>
            <CountryRewardsModal
                open={open}
                onClose={onClose}
                data={tableData}
                noSortColumns={rewardsNoSortColumns}
                columnWidth={rewardsColumnWidth}
                heading={pointsConversionsHeading(nameForPoints)}
                header={header}
                loading={isSavingModalValues}
                onSave={handleFinalSave}
                width={652}
                history={history}
                disableSave={disableSave}
            />
        </div>
    );
};
const mapStateToProps = ({ User }) => ({
    userCountry: User.userCountry,
});
Index.propTypes = {
    open: PropTypes.bool,
    setOpenRewardsModal: PropTypes.func,
    config: PropTypes.object,
    onClose: PropTypes.func,
    conversionModalData: PropTypes.array,
    history: PropTypes.object,
    nameForPoints: PropTypes.string,
};

Column.propTypes = {
    name: PropTypes.string,
};

export default Index;
