export const STATUS_STATES = {
    REJECTED: 'rejected',
    APPROVED: 'approved',
    PENDING: 'pending',
    EXPIRED: 'Expired',
    COMPLETED: 'Completed',
    SENT: 'Sent',
    APPROVAL_PENDING: 'Approval Pending',
    CANCELLED: 'Cancelled',
    REJECTED_STATUS: 'Rejected',
};
