import React from 'react';
import { ReactComponent as EWLogo } from 'Assets/images/EW-logo-2.svg';
import { ReactComponent as ModuleBanner } from 'Assets/images/EW-module-banner.svg';
import './style.scss';

const Index = () => {
    return (
        <div className='welcome-banner-left'>
            <div className='module-logo' data-testid='module-logo'>
                <EWLogo />
            </div>
            <div className='module-description' data-testid='module-description'>
                <div className='banner-heading'>Welcome to EngageWith</div>
                <div className='banner-sub-heading'>
                    According to a Great Place To Work survey, employees who are appreciated by their peers are{' '}
                    <strong>2.6x</strong> more productive when compared to under-appreciated employees.
                </div>
            </div>
            <div className='banner'>
                <ModuleBanner />
            </div>
        </div>
    );
};

export default Index;
