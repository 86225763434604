import React from 'react';
import { CustomSwitch } from 'components';
import PropTypes from 'prop-types';
import EWButton from 'components/ReusableComponents/EWButton';
import { FEEDBACK } from '../../constants';
import './style.scss';

const Index = ({ index, option, toggleConfiguration, history, fontSize }) => {
    return (
        <div className='configuration-op-container'>
            <div className='configuration-heading-container'>
                <div className='configuration-new-badge'>
                    <p className='ob-heading' style={{ fontSize: fontSize || '14px' }}>
                        {option?.heading}
                    </p>
                    {option.key !== 'addCategories' ? (
                        <div className='configuration-switch-container'>
                            <CustomSwitch
                                checked={option?.isEnabled}
                                onClick={() => toggleConfiguration(index, option?.key, !option?.isEnabled)}
                                name={'feedbackCheck' + index}
                            />
                        </div>
                    ) : (
                        <EWButton
                            buttonStyleClass='ml-3'
                            buttonText={option.buttonText}
                            onClick={() => history.push(FEEDBACK.ADD_CATEGORIES)}
                            primary={false}
                            plainTextButton
                        />
                    )}
                </div>
                <p>{option?.subtext}</p>
            </div>
        </div>
    );
};

Index.propTypes = {
    index: PropTypes.number,
    option: PropTypes.object,
    toggleConfiguration: PropTypes.func,
    fontSize: PropTypes.string,
    history: PropTypes.object,
};

export default Index;
