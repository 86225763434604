import React from 'react';
import EWModal from 'components/ReusableComponents/EWModal';
import EWButton from 'components/ReusableComponents/EWButton';
import UserTag from '../../RecognitionFeed/RecognitionItem/UserTag';
import { ReactComponent as RecognitionSuccessImage } from 'Assets/images/ic-recognition-success.svg';
import PropTypes from 'prop-types';
import './style.scss';
import { PLATFORM } from 'constants.js';

const Index = ({ open, onClose, recognitionPostData, platform }) => {
    if (!open) {
        return null;
    }

    const isWebOnlyUser = platform === PLATFORM.WEB;
    const { type, receivers, messageLink, pendingApproval } = recognitionPostData;

    const receiversData = receivers.length > 2 ? receivers.slice(0, 2) : receivers;

    return (
        <EWModal open={open} onClose={onClose} width='580px' height='450px' showCloseButton={false}>
            <div className='container-recognition-success'>
                <div className='crs-image'>
                    <RecognitionSuccessImage />
                </div>
                <span className='crs-message'>
                    Wohoo! you just gave a <strong>{type}</strong> to{' '}
                </span>
                <div className='crs-users'>
                    {receiversData.map((user) => {
                        return <UserTag key={user?._id} userName={user?.userName} imageURL={user?.pictureURL} />;
                    })}
                    {receivers?.length > 2 && <span>and {receivers.length - 2} more</span>}
                </div>

                {pendingApproval && (
                    <p className='pending-Note'>
                        <span>Note:</span> Your recognition will be sent and posted on{' '}
                        {isWebOnlyUser ? 'Recognition Feed' : 'channel'} only after the approval.
                    </p>
                )}
                {!pendingApproval && !isWebOnlyUser && (
                    <>
                        <div className='crs-divider' />
                        <EWButton
                            border={true}
                            primary={false}
                            buttonText='View recognition post'
                            onClick={() => window.open(messageLink, '_blank')}
                        />
                    </>
                )}
                <EWButton
                    primary={false}
                    plainTextButton
                    buttonText='Close'
                    onClick={onClose}
                    buttonStyleClass='crs-close-button'
                />
            </div>
        </EWModal>
    );
};

Index.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    recognitionPostData: PropTypes.object,
    platform: PropTypes.string,
};

export default Index;
