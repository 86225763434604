import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as UsersIcon } from 'Assets/images/group_selected.svg';
import MemberTag from 'components/ReusableComponents/MemberTag';
import { HISTORY_FROM } from 'constants.js';
import NotificationWrapper from '../../NotificationWrapper';
import '../styles.scss';

const Index = ({ data, history }) => {
    const deactivatedUser = data?.metadata?.deactivatedUser;
    const deactivatedBy = data?.metadata?.deactivatedBy;

    return (
        <div className='notification-event-container'>
            <UsersIcon width={16} height={16} />
            <p className='notification-module-text'>Organization: </p>
            <div className='notification-user-added-event'>
                <MemberTag
                    memberID={deactivatedUser?._id}
                    memberName={deactivatedUser?.name}
                    memberPhoto={deactivatedUser?.pictureURL}
                    history={history}
                    navMenuCode={HISTORY_FROM.NOTIFICATIONS}
                    deactivated={true}
                />
                <p className='notification-user-deactivated-tag'>(De-activated)</p>
                <p>Manager de-activated by</p>
                <MemberTag
                    memberID={deactivatedBy?._id}
                    memberName={deactivatedBy?.name}
                    memberPhoto={deactivatedBy?.pictureURL}
                    history={history}
                    navMenuCode={HISTORY_FROM.NOTIFICATIONS}
                />
            </div>
        </div>
    );
};

Index.propTypes = {
    data: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
};

export default NotificationWrapper(Index);
