import React from 'react';
import PropTypes from 'prop-types';
import ConfirmModal from 'components/ReusableComponents/ConfirmModal';
import './style.scss';

const Index = ({ setDeactivateUser, profile, isDeactivating, handleConfirm }) => {
    const modalData = {
        heading: `Are you sure you want to deactivate ${profile?.Name}? `,
        subHeading: "Once deactivated, they won't be able to use EngageWith anymore.",
        confirm: 'Deactivate',
    };

    return (
        <div>
            <ConfirmModal
                open
                onClose={() => setDeactivateUser(false)}
                onConfirm={handleConfirm}
                data={modalData}
                loading={isDeactivating}
                red
            />
        </div>
    );
};

Index.propTypes = {
    setDeactivateUser: PropTypes.func,
    profile: PropTypes.object,
    isDeactivating: PropTypes.bool,
    handleConfirm: PropTypes.func,
};

export default Index;
