import React from 'react';
import CustomSwitch from 'components/Styles/CustomSwitch';
import PropTypes from 'prop-types';
import './style.scss';

const Index = ({ title, subTitle, value, onChange }) => {
    return (
        <div className='toggle-container'>
            <div className='toggle-title'>
                <h6>{title}</h6>
                <CustomSwitch checked={value} onChange={onChange} />
            </div>
            {subTitle && <p className='toggle-subtitle'>{subTitle}</p>}
        </div>
    );
};

Index.propTypes = {
    title: PropTypes.string,
    subTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    value: PropTypes.bool,
    onChange: PropTypes.func,
};

export default Index;
