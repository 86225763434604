import React, { useRef, useState, forwardRef, useImperativeHandle } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { AddWalletBalance, GetStripeSecret } from 'redux/payments/actions';
import { useToaster } from 'Context/SnackbarContext';
import { PaymentMethodStripe, STRIPE_FEES, handlePlanAction } from '../../constants';
import { DisclaimerNote } from '../../DisclaimerNote';
import { updateWorkspaceDetails } from 'redux/workspace/actions';
import { LinkAuthenticationElement, PaymentElement, Elements, useStripe, useElements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { actions } from 'redux/payments/actionEvents';
import EWButton from 'components/ReusableComponents/EWButton';
import { showSnackBarMessage } from 'utils/HelperFunctions';
import { WalletInfoBox } from '../styles';
import './style.scss';

const stripeLoad = loadStripe(process.env.REACT_APP_STRIP_CARD_SECRET, {
    // betas: ['link_beta_2'],
    betas: ['link_beta_3', 'elements_customers_beta_1'],
    apiVersion: '2020-08-27; link_beta=v1',
});

const WalletInfo = () => {
    const dispatch = useDispatch();
    const stripeElementRef = useRef(null);
    const { SetSnackbar } = useToaster();
    const { subscription, workspaceWallet } = useSelector(mapStateToProps, shallowEqual);
    const [loading, setLoading] = useState(false);
    const [fundLoading, setFundLoading] = useState(false);
    const [amount, setAmount] = useState('');
    const [stripeData, setStripeData] = useState(null);

    const isUSA = workspaceWallet?.currency === 'USD';

    const handleAddFund = async () => {
        if (!amount || amount < 0) {
            return false;
        }
        let result,
            error = null,
            data;

        if (isUSA) {
            setFundLoading(true);
            if (!stripeData) {
                const stripeSecret = await GetStripeSecret(amount * 100);
                setStripeData(stripeSecret);
                setFundLoading(false);
                return null;
            }

            try {
                const { paymentIntent: { status, amount: receivedAmount } = {}, error: elementError } =
                    await stripeElementRef.current.confirmHandle();
                if (elementError) {
                    result = false;
                    error = elementError;
                } else {
                    result = status === 'succeeded';
                    data = receivedAmount / (100 + STRIPE_FEES.LINK);
                    dispatch({ type: actions.ADD_WALLET_BALANCE, payload: data });
                    setStripeData(null);
                }
            } catch (e) {
                result = false;
                error = e;
            }
            setFundLoading(false);
        } else {
            setLoading(true);
            const payload = { amount: ~~amount };
            const apiResult = await AddWalletBalance(dispatch, payload);
            result = apiResult.result;
            error = apiResult.error || null;
            data = apiResult.data;
            setLoading(false);
        }
        if (result) {
            setAmount('');
            SetSnackbar({ open: true, variant: 'success', message: 'Fund loaded to wallet' });
            // wallet update
            updateWorkspaceDetails(dispatch, {
                workspaceWallet: { ...workspaceWallet, balance: workspaceWallet.balance + data },
            });
        } else {
            SetSnackbar({ open: true, variant: 'error', message: error?.response?.data?.message || error.message });
        }
    };

    const handleWalletAmountChange = ({ target: { value } }) => {
        setAmount(!value || isNaN(value) ? '' : _.toInteger(Math.abs(value)));
    };

    const handleOpenPortal = () => handlePlanAction((err) => showSnackBarMessage(SetSnackbar, 'error', err?.message));

    return (
        <div>
            <WalletInfoBox>
                <div className='heading'>Recharge Redemption Wallet</div>
                <div className='margin-top-14' />
                <div className='d-flex'>
                    <div className='wallet-amount-input-container'>
                        <p className='currency' style={{ background: '#dfdefd66' }}>
                            {workspaceWallet?.currency}
                        </p>
                        <input
                            type='text'
                            value={amount}
                            className='wallet-amount-input'
                            placeholder='Enter amount'
                            onChange={handleWalletAmountChange}
                        />
                    </div>
                    <EWButton
                        buttonText={isUSA && !stripeData ? 'Initiate' : 'Add Funds'}
                        onClick={() => handleAddFund()}
                        loading={loading || fundLoading}
                        disabled={
                            (!isUSA && !subscription?.isPaymentMethodAdded) ||
                            !amount ||
                            amount < 0 ||
                            fundLoading ||
                            loading
                        }
                    />
                </div>
                <PaymentMethodStripe
                    isPaymentMethodAdded={subscription?.isPaymentMethodAdded}
                    onClick={handleOpenPortal}
                />
                {isUSA && stripeData ? (
                    <div className='margin-top-30'>
                        <Elements stripe={stripeLoad} options={stripeData}>
                            <CheckoutSection ref={stripeElementRef} />
                        </Elements>
                    </div>
                ) : null}
            </WalletInfoBox>
            <DisclaimerNote currency={workspaceWallet?.currency} />
        </div>
    );
};

const CheckoutSection = forwardRef((_props, ref) => {
    const stripe = useStripe();
    const elements = useElements();

    const confirmHandle = () => {
        return stripe.confirmPayment({
            elements,
            redirect: 'if_required',
        });
    };
    useImperativeHandle(ref, () => ({ confirmHandle }));
    return (
        <div>
            <LinkAuthenticationElement />
            <PaymentElement />
        </div>
    );
});

const mapStateToProps = ({ Payments }) => ({
    subscription: Payments?.subscription,
    workspaceWallet: Payments?.workspaceWallet,
});

export default WalletInfo;
