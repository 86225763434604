import React from 'react';
import PropTypes from 'prop-types';
import { Stepper, Step, StepLabel } from '@material-ui/core';
import clsx from 'clsx';
import './style.scss';

const ScheduleStepper = ({ steps }) => {
    return (
        <div className='container-schedule-stepper'>
            <Stepper activeStep={-1} orientation='vertical'>
                {steps.map((step) => (
                    <Step key={step.step}>
                        <StepLabel className={clsx({ 'step-icon-padding': step.step === 2 })} icon={step.icon}>
                            {step?.label}
                        </StepLabel>
                    </Step>
                ))}
            </Stepper>
        </div>
    );
};

ScheduleStepper.propTypes = {
    steps: PropTypes.array,
};

export default ScheduleStepper;
