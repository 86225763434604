import React from 'react';
import PropTypes from 'prop-types';
import NotificationWrapper from '../../NotificationWrapper';
import { ReactComponent as WalletIcon } from 'Assets/images/Notification-Payment-icon.svg';
import '../styles.scss';

const Index = ({ data }) => {
    return (
        <div className='notification-event-container'>
            <WalletIcon width={23} height={23} />
            <p className='notification-module-text'>Payments:</p>
            <p className='notification-user-added-event'>
                ⚠️ Your subscription is expired, please renew to start using EngageWith
            </p>
        </div>
    );
};

Index.propTypes = {
    data: PropTypes.object.isRequired,
};

export default NotificationWrapper(Index);
