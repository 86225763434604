export const TRANSACTION_STATUS = {
    Sent: {
        value: 'sent',
        label: 'Sent',
        color: '#1EB89C',
        background: '#21A41E0F',
    },
    Cancelled: {
        value: 'cancelled',
        label: 'Cancelled',
        color: '#A3A3A3',
        background: '#80808014',
    },
    Expired: {
        value: 'expired',
        label: 'Expired',
        color: '#BC4AB1',
        background: '#E84F4814',
    },
    'Approval Pending': {
        value: 'approval_pending',
        label: 'Approval Pending',
        color: '#F4AE27',
        background: '#FFB63614',
    },
    Approved: {
        value: 'approved',
        label: 'Approved',
        color: '#1EB89C',
        background: '#21A41E0F',
    },
    Rejected: {
        value: 'rejected',
        label: 'Rejected',
        color: '#E84F48',
        background: '#E84F4814',
    },
};
