import React from 'react';
import PropTypes from 'prop-types';
import EWModal from 'components/ReusableComponents/EWModal';
import './style.scss';

const Index = ({
    open,
    onClose,
    data,
    votesHandler,
    userButtonHandler,
    isFeedbackReadableModal = false,
    isSuggestionBoxAdmin = false,
}) => {
    const modalData = {
        heading: isFeedbackReadableModal ? 'Feedback' : 'Suggestions',
    };

    const renderContent = () => {
        if (isFeedbackReadableModal) {
            return userButtonHandler(data, 'sender');
        } else if (isSuggestionBoxAdmin) {
            return userButtonHandler(data);
        } else {
            return <span>{data.anonymous ? 'Anonymously' : 'Non-Anonymously'}</span>;
        }
    };
    return (
        <EWModal open={open} onClose={onClose} width='450px' modalData={modalData}>
            <div className='ew-modal-body'>
                <div className='ew-modal-details'>
                    <strong className='ew-modal-details-heading'>
                        {isFeedbackReadableModal || isSuggestionBoxAdmin ? 'From' : 'Sent'}
                    </strong>
                    {renderContent()}
                </div>
                {isFeedbackReadableModal && (
                    <div className='ew-modal-details'>
                        <strong className='ew-modal-details-heading'>To</strong>
                        {userButtonHandler(data, 'receiver')}
                    </div>
                )}
                <div className='ew-modal-details'>
                    <strong className='ew-modal-details-heading'>Sent on</strong>
                    {data.date}
                </div>
                {isFeedbackReadableModal ? (
                    <>
                        {data.strength.length > 0 && (
                            <div>
                                <strong className='ew-modal-details-heading'>Strength</strong>
                                <div>{data.strength.join(', ')}</div>
                            </div>
                        )}
                        {data.improvement.length > 0 && (
                            <div>
                                <strong className='ew-modal-details-heading'>Needs improvement</strong>
                                <div>{data.improvement.join(', ')}</div>
                            </div>
                        )}
                    </>
                ) : (
                    votesHandler(data)
                )}

                <div>
                    <strong className='ew-modal-details-heading'>
                        {isFeedbackReadableModal ? 'Feedback message' : 'Suggestion message'}
                    </strong>
                    <div style={{ minHeight: 'fit-content', maxHeight: '150px', overflowY: 'scroll' }}>
                        {data.message}
                    </div>
                </div>
            </div>
        </EWModal>
    );
};

Index.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    data: PropTypes.object,
    votesHandler: PropTypes.func,
    userButtonHandler: PropTypes.func,
    isFeedbackReadableModal: PropTypes.bool,
    isSuggestionBoxAdmin: PropTypes.bool,
};

export default Index;
