import React, { useState } from 'react';
import ArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import InfiniteScroll from 'react-infinite-scroll-component';
import CustomLoader from 'components/ReusableComponents/CustomLoader';
import AddOnRecognitionsListItem from './AddOnRecognitionsListItem';
import { makePlural } from 'utils/HelperFunctions';
import PropTypes from 'prop-types';
import './style.scss';

const Index = ({ addOnRecognitionCount, addOnRecognitionsList, fetchNextPage, hasNextPage }) => {
    const [isExpanded, setIsExpanded] = useState(true);

    return (
        <div className='container-add-on-recognitions-list'>
            <button className='cao-recognitions-list-header' onClick={() => setIsExpanded(!isExpanded)}>
                {`${makePlural(addOnRecognitionCount, 'Add on recognition')}`}{' '}
                <ArrowDownIcon
                    style={{
                        transform: !isExpanded ? 'rotate(-180deg)' : '',
                        transition: 'transform 250ms ease',
                    }}
                />
            </button>
            <div className={`cao-recognitions-content ${isExpanded ? '' : 'collapsed'}`}>
                {addOnRecognitionsList?.pages?.[0]?.data?.data?.recognitions?.length > 0 && (
                    <InfiniteScroll
                        dataLength={addOnRecognitionsList?.pages?.length * 9}
                        next={fetchNextPage}
                        hasMore={hasNextPage}
                        height={600}
                        loader={<CustomLoader size={10} />}
                    >
                        {addOnRecognitionsList?.pages?.map((pageData) =>
                            pageData?.data?.data?.recognitions?.map((addOnRecognition) => (
                                <AddOnRecognitionsListItem
                                    key={addOnRecognition?._id}
                                    addOnRecognitionData={addOnRecognition}
                                />
                            ))
                        )}
                    </InfiniteScroll>
                )}
            </div>
        </div>
    );
};

Index.propTypes = {
    addOnRecognitionCount: PropTypes.number,
    addOnRecognitionsList: PropTypes.array.isRequired,
    fetchNextPage: PropTypes.func,
    hasNextPage: PropTypes.bool,
};

export default Index;
