import React from 'react';
import Cookies from 'js-cookie';
import {
    isFuture,
    addDays,
    addMonths,
    subDays,
    startOfQuarter,
    subQuarters,
    lastDayOfQuarter,
    subMonths,
    startOfMonth,
    lastDayOfMonth,
    startOfYear,
    subYears,
    lastDayOfYear,
    differenceInMinutes,
    addMinutes,
    isThisYear,
    format,
    isToday,
    isYesterday,
} from 'date-fns';
import { ONBOARDING_MODULES_MAP } from 'components/OnboardingV2/Pages/ModuleSelection/constants';
import { ANALYTICS_DATE_RANGE, PLATFORM, PLAN_STATUS, ROUTES } from 'constants.js';
import clsx from 'clsx';
import { find } from 'lodash';
import { REDEEM_POINTS } from 'components/Dashboard/Content/RedeemPoints/constant';
import { CELEBRATIONS } from 'components/Dashboard/Content/Celebration/constants';
import { EMPLOYEE_AWARDS } from 'components/Dashboard/Content/EmployeeAwards/constants';
import { FEEDBACK } from 'components/Dashboard/Content/Feedback/constants';

export const isEmptyObject = (obj) => {
    return Object.keys(obj).length === 0;
};

export const validateEmail = (email) => {
    const regexp = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return regexp.test(email);
};

/**
 * Time slot with interval
 *
 * @param {number} interval - The interval in minutes
 * @param {date} date - The date to start from
 * @return {array} - An array of future time slots of interval given
 *
 * validation:  [ All time slots are in future ]
 *
 * @example
 *
 *     clockTimeWithMinInterval(30, new Date())
 *
 * input: 30, new Date()
 * output:[12:00 AM, 12:30 AM ---> 11:00 PM, 11:30 PM] future time slots
 */

export const clockTimeWithMinInterval = (interval, date) => {
    const timeArray = [],
        periods = ['AM', 'PM'],
        hours = [12, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];
    const currentTime = new Date();
    currentTime.setSeconds(0, 0);
    for (const prop in periods) {
        for (const hour in hours) {
            for (let min = 0; min < 60; min += interval) {
                let hrs = hours[hour];
                let time = ('0' + hrs).slice(-2) + ':' + ('0' + min).slice(-2) + ' ' + periods[prop];
                if (periods[prop] === 'AM' && hrs === 12) hrs = 0;
                if (periods[prop] === 'PM' && hrs < 12) hrs += 12;
                date.setHours(hrs, min, 0, 0);
                isFuture(date) && differenceInMinutes(date, currentTime) > 15 && timeArray.push(time);
            }
        }
    }
    return timeArray;
};

export const getMinutes = (time) => {
    const [hours, minutes] = time.split(':');
    return parseInt(hours) * 60 + parseInt(minutes);
};

// get time from HH:MM AM/PM format to minutes
export const getTime = (time) => {
    let hour = parseInt(time.substring(0, 2));
    let min = parseInt(time.substring(3, 5));
    let totalMinutes;
    if (time?.includes('PM')) {
        hour = hour === 12 ? 0 : hour;
        totalMinutes = (hour + 12) * 60 + min;
    } else {
        hour = hour === 12 ? 0 : hour;
        totalMinutes = hour * 60 + min;
    }
    return totalMinutes;
};

// get time from minutes to HH:MM AM/PM format
export const getTimeInFormat = (minutes) => {
    let hour = Math.floor(minutes / 60);
    let min = minutes % 60;
    let ampm = hour >= 12 ? 'PM' : 'AM';
    hour = hour % 12;
    hour = hour ? pad(hour) : 12; // the hour '0' should be '12'
    min = min < 10 ? '0' + min : min;
    return hour + ':' + min + ' ' + ampm;
};

export const getTimeIndex = (timeArray, value) => {
    return timeArray.findIndex((item) => item?.minutes === value);
};

export const timeInterval = (date, addMinutes = false) => {
    const timeIntervalOptions = clockTimeWithMinInterval(15, date)?.map((slot, index) => {
        const option = {
            label: slot,
            value: index + 1,
            ...(addMinutes && { minutes: getTime(slot) }),
        };
        return option;
    });
    /* The above code is checking if the current date is the same as the date stored in the "date"
   variable. If they are the same, it adds an option to the beginning of the "timeIntervalOptions"
   array with a label of "Send instantly" and a value of 0. */
    if (date.getDate() === new Date().getDate()) {
        timeIntervalOptions.unshift({ label: 'Send instantly', value: 0 });
    }
    return timeIntervalOptions;
};

/** getDateOfDay
 *
 * @param {number} weekDay - Selected day of week
 * @returns {Object} Date  - Date of the Selected day of week
 *
 * example:
 * --- GET DATE OF CURRENT OR NEXT WEEK ---
 * getDateOfDay(3) =>  02-02-2022  // (Wed)
 * getDateOfDay(4) =>  24-02-2022  // (Thu) --> CURRENT DATE
 * getDateOfDay(5) =>  25-02-2022  // (Fri)
 */

export const getDateOfDay = (weekDay) => {
    let resultDate = new Date();
    resultDate = addDays(resultDate, (7 + weekDay - new Date().getDay()) % 7);
    return resultDate;
};

/**
 * setDate - [
 *              - Make a Date Object with custom Date and Time
 *              - Set the Date and Time of the Date Object
 *              - Return the Date Object
 *
 * ]
 * @param  {object} date
 * @param  {number} time [index of selected time slot in Array by clockTimeWithMinInterval(30)]
 * @param  {boolean} freqCheck [check frequency is DAILY , WEELKY]
 * @return {object} date [description]
 *
 *
 * validations - [ For Date and Time
 *                 - Date & Time should be in the future
 *               ]
 *
 * @example
 *  setDate(new Date(), 3, true)
 *
 * input: new Date(),index,freq
 * output: Wed Aug 25 2021 01:30:00 GMT+0530 (India Standard Time)
 */

export const setDate = (date, time) => {
    let tempdate = new Date(date);

    //Time Validation
    if (tempdate.getDate() === new Date().getDate()) {
        // Calculate the difference in hours between the current time and midnight, rounded up to the nearest hour
        const hourlyDiff = Math.ceil((new Date().getHours() * 60 + new Date().getMinutes()) / 15);
        // Set the hour of tempdate to be the current quarter of the day (each hour is divided into 4 quarters of 15 minutes each)
        tempdate.setHours(((time + hourlyDiff + 1) / 4) >> 0);
        // Set the minutes of tempdate to be the remainder of the division by 4 (which gives the quarter of the hour)
        const mins = ((time + hourlyDiff + 1) % 4) * 15;
        tempdate.setMinutes(mins);
        // If the difference between the current time and the time in tempdate is less than 15 minutes
        // then add 15 minutes to tempdate to ensure it is at least 15 minutes in the future
        if (differenceInMinutes(tempdate, new Date()) < 15) {
            tempdate = addMinutes(tempdate, 15);
        }
    } else {
        tempdate.setHours((time / 4) >> 0);
        tempdate.setMinutes((time % 4) * 15);
        if (tempdate.getDate() === new Date().getDate() && differenceInMinutes(tempdate, new Date()) < 15) {
            tempdate = addMinutes(tempdate, 15);
        }
    }

    return isFuture(tempdate) ? tempdate : addMonths(tempdate, 1);
};

export const getCountryFlagURL = (countryName) => {
    return `https://assets-springengage.s3.us-east-1.amazonaws.com/development/country-flags/${countryName}.png`;
};

export const conditionalArrayElem = (condition, data) => (condition ? data : []);

/**
 * getFutureDate- [ - Get the future date of next month of the given date]
 *
 * @param {number} date
 * @returns {object} date object
 *
 * @example
 * current Date is: 29/11/2021
 * getFutureDate(29)
 */
export const getFutureDate = (date) => {
    const tempDate = new Date();
    tempDate.setDate(date);
    !isFuture(tempDate) && tempDate.setMonth(tempDate.getMonth() + 1);
    return tempDate;
};

export function ordinalSuffix(date) {
    let suffix = 'th';

    if (date % 10 === 1 && date !== 11) {
        suffix = 'st';
    } else if (date % 10 === 2 && date !== 12) {
        suffix = 'nd';
    } else if (date % 10 === 3 && date !== 13) {
        suffix = 'rd';
    }

    return suffix;
}

export function formatNumber(value) {
    return +value === parseInt(value) ? parseInt(value) : +(+value).toFixed(2);
}

export const bytesToMb = (bytes) => bytes / 1024 / 1024;

export const capitalizeFirstLetter = (string) => `${string.charAt(0).toUpperCase()}${string.slice(1)}`;
export const getLastNDays = (numberOfDays) => ({ startDate: subDays(new Date(), numberOfDays), endDate: new Date() });

export const getLastQuarterDates = () => ({
    startDate: startOfQuarter(subQuarters(new Date(), 1)),
    endDate: lastDayOfQuarter(subQuarters(new Date(), 1)),
});

export const getLastMonthDates = () => ({
    startDate: startOfMonth(subMonths(new Date(), 1)),
    endDate: lastDayOfMonth(subMonths(new Date(), 1)),
});

export const getLastYearDates = () => ({
    startDate: startOfYear(subYears(new Date(), 1)),
    endDate: lastDayOfYear(subYears(new Date(), 1)),
});

export const getQuarterToDate = () => ({
    startDate: startOfQuarter(new Date()),
    endDate: new Date(),
});

export const getYearToDate = () => ({
    startDate: startOfYear(new Date()),
    endDate: new Date(),
});

export const getAllTime = (workspaceCreatedAt) => ({
    startDate: new Date(workspaceCreatedAt),
    endDate: new Date(),
});

export const formatDateRange = (startDate, endDate) => {
    const dateFormatShort = 'dd MMM';
    const dateFormatLong = 'dd MMM yyyy';

    const start = new Date(startDate);
    const end = new Date(endDate);

    const formattedStartDate = format(start, isThisYear(start) ? dateFormatShort : dateFormatLong);

    const formattedEndDate = format(end, isThisYear(end) ? dateFormatShort : dateFormatLong);

    return `${formattedStartDate} - ${formattedEndDate}`;
};

export const formatDate = (startDate, endDate) => {
    const dateFormatShort = 'MMM do';
    const dateFormatLong = 'MMM do yyyy';

    const start = new Date(startDate);
    const end = new Date(endDate);

    const formattedStartDate = format(start, isThisYear(start) ? dateFormatShort : dateFormatLong);

    const formattedEndDate = format(end, isThisYear(end) ? dateFormatShort : dateFormatLong);

    return `${formattedStartDate} - ${formattedEndDate}`;
};

/**
 * Determine default date range by today's date
    If today’s date < 10 / default date range to last 30 days
    If today’s date is >= 10 / default date range to MTD
 * @returns 
 */
export const getDefaultDateRange = () => {
    const today = new Date().getDate();
    const { startDate, endDate } = today < 10 ? { ...getLastNDays(30) } : { ...getLastNDays(new Date().getDate() - 1) };
    const index = today < 10 ? 1 : 5;
    return { index: index, title: ANALYTICS_DATE_RANGE[index], startDate, endDate };
};

/**
 * -100 to +50: red
 *  51 to 80: yellow
 *  81 to 100: green
 * @param {Number} enps - eNPS score ranging rom -100 to +100
 * @returns {Object}
 */
export const getEnpsClassesForAnalyticsTables = (enps) => {
    const classValues = { 'enps-container': 1 };
    if (enps <= 50) {
        classValues['detractor'] = 1;
    } else if (enps >= 51 && enps <= 80) {
        classValues['passive'] = 1;
    } else {
        classValues['promoter'] = 1;
    }
    return classValues;
};

/**
 *
 * @param {Number} total
 * @param {String} type - Ex: User, Manager, Department, Journey...
 * @returns {String}
 */
export const getTableHeadingTitle = (total, type = 'User') => {
    if (total > 1) {
        return `${total} ${type}s`;
    } else if (!total) {
        return `${type}s`;
    }
    return `${total} ${type}`;
};

/**
 * Adds plural to the word if number is greater than 1
 *
 * @param {Number} number 2
 * @param {String} word question
 * @returns 2 questions
 */
export const makePlural = (number, word) => {
    return number === 1 ? `${number} ${word}` : `${number} ${word}s`;
};

/**
 * When BE sends data with one valid eNPS score, eNPS column is set and we are simply display the values, then we should show the styled score or '-'
 * If eNPS column is already set then we should show '-' if no score is there
 * Else, hide data
 */
export const getENPSColumnData = (condition, eNPS) => {
    if (condition) {
        return typeof eNPS === 'number'
            ? [
                  <div key='enps' className={clsx(getEnpsClassesForAnalyticsTables(eNPS))}>
                      {formatNumber(eNPS)}
                  </div>,
              ]
            : ['-'];
    }
    return [];
};

/**
 * The "value" is added for search functionality in the dropdown while "countryCode" is added for saving userCountry in BE
 * @param {Object} item - Country object containing name, code, flag etc
 * @param {Array} refCountries - Array populated from the country-detail api
 * @returns {Object}
 */
export const countryMapper = (item, refCountries = []) => {
    const matchedCountry = refCountries.find((country) => country.country === item.name);
    return {
        ...item,
        value: item?.country,
        countryCode: matchedCountry?.countryCode,
        label: (
            <>
                <img src={item?.countryFlag || matchedCountry?.countryFlag} className='country-image' alt='' />
                &nbsp;{item?.name}
            </>
        ),
    };
};

/**
 * @example Input - [{ module: "RNR", completed: true }, { module: "ORG_HEALTH", completed: false }]; Output - '/dashboard/redeem/list'
 * @param {Array<Objects>} modules
 * @param {Boolean} isAdmin
 * @returns {String}
 */
export const computeRedirectionPath = (modules = [], isAdmin = true) => {
    const {
        RnR,
        CELEBRATIONS: CELEBRATION_MODULE,
        ORG_HEALTH,
        FEEDBACK: FEEDBACK_MODULE,
        EMPLOYEE_AWARDS: EMPLOYEE_AWARDS_MODULE,
    } = ONBOARDING_MODULES_MAP;
    const defaultRnRPath = REDEEM_POINTS.LIST;
    const defaultUserNonRnRPath = ROUTES.LOGIN;
    const areAllPathsCovered = !modules.some((module) => !module.completed);

    // Not using index as order may vary.
    let rnrModule = null;
    let celebrationsModule = null;
    let orgHealthModule = null;
    let feedbackModule = null;
    let employeeAwardsModule = null;
    modules.forEach((module) => {
        if (module.module === RnR) {
            rnrModule = module;
        } else if (module.module === CELEBRATION_MODULE) {
            celebrationsModule = module;
        } else if (module.module === ORG_HEALTH) {
            orgHealthModule = module;
        } else if (module.module === FEEDBACK_MODULE) {
            feedbackModule = module;
        } else if (module.module === EMPLOYEE_AWARDS_MODULE) {
            employeeAwardsModule = module;
        }
    });

    /* Best-case scenario where all paths are completed and is now being used by all users */
    if (areAllPathsCovered && (rnrModule || celebrationsModule)) {
        return defaultRnRPath;
    }
    const selectedModules = !!find(modules, (item) => item.completed);
    if (isAdmin) {
        /**Computations when all paths are not completed
         * 1. If RnR is completed, redirect user to Redeem points regardless of next module completion.
         * 2. If RnR is not completed, redirect user to RnR onboarding flow.
         * 3. If RnR is not opted -> priority given to Org Health, Celebrations, feedback, and then employee award.
         * 4. If none of the conditions are met, redirect admin to organization page.
         */

        if (rnrModule) {
            if (rnrModule.completed) {
                return defaultRnRPath;
            } else if (!selectedModules) {
                return '/onboarding/RnR/intro';
            }
        }

        if (feedbackModule && !feedbackModule.completed && modules.length === 1) {
            return FEEDBACK.SETUP_FEEDBACK;
        }

        if (orgHealthModule) {
            // Take admin to eNPS create page if org health is not completed, else get next path.
            if (orgHealthModule.completed) {
                return computeRedirectionPath(
                    modules.filter((item) => item.module !== ORG_HEALTH),
                    true
                );
            } else if (!selectedModules) {
                return '/dashboard/eNPS/create?isOnboarding=true';
            }
        }

        if (celebrationsModule) {
            // Redirect users to redeem page if celebrations module is present
            if (modules.length > 1 && celebrationsModule.completed) {
                // Pushing empty object to avoid redirecting to feedback splash screen if feedback is not completed.
                modules.push({});
                return computeRedirectionPath(
                    modules.filter((item) => item.module !== CELEBRATION_MODULE),
                    true
                );
            } else if (!celebrationsModule.completed) {
                return CELEBRATIONS.CELEBRATIONS;
            }
            return defaultRnRPath;
        }

        if (employeeAwardsModule) {
            // Redirect to the employee award page
            return EMPLOYEE_AWARDS.HOME;
        }
        // Default re-direction would then be to organization page for admins
        return ROUTES.USERS;
    } else {
        if (rnrModule?.completed || celebrationsModule) {
            return defaultRnRPath;
        }
        return defaultUserNonRnRPath;
    }
};

export const showSnackBarMessage = (SetSnackbar, variant, message) => {
    SetSnackbar({
        open: true,
        variant,
        message: message || 'Some error occurred',
    });
};

export const textShortener = (text, length = 20) => {
    return text?.length > length ? `${text.slice(0, length - 3)}...` : text;
};

/**
 * @param {String} digit
 * @returns {String} - 00, 01, 02, ... 10, 11, ...
 */
export const pad = (digit) => {
    return String('0' + digit).slice(-2);
};

/**
 * Util function to handle an input field to ensure the value only contains
 * digits and at most one decimal point. Additionally, the value should not start with a decimal point.
 * @param {string} valueInput
 *
 * @example
 * valueInput = "22.55."
 * // returns "22.55"
 *
 * @example
 * valueInput = "abc22.55.xyz"
 * // returns "2255"
 *
 * @example
 * valueInput = ".22.55."
 * // returns "22.55"
 *
 * @example
 *  valueInput = "..255"
 * // returns "255"
 */
export const returnPureNumberOrDecimal = (valueInput) => valueInput.replace(/[-+]?\d+(\.\d+)?/g, '');

export const checkForValidNumber = (value) => {
    const regex = /^\d+\.?\d?$/;
    return regex.test(value);
};

export const getOrdinal = (instance) => {
    if (instance % 10 === 1 && instance % 100 !== 11) {
        return instance + 'st';
    } else if (instance % 10 === 2 && instance % 100 !== 12) {
        return instance + 'nd';
    } else if (instance % 10 === 3 && instance % 100 !== 13) {
        return instance + 'rd';
    }
    return instance + 'th';
};
export const addOptionAll = (optionsData, propertyNameForLabel = 'name') => {
    const allOption = { [propertyNameForLabel]: 'ALL', label: 'ALL' };
    const options =
        optionsData?.map((option) => ({
            ...option,
            label: option?.[propertyNameForLabel],
        })) || [];
    return [allOption, ...options];
};
/**
 * This function adds an item if it does not exits and removes if exits from the array
 * @param {*} data array
 * @param {*} item item to be added/removed
 */
export const addOrRemoveElement = (data, item) => {
    // isIdPresent is to handle data as it contains _id sometimes and id sometimes
    const isIdPresent = item.id !== undefined;
    if (!data.find((dataItem) => (isIdPresent ? dataItem.id === item.id : dataItem._id === item._id))) {
        data = [...data, item];
    } else {
        data = data.filter((dataItem) => (isIdPresent ? dataItem.id !== item.id : dataItem._id !== item._id));
    }
    return data;
};

export const isSlack = (platform) => {
    return platform?.toLowerCase() === PLATFORM.SLACK;
};

export const getChannelNameByPlatform = (channelName, platform) => {
    return isSlack(platform) ? `#${channelName}` : channelName;
};

export const getFormattedDate = (date) => {
    if (isToday(date)) {
        return format(date, "'Today, ' h:mm a");
    } else if (isYesterday(date)) {
        return format(date, "'Yesterday, ' h:mm a");
    }
    return format(date, 'MMMM d yyyy, h:mm a');
};

export const getChannelOptionsByPlatform = (channelData, platform) => {
    return channelData.map((channel) => {
        let label;
        if (isSlack(platform)) {
            label = channel.isPrivate ? `🔒${channel.channelName}` : `#${channel.channelName}`;
        } else {
            label = `${channel.groupName} \u2192 ${channel.channelName}`;
        }

        return {
            ...channel,
            channelId: channel.channelId ?? channel.channelID,
            label,
            value: channel.channelID,
        };
    });
};

export const getIntegratedChannelName = (channel, platform, isDefaultChannel = false) => {
    let groupName = channel?.groupName;
    let channelName = isDefaultChannel ? channel.name : channel.channelName;
    if (channelName) {
        if (isSlack(platform)) {
            return channel.isPrivate ? `🔒${channelName}` : '#' + channelName;
        } else {
            return `${groupName} \u2192 ${channelName}`;
        }
    }
    return 'Select';
};

export const sortUsersByDisability = (users, isOptionDisabledHandler, isRestrictedOptionsAllowed) => {
    const sortedUsers = users.reduce(
        (acc, user) => {
            const isDisabled = isRestrictedOptionsAllowed && isOptionDisabledHandler(user);
            if (isDisabled) {
                acc.disabledUsers.push(user);
            } else {
                acc.enabledUsers.push(user);
            }
            return acc;
        },
        { enabledUsers: [], disabledUsers: [] }
    );

    return [...sortedUsers.enabledUsers, ...sortedUsers.disabledUsers];
};
export const isSubscriptionCancelled = (subscription) => {
    /* it is checking if the `subscription` object
    exists and if its `status` property is equal to `PLAN_STATUS.CANCELLED`. If the
    condition is true, the code will return and exit the current function or block of code. */
    return subscription?.status === PLAN_STATUS.CANCELLED;
};

export const handleUserLogin = (history) => {
    const userAuthToken = Cookies.get('AuthToken');
    const admin = JSON.parse(localStorage.getItem('user') || null);
    if (userAuthToken && admin?.['team']) {
        history.push(ROUTES.DASHBOARD);
    }
};
