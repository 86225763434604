import React from 'react';
import PropTypes from 'prop-types';
import { CustomTag } from 'utils/Stylesheet/style';
import './style.scss';

const Index = ({ userName, pictureURL, department, tag }) => {
    return (
        <div className='container-user-details'>
            <img src={pictureURL || require('Assets/images/defaultUser.png')} alt='manager' />
            <div className='cv-user-details'>
                <div className='cv-user-name'>
                    {userName}
                    {tag && (
                        <CustomTag
                            color='#535961'
                            background='#F1F3F6'
                            fontWeight='600'
                            marginLeft='4px'
                            radius='90px'
                            padding='0.125rem 0.5rem'
                        >
                            {tag}
                        </CustomTag>
                    )}
                </div>
                <p className='cv-department'>{department}</p>
            </div>
        </div>
    );
};

Index.propTypes = {
    userName: PropTypes.string,
    pictureURL: PropTypes.string,
    department: PropTypes.string,
    tag: PropTypes.string,
};

export default Index;
