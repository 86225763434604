import React, { useEffect } from 'react';
import './style.scss';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { VoiletTag } from 'utils/Stylesheet/style';
import { ReactComponent as EyeInvisible } from 'Assets/images/eye-invisible.svg';
import { ReactComponent as Clock } from 'Assets/images/clock.svg';
import { ReactComponent as QuestionBook } from 'Assets/images/question-book.svg';
import { ReactComponent as SeparatorDot } from 'Assets/images/separator-dot.svg';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { toggleFullscreen } from 'redux/layout/actions';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { getENPSQuestionTitle } from 'components/Dashboard/Content/ENPS/constant';

const Index = ({ history }) => {
    const { todo, canLaunchNewEnps } = useSelector(mapStateToProps, shallowEqual);
    const createCTAText = 'Next';
    const dispatch = useDispatch();
    const { companyName } = useSelector(mapStateToProps, shallowEqual);
    const eNPSQuestionsList = [
        {
            title: getENPSQuestionTitle(companyName),
        },
        {
            title: 'What is the main reason for your score?',
        },
    ];

    useEffect(() => {
        toggleFullscreen(dispatch, true);
    }, []);

    if (todo.isENPSFlowCompleted && !canLaunchNewEnps) {
        return <Redirect to='/dashboard/eNPS' />;
    }

    return (
        <div className='enps-template-list'>
            <div className='container_left'>
                <div className='left-header'>
                    <div className='template-count'>
                        <button className='a-default back-btn' onClick={() => history.push('/dashboard/eNPS')}>
                            <strong>
                                <ArrowBackIosIcon /> Back
                            </strong>
                        </button>
                        <p className='d-inline-block mb-0'>eNPS</p>
                    </div>
                    <div className='template-search-dropdown enps-info'>
                        Employer Net Promoter Score, or eNPS, is a scoring system designed to help employers measure
                        employee satisfaction and loyalty within their organizations. It is based on the Net Promoter
                        Score system.
                    </div>
                </div>
            </div>

            <div className='container_right'>
                <div className='container-header'>
                    <div className='container-header-block'>
                        <div className='container-title'>eNPS</div>
                        <div className='container-header-info'>
                            <EyeInvisible /> Anonymous
                            <SeparatorDot />
                            <QuestionBook /> 2 questions
                            <SeparatorDot />
                            <Clock />1 min
                        </div>
                    </div>
                    <button
                        className='ew-btn ob-btn'
                        onClick={() => {
                            history.push(`/dashboard/eNPS/create/template`);
                        }}
                    >
                        {createCTAText}
                    </button>
                </div>
                <div className='separator'></div>
                <div className='question-list'>
                    {eNPSQuestionsList?.map((question, index) => (
                        <div className='question-card' key={question.title}>
                            <div className='question-card-index'>Q{index + 1}</div>
                            <div className='question-card-content'>
                                {question?.category && <VoiletTag>{question.category}</VoiletTag>}
                                <div>{question.title}</div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => ({
    companyName: state.Workspace.companyName,
    todo: state.Workspace.todo,
    canLaunchNewEnps: state.Workspace.canLaunchNewEnps,
});

Index.propTypes = {
    history: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

export default Index;
