import React from 'react';
import PropTypes from 'prop-types';
import { GreenPointsTag, KudosTag, ShoutoutTag } from 'utils/Stylesheet/style';
import UserButton from 'components/ReusableComponents/UserButton';
import './style.scss';

const Index = ({ recognition, isSelfRecognition }) => {
    return (
        <div className='pending-container flex'>
            <div className='pending-approval-container'>
                <p className='pending-approval'>Pending Approval</p>
                <p className='reward'>
                    {recognition.rewardType === 'shoutout' ? (
                        <ShoutoutTag> Shoutout </ShoutoutTag>
                    ) : (
                        <KudosTag> Kudos </KudosTag>
                    )}
                    <GreenPointsTag>{recognition?.points} Points</GreenPointsTag>
                </p>
            </div>
            {isSelfRecognition && (
                <div className='approver'>
                    <span>Approver :</span>
                    <UserButton
                        id={recognition?.approver?._id}
                        showImage={true}
                        imageURL={recognition.approver?.pictureURL || require('Assets/images/defaultUser.png')}
                        senderName={recognition?.approver?.userName}
                        clickableUser={true}
                        customClassname='approver-name'
                    />
                </div>
            )}
        </div>
    );
};

Index.propTypes = {
    recognition: PropTypes.object,
    isSelfRecognition: PropTypes.bool,
};

export default Index;
