import React from 'react';
import './style.scss';
import CloseIcon from '@material-ui/icons/Close';
import PropTypes from 'prop-types';

const Index = ({ tags, onRemove, searchText, platform }) => {
    const channelName = (option) => `${platform === 'MSTeams' ? '' : '#'}${option.label}`;
    return (
        <div>
            {tags && tags.length > 0 && (
                <div className='add-tag-view'>
                    {tags.map(
                        (tag, index) =>
                            tag.label
                                .toLowerCase()
                                .includes(searchText !== undefined ? searchText.toLowerCase() : '') && (
                                <div className='item-tag-container' key={tag.value}>
                                    <p className={'item-tag'}>{channelName(tag)}</p>
                                    {!tag?.alreadyPresent && (
                                        <CloseIcon
                                            className='close-icon'
                                            onClick={() => onRemove(tag, index)}
                                            data-testid='close-icon'
                                        />
                                    )}
                                </div>
                            )
                    )}
                </div>
            )}
        </div>
    );
};

Index.propTypes = {
    tags: PropTypes.array.isRequired,
    onRemove: PropTypes.func.isRequired,
    searchText: PropTypes.string,
    platform: PropTypes.string,
};

export default Index;
