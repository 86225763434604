import React, { useEffect, useRef, useState } from 'react';
import { CustomSwitch } from 'components';
import CustomFilterDropdown from 'components/ReusableComponents/CustomFilterDropdown';
import { reminderFrequency } from 'constants.js';
import clsx from 'clsx';
import './style.scss';
import { filter, find } from 'lodash';
import PropTypes from 'prop-types';

const REMINDER_DESCRIPTION = "Send reminder to users if they haven't responded before\n the pulse ends.";

const Reminders = ({ scheduling, setScheduling, isRollingPulse, responseDays, numberOfQuestions, disabled }) => {
    const [scheduleReminder, setScheduleReminder] = useState(scheduling.remindersEnabled);
    const [reminderOptions, setReminderOptions] = useState([]);
    const selectedOptionsCount = useRef(0);

    const handleReminderFrequency = (index) => {
        const temp = [...reminderOptions];
        temp[index].isChecked = !temp[index].isChecked;
        selectedOptionsCount.current = filter(temp, (item) => item.isChecked).length || 0;
        setReminderOptions(temp);
        setReminderValue(reminderOptions);
    };

    const setReminderValue = (reminderData) => {
        setScheduling({
            ...scheduling,
            reminderValue: filter(reminderData, (item) => item.isChecked),
        });
    };

    const isValidReminder = ({ value, unit, allowResponseDays, daysLeft }) => {
        /*  Eg : 8 questions, 5 days
                Each day 2 questions will be sent.
                By the end of 3rd day all the questions will be sent.
                So we will have reminders for [3hrs, 6hrs, 12hrs, 1day]
        */
        if (isRollingPulse) {
            if (unit === 'hours') {
                return daysLeft >= 0;
            } else if (unit === 'days') {
                return value <= daysLeft;
            }
            return false;
        }
        if (unit === 'hours') {
            return allowResponseDays >= 1;
        } else if (unit === 'days') {
            return value < allowResponseDays;
        }
        return false;
    };

    const handleReminders = () => {
        let temp = [];
        let count = 0;
        const questionsPerDay = Math.ceil(numberOfQuestions / scheduling.responseForDays);
        const daysLeft = scheduling.responseForDays - Math.ceil(numberOfQuestions / questionsPerDay);
        reminderFrequency.forEach((item) => {
            const reminderExists = find(
                scheduling.reminderValue,
                (reminder) => reminder.value === item.value && reminder.unit === item.unit
            );
            if (
                isValidReminder({
                    value: item.value,
                    unit: item.unit,
                    allowResponseDays: scheduling.responseForDays,
                    daysLeft: daysLeft,
                })
            ) {
                temp.push({ ...item, isChecked: !!reminderExists });
                count = count + Number(!!reminderExists);
            }
        });
        selectedOptionsCount.current = count;
        setReminderOptions(temp);
        setReminderValue(temp);
    };

    useEffect(() => {
        handleReminders();
    }, [responseDays]);

    return (
        <div>
            <div className='d-flex'>
                <h5 className={clsx({ heading: true })}>Schedule Reminder</h5>
                {
                    <div className='d-flex justify-content-start align-items-center rolling-toggle-container'>
                        <CustomSwitch
                            checked={scheduleReminder}
                            onChange={() => {
                                setScheduleReminder(!scheduleReminder);
                                setScheduling({
                                    ...scheduling,
                                    remindersEnabled: !scheduleReminder,
                                });
                            }}
                            disabled={disabled}
                        />
                        {scheduleReminder ? 'Enabled' : 'Disabled'}
                    </div>
                }
            </div>
            {
                <div>
                    <p className='rp-desc'>{REMINDER_DESCRIPTION}</p>
                    {scheduleReminder && (
                        <div>
                            <p>
                                <strong>How long before the pulse ends should the reminder be sent?</strong>
                            </p>
                            <CustomFilterDropdown
                                dropdownStyleClass='margin-top-8'
                                filterOptions={reminderOptions}
                                selectedName={
                                    selectedOptionsCount.current > 0
                                        ? `Selected (${selectedOptionsCount.current})`
                                        : 'Select'
                                }
                                optionsSelected={selectedOptionsCount.current > 0}
                                handleSelection={(index) => handleReminderFrequency(index)}
                                dropDownID='scheduleReminder'
                                buttonStyleClass='width-240 mr-5'
                                dropdownWidth={240}
                                dropdownHeight={142}
                                disabled={disabled}
                                error={scheduleReminder && selectedOptionsCount.current === 0}
                            />
                            {scheduleReminder && selectedOptionsCount.current === 0 && (
                                <p className='mt-2 reminder-error'> Set reminders before proceeding </p>
                            )}
                        </div>
                    )}
                </div>
            }
        </div>
    );
};

export default Reminders;

Reminders.propTypes = {
    scheduling: PropTypes.shape({
        onDate: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]).isRequired,
        onTime: PropTypes.shape({
            label: PropTypes.string,
            value: PropTypes.number,
        }),
        frequencyType: PropTypes.array,
        onDays: PropTypes.string,
        rollingPulse: PropTypes.bool,
        responseForDays: PropTypes.number,
        remindersEnabled: PropTypes.bool,
        reminderValue: PropTypes.array,
    }),
    setScheduling: PropTypes.func.isRequired,
    isRollingPulse: PropTypes.bool.isRequired,
    responseDays: PropTypes.number.isRequired,
    numberOfQuestions: PropTypes.number.isRequired,
    disabled: PropTypes.bool,
};
