import React from 'react';
import PropTypes from 'prop-types';
import { useMutation, useQueryClient } from 'react-query';
import { useToaster } from 'Context/SnackbarContext';
import ConfirmModal from 'components/ReusableComponents/ConfirmModal';
import { removeChannel } from 'Services/apiFunctions';
import { GET_INTEGRATED_CHANNELS } from 'Services/apiKeys';
import { shallowEqual, useSelector } from 'react-redux';
import { showSnackBarMessage } from 'utils/HelperFunctions';

const Index = ({ channel, onClose, setPrivateChannelOpen }) => {
    const { SetSnackbar } = useToaster();
    const queryClient = useQueryClient();
    const { platform } = useSelector(mapStateToProps, shallowEqual);

    const { mutateAsync, isLoading } = useMutation(removeChannel);

    const handleRemoveChannel = async () => {
        try {
            await mutateAsync({ id: channel.value });
            queryClient.invalidateQueries(GET_INTEGRATED_CHANNELS);
            showSnackBarMessage(SetSnackbar, 'success', 'Channel removed.');
        } catch (err) {
            if (err.message === 'Unable to remove from private channel') {
                setPrivateChannelOpen({ open: true, channel });
            } else {
                showSnackBarMessage(SetSnackbar, 'error', err?.message);
            }
        } finally {
            onClose();
        }
    };

    const text = {
        heading: 'Remove EngageWith',
        subHeading: `<span> Would you like to remove EngageWith from ${
            platform === 'MSTeams' ? ` ${channel.label} team` : ` #${channel.label} channel`
        }? <br /> Users will not be notified about recognitions in this channel if you continue.</span>`,
    };
    return (
        <ConfirmModal
            open
            onClose={onClose}
            data={text}
            onConfirm={handleRemoveChannel}
            loading={isLoading}
            red
            height={'230px'}
        />
    );
};

const mapStateToProps = ({ Workspace }) => ({
    platform: Workspace.platform,
});

Index.propTypes = {
    channel: PropTypes.object,
    onClose: PropTypes.func,
    setPrivateChannelOpen: PropTypes.func,
};

export default Index;
