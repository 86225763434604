import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, shallowEqual } from 'react-redux';
import CustomLoader from 'components/ReusableComponents/CustomLoader';
import { useCommonContext } from 'Context/CommonContext';
import { computeRedirectionPath } from 'utils/HelperFunctions';
import { ONBOARDING_MODULES_MAP } from 'components/OnboardingV2/Pages/ModuleSelection/constants';
import { find } from 'lodash';

const Index = ({ history }) => {
    const { onboarding, onboardingInfo, setOnboarding } = useCommonContext();
    const { isAdmin } = useSelector(mapStateToProps, shallowEqual);

    useEffect(() => {
        const selectedModules = !!find(onboardingInfo, (item) => item.completed);
        if (!selectedModules) {
            history.push('/onboarding/welcome/');
        } else if (onboardingInfo.hasOwnProperty('onboardingModules') && !onboardingInfo.onboardingModules.length) {
            const path = computeRedirectionPath([{ module: ONBOARDING_MODULES_MAP.RnR, completed: true }], isAdmin);
            history.push(path);
            if (onboarding) {
                setOnboarding(false);
            }
        } else {
            const path = computeRedirectionPath(onboardingInfo, isAdmin);
            history.push(path);
        }
    }, [isAdmin]);

    return (
        <div>
            <CustomLoader />
        </div>
    );
};

const mapStateToProps = (state) => ({ isAdmin: state.User.isAdmin });

Index.propTypes = {
    history: PropTypes.object,
};

export default Index;
