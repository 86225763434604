import { find } from 'lodash';
import { FEEDBACK_CATEGORY_LENGTH_LIMIT } from './constants.js';
import { GET_FEEDBACK_CATEGORIES_LIST } from 'Services/apiKeys';
import { showSnackBarMessage } from 'utils/HelperFunctions';

export const getCharsLeft = (limit, value = 0) => Math.max(limit - value, 0);

export const handleCategoryChange = ({
    targetCategory,
    setCategory,
    categoriesText,
    setCategoriesText,
    categories,
    currentCategory,
    setError,
}) => {
    if (targetCategory.trim().length <= FEEDBACK_CATEGORY_LENGTH_LIMIT) {
        // If there are special characters or new line entered replace them
        const value = targetCategory.replace(/[\r\n*#@/\\]/g, '');
        setCategory(value);
        setCategoriesText({
            ...categoriesText,
            charsLeft: getCharsLeft(FEEDBACK_CATEGORY_LENGTH_LIMIT, value.length),
        });
        const showError =
            categories.includes(value.toLowerCase()) && currentCategory.toLowerCase() !== value.toLowerCase();
        setError(!!showError);
    }
};

export const handleAddEditCategoryClick = async ({
    addModal,
    category,
    categories,
    onClose,
    addCategory,
    updateCategory,
    queryClient,
    currentCategory,
    SetSnackbar,
}) => {
    if (categories.includes(category.toLowerCase()) && currentCategory.toLowerCase() !== category.toLowerCase()) {
        showSnackBarMessage(SetSnackbar, 'error', 'Category already present!');
    } else if (addModal.category) {
        try {
            const updatedCategory = {
                update: category.trim(),
            };
            await updateCategory({ prevCategory: addModal.category, apiData: updatedCategory });
            queryClient.setQueryData([GET_FEEDBACK_CATEGORIES_LIST], (oldData) => {
                const tempData = { ...oldData };
                tempData.categories[addModal.index] = updatedCategory.update;
                return tempData;
            });
            showSnackBarMessage(SetSnackbar, 'success', 'Categories saved successfully!');
            onClose();
        } catch (err) {
            showSnackBarMessage(SetSnackbar, 'error', err?.message);
        }
    } else {
        try {
            await addCategory({ apiData: { categories: [category.trim()] } });
            queryClient.setQueryData([GET_FEEDBACK_CATEGORIES_LIST], (oldData) => {
                const tempData = { ...oldData };
                tempData.categories.unshift(category.trim());
                return tempData;
            });
            onClose();
        } catch (err) {
            showSnackBarMessage(SetSnackbar, 'error', err?.message);
        }
    }
};
export const handleCategoryEdit = ({ open, index, value, setAddModal }) => {
    setAddModal({
        open: open,
        category: value,
        index,
    });
};
export const handleValueDelete = ({ open, index, setDeleteModal }) => {
    setDeleteModal({ open: open, index });
};

export const handleSameTeamFeedback = (data, feedbackConfigs, setData, SetSnackbar) => {
    if (feedbackConfigs?.feedbackLevel === 'TEAM') {
        // the organization/users api sends details of the reportees of the same manager but not the manager itself,
        //so we retrieve it from the manager field of the user and add to the array
        const tempData = { ...data };
        try {
            const managerData = tempData?.pages[0]?.data?.users[0]?.manager;
            const tempPageData = tempData?.pages?.[tempData?.pages?.length - 1];
            // check if manager data is already present in the users list, this happens when same person is manager
            // eg. A is manager of A
            const isManagerAlreadyPresent = find(tempData?.pages, (page) =>
                find(page?.data?.users, (user) => user?._id === managerData?._id)
            );
            if (tempPageData?.data?.users?.length < 10 && !isManagerAlreadyPresent) {
                tempPageData.data.users.push(managerData);
            }
        } catch (error) {
            showSnackBarMessage(SetSnackbar, 'error', error?.message);
        } finally {
            setData(tempData);
        }
    } else {
        setData(data);
    }
};
