import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useToaster } from 'Context/SnackbarContext';
import { Link } from 'react-router-dom';
import { TOP_UP_RECOGNITION_POINTS } from '../constants';
import ErrorField from 'components/ReusableComponents/ErrorField';
import { modifyUser } from 'Services/apiFunctions';
import { useMutation } from 'react-query';
import { showSnackBarMessage } from 'utils/HelperFunctions';
import EWModal from 'components/ReusableComponents/EWModal';
import './style.scss';

const Index = ({ open, points, setPoints, setOpen, selectedUserIds, onSuccess, currentId }) => {
    const { SetSnackbar } = useToaster();
    const [error, setError] = useState(false);

    const { mutateAsync: topUpHandler } = useMutation(modifyUser);

    const ERROR_TEXT = 'Please enter a numeric value greater than 0 to continue';

    const handlePointsUpdate = (value) => {
        const regName = /^\d\d*$/;
        if (value === '' || regName.test(value)) {
            if (parseInt(value) > 99999) {
                showSnackBarMessage(SetSnackbar, 'warning', `Maximum limit for Point value is 99999`);
            } else {
                setPoints(value);
                if (value < 1 && value !== '') {
                    setError(true);
                } else {
                    setError(false);
                }
            }
        }
    };
    const handleSubmit = async () => {
        let userIds = [...selectedUserIds];
        if (selectedUserIds.length === 0) {
            userIds.push(currentId);
        }
        const apiData = {
            userIds: userIds,
            adhocPoints: points,
        };
        try {
            await topUpHandler({ apiData });
            showSnackBarMessage(SetSnackbar, 'success', 'Recognition request Approved');
            onSuccess();
        } catch (error) {
            showSnackBarMessage(SetSnackbar, 'error', error?.message);
        }
    };
    const modalData = {
        heading: 'Top-up recognition points',
        rightButtonText: 'Add',
        handleRightButtonClick: handleSubmit,
        disabled: points === '' || error,
    };

    return (
        <EWModal open={open} width='528px' onClose={() => setOpen(false)} modalData={modalData}>
            <div className='top-up-modal-container'>
                <div className='top-up-recognition-list'>
                    <ul>
                        {TOP_UP_RECOGNITION_POINTS.map((item) => (
                            <li key={item} className='list' dangerouslySetInnerHTML={{ __html: item }}></li>
                        ))}
                        <li className='list'>
                            At the start of the next month, the points balance will revert to the configurations in{' '}
                            <Link to={'/dashboard/recognitions/configuration'}>Recognitions</Link>
                        </li>
                    </ul>
                </div>
                <div className='top-up-balance-wrapper'>
                    <p>Enter the amount to top up recognition balance by</p>
                </div>
                <div className='input-container'>
                    <input
                        className='input-value'
                        placeholder={'Enter amount'}
                        type='text'
                        value={points}
                        onChange={(e) => {
                            handlePointsUpdate(e.target.value);
                        }}
                    />
                    {error && <ErrorField errorText={ERROR_TEXT} />}
                </div>
            </div>
        </EWModal>
    );
};

Index.propTypes = {
    open: PropTypes.bool,
    setOpen: PropTypes.func,
    selectedUserIds: PropTypes.array,
    onSuccess: PropTypes.func,
    currentId: PropTypes.string,
    points: PropTypes.string,
    setPoints: PropTypes.func,
};

export default Index;
