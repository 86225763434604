import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import RewardList from './RewardList';
import RedeemGiftCard from './RedeemGiftCard';
import RedeemHistory from './RedeemHistory';
import { REDEEM_POINTS } from './constant';

const Index = () => {
    return (
        <div>
            <Switch>
                <Route path={REDEEM_POINTS.LIST} render={(props) => <RewardList {...props} />} />
                <Route path={REDEEM_POINTS.HISTORY} component={RedeemHistory} />
                <Route path={REDEEM_POINTS.CONFIRM_PURCHASE} component={RedeemGiftCard} />
                <Redirect to={REDEEM_POINTS.LIST} />
            </Switch>
        </div>
    );
};

export default Index;
