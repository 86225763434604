import React from 'react';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import './style.scss';

const Index = ({
    children,
    handleClose,
    title,
    selectedName,
    buttonWidth = 180,
    dropdownHeight = 200,
    dropdownWidth = 200,
    buttonStyleClass,
    blockSelection,
    open,
    setOpen,
    optionsSelected = true,
}) => {
    const onClose = () => {
        setOpen(false);
        if (handleClose) {
            handleClose();
        }
    };

    return (
        <ClickAwayListener onClickAway={onClose}>
            <div className='custom-dropdown-container'>
                <p className='filter-title'>{title}</p>
                <button
                    className={clsx({
                        'dropdown-select-button': true,
                        'dropdown-button-open': open,
                        'dropdown-options-not-selected': !optionsSelected,
                        [buttonStyleClass]: buttonStyleClass,
                    })}
                    style={{ width: buttonWidth }}
                    onClick={() => setOpen(!open)}
                    disabled={blockSelection}
                >
                    <span style={{ width: buttonWidth - 40 }}>{selectedName}</span>
                    {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </button>
                <div
                    className={clsx({ 'dropdown-container': true, 'display-none': !open })}
                    style={{ height: dropdownHeight, width: dropdownWidth, top: title ? '55px' : '45px' }}
                >
                    {children}
                </div>
            </div>
        </ClickAwayListener>
    );
};

Index.propTypes = {
    open: PropTypes.bool,
    setOpen: PropTypes.func,
    children: PropTypes.any,
    handleClose: PropTypes.func,
    title: PropTypes.string,
    selectedName: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    buttonWidth: PropTypes.number,
    dropdownHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    dropdownWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    buttonStyleClass: PropTypes.string,
    blockSelection: PropTypes.bool,
    optionsSelected: PropTypes.bool,
};

export default Index;
