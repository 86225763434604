import React from 'react';
import { ReactComponent as AllowanceImg } from 'Assets/images/allowanceImg.svg';

const IMAGE_URLS = {
    recognitionImg: 'https://assets-springengage.s3.amazonaws.com/production/icons/recognitionImg.svg',
    channelImgSlack:
        'https://assets-springengage.s3.amazonaws.com/production/icons/channel-recognition-preview-slack.svg',
    channelImgTeams:
        'https://assets-springengage.s3.amazonaws.com/production/icons/channel-recognition-preview-teams.svg',
    rewardImg: 'https://assets-springengage.s3.amazonaws.com/production/icons/voucher-cards.svg',
};

export const SIDEPANEL = () => [
    {
        imageSrc: <img src={IMAGE_URLS.recognitionImg} alt='Recognition' />,
        info: [
            {
                question: 'What are Points?',
                description: 'Points can be awarded when you recognize someone.',
            },
            {
                question: 'What are Kudos and Shoutout?',
                description:
                    'These are two recognition types. Kudos are typically used to appreciate a teammate for a job well done. Shoutouts are given when a teammate goes above and beyond or has accomplished something exceptional.',
            },
            {
                question: 'What should be the ratio of the value given to both?',
                description: (
                    <span>
                        1:10 ratio for Kudos:Shoutout is a good practice. Example: If Kudos is set to{' '}
                        <strong>10</strong>, the Shoutout can be set to <strong>100.</strong>
                    </span>
                ),
            },
        ],
    },
    {
        imageSrc: <AllowanceImg />,
        info: [
            {
                question: 'I want to give different monthly points to each employee. How can I do that?',
                description:
                    'You can customize Point allocation for each employee from the dashboard. For now you may enter a general number which will be applicable to all users.',
            },
            {
                question: 'What happens if an employee exhausts their Points?',
                description:
                    'The Points get refreshed every month, but you can give a boost allowance to employees manually from the dashboard.',
            },
        ],
    },
    {
        imageSrc: <img src={IMAGE_URLS.rewardImg} alt='Vouchers card' />,
        imagePosition: 'text-right',
        info: [
            {
                question: 'What are rewards?',
                description:
                    'Every point received through recognitions can have a monetary value. Employees can choose to redeem this points from a global catalog with 1000+ premium brands and 20+ categories.',
            },
            {
                question: 'What if I dont want to add monetary value and just have recognitions?',
                description: `That's perfectly fine too! You can enable rewards anytime later from settings.`,
            },
            {
                question: 'Why is country needed?',
                description:
                    'Country will determine your default currency and the conversion ratio too. You can add multiple countries for global employees.',
            },
        ],
    },
];

export const STEPS = [
    {
        title: 'Set up points',
        subtitle: 'Enter Points for Kudos and Shoutout',
        next: 'forward',
    },
    {
        title: 'Monthly Points allowance',
        subtitle: 'This is the number of points all employees will get at the beginning of the month.',
        next: 'forward',
    },
    {
        title: 'Enable Rewards',
        subtitle: 'Enable your employees to redeem their points for gifts and experiences',
        next: 'finish setup',
    },
];
