import React from 'react';
import PropTypes from 'prop-types';

const Index = ({ active, setActive }) => {
    return (
        <div className='d-flex justify-content-end activities-toggle'>
            <button
                className={active === 'ALLACTIVITIES' ? 'toggle-filled' : 'toggle-outlined'}
                onClick={() => setActive('ALLACTIVITIES')}
            >
                All Activities
            </button>
            <button
                className={active === 'MYACTIVITIES' ? 'toggle-filled' : 'toggle-outlined'}
                onClick={() => setActive('MYACTIVITIES')}
            >
                My Activities
            </button>
        </div>
    );
};
Index.propTypes = {
    active: PropTypes.string,
    setActive: PropTypes.func,
};

export default Index;
