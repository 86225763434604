import { actions } from './actionEvents';
import { getData } from 'Services/apiCall';
import { showSnackBarMessage } from 'utils/HelperFunctions';

export const getWorkspaceDetails = async (dispatch, SetSnackbar) => {
    try {
        const { data } = await getData('workspace/profile');
        dispatch({ type: actions.GET_WORKSPACE_DETAILS, payload: { ...data } });
    } catch (err) {
        dispatch({ type: actions.GET_WORKSPACE_DETAILS, payload: { nameForPoints: 'Points' } });
        if (err?.response?.data?.message === 'Configs Not set for this workspace') {
            showSnackBarMessage(SetSnackbar, 'error', 'Please complete onboarding before accessing the dashboard');
        } else {
            showSnackBarMessage(SetSnackbar, 'error', err?.response?.data?.message);
        }
    }
};

export const updateWorkspaceDetails = (dispatch, payload) => {
    dispatch({ type: actions.UPDATE_WORKSPACE_DETAILS, payload });
};
