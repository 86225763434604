import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Tooltip } from 'components';
import CustomFilterDropdown from 'components/ReusableComponents/CustomFilterDropdown';
import { ReactComponent as InfoIcon } from 'Assets/images/info-grey-filled.svg';
import Timetip from '../Timetip';
import PropTypes from 'prop-types';

const useStyles = makeStyles({
    text: {
        fontFamily: 'Open Sans',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '0.75rem',
        lineHeight: '1.5em',
        color: '#82878d !important',
        marginBottom: 8,
        textTransform: 'uppercase',
    },
    tooltip: {
        cursor: 'pointer',
        margin: '-2px 8px 0',
        height: '14px',
        width: '14px',
        color: '#A9ADB1',
    },
});

const Index = ({
    dropDownID,
    name,
    classname,
    timeOptions,
    handleChange,
    titleClassName,
    label = 'TIME',
    disabled,
}) => {
    const classes = useStyles();

    return (
        <div>
            {!!label && (
                <div className={titleClassName || classes.text}>
                    {label}
                    <Tooltip title={<Timetip />} placement='top'>
                        <InfoIcon className={classes.tooltip} />
                    </Tooltip>
                </div>
            )}
            <CustomFilterDropdown
                dropDownID={dropDownID}
                selectedName={name}
                buttonStyleClass={classname}
                dropdownWidth={240}
                dropdownHeight={142}
                filterOptions={timeOptions}
                optionsSelected
                handleSelection={handleChange}
                singleSelect
                clock
                disabled={disabled}
            />
        </div>
    );
};

Index.propTypes = {
    dropDownID: PropTypes.string,
    name: PropTypes.string,
    classname: PropTypes.string,
    timeOptions: PropTypes.array,
    handleChange: PropTypes.func,
    titleClassName: PropTypes.string,
    disabled: PropTypes.bool,
    label: PropTypes.string,
};

export default Index;
