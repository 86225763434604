import React from 'react';
import './style.scss';
import PropTypes from 'prop-types';
import CloseIcon from '@material-ui/icons/Close';
import { ReactComponent as RetryIcon } from 'Assets/images/retry.svg';

const Index = ({ onClose, errorData, setCurrentModal }) => {
    const HANDLE_ACTIONS = {
        BACK: 'BACK',
        RETRY_UPLOAD: 'RETRY_UPLOAD',
    };

    const handleAction = (type) => {
        if (type === HANDLE_ACTIONS.BACK) {
            setCurrentModal(2);
        } else if (type === HANDLE_ACTIONS.RETRY_UPLOAD) {
            setCurrentModal(1);
        }
        return true;
    };

    return (
        <div className='csv-errors-container'>
            <div className='csv-errors-header'>
                <p>Error Messages</p>
                <CloseIcon className='close-icon' onClick={onClose} />
            </div>
            <div className='csv-errors-back'>
                <button onClick={() => handleAction(HANDLE_ACTIONS.BACK)}>{'< back'}</button>
            </div>
            <div className='csv-errors-body'>
                {errorData.map((message) => {
                    return (
                        <p className='csv-error-messages' data-testid={'error'} key={message?.key}>
                            <span>{message?.key} - </span> {message?.value}
                        </p>
                    );
                })}
            </div>
            <div className='csv-errors-footer'>
                <button onClick={() => handleAction(HANDLE_ACTIONS.RETRY_UPLOAD)}>
                    <RetryIcon />
                    <span> Retry Upload </span>
                </button>
            </div>
        </div>
    );
};

Index.propTypes = {
    onClose: PropTypes.func,
    errorData: PropTypes.array.isRequired,
    setCurrentModal: PropTypes.func,
};

export default Index;
