import React from 'react';
import PropTypes from 'prop-types';
import SummaryInfo from './SummaryInfo';
import PlanInfo from './PlanInfo';

const Summary = ({ changeSection }) => {
    return (
        <>
            <PlanInfo />
            <SummaryInfo changeSection={changeSection} />
        </>
    );
};

Summary.propTypes = {
    changeSection: PropTypes.func,
};

export default Summary;
