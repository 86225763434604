export const TRANSACTION_STATES = [
    { label: 'Completed', value: 'approved' },
    { label: 'Pending', value: 'pending' },
    { label: 'Rejected', value: 'rejected' },
];

export const MYACTIVITIES_TRANSACT_STATES_ADMIN = [
    { label: 'Received', value: 'received' },
    { label: 'Given', value: 'given' },
    { label: 'Requested', value: 'requested' },
];

export const MYACTIVITIES_TRANSACT_STATES_NON_ADMIN = [
    { label: 'Received', value: 'received' },
    { label: 'Given', value: 'given' },
    { label: 'Rejected', value: 'rejected' },
];
