import React from 'react';
import PropTypes from 'prop-types';
import radioButtonSelected from 'Assets/images/radioButtonSelected.svg';
import radioButtonNotSelected from 'Assets/images/radioButtonNotSelected.svg';
import './style.scss';
import clsx from 'clsx';

const RadioComponent = ({ heading, subHeading, selectedOption, setSelectedOption, value }) => {
    return (
        <div>
            <div>
                <label className='radio-label mt-4' htmlFor={`radio-${value}`}>
                    <input
                        type='radio'
                        name='options'
                        value={value}
                        checked={selectedOption === value}
                        onChange={() => setSelectedOption(value)}
                        id={`radio-${value}`}
                    />
                    <img src={selectedOption === value ? radioButtonSelected : radioButtonNotSelected} alt='' />
                </label>

                <span className={clsx('large-text', { 'no-subHeading': subHeading })}>{heading}</span>
                <p className='small-text'>{subHeading}</p>
            </div>
        </div>
    );
};

RadioComponent.propTypes = {
    heading: PropTypes.string.isRequired,
    subHeading: PropTypes.string.isRequired,
    selectedOption: PropTypes.string.isRequired,
    setSelectedOption: PropTypes.func.isRequired,
    value: PropTypes.string.isRequired,
};

export default RadioComponent;
