import React from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { useToaster } from 'Context/SnackbarContext';
import { BeatLoader } from 'react-spinners';
import NotificationItem from './NotificationItem';
import { useGetNotificationsQuery, useToggleNotificationMutation } from '../ApiHooks';
import { showSnackBarMessage } from 'utils/HelperFunctions';
import './style.scss';

const Index = () => {
    const { SetSnackbar } = useToaster();
    const { platform, nameForPoints } = useSelector(mapStateToProps, shallowEqual);

    const showError = (err) => showSnackBarMessage(SetSnackbar, 'error', err?.response?.data?.message);

    const { data: notifications, isLoading } = useGetNotificationsQuery({
        platform,
        nameForPoints,
        showError,
    });

    const toggleNotification = useToggleNotificationMutation({
        showError,
        notifications,
        SetSnackbar,
    });

    const getCustomClassname = (index) => {
        if (index === 0) {
            return 'config-notification-border';
        } else if (index === 1) {
            return 'config-top-border';
        } else if (index === notifications.length - 1) {
            return 'config-bottom-border';
        }
        return '';
    };

    if (isLoading) {
        return (
            <div className='text-center'>
                <BeatLoader size={15} color={'#5D5CF5'} />
            </div>
        );
    }

    return (
        <div className='config-notification-container'>
            {notifications?.map((option, index) => (
                <NotificationItem
                    option={option}
                    index={index}
                    toggleNotification={toggleNotification}
                    key={option?.heading}
                    customClassName={getCustomClassname(index)}
                    showDivider={index > 0 && index < notifications?.length - 1}
                />
            ))}
        </div>
    );
};

const mapStateToProps = (state) => state.Workspace;

export default Index;
