import React from 'react';
import Answer from './Answer';
import PercentageBarChart from 'components/ReusableComponents/PercentageBarChart';
import { getBarFillColor } from './componentUtils';
import PropTypes from 'prop-types';

const ChoiceResult = ({ element }) => {
    return (
        <div className='question-borders'>
            <div className='d-flex flex-column mt-3'>
                {element?.responses?.map((answer) => (
                    <React.Fragment key={answer.ans}>
                        <div className='d-flex flex-column ' style={{ width: '516px' }}>
                            <Answer element={element} answerText={answer?.ans} />
                            <div className='d-flex align-items-center mb-3'>
                                <PercentageBarChart
                                    horizontal
                                    bgcolorActive={getBarFillColor(element?.question?.type)}
                                    bgcolorTotal='#F5F5F5'
                                    text={answer.frequency + ' responses'}
                                    percentage={answer.percentage}
                                    xAxisLabel={answer.ans}
                                    left
                                />
                                <span className='ml-3'>{answer.percentage}%</span>
                            </div>
                        </div>
                    </React.Fragment>
                ))}
            </div>
        </div>
    );
};

ChoiceResult.propTypes = {
    element: PropTypes.object,
};

export default ChoiceResult;
