import { useMutation, useQueryClient } from 'react-query';
import { modifyUser, updateDepartment } from 'Services/apiFunctions';
import { GET_PAGINATED_USERS } from 'Services/apiKeys';

export const useModifyUser = ({ onSuccess, onError }) => {
    const queryClient = useQueryClient();
    return useMutation(modifyUser, {
        onError: async (err, _newUserData, context) => {
            onError(err);
            if (context?.previousData) {
                await queryClient.setQueryData(GET_PAGINATED_USERS, context.previousData);
            }
        },
        onSuccess: async () => {
            await queryClient.invalidateQueries(GET_PAGINATED_USERS);
            onSuccess();
        },
    });
};

export const useModifyOrganisation = ({ onSuccess, onError }) => {
    const queryClient = useQueryClient();
    return useMutation(updateDepartment, {
        onError: async (err, _newUserData, context) => {
            onError(err?.message);
            if (context?.previousData) {
                await queryClient.setQueryData(GET_PAGINATED_USERS, context.previousData);
            }
        },
        onSuccess: async () => {
            await queryClient.invalidateQueries(GET_PAGINATED_USERS);
            onSuccess();
        },
    });
};
