import React, { useState } from 'react';
import DefaultUser from 'Assets/images/defaultUser.png';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import './style.scss';
import PropTypes from 'prop-types';
import { handlePageRedirectionWithinSubMenu } from 'utils/SessionUtils/sessionUtils';
import EWButton from 'components/ReusableComponents/EWButton';

const Index = ({ assignedMembers, history, navigateTo, moreUsers, customHeight, fromLocation }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        if (open) {
            setAnchorEl(null);
        } else {
            setAnchorEl(event.currentTarget);
        }
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleMemberClick = (_id) => {
        handleClose();
        if (_id) {
            if (navigateTo) {
                handlePageRedirectionWithinSubMenu({
                    history,
                    redirectionURL: `/dashboard/profile/${_id}`,
                    navMenuCode: navigateTo,
                })();
            } else {
                history.push(`/dashboard/profile/${_id}`, { ...(fromLocation && { from: fromLocation }) });
            }
        }
    };

    return (
        <span className='avatar-group-popup-container'>
            {assignedMembers?.length > 0 && (
                <ClickAwayListener onClickAway={handleClose}>
                    <div>
                        <EWButton
                            plainTextButton={true}
                            primary={false}
                            onClick={handleClick}
                            {...(customHeight && { height: customHeight })}
                        >
                            {!moreUsers ? (
                                <span className='team-tag-container'>
                                    <img src={require('Assets/images/group.svg')} alt='group' />
                                    {assignedMembers.length}
                                </span>
                            ) : (
                                <span className='more-users-button'>{assignedMembers.length} more</span>
                            )}
                            <span>
                                {anchorEl && (
                                    <div className='position-absolute members-list'>
                                        {assignedMembers?.map((member, index) => (
                                            <button
                                                className='menu-item'
                                                key={member._id || index}
                                                onClick={() => handleMemberClick(member._id)}
                                            >
                                                <img
                                                    src={
                                                        member?.imageURL ||
                                                        member?.imageUrl ||
                                                        member?.pictureURL ||
                                                        DefaultUser
                                                    }
                                                    className='avatar-24 mr-2'
                                                    alt='user-avatar'
                                                />
                                                <p className='username'>{member.name || member.userName}</p>
                                            </button>
                                        ))}
                                    </div>
                                )}
                            </span>
                        </EWButton>
                    </div>
                </ClickAwayListener>
            )}
        </span>
    );
};

Index.propTypes = {
    assignedMembers: PropTypes.array,
    history: PropTypes.object,
    navigateTo: PropTypes.string,
    moreUsers: PropTypes.bool,
    customHeight: PropTypes.string,
    fromLocation: PropTypes.string,
};

export default Index;
