import React from 'react';
import PropTypes from 'prop-types';
import ConfirmModal from 'components/ReusableComponents/ConfirmModal';

const Index = ({
    open,
    modalData,
    onConfirm,
    onClose,
    loading,
    height,
    showRadioButtons,
    instances,
    selectedOption,
    setSelectedOption,
    isRollingPulse,
    questionsToBeRolled,
    totalQuestions,
    isEditing,
    disableButton,
}) => {
    if (!open) {
        return null;
    }
    return (
        <ConfirmModal
            open
            onClose={onClose}
            onConfirm={onConfirm}
            data={modalData}
            loading={loading}
            red={!isEditing}
            height={height}
            showRadioButtons={showRadioButtons}
            instances={instances}
            selectedOption={selectedOption}
            setSelectedOption={setSelectedOption}
            isRollingPulse={isRollingPulse}
            questionsToBeRolled={questionsToBeRolled}
            totalQuestions={totalQuestions}
            disableButton={disableButton}
        />
    );
};

Index.propTypes = {
    open: PropTypes.bool,
    onConfirm: PropTypes.func,
    modalData: PropTypes.object,
    onClose: PropTypes.func,
    loading: PropTypes.bool,
    height: PropTypes.string,
    showRadioButtons: PropTypes.bool,
    instances: PropTypes.array,
    selectedOption: PropTypes.string,
    setSelectedOption: PropTypes.func,
    isRollingPulse: PropTypes.bool,
    questionsToBeRolled: PropTypes.number,
    totalQuestions: PropTypes.number,
    isEditing: PropTypes.bool,
    disableButton: PropTypes.bool,
};

export default Index;
