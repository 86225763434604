import React, { useEffect, useState } from 'react';
import CustomLoader from 'components/ReusableComponents/CustomLoader';
import RemoveValue from 'components/Modals/RemoveValues';
import ValueItem from './ValueItem';
import AddValueModal from './AddValueModal';
import Table from 'components/ReusableComponents/Table';
import { useToaster } from 'Context/SnackbarContext';
import { handleAddValue, handleValueDelete, handleValueEdit } from '../componentsUtil';
import { useGetCoreValuesQuery } from '../ApiHooks';
import { GET_CONFIG_WORKSPACE } from 'Services/apiKeys';
import { useQuery, useMutation } from 'react-query';
import { getWorkspaceConfig, updateIsValuesRequired } from 'Services/apiFunctions';
import { whatAreValues, valuesNote } from '../constants';
import CustomSwitch from 'components/Styles/CustomSwitch';
import { showSnackBarMessage } from 'utils/HelperFunctions';
import './style.scss';

const Index = () => {
    const { SetSnackbar } = useToaster();
    const [companyValue, setCompanyValue] = useState({});
    const [addModal, setAddModal] = useState({ open: false, value: '', index: -1 });
    const [deleteModal, setDeleteModal] = useState({ open: false, index: -1 });
    const [isValuesRequired, setIsValuesRequired] = useState(false);
    const [isValuesRequiredDisabled, setIsValuesRequiredDisabled] = useState(false);
    const { data, isLoading } = useGetCoreValuesQuery({ SetSnackbar });
    const { values = [] } = data || {};

    useQuery([GET_CONFIG_WORKSPACE], getWorkspaceConfig, {
        onSuccess: (data) => {
            setCompanyValue(data.companyValue);
            setIsValuesRequired(data.companyValue.isRequired);
        },
    });

    const { mutateAsync: updateIsValuesRequiredMutation, isLoading: isLoadingMutation } =
        useMutation(updateIsValuesRequired);

    const handleToggleSwitch = async (checked) => {
        try {
            setIsValuesRequired(checked);
            const { data } = await updateIsValuesRequiredMutation({ apiData: { isCompanyValueRequired: checked } });
            showSnackBarMessage(SetSnackbar, 'success', data.message);
        } catch (err) {
            showSnackBarMessage(SetSnackbar, 'error', err?.message);
            setIsValuesRequired(!checked);
        }
    };

    const updateIsValuesRequiredState = async (isRequired, isDisabled) => {
        setIsValuesRequired(isRequired);
        try {
            await updateIsValuesRequiredMutation({ apiData: { isCompanyValueRequired: isRequired } });
        } catch (err) {
            showSnackBarMessage(SetSnackbar, 'error', err?.message);
        }
        setIsValuesRequiredDisabled(isDisabled);
    };

    useEffect(() => {
        if (values.length < 1) {
            setIsValuesRequired(false);
            setIsValuesRequiredDisabled(true);
        } else {
            setIsValuesRequired(companyValue.isRequired);
            setIsValuesRequiredDisabled(false);
        }
    }, [values, companyValue]);

    if (isLoading) {
        return <CustomLoader />;
    }

    const valuesTableData = values.map((value, index) => ({
        id: value.id,
        row: [
            <ValueItem
                key={value.id}
                index={index}
                value={value}
                handleEdit={(open, index, value) => handleValueEdit({ open, index, value, setAddModal })}
                handleDelete={(open, index) => handleValueDelete({ open, index, setDeleteModal })}
            />,
        ],
    }));

    return (
        <div className='values-config-container'>
            <div className='add-values-container'>
                <div className='header'>
                    <div className=' company-values-header'>
                        <div className=' company-values-header-left-pane'>
                            <h3 className='header-4 header-reward'>Values ({values.length})</h3>
                            <p className='body-3 sub-header-reward'>{whatAreValues}</p>
                        </div>
                        <button
                            className='ew-btn pb-btn'
                            onClick={() => handleAddValue({ values, setAddModal, SetSnackbar })}
                        >
                            Add Values
                        </button>
                    </div>
                    <div className=' company-value-mandatory-container'>
                        <div className=' company-value-mandatory-switch-container'>
                            <p className=' company-value-mandatory-heading'>
                                Make company value mandatory when sending recognition
                            </p>
                            <CustomSwitch
                                checked={isValuesRequired}
                                onClick={() => handleToggleSwitch(!isValuesRequired)}
                                disabled={isLoadingMutation || isValuesRequiredDisabled}
                            />
                        </div>
                        {values.length < 1 && isValuesRequiredDisabled ? (
                            <p className=' body-3 sub-header-reward disabled-text'>
                                Disabled because there are no company values added.
                            </p>
                        ) : (
                            <p className=' body-3 sub-header-reward'>
                                It will get disabled if you have no company values added
                            </p>
                        )}
                    </div>
                    {!companyValue?.isEdited && (
                        <div className='body-3 value-notes-container'>
                            <p>
                                <strong>Note</strong>: {valuesNote}
                            </p>
                        </div>
                    )}
                </div>
            </div>
            {values.length > 0 && (
                <div className='values-list-container'>
                    <Table
                        data={valuesTableData}
                        noShadow={true}
                        loading={isLoading}
                        noSortColumns={[0]}
                        columnWidth={['100%']}
                    />
                </div>
            )}
            {addModal.open && (
                <AddValueModal
                    addModal={addModal}
                    setAddModal={setAddModal}
                    values={values}
                    updateIsValuesRequiredState={updateIsValuesRequiredState}
                />
            )}
            {deleteModal.open && (
                <RemoveValue
                    values={values}
                    deleteIndex={deleteModal.index}
                    setDeleteModal={setDeleteModal}
                    updateIsValuesRequiredState={updateIsValuesRequiredState}
                />
            )}
        </div>
    );
};

export default Index;
