export const ROUTES_ONBOARDING = {
    ONBOARDING: '/onboarding/',
    WELCOME: '/onboarding/welcome/',
    SETUP: '/onboarding/setup',
    SETUP_WEB: '/onboarding/setup-web',
    MODULE_SELECTION: '/onboarding/module',
    RNR_INTRO: '/onboarding/RnR/intro',
    RNR_START_SETUP: '/onboarding/start',
    RNR_SETUP_COMPLETE: '/onboarding/RnR/complete',
};
