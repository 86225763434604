import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Modal from '@material-ui/core/Modal';
import CloseIcon from '@material-ui/icons/Close';
import { useToaster } from 'Context/SnackbarContext';
import { useAddEditValuesMutation } from '../../ApiHooks';
import { handleCoreValueChange } from '../../componentsUtil';
import EWButton from 'components/ReusableComponents/EWButton';
import { VALUES_LENGTH_LIMIT } from 'constants.js';
import './style.scss';

const Index = ({ addModal, setAddModal, values, updateIsValuesRequiredState }) => {
    const { SetSnackbar } = useToaster();
    const [value, setValue] = useState(addModal.value);
    const [valuesText, setValuesText] = useState({
        isFocused: false,
        charsLeft: addModal?.value ? VALUES_LENGTH_LIMIT - addModal.value.length : VALUES_LENGTH_LIMIT,
    });

    const onClose = () => setAddModal({ open: false, value: '', index: -1 });

    const onError = (err) =>
        SetSnackbar({
            open: true,
            variant: 'error',
            message: err?.response?.data?.message || 'Some error occurred!',
        });

    const { handleClick, isAddingValue, isUpdatingValue } = useAddEditValuesMutation({
        addModal,
        value,
        values,
        onClose,
        onError,
        SetSnackbar,
    });

    const handleChange = (event) =>
        handleCoreValueChange({
            targetValue: event.target.value,
            setValue,
            SetSnackbar,
            valuesText,
            setValuesText,
        });

    const handleEvents = (focus) => setValuesText({ ...valuesText, isFocused: focus });

    const content = (
        <div className='add-value-container' data-testid='add-value-modal'>
            <div className='add-value-header'>
                <p>{addModal.value ? 'Edit Value' : 'Add new value'}</p>
                <CloseIcon onClick={onClose} />
            </div>
            <div className='add-value-body'>
                <p className='add-value-text'>
                    Enter your Organization's Core Values in EngageWith. Make company <br /> culture a part of your
                    appreciation.
                </p>
                <textarea
                    value={value}
                    onChange={handleChange}
                    onFocus={() => handleEvents(true)}
                    onBlur={() => handleEvents(false)}
                />
            </div>
            {valuesText.isFocused && <p className='char-count'>Characters left: {valuesText.charsLeft}</p>}
            <div className='add-value-footer'>
                <EWButton
                    buttonText='Cancel'
                    buttonStyleClass='btn-cancel'
                    primary={false}
                    plainTextButton
                    onClick={onClose}
                    disabled={isAddingValue || isUpdatingValue}
                />
                <EWButton
                    buttonText='Save'
                    onClick={() => {
                        handleClick();
                        if (values.length < 1) {
                            updateIsValuesRequiredState(true, false);
                        }
                    }}
                    disabled={!value.trim() || value === addModal.value || isAddingValue || isUpdatingValue}
                    loading={isAddingValue || isUpdatingValue}
                />
            </div>
        </div>
    );

    return (
        <div>
            <Modal open={addModal.open} onClose={onClose}>
                {content}
            </Modal>
        </div>
    );
};

Index.propTypes = {
    addModal: PropTypes.object,
    setAddModal: PropTypes.func,
    values: PropTypes.array,
    updateIsValuesRequiredState: PropTypes.func.isRequired,
};

export default Index;
