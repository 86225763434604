import React from 'react';
import PropTypes from 'prop-types';

const Index = ({ condition, wrapper, children }) => (condition ? wrapper(children) : <>{children}</>);

Index.propTypes = {
    condition: PropTypes.bool.isRequired,
    wrapper: PropTypes.func,
    children: PropTypes.any,
};

export default Index;

/**** Example Usage
<ConditionalWrapper condition={true} wrapper={children => <div>{children}</div>} >
<h1>Some text</h1>
<p>Lorem Ipsum</p>
</ConditionalWrapper>
****/
