import React, { useEffect, useState } from 'react';
import { useInfiniteQuery, useMutation, useQueryClient } from 'react-query';
import _, { debounce, cloneDeep } from 'lodash';
import InfiniteScroll from 'react-infinite-scroll-component';
import CustomLoader from 'components/ReusableComponents/CustomLoader';
import Loader from 'components/ReusableComponents/Loader';
import CustomFilterDropdown from 'components/ReusableComponents/CustomFilterDropdown';
import CustomSearchBox from 'components/Styles/CustomSearchBox';
import { CustomCheckbox } from 'components';
import { GET_REWARDS_DATA } from 'Services/apiKeys';
import { ReactComponent as GiftTagImage } from 'Assets/images/giftTag.svg';
import EmptyContent from 'components/ReusableComponents/EmptyScreen';
import RewardItem from './RewardItem';
import ConfirmModal from 'components/ReusableComponents/ConfirmModal';
import { deleteReward, getRewardsCatalog, updateVoucherStatus } from 'Services/apiFunctions';
import { useToaster } from 'Context/SnackbarContext';
import './style.scss';
import MultiSelect from 'components/ReusableComponents/MultiSelect';
import { shallowEqual, useSelector } from 'react-redux';
import { getCountryFlagURL, showSnackBarMessage } from 'utils/HelperFunctions';
import { getCountryDetails } from 'utils/countryUtils';
import { CATALOG } from '../constant';
import PropTypes from 'prop-types';

// fetching catalog rewards
const getRewardsData = async ({ pageParam = 1, queryKey: [_key, search, showLive, categoryFilter, countryFilter] }) => {
    const categories = Object.keys(categoryFilter).map((key) => categoryFilter[key]);

    const apiData = {
        page: pageParam,
        initiationStage: 'catalog',
        ...(search && { search }),
        ...(showLive && { type: 'active' }),
        ...(categories.length > 0 && { categories }),
        ...(countryFilter?.country && { country: countryFilter.country }),
    };
    try {
        return await getRewardsCatalog({ apiData });
    } catch (err) {
        throw new Error(err);
    }
};

const Index = ({ history, reward, setReward, setShowAddCustomRewardButton }) => {
    const { userCountry } = useSelector(mapStateToProps, shallowEqual);
    const [categoryFilter, setCategoryFilter] = useState({}); //keeps selected values as keys
    const [showLive, setShowLive] = useState(false);
    const [search, setSearch] = useState('');
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [categoryList, setCategoryList] = useState([]);

    const { SetSnackbar } = useToaster();

    const deleteData = {
        heading: `Are you sure you want to delete ${reward?.name || 'this custom reward'}?`,
        subHeading: "Once a reward is deleted, it can't be recovered",
    };

    const noResultEmpty = {
        image: <GiftTagImage />,
        headingText: 'No vouchers found',
        subheadingText: 'Try adjusting the filters',
    };

    /**
     * Order of showing ->
     * Show selected country in dropdown
     * When a country is selected in settings, we show that country by default
     * When more than one country is selected, we show the first country
     * Show the default country (USA)
     */
    const [selectedCountry, setSelectedCountry] = useState({
        country: userCountry,
        countryFlag: getCountryFlagURL(userCountry),
    });

    const { data, error, isLoading, isFetching, isFetchingNextPage, fetchNextPage, hasNextPage } = useInfiniteQuery(
        [GET_REWARDS_DATA, search, showLive, categoryFilter, selectedCountry],
        getRewardsData,
        {
            getNextPageParam: (lastPage, pages) => {
                return lastPage?.data?.hasMore ? pages.length + 1 : null;
            },
            refetchOnWindowFocus: false,
            keepPreviousData: true,
            staleTime: 5 * 60 * 1000,
        }
    );

    const { mutateAsync: updateVoucher } = useMutation(updateVoucherStatus);
    const queryClient = useQueryClient();

    const { mutate: deleteCustomReward, isLoading: isDeletingReward } = useMutation(deleteReward, {
        onSuccess: async () => {
            setShowDeleteModal(false);
            showSnackBarMessage(SetSnackbar, 'success', `${reward?.name} reward deleted`);
            queryClient.invalidateQueries(GET_REWARDS_DATA);
        },
        onError: () => {
            setShowDeleteModal(false);
            showSnackBarMessage(SetSnackbar, 'error', error?.message);
        },
    });

    const handleSearchChange = debounce((value) => setSearch(value), 300);

    const firstPageData = data?.pages?.[0]?.data;
    const countryList = [...(firstPageData?.countries || [])];
    const showAddRewardButton = !isLoading && !error && firstPageData?.redemptionEnabledCountries?.length > 0;

    // updating category filter based on dropdown selection
    const handleCategoryFilter = (_event, index) => {
        const tempCategory = { ...categoryFilter };
        if (tempCategory[index] !== undefined) {
            delete tempCategory[index];
        } else {
            tempCategory[index] = categoryList[index].label;
        }
        setCategoryFilter(tempCategory);
    };

    // updating category filter based on dropdown selection
    const handleCountryFilter = (index) => {
        const country = countryList[index];
        setSelectedCountry({ country, countryFlag: getCountryFlagURL(country) });
    };

    const getCountryNameWithFlag = ({ country, countryFlag }) => {
        return (
            <>
                <img className='country-image' src={countryFlag} alt='' />
                &nbsp;{country}
            </>
        );
    };

    // updating status of coupons when switch is toggled.
    const handleToggleStatus = async (item, pageNumber, index) => {
        try {
            const apiData = {
                productId: item?.productId,
                isEnabled: !item?.isEnabled,
                countryCode: getCountryDetails(selectedCountry?.country)?.countryCode,
            };
            await updateVoucher({ apiData });
            //Optimistic update for instant toggles
            queryClient.setQueryData(
                [GET_REWARDS_DATA, search, showLive, categoryFilter, selectedCountry],
                (oldData) => {
                    const oldDataCopy = cloneDeep(oldData);
                    oldDataCopy.pages[pageNumber].data.vouchers[index].isEnabled = apiData.isEnabled;
                    return oldDataCopy;
                }
            );
        } catch (err) {
            showSnackBarMessage(SetSnackbar, 'error', err?.message);
        }
    };

    const handleEdit = (item) => {
        setReward(item);
        history.push(CATALOG.CREATE_CUSTOM_REWARD, { productId: item.productId });
    };

    const onConfirmDeleteReward = () => deleteCustomReward(reward?._id);

    const handleDeleteModalClose = () => setShowDeleteModal(false);

    const getCountries = () => {
        return countryList.map((item) => ({
            label: (
                <>
                    <img className='country-image' src={getCountryFlagURL(item)} alt='' />
                    &nbsp;{item}
                </>
            ),
            value: item,
        }));
    };

    useEffect(() => {
        setSelectedCountry({ country: userCountry, countryFlag: getCountryFlagURL(userCountry) });
    }, [userCountry]);

    useEffect(() => {
        setShowAddCustomRewardButton(showAddRewardButton);
    }, [showAddRewardButton, selectedCountry]);

    useEffect(() => {
        // as new data is fetched, order of categories get changed, so only updating the category list when we change the country
        if (firstPageData?.categories && !_.isEqual(firstPageData.categories, categoryList)) {
            setCategoryList(firstPageData.categories.map((item) => ({ label: item, value: item })));
        }
    }, [firstPageData]);

    if (isLoading) {
        return <CustomLoader />;
    }

    return (
        <div className='catalog-rewards'>
            <div className='ct-filter-options'>
                <div className='d-flex'>
                    <MultiSelect
                        title='Category'
                        className='multi-select-tag-dropdown'
                        itemList={categoryList}
                        selectedIndices={categoryFilter}
                        onClick={handleCategoryFilter}
                    />
                    <CustomFilterDropdown
                        title='Country'
                        dropDownID={'selectCountry'}
                        filterOptions={getCountries()}
                        selectedName={getCountryNameWithFlag(selectedCountry)}
                        optionsSelected
                        handleSelection={handleCountryFilter}
                        buttonStyleClass='width-180 mr-0'
                        singleSelect
                        search
                    />
                    <div className='container-check-gift-cards'>
                        <CustomCheckbox checked={showLive} onClick={() => setShowLive(!showLive)} />
                        <span className='show-live'>Enabled gift cards</span>
                    </div>
                </div>
                <div className='mt-auto'>
                    <CustomSearchBox
                        width='215px'
                        placeholder='Search cards by name'
                        className='search-card'
                        handleSearch={(e) => handleSearchChange(e.target.value)}
                    />
                </div>
            </div>

            {!data?.pages?.[0]?.data?.vouchers?.length > 0 && (
                <div className='empty-container'>
                    <EmptyContent {...noResultEmpty} />
                </div>
            )}
            {data?.pages?.[0]?.data?.vouchers?.length > 0 && (
                <InfiniteScroll
                    dataLength={data.pages.length * 18}
                    next={fetchNextPage}
                    hasMore={hasNextPage}
                    height={820}
                    loader={<CustomLoader />}
                >
                    <div className='rewards-cards-container'>
                        {data.pages.map((pageData, pageNumber) =>
                            pageData.data.vouchers.map((item, index) => (
                                <RewardItem
                                    key={item?._id}
                                    item={item}
                                    handleToggleStatus={(selectedItem) =>
                                        handleToggleStatus(selectedItem, pageNumber, index)
                                    }
                                    handleEdit={handleEdit}
                                />
                            ))
                        )}
                    </div>
                </InfiniteScroll>
            )}
            {isFetching && !isFetchingNextPage && <Loader />}
            {showDeleteModal && (
                <ConfirmModal
                    open
                    onClose={handleDeleteModalClose}
                    onConfirm={onConfirmDeleteReward}
                    data={deleteData}
                    loading={isDeletingReward}
                    red
                />
            )}
        </div>
    );
};

const mapStateToProps = ({ User }) => ({
    userCountry: User.userCountry || 'United States of America',
});
Index.propTypes = {
    history: PropTypes.object,
    reward: PropTypes.object,
    setReward: PropTypes.func,
    setShowAddCustomRewardButton: PropTypes.func,
};

export default Index;
