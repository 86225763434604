import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import './style.scss';

const Index = ({ text, maxLines }) => {
    const [isTruncated, setIsTruncated] = useState(true);
    const textRef = useRef(null);
    const lineHeight = 20;

    const toggleTruncate = () => {
        setIsTruncated(!isTruncated);
    };

    useEffect(() => {
        if (textRef.current.scrollHeight > textRef.current.clientHeight) {
            setIsTruncated(true);
        } else {
            setIsTruncated(false);
        }
    }, [text]);

    const textLines = Math.ceil((textRef.current?.clientHeight || 0) / lineHeight);

    return (
        <div className='container-view-more'>
            <div
                className={`cvm-text ${isTruncated ? 'cvm-text-truncated' : 'cvm-text-expanded'}`}
                ref={textRef}
                style={{ maxHeight: isTruncated ? `${maxLines * 20}px` : 'none' }}
            >
                {text}
            </div>
            {textLines >= maxLines && (
                <button className='cvm-button' onClick={toggleTruncate}>
                    {isTruncated ? 'view more' : 'view less'}
                </button>
            )}
        </div>
    );
};

Index.propTypes = {
    text: PropTypes.string,
    maxLines: PropTypes.number,
};

export default Index;
