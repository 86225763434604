import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { FEEDBACK } from './constants';
import Configuration from './Configuration';
import MyFeedback from './MyFeedback';
import FeebackCategories from './FeebackCategories';
import SetupFeedback from './SetupFeedback';

const Index = () => {
    return (
        <div>
            <Switch>
                <Route exact path={FEEDBACK.SETUP_FEEDBACK} component={SetupFeedback} />
                <Route exact path={FEEDBACK.MY_FEEDBACK} component={MyFeedback} />
                <Route exact path={FEEDBACK.CONFIGURATION} component={Configuration} />
                <Route exact path={FEEDBACK.ADD_CATEGORIES} component={FeebackCategories} />
            </Switch>
        </div>
    );
};

export default Index;
