import React from 'react';
import TitleBar from 'components/ReusableComponents/TitleBar';
import AnalyticsFilters from './AnalyticsFilters';
import RewardStats from './RewardStats';
import AnalyticsDoughnutChart from './AnalyticsDoughnutChart';
import LineChart from './LineChart';
import CustomLoader from 'components/ReusableComponents/CustomLoader';
import UserAnalyticsTable from './AnalyticsTables/UserAnalyticsTable';
import ManagerAnalyticsTable from './AnalyticsTables/ManagerAnalyticsTable';
import DepartmentAnalyticsTable from './AnalyticsTables/DepartmentAnalyticsTable';
import PropTypes from 'prop-types';
import { COHORT_TYPE } from 'constants.js';
import './style.scss';

const Analytics = ({
    history,
    view,
    setView,
    selectedCohort,
    setSelectedCohort,
    selectedDateRange,
    setSelectedDateRange,
    analyticsGroupBy,
    setAnalyticsGroupBy,
    cohortData,
    isLoading,
}) => {
    if (isLoading) {
        return (
            <div data-testid='loader' className='analytics-container'>
                <CustomLoader />
            </div>
        );
    }

    const showManagerTable =
        COHORT_TYPE[selectedCohort?.type?.toUpperCase()] === COHORT_TYPE.WORKSPACE ||
        COHORT_TYPE[selectedCohort?.type?.toUpperCase()] === COHORT_TYPE.DEPARTMENT;

    const showDepartmentTable = COHORT_TYPE[selectedCohort?.type?.toUpperCase()] === COHORT_TYPE.WORKSPACE;

    return (
        <div className='analytics-container'>
            <TitleBar title='Analytics' />
            {selectedCohort && (
                <>
                    <AnalyticsFilters
                        cohortData={cohortData}
                        selectedCohort={selectedCohort}
                        handleCohortSelection={(cohortValue) => setSelectedCohort(cohortValue)}
                        selectedDateRange={selectedDateRange}
                        setSelectedDateRange={setSelectedDateRange}
                        setView={setView}
                    />

                    <RewardStats cohort={selectedCohort} {...selectedDateRange} />
                    <div className='chart-container'>
                        <LineChart
                            cohort={selectedCohort}
                            {...selectedDateRange}
                            groupBy={analyticsGroupBy}
                            setAnalyticsGroupBy={setAnalyticsGroupBy}
                            view={view}
                            setView={setView}
                        />
                        <AnalyticsDoughnutChart cohort={selectedCohort} {...selectedDateRange} />
                    </div>

                    <UserAnalyticsTable
                        history={history}
                        selectedCohort={selectedCohort}
                        selectedDateRange={selectedDateRange}
                    />

                    {showManagerTable && (
                        <ManagerAnalyticsTable
                            history={history}
                            selectedCohort={selectedCohort}
                            selectedDateRange={selectedDateRange}
                        />
                    )}
                    {showDepartmentTable && (
                        <DepartmentAnalyticsTable
                            history={history}
                            selectedCohort={selectedCohort}
                            selectedDateRange={selectedDateRange}
                        />
                    )}
                </>
            )}
        </div>
    );
};
Analytics.propTypes = {
    history: PropTypes.object,
    view: PropTypes.string,
    setView: PropTypes.func,
    selectedCohort: PropTypes.object,
    setSelectedCohort: PropTypes.func,
    selectedDateRange: PropTypes.object,
    setSelectedDateRange: PropTypes.func,
    analyticsGroupBy: PropTypes.string,
    setAnalyticsGroupBy: PropTypes.func,
    cohortData: PropTypes.object,
    isLoading: PropTypes.bool,
};

export default Analytics;
