import React from 'react';
import PropTypes from 'prop-types';
import { useMutation, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import { CustomSwitch } from 'components';
import { useToaster } from 'Context/SnackbarContext';
import { rewardsSubHeading } from '../../constants';
import { updateRedemptionSettings } from 'Services/apiFunctions';
import { handleEditRedemption } from '../../componentsUtil';
import { CATALOG } from 'components/Dashboard/Content/Catalog/constant';
import './style.scss';

const Index = ({ history, enableRewards, setEnableRewards }) => {
    const dispatch = useDispatch();
    const { SetSnackbar } = useToaster();
    const queryClient = useQueryClient();

    const { mutateAsync: handleSave } = useMutation(updateRedemptionSettings);
    return (
        <div className='enable-rewards-config-container'>
            <div className='d-flex justify-content-between align-item-center'>
                <div className='d-flex flex-column'>
                    <h3 className='header-reward'>Enable redemptions for recognitions</h3>
                    <span className='sub-header-reward'>
                        {rewardsSubHeading}
                        <button onClick={() => history.push(CATALOG.HOME)}>catalog.</button>
                    </span>
                </div>
                <CustomSwitch
                    checked={enableRewards}
                    onClick={handleEditRedemption({
                        enableRewards,
                        setEnableRewards,
                        dispatch,
                        queryClient,
                        SetSnackbar,
                        handleSave,
                    })}
                    name='editRewards'
                />
            </div>
        </div>
    );
};

Index.propTypes = {
    history: PropTypes.object,
    enableRewards: PropTypes.bool,
    setEnableRewards: PropTypes.func,
};

export default Index;
