import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as EWLogo } from 'Assets/images/EW-logo.svg';
import { ReactComponent as EWWelcomeBanner } from 'Assets/images/EW-welcome-banner.svg';
import { ROUTES_ONBOARDING } from '../../constants';
import { PLATFORM } from 'constants.js';
import './style.scss';

const Index = ({ history, platform }) => {
    const handleClick = () => {
        if (platform === PLATFORM.WEB) {
            history.replace(ROUTES_ONBOARDING.SETUP_WEB);
        } else {
            history.replace(ROUTES_ONBOARDING.SETUP);
        }
    };

    return (
        <div className='d-flex flex-column align-items-center justify-content-center height-100-vh bg-white'>
            <EWLogo />
            <EWWelcomeBanner className='margin-top-10' />
            <div className='welcome-heading'>Welcome to EngageWith</div>
            <div className='welcome-sub-heading'>{'Your partner in building a people-first culture'}</div>
            <button className='ew-btn pb-btn btn width-200' onClick={handleClick}>
                Let's get started
            </button>
        </div>
    );
};

Index.propTypes = {
    history: PropTypes.object,
    platform: PropTypes.string,
};

export default Index;
