import { isSlack } from 'utils/HelperFunctions';

export const RECOGNITION_CONFIG = {
    CONFIGURATION: '/dashboard/recognitions/configuration',
};
/** Below are constants used or Rewards component */

export const promptMessage =
    'Leaving this page will discard the changes you have made in the settings. Do you want to continue?';

export const balanceHeaderReward = (nameForPoints) => `Set monthly ${nameForPoints} for recognition`;
export const balanceRewardSubHeader = (nameForPoints) =>
    `These are the monthly ${nameForPoints} that each user will get to recognize someone. Any unused ${nameForPoints} will expire at the end of the month unless carry forward is enabled.`;
export const balanceCarryForwardLabel = (nameForPoints) => `Do you wish to carry forward ${nameForPoints}?`;
export const balanceAllottedHeading = (nameForPoints) => `Set up monthly ${nameForPoints} for each user`;
export const balanceTooltipInfo = (nameForPoints) =>
    `Any unused ${nameForPoints} from the previous month will be added to the next month.`;
export const balanceCarryForwardHeading = (nameForPoints) =>
    `Enter ${nameForPoints} a user can carry forward per month`;
export const balanceNote = (nameForPoints, points, carryAmount) =>
    `Every user will get a maximum of ${points}+${carryAmount} = ${points + carryAmount} ${nameForPoints} in a month`;

export const rewardsSubHeading =
    'Attach a monetary value to Recognitions. Empower employees to redeem Points from our\n exhaustive ';

export const pointsConversionSubHeading = (nameForPoints) =>
    `Set up a conversion rate for each ${nameForPoints} which can be redeemed by your teammates.\n` +
    `E.g., If John sends a Kudos (10 ${nameForPoints}) to Carol, as per the conversion rate of 1 ${nameForPoints}= $2,\n` +
    `Carol gets a total of $20 that can be redeemed.`;

export const pointsName = ['Points', 'Hearts', 'Stars'];
export const pointsHeaderReward = 'Give Points a unique name';
export const pointsSubHeader = 'Call it Points, Likes, Hearts or Smiles. Whatever you like!';
export const pointsNameOptions = [
    { label: 'Points', value: 'Points' },
    { label: 'Hearts', value: 'Hearts' },
    { label: 'Stars', value: 'Stars' },
    { label: 'Custom', value: 'Custom' },
];

export const recogSetupHeaderReward = 'Configure Recognitions';
export const recogSetupSubHeader = (nameForPoints) =>
    `When you recognize a teammate, ${nameForPoints} are sent from your EngageWith wallet to theirs.\n` +
    `Configure your recognitions and the Points attributed here. You can modify this at any point in time.`;
export const approvalReq = 'Approval required';
export const approvalReqToolTip = (rewardName) => `Manager approval will be required to send ${rewardName}`;

export const whatAreValues = `Values signify the core beliefs that your organization believes in. This is an excellent way of aligning recognition with company values. When any recognition is made, the values can be attached to recognition.\n\n`;
export const valuesNote =
    'You can either use the default values provided or customize and add up to ten values that are specific to your company.';

export const selectChannel = 'Select Channel';
export const toggleSubText = 'Select a dedicated channel to post all the recognitions.';
export const confirmationMessage = 'Are you sure you want to disable this?';
export const orgWideApproverText = [
    `Organization-wide approver will approve all recognitions (if approval is enabled) before they are posted on\n` +
        `the channel. By default, the EngageWith Admin is the organization-wide approver. However, you\ncan set up teams and assign managers from the `,
    ' to approve recognitions.',
];
export const broadcastMessages = 'broadcastMessages';
export const defaultRecognitionChannel = 'defaultRecognitionChannel';

export const getEndText = (enableCondition, platform) => {
    const platformText = isSlack(platform) ? 'channel' : 'team';
    return enableCondition
        ? `If you don't see a specific ${platformText}, make sure EngageWith is integrated with the ${platformText}.`
        : `(Note: This is currently disabled. Click on the toggle (top-right corner) to enable and view the ${platformText} in the drop-down)`;
};

export const rewardsNoSortColumns = [0, 1, 2, 3];
export const rewardsColumnWidth = ['20%', '20%', '40%', '20%'];
export const pointsConversionsHeading = (nameForPoints) => `${nameForPoints} Conversion Rate`;
