import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { isApplicableForQuestionType, isQuestionTypeBinary } from './componentUtils';

const Answer = ({ element, answerText }) => (
    <span
        className={clsx({
            'answer-text font-size-14': isApplicableForQuestionType(element),
            'mt-5 p-2 text-break': !isApplicableForQuestionType(element),
            'ml-3 max-width-362': isQuestionTypeBinary(element),
            'mcq-title': element?.question?.type === 'SINGLE_CHOICE' || element?.question?.type === 'MULTIPLE_CHOICE',
        })}
    >
        {answerText}
    </span>
);

Answer.propTypes = {
    element: PropTypes.object,
    answerText: PropTypes.string,
};

export default Answer;
