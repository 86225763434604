import { getData, postData, patchData, deleteData } from 'Services/apiCall';
import queryString from 'query-string';

export const postRecognition = async ({ apiData }) => {
    try {
        const { data } = await postData('recognition', apiData);
        return data;
    } catch (err) {
        throw new Error(err?.response?.data?.message);
    }
};

export const getRecognitions = async ({ pageParam = 1, queryKey: [_key, recognitionId, userIds, addOn] }) => {
    try {
        const queryParams = {
            page: pageParam,
            ...(addOn && { addOn: true }),
            ...(recognitionId && { recognitionId }),
            ...(userIds?.length > 0 && { userIds: userIds.join(',') }),
        };
        const queryStringParams = queryString.stringify(queryParams);
        const path = `recognition/feed?${queryStringParams}`;
        return await getData(path);
    } catch (err) {
        throw new Error(err?.response?.data?.message);
    }
};

export const getRecognitionById = async ({ queryKey: [_key, id] }) => {
    try {
        const path = `recognition/feed?page=1&recognitionId=${id}`;
        const { data } = await getData(path);
        return data;
    } catch (err) {
        throw new Error(err?.response?.data?.message);
    }
};

export const getPendingRecognitions = async ({ queryKey: [_key] }) => {
    try {
        const path = 'users/tasks?page=1&feed=true';
        const { data } = await getData(path);
        return data;
    } catch (err) {
        throw new Error(err?.response?.data?.message);
    }
};

export const getRecognitionsRestrictedUser = async ({ queryKey: [_key, feature] }) => {
    try {
        const path = `users/permissions?feature=${feature}`;
        const { data } = await getData(path);
        return data;
    } catch (err) {
        throw new Error(err?.response?.data?.message);
    }
};

export const postRecognitionRestrictedUser = async ({ apiData }) => {
    try {
        const { data } = await postData('users/permissions', apiData);
        return data;
    } catch (err) {
        throw new Error(err?.response?.data?.message);
    }
};

export const postRecognitionTypes = async ({ apiData }) => {
    try {
        return await postData('recognition/recognition-type', apiData);
    } catch (err) {
        throw new Error(err?.response?.data?.message);
    }
};

export const updateRecognitionTypes = async ({ apiData }) => {
    try {
        return await patchData('recognition/recognition-type', apiData);
    } catch (err) {
        throw new Error(err?.response?.data?.message);
    }
};

export const deleteRecognitionType = async (id) => {
    try {
        return await deleteData(`recognition/recognition-type/${id}`);
    } catch (err) {
        throw new Error(err?.response?.data?.message);
    }
};

export const getRecognitionTypes = async () => {
    try {
        const res = await getData('recognition/configure/points');
        return res.data;
    } catch (err) {
        throw new Error(err?.response?.data?.message);
    }
};
