import React from 'react';
import PropTypes from 'prop-types';
import { GET_PAGINATED_USERS } from 'Services/apiKeys';
import { useQueryClient } from 'react-query';
import CloseIcon from '@material-ui/icons/Close';
import clsx from 'clsx';
import './style.scss';

const Index = ({ setCurrentModal, onClose, data, setErrorData }) => {
    const queryClient = useQueryClient();
    const failedResultsLength = Object.keys(data?.errorObject)?.length;

    const handleNext = async () => {
        queryClient.invalidateQueries(GET_PAGINATED_USERS);
        onClose();
    };

    const handleClick = (index) => {
        if (index === 1 && failedResultsLength > 0) {
            const errors = Object.entries(data.errorObject).map(([key, value]) => {
                const detailedError = Object.keys(value).map((errorField) => ` ${errorField}: ${value[errorField]}, `);
                return { key, value: detailedError };
            });
            setErrorData(errors);
            setCurrentModal(6);
        }
    };

    const results = [
        {
            img: 'success',
            title: `${data.processed} Processed`,
            content: 'Correct Information passed and managers assigned',
            value: data.processed,
        },
        {
            img: 'error',
            title: `${failedResultsLength} Uploads Failed`,
            content: 'Incorrect information in csv',
            value: failedResultsLength,
        },
        {
            img: 'ignore',
            title: `${data.ignored} Ignored`,
            content: 'People who are already assigned managers',
            value: data.ignored,
        },
    ];
    return (
        <div className='upload-result-container report-methods'>
            <div className='upload-result-header'>
                <p>Upload teams/members through CSV</p>
                <CloseIcon className='close-icon' onClick={onClose} />
            </div>

            <div className='upload-result-content make-relative'>
                <h6>Result</h6>
                <div className='contents-report make-relative'>
                    {results.map((result, i) =>
                        result.value > 0 ? (
                            <button
                                className={clsx('report-btns', {
                                    'row mb-4': true,
                                    'pointer-cursor':
                                        (i === 1 && failedResultsLength > 0) || (i === 2 && data.ignored > 0),
                                })}
                                onClick={() => handleClick(i)}
                                key={result.img}
                            >
                                <div className='col-1 text-right pr-0'>
                                    <img src={require(`Assets/images/${result.img}.svg`)} alt='img' />
                                </div>
                                <div
                                    className={clsx({
                                        'col-11 text-left': true,
                                        'succes-col': i === 0,
                                        'error-col': i === 1,
                                        'ignore-col': i !== 1 && i !== 0,
                                    })}
                                >
                                    <p className='mb-0 font-size-18'>
                                        <b>{result.title}</b>
                                    </p>
                                    <p className='mb-0 font-size-10'>{result.content}</p>
                                </div>
                            </button>
                        ) : null
                    )}
                </div>
            </div>
            {failedResultsLength > 0 ? (
                <p className='font-size-10 result-error-text mt-3'>
                    You can fix the errors and then reupload CSV by clicking on retry upload
                </p>
            ) : (
                <div style={{ width: '100%', height: '60px' }}></div>
            )}

            <div className='upload-result-button-container'>
                {failedResultsLength > 0 ? (
                    <button className='ew-btn tb-btn' onClick={() => setCurrentModal(1)}>
                        Retry upload
                    </button>
                ) : null}
                <button className='ew-btn pb-btn' onClick={handleNext}>
                    Done
                </button>
            </div>
        </div>
    );
};

Index.propTypes = {
    setCurrentModal: PropTypes.func,
    onClose: PropTypes.func,
    data: PropTypes.object,
    setErrorData: PropTypes.func,
};

export default Index;
