import React from 'react';
import PercentageBarChart from 'components/ReusableComponents/PercentageBarChart';
import { getBarFillColor, isQuestionTypeBinary } from './componentUtils';
import Answer from './Answer';
import clsx from 'clsx';
import PropTypes from 'prop-types';

const ResultBox = ({ element }) => (
    <div
        className={clsx({
            'question-borders': true,
            'height-300': !isQuestionTypeBinary(element),
        })}
    >
        {['RATING', 'RATING_5'].includes(element.question.type) && <p className='average-field'>Avg {element.avg}</p>}
        <div
            className={clsx({
                'mt-5': true,
                'd-flex': !isQuestionTypeBinary(element),
            })}
        >
            {element?.responses?.map((answer, idx) => (
                <React.Fragment key={answer.ans}>
                    {idx === 0 && ['RATING', 'RATING_5', 'LIKERT'].includes(element.question.type) && <></>}
                    <div
                        style={['RATING', 'RATING_5'].includes(element.question.type) ? { width: '70px' } : {}}
                        className={clsx({
                            'd-flex align-items-center': true,
                            'justify-content-center mb-3': isQuestionTypeBinary(element),
                            'flex-column  min-width-70': !isQuestionTypeBinary(element),
                        })}
                    >
                        <span
                            className={clsx({
                                'answer-text': true,
                                'font-size-14': isQuestionTypeBinary(element),
                                'mr-3': isQuestionTypeBinary(element),
                            })}
                        >
                            {answer.percentage}%
                        </span>
                        <PercentageBarChart
                            horizontal={isQuestionTypeBinary(element)}
                            bgcolorActive={getBarFillColor(element?.question?.type)}
                            bgcolorTotal='#F5F5F5'
                            text={isQuestionTypeBinary(element) ? answer?.ans : answer.frequency + ' responses'}
                            percentage={answer.percentage}
                            xAxisLabel={answer.ans}
                        />
                        <Answer
                            element={element}
                            answerText={isQuestionTypeBinary(element) ? answer?.frequency + ' responses' : answer?.ans}
                        />
                    </div>
                </React.Fragment>
            ))}
        </div>
    </div>
);

ResultBox.propTypes = {
    element: PropTypes.object,
};

export default ResultBox;
