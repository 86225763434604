import { actions } from './actionEvents';

const initialState = null;

export default function XoxodayReducer(state = initialState, action) {
    const { payload, type } = action;
    if (type === actions.SET_XOXODAY) {
        return payload;
    } else {
        return state;
    }
}
