import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from 'components';

const QuestionTitle = ({ title }) => {
    return title?.length > 20 ? (
        <Tooltip title={title}>
            <div className='question'>{title}</div>
        </Tooltip>
    ) : (
        <div className='question'>{title}</div>
    );
};

QuestionTitle.propTypes = {
    title: PropTypes.string,
};

export default QuestionTitle;
