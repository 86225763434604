import { nanoid } from 'nanoid';
import { pulseQuestionTypes } from 'constants.js';
import { find } from 'lodash';

export const formatQuestion = (pulseDataQuestion) =>
    pulseDataQuestion?.map((question) => ({
        _id: question._id,
        id: question._id,
        question: { name: question.title, error: false },
        questionType: question.type === 'MULTIPLE_CHOICE' || question.type === 'SINGLE_CHOICE' ? 'MCQ' : question.type,
        questionLabel:
            question?.type === 'MULTIPLE_CHOICE' || question?.type === 'SINGLE_CHOICE'
                ? 'Multiple choice'
                : find(pulseQuestionTypes, (type) => type.value === question.type)?.label,
        required: question?.required,
        multipleSelection: question?.type === 'MULTIPLE_CHOICE',
        options: question?.options
            ? question.options.map((option) => ({ name: option, error: false, id: nanoid() }))
            : [
                  { name: '', error: false, id: nanoid() },
                  { name: '', error: false, id: nanoid() },
              ],
    }));
export const getStandardQuestions = (questions, eNPSQuestionsList) => {
    return eNPSQuestionsList.map((title) => questions.find((question) => question.title === title)).filter(Boolean);
};

export const getAddedQuestions = (questions, eNPSQuestionsList) => {
    return questions.filter((question) => !eNPSQuestionsList.includes(question.title));
};

export const formatStandardQuestions = (standardQuestions) => {
    return standardQuestions.map((question) => ({
        ...question,
        question: question.title,
        questionType: question.type,
    }));
};
