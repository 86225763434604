import React from 'react';
import PropTypes from 'prop-types';
import SuggestionTable from '../SuggestionsTable';
import '../style.scss';

const Index = ({ history, isSuggestionBoxAdmin }) => {
    return (
        <div className='my-suggestion-container'>
            <SuggestionTable history={history} isSuggestionBoxAdmin={isSuggestionBoxAdmin} />
        </div>
    );
};

Index.propTypes = {
    history: PropTypes.object,
    isSuggestionBoxAdmin: PropTypes.bool,
};

export default Index;
