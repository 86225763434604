import React from 'react';
import { Switch, Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import CatalogHome from './CatalogHome';
import CreateCustomReward from './CreateCustomReward';
import { CATALOG } from './constant';

const Index = ({ history }) => {
    return (
        <div>
            <Switch>
                <Route exact path={CATALOG.HOME} render={() => <CatalogHome history={history} />} />
                <Route
                    exact
                    path={CATALOG.CREATE_CUSTOM_REWARD}
                    render={() => <CreateCustomReward history={history} />}
                />
            </Switch>
        </div>
    );
};

Index.propTypes = {
    history: PropTypes.object,
};

export default Index;
