import { useMutation } from 'react-query';
import { setupRnR } from 'Services/apiFunctions';

export const useRnRSetup = ({ onError, onSuccess }) => {
    return useMutation(setupRnR, {
        onError: async (err) => {
            onError(err);
        },
        onSuccess: async () => {
            onSuccess();
        },
    });
};
