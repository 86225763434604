import React, { useState } from 'react';
import { getRecognitions } from '../../../Services/apiFunctions';
import { GET_ADD_ON_RECOGNITION_POSTS } from 'Services/apiKeys';
import { useInfiniteQuery } from 'react-query';
import SideOverlay from 'components/ReusableComponents/SideOverlay';
import RecognizedUsers from '../RecognitionItem/RecognizedUsers';
import RecognitionMessage from '../RecognitionItem/RecognitionMessage';
import Recognizer from '../RecognitionItem/Recognizer';
import RecognitionDate from '../RecognitionItem/RecognitionDate';
import AddOnRecognitionsList from './AddOnRecognitionsList';
import { useToaster } from 'Context/SnackbarContext';
import { showSnackBarMessage } from 'utils/HelperFunctions';
import PropTypes from 'prop-types';
import './style.scss';

const Index = ({ open, onClose, recognitionData, handleGiveAddOnRecognition }) => {
    const { SetSnackbar } = useToaster();
    const [addOnRecognitionPosts, setAddOnRecognitionPosts] = useState([]);

    const { fetchNextPage, hasNextPage } = useInfiniteQuery(
        [GET_ADD_ON_RECOGNITION_POSTS, recognitionData?._id, null, true],
        getRecognitions,
        {
            getNextPageParam: (lastPage, pages) => {
                return lastPage?.data?.data?.hasMore ? pages.length + 1 : undefined;
            },
            refetchOnWindowFocus: false,
            keepPreviousData: true,
            onSuccess: (data) => {
                setAddOnRecognitionPosts(data);
            },
            onError: (err) => showSnackBarMessage(SetSnackbar, 'error', err.message),
            retry: 0,
        }
    );

    return (
        <SideOverlay open={open} onClose={onClose} height='100%' heading='Recognition'>
            <div className='container-add-on-recognitions'>
                <div className='cao-recognitions-header'>
                    <RecognitionDate date={recognitionData?.timestamp} />
                    <div style={{ marginTop: '8px' }}>
                        <Recognizer
                            userName={recognitionData?.sender?.userName}
                            pictureURL={recognitionData?.sender?.pictureURL}
                            recognitionType={recognitionData?.type}
                        />
                    </div>
                </div>
                <RecognizedUsers showValue={true} users={recognitionData?.receivers} value={recognitionData?.value} />
                <RecognitionMessage
                    showMessageTitle={true}
                    showRecognitionImage={false}
                    recognitionData={recognitionData}
                    showGiveAddOnButton={true}
                    handleGiveAddOnRecognition={handleGiveAddOnRecognition}
                    showViewMoreButtonForMessage={true}
                    showChannelIcon={true}
                />
                <AddOnRecognitionsList
                    addOnRecognitionCount={recognitionData?.addOnRecognitions?.length}
                    fetchNextPage={fetchNextPage}
                    hasNextPage={hasNextPage}
                    addOnRecognitionsList={addOnRecognitionPosts}
                />
            </div>
        </SideOverlay>
    );
};

Index.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    recognitionData: PropTypes.object.isRequired,
    handleGiveAddOnRecognition: PropTypes.func,
};

export default Index;
