import Switch from '@material-ui/core/Switch';
import { withStyles } from '@material-ui/core/styles';

const CustomSwitch = withStyles((theme) => ({
    root: {
        width: 42,
        height: 24,
        padding: 0,
        display: 'flex',
    },
    switchBase: {
        padding: 1,
        color: theme.palette.common.white,
        '&$checked': {
            transform: 'translateX(18px)',
            color: theme.palette.common.white,
            '& + $track': {
                opacity: 1,
                backgroundColor: '#5d5cf5',
                border: 'none',
            },
        },
    },
    thumb: {
        width: '20px !important',
        height: '20px !important',
    },
    track: {
        border: '1px solid #c9ced6',
        borderRadius: 24 / 2,
        opacity: 1,
        backgroundColor: '#c9ced6',
    },
    checked: {},
}))(Switch);

export const CustomSwitchBlue = withStyles((theme) => ({
    root: {
        width: 42,
        height: 24,
        padding: 0,
        display: 'flex',
    },
    switchBase: {
        padding: 1,
        color: theme.palette.common.white,
        '&$checked': {
            transform: 'translateX(18px)',
            color: theme.palette.common.white,
            '& + $track': {
                opacity: 1,
                backgroundColor: '#5d5cf5',
                border: 'none',
            },
        },
    },
    thumb: {
        width: '20px !important',
        height: '20px !important',
    },
    track: {
        border: '1px solid #5d5cf5',
        borderRadius: 24 / 2,
        opacity: 1,
        backgroundColor: '#5d5cf5',
    },
    checked: {},
}))(Switch);
export default CustomSwitch;
