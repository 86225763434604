import React, { useState } from 'react';
import PropTypes from 'prop-types';
import CustomFilterDropdown from 'components/ReusableComponents/CustomFilterDropdown';
import { pointsName, pointsNameOptions, pointsHeaderReward, pointsSubHeader } from '../../constants';
import './style.scss';
import { pointsNameOnChangeHandler, pointsNameOnFocusedBlurHandler } from '../../componentsUtil';

const Index = ({ customPoints, setCustomPoints }) => {
    const [customPointsFieldValidation, setCustomPointsFieldValidation] = useState({
        isFocused: false,
        charsLeft: 10,
    });
    const handleChange = (index) => {
        setCustomPoints(index === 3 ? '' : pointsNameOptions[index].value);
    };

    return (
        <div className='config-rewards-points-container'>
            <h3 className='header-reward'>{pointsHeaderReward}</h3>
            <p className='sub-header-reward'>{pointsSubHeader}</p>
            <div className='points-name-container'>
                <div className='filter-container'>
                    <CustomFilterDropdown
                        title='Choose a name'
                        filterOptions={pointsNameOptions}
                        selectedName={pointsName.includes(customPoints) ? customPoints : 'Custom'}
                        optionsSelected
                        handleSelection={(index) => handleChange(index)}
                        dropDownID='pointName'
                        singleSelect
                        buttonStyleClass='item'
                    />
                </div>

                {!pointsName.includes(customPoints) && (
                    <div className='filter-container'>
                        <p>Write a custom name</p>
                        <div className='d-flex align-items-center justify-content-between'>
                            <input
                                type='text'
                                value={customPoints}
                                className='item input-item'
                                placeholder='Enter name'
                                onChange={(e) =>
                                    pointsNameOnChangeHandler({
                                        value: e.currentTarget.value,
                                        customPoints,
                                        setCustomPoints,
                                        customPointsFieldValidation,
                                        setCustomPointsFieldValidation,
                                    })
                                }
                                onFocus={pointsNameOnFocusedBlurHandler({
                                    isFocused: true,
                                    setCustomPointsFieldValidation,
                                    customPoints,
                                })}
                                onBlur={pointsNameOnFocusedBlurHandler({
                                    isFocused: false,
                                    setCustomPointsFieldValidation,
                                    customPoints,
                                })}
                            />
                            {customPointsFieldValidation.isFocused && (
                                <p className='custom-points-validation'>
                                    Characters left: {customPointsFieldValidation.charsLeft}
                                </p>
                            )}
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

Index.propTypes = {
    customPoints: PropTypes.string,
    setCustomPoints: PropTypes.func,
};

export default Index;
