import React from 'react';
import NotificationItem from './NotificationItem';

const NotificationWrapper = (renderNotification) => {
    return (props) => {
        return <NotificationItem {...props} renderNotification={renderNotification} />;
    };
};

export default NotificationWrapper;
