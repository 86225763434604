import { isValid } from 'date-fns';
import React from 'react';
import PropTypes from 'prop-types';

const NOTE_WEEKEND = '💡 Note: Scheduling pulse on the weekend might go un-noticed.';
const NOTE_AFTER_5PM =
    '💡 Note: Your team members might have logged out by this time.\nScheduling pulse after work hours might have lower response rates.';

const DateDisclaimer = ({ scheduling, time }) => {
    const scheduleDate = isValid(scheduling?.onDate) ? new Date(scheduling?.onDate) : null;

    if (!scheduleDate) {
        return null;
    }

    const isWeekend = scheduleDate && (scheduleDate.getDay() === 0 || scheduleDate.getDay() === 6);
    const isTimeAfter5pm = isValid(time) && new Date(time).getHours() >= 17;

    const disclaimerNote = () => {
        if (isWeekend) {
            return NOTE_WEEKEND;
        }
        return isTimeAfter5pm ? NOTE_AFTER_5PM : '';
    };

    return (
        <div className='pulse-date-disclaimer'>
            <span className='disclaimer-text'>{disclaimerNote()}</span>
        </div>
    );
};

DateDisclaimer.propTypes = {
    scheduling: PropTypes.object,
    time: PropTypes.object,
};

export default DateDisclaimer;
