import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as UsersIcon } from 'Assets/images/group_selected.svg';
import MemberTag from 'components/ReusableComponents/MemberTag';
import { HISTORY_FROM } from 'constants.js';
import NotificationWrapper from '../../NotificationWrapper';
import '../styles.scss';

const Index = ({ data, history }) => {
    const user = data?.metadata;
    return (
        <div className='notification-event-container'>
            <UsersIcon width={16} height={16} />
            <p className='notification-module-text'>Onboarding: </p>
            <div className='notification-user-added-event'>
                <p>New User </p>
                <MemberTag
                    memberID={user?._id}
                    memberName={user?.name}
                    memberPhoto={user?.pictureURL}
                    history={history}
                    navMenuCode={HISTORY_FROM.NOTIFICATIONS}
                />
                <p>
                    added to EngageWith (
                    {user.invitationType === 'email' ? 'accepted email invite' : 'public link signup'})
                </p>
            </div>
        </div>
    );
};

Index.propTypes = {
    data: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
};

export default NotificationWrapper(Index);
