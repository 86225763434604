import React from 'react';
import PropTypes from 'prop-types';
import { getFormattedDate } from 'utils/HelperFunctions';
import './style.scss';

const Index = ({ date }) => {
    return <div className='container-recognition-date'>{getFormattedDate(new Date(date))}</div>;
};

Index.propTypes = {
    date: PropTypes.string,
};

export default Index;
