import { format, parseISO } from 'date-fns';
import React from 'react';

export const rowFormatter = (award) => ({
    id: award._id,
    row: [
        <strong key={award._id}>{award.awardName}</strong>,
        award?.awardDate ? format(parseISO(award.awardDate), 'MMM dd, yyyy') : '-',
        award?.amount ? `${award.currency} ${award.amount}` : 'N/A',
    ],
});

export const getSortHandler = (sort, setSort, tableProperties) => (index) =>
    setSort({ key: tableProperties.SORT_KEY[index], direction: -(sort.direction || -1) });
