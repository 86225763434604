import React from 'react';
import PropTypes from 'prop-types';
import ConfirmModal from '../../ReusableComponents/ConfirmModal';
import { ReactComponent as Warning } from 'Assets/images/warning-yellow.svg';

const Index = ({ open, setOpen, loading, handleConfirm, handleCancel }) => {
    if (!open) {
        return null;
    }

    const handleClose = () => {
        setOpen(!open);
    };

    const text = {
        heading: (
            <>
                <Warning style={{ width: '18px', height: '14px' }} /> You have unsaved changes
            </>
        ),
        subHeading: `Leaving this page will discard the changes you have made in the settings. Do you want to continue?`,
        cancel: `Don't Save`,
        confirm: 'Save and Proceed',
    };

    return (
        <div>
            <ConfirmModal
                loading={loading}
                open={open}
                onClose={handleClose}
                onCancel={handleCancel}
                data={text}
                onConfirm={handleConfirm}
            />
        </div>
    );
};

Index.propTypes = {
    open: PropTypes.bool,
    setOpen: PropTypes.func,
    loading: PropTypes.bool,
    handleConfirm: PropTypes.func,
    handleCancel: PropTypes.func,
};

export default Index;
