import React, { useState } from 'react';
import { useQueryClient, useMutation } from 'react-query';
import { useCommonContext } from 'Context/CommonContext';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { updateWorkspaceDetails } from 'redux/workspace/actions';
import { GET_PAGINATED_USERS } from 'Services/apiKeys';
import { modifyUser } from 'Services/apiFunctions';
import { useToaster } from 'Context/SnackbarContext';
import { showSnackBarMessage } from 'utils/HelperFunctions';
import UserDetailsCard from 'components/ReusableComponents/UserDetailsCard';
import EWModal from 'components/ReusableComponents/EWModal';
import UserSelectionDropdown from 'components/ReusableComponents/UserSelectionDropdown';
import LabelWithImage from 'components/ReusableComponents/LabelWithImage';
import PropTypes from 'prop-types';
import './style.scss';

const Index = ({ open, onClose, userData }) => {
    const dispatch = useDispatch();
    const { setManagerflow } = useCommonContext();
    const { todo } = useSelector(mapStateToProps, shallowEqual);
    const queryClient = useQueryClient();
    const { SetSnackbar } = useToaster();
    const [selectedManager, setSelectedManager] = useState();

    const { mutateAsync: updateManager, isLoading } = useMutation(modifyUser);

    const handleManagerSelection = (user) => {
        setSelectedManager(user);
    };

    const handleSave = async () => {
        try {
            const apiData = {
                managerId: selectedManager?._id,
                userIds: [userData._id],
            };
            await updateManager({ apiData });
            queryClient.invalidateQueries(GET_PAGINATED_USERS);
            // when manager is assigned for first time and isManagerFlowCompleted is false, update workspace configs
            if (!todo?.isManagerFlowCompleted) {
                setManagerflow(true);
                updateWorkspaceDetails(dispatch, { todo: { ...todo, isManagerFlowCompleted: true } });
            }
            onClose();
        } catch (error) {
            showSnackBarMessage(SetSnackbar, 'error', error?.message);
        }
    };

    const modalData = {
        heading: userData?.manager ? 'Change Manager' : 'Assign Manager',
        rightButtonText: 'Save',
        handleRightButtonClick: handleSave,
        loading: isLoading,
        disabled: isLoading || !selectedManager,
    };

    return (
        <EWModal open={open} onClose={() => onClose(false)} width='528px' height='336px' modalData={modalData}>
            <div className='container-assign-change-manager'>
                <p className='assign-change-manager-title'>USER</p>
                <UserDetailsCard
                    userName={userData?.userName}
                    pictureURL={userData?.pictureURL}
                    department={userData?.department}
                />
                <div style={{ margin: '28px 0px 50px' }}>
                    <UserSelectionDropdown
                        id='assignOrChangeManager'
                        title='Manager'
                        dropdownWidth={480}
                        buttonWidth={480}
                        selectedUser={
                            <LabelWithImage
                                {...(selectedManager && {
                                    image: selectedManager?.pictureURL || require('Assets/images/defaultUser.png'),
                                })}
                                name={selectedManager?.userName || 'Select a manager'}
                                teamSize={selectedManager?.team?.length}
                            />
                        }
                        handleUserSelection={handleManagerSelection}
                        placeholder='Search name'
                        showTeam
                        optionsSelected={!!selectedManager}
                    />
                </div>
            </div>
        </EWModal>
    );
};

const mapStateToProps = (state) => ({
    todo: state.Workspace.todo,
});

Index.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    title: PropTypes.string,
    userData: PropTypes.object,
};

export default Index;
