export const AWARD_TABLE_PROPERTIES = {
    HEADERS: ['AWARD NAME', 'AWARD DATE', 'AWARDEE', 'AWARD PRIZE', 'AWARD TYPE'],
    NO_SORT: [2, 3, 4],
    COLUMN_WIDTH: ['20%', '20%', '15%', '15%', '30%'],
    SORT_KEY: ['name', 'awardDate', '', '', ''],
};

export const AWARD_TYPE_LIST = [
    { label: 'All', value: '' },
    { label: 'Direct', value: 'Direct' },
    { label: 'Poll', value: 'Poll' },
];

export const AWARD_TYPE = {
    DIRECT: 'Direct',
    POLL: 'Poll',
};

export const EMPLOYEE_AWARDS = {
    PROFILE: '/dashboard/profile/',
};
