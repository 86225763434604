import React, { useState, useRef } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import PropTypes from 'prop-types';
import TitleBar from 'components/ReusableComponents/TitleBar';
import EWButton from 'components/ReusableComponents/EWButton';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import Banner from 'components/Dashboard/Content/Celebration/Banner';
import { SUGGESTION } from '../constants';
import { ReactComponent as SuggestionDisabled } from 'Assets/images/suggestions-disabled.svg';
import SendSuggestionBoxModal from './SendSuggestionBoxModal';
import Suggestion from './Suggestion';
import { GET_SUGGESTION_BOX_CONFIGURATION, GET_SUGGESTION_BOX_LIST } from 'Services/apiKeys';
import SuggestionBoxCreatedModal from 'components/Modals/PulseCreatedModal';
import { getSuggestionBoxConfiguration } from '../Services/apiFunctions';
import { useToaster } from 'Context/SnackbarContext';
import { showSnackBarMessage, isSubscriptionCancelled } from 'utils/HelperFunctions';
import { updateWorkspaceDetails } from 'redux/workspace/actions';
import { HISTORY_FROM } from 'constants.js';
import SubscriptionCancelledModal from 'components/Modals/SubscriptionCancelledModal';
import './style.scss';

const Index = ({ history }) => {
    const queryClient = useQueryClient();
    const { SetSnackbar } = useToaster();
    const dispatch = useDispatch();
    const { suggestionConfigs, isAdmin, UserID, subscription } = useSelector(mapStateToProps, shallowEqual);
    const [showSuggestionBoxModal, setShowSuggestionBoxModal] = useState(false);
    const [subscriptionCancelled, setSubscriptionCancelled] = useState(false);

    const suggestionBoxSentModalData = useRef({
        title: 'Suggestion shared successfully',
        buttonText: 'Show message',
    });
    const [suggestionBoxCreated, setSuggestionBoxCreated] = useState(false);
    const [isSuggestionBoxAdmin, setIsSuggestionBoxAdmin] = useState(false);
    const titleName = isAdmin || isSuggestionBoxAdmin ? 'All Suggestions' : 'My Suggestions';

    /**
     * Opens suggestion sent modal and sets the data required to show
     * @param {string} messageUrl - slack message url to open
     * @param {string} userName - name of the user to whom the suggestion was sent
     * @param {boolean} anonymous - suggestion was sent anonymously or not
     */
    const handleSuggestionBoxCreatedModalSuccess = (messageUrl, receiver, anonymous = false) => {
        setShowSuggestionBoxModal(false);
        suggestionBoxSentModalData.current = {
            ...suggestionBoxSentModalData.current,
            content: `Your Suggestion was shared with ${receiver} ${anonymous ? 'anonymously' : ''}`,
            messageUrl,
        };
        setSuggestionBoxCreated(true);
    };

    const handleShowSuggestionModalClick = () => {
        if (isSubscriptionCancelled(subscription)) {
            setSubscriptionCancelled(true);
            return;
        }
        setShowSuggestionBoxModal(true);
    };

    const { isLoading } = useQuery([GET_SUGGESTION_BOX_CONFIGURATION], getSuggestionBoxConfiguration, {
        onSuccess: (data) => {
            const config = data?.data;
            if (config?.hasOwnProperty('enabled')) {
                updateWorkspaceDetails(dispatch, { suggestionBox: { ...config } });
            }
            if (config.admins[0]._id === UserID) {
                setIsSuggestionBoxAdmin(true);
            }
        },
        onError: (error) => showSnackBarMessage(SetSnackbar, 'error', error?.message),
    });
    return (
        <div className='suggestion-container navigatortabs'>
            <TitleBar title={titleName}>
                {suggestionConfigs?.enabled && (
                    <EWButton
                        buttonText='Send Suggestion'
                        buttonStyleClass='margin-botton-neg-50'
                        onClick={handleShowSuggestionModalClick}
                    />
                )}
            </TitleBar>
            {isAdmin && !isLoading && !suggestionConfigs?.enabled && (
                <div className='mb-3'>
                    <Banner
                        heading={'You have disabled Suggestions'}
                        subHeading={'Please enable to access it'}
                        buttonText='Enable Suggestions'
                        handleClick={() => history.push(SUGGESTION.CONFIGURATION)}
                        image={<SuggestionDisabled className='suggestion-banner' />}
                    />
                </div>
            )}
            <Suggestion history={history} isSuggestionBoxAdmin={isSuggestionBoxAdmin} />
            {showSuggestionBoxModal && (
                <SendSuggestionBoxModal
                    open={showSuggestionBoxModal}
                    onSuccess={handleSuggestionBoxCreatedModalSuccess}
                    onClose={() => setShowSuggestionBoxModal(false)}
                    history={history}
                />
            )}
            <SuggestionBoxCreatedModal
                open={suggestionBoxCreated}
                data={suggestionBoxSentModalData.current}
                onConfirm={() => {
                    window.open(suggestionBoxSentModalData.current?.messageUrl, '_blank');
                    setSuggestionBoxCreated(false);
                    queryClient.invalidateQueries(GET_SUGGESTION_BOX_LIST);
                }}
                onClose={() => {
                    setSuggestionBoxCreated(false);
                    queryClient.invalidateQueries(GET_SUGGESTION_BOX_LIST);
                }}
            />
            <SubscriptionCancelledModal
                open={subscriptionCancelled}
                onClose={() => setSubscriptionCancelled(false)}
                history={history}
                currentRoute={HISTORY_FROM.SUGGESTION_BOX}
            />
        </div>
    );
};

Index.propTypes = {
    history: PropTypes.object,
};
const mapStateToProps = ({ Workspace, User, Payments }) => ({
    suggestionConfigs: Workspace.suggestionBox,
    isAdmin: User.isAdmin,
    UserID: User._id,
    subscription: Payments?.subscription,
});

export default Index;
