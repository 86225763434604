import React, { useState } from 'react';
import PropTypes from 'prop-types';
import CustomFilterDropdown from 'components/ReusableComponents/CustomFilterDropdown';
import CustomSearchBox from 'components/Styles/CustomSearchBox';
import { dateRanges } from '../../constants.js';
import clsx from 'clsx';
import { debounce } from 'lodash';

const LogsFilter = ({ setFilterOption, actionByDropdownOptions, settingNameDropdown }) => {
    const [selectedValue, setSelectedValue] = useState({
        date: 'All time',
        actionBy: 'All',
        settingName: 'All',
        search: '',
    });

    const handleSelection = (type, selectedOption) => {
        // Update the selected value in state
        setSelectedValue((prevState) => ({
            ...prevState,
            [type]: selectedOption.label,
        }));
        // handle filter
        setFilterOption((prevState) => ({
            ...prevState,
            [type]: selectedOption.value,
        }));
    };

    const handleSearchChange = debounce(
        (value) =>
            setFilterOption((prevState) => ({
                ...prevState,
                ['search']: value.trim(),
            })),
        300
    );

    return (
        <div
            className={clsx({
                'd-flex justify-content-between align-items-center mb-2': true,
            })}
        >
            <div className='d-flex justify-content-start align-items-center'>
                <div className='d-flex flex-column ml-3'>
                    <CustomFilterDropdown
                        title='Date Range'
                        filterOptions={dateRanges}
                        selectedName={selectedValue['date']}
                        optionsSelected
                        handleSelection={(index, selectedOption) => handleSelection('date', selectedOption)}
                        dropDownID='auditLogsDateRange'
                        buttonStyleClass='width-188'
                        singleSelect
                    />
                </div>

                <div className='d-flex flex-column ml-3'>
                    <CustomFilterDropdown
                        title='Action by'
                        filterOptions={actionByDropdownOptions}
                        selectedName={selectedValue['actionBy']}
                        optionsSelected
                        handleSelection={(index, selectedOption) => handleSelection('actionBy', selectedOption)}
                        dropDownID='actionFilter'
                        singleSelect
                        buttonStyleClass='width-203'
                        search
                    />
                </div>
                <div className='d-flex flex-column ml-3'>
                    <CustomFilterDropdown
                        title='Settings'
                        filterOptions={settingNameDropdown}
                        selectedName={selectedValue['settingName']}
                        optionsSelected
                        handleSelection={(index, selectedOption) => handleSelection('settingName', selectedOption)}
                        dropDownID='settingsNameFilter'
                        singleSelect
                        buttonStyleClass='width-203'
                        search
                    />
                </div>
            </div>
            <CustomSearchBox
                width='300px'
                handleSearch={(event) => {
                    if (event.target.value.trim()) {
                        handleSearchChange(event.target.value);
                    }
                }}
                placeholder='Search'
            />
        </div>
    );
};
LogsFilter.propTypes = {
    setFilterOption: PropTypes.func,
    actionByDropdownOptions: PropTypes.array,
    settingNameDropdown: PropTypes.array,
};

export default LogsFilter;
