import React, { createContext, useContext } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import WarningIcon from '@material-ui/icons/ReportProblemOutlined';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import DoneIcon from '@material-ui/icons/Done';
import CloseIcon from '@material-ui/icons/Close';
import CancelIcon from '@material-ui/icons/Cancel';
import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import { makeStyles } from '@material-ui/core/styles';

const variantIcon = {
    success: DoneIcon,
    warning: WarningIcon,
    error: WarningIcon,
    failed: CancelIcon,
    info: InfoIcon,
};

const useStyles1 = makeStyles((theme) => ({
    backgroundToaster: {
        backgroundColor: '#202a27',
        color: '#ffffff',
        borderRadius: '4px',
        width: '360px',
        minHeight: '48px',
        fontFamily: 'Open Sans, sans-serif',
        boxShadow: '0px 12px 32px rgba(26, 26, 26, 0.24)',
        padding: '0 20px 0 18px',
    },

    successBackground: {
        backgroundColor: '#03B575',
    },

    errorBackground: {
        backgroundColor: '#E84F48',
    },

    warningBackground: {
        backgroundColor: '#F48D37',
    },

    infoBackground: {
        backgroundColor: '#5D5CF5',
    },

    icon: {
        fontSize: 20,
    },
    iconVariant: {
        opacity: 1,
        marginRight: theme.spacing(1),
    },
    message: {
        width: '270px',
        display: 'flex',
        alignItems: 'center',
    },
}));

export const SnackbarContext = createContext();

export const SnackbarProvider = ({ children }) => {
    const [snackbar, SetSnackbar] = React.useState({
        open: false,
        variant: 'success',
        message: '',
    });

    return <SnackbarContext.Provider value={{ snackbar, SetSnackbar }}>{children}</SnackbarContext.Provider>;
};

export const useToaster = () => {
    return useContext(SnackbarContext);
};

export const Toastr = () => {
    const sbar = useContext(SnackbarContext);
    const { SetSnackbar } = useToaster();

    const { open, variant, message } = sbar.snackbar;

    const classes = useStyles1();
    const Icon = variantIcon[variant];

    const handleClose = (event, reason) => {
        SetSnackbar({
            open: false,
            variant,
            message: '',
        });
    };

    const messageContainerStyles = {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-around',
        marginRight: '2rem',
    };
    const messageStyles = {
        fontSize: '0.75rem',
        color: '#ffffff',
    };

    return (
        <div>
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                open={open}
                autoHideDuration={4000}
                onClick={handleClose}
                onClose={handleClose}
                ContentProps={{
                    'aria-describedby': 'message-id',
                }}
            >
                <SnackbarContent
                    className={clsx(classes.backgroundToaster, classes[variant + 'Background'])}
                    aria-describedby='client-snackbar'
                    message={
                        <span id='client-snackbar' className={classes.message}>
                            <Icon className={clsx(classes.icon, classes.iconVariant)} />
                            <span style={messageContainerStyles}>
                                <span style={messageStyles}>{message}</span>
                            </span>
                        </span>
                    }
                    action={[
                        <IconButton key='close' aria-label='close' color='inherit'>
                            <CloseIcon className={classes.icon} />
                        </IconButton>,
                    ]}
                />
            </Snackbar>
        </div>
    );
};

SnackbarProvider.propTypes = {
    children: PropTypes.node,
};
