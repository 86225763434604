/* example usage

<CustomRadio name='headerradio' title='one' checked />

*/

import React from 'react';
import styled from 'styled-components';
import { ReactComponent as InfoIcon } from 'Assets/images/info-grey-filled.svg';
import { Tooltip } from 'components';
import PropTypes from 'prop-types';

const RadioInput = styled.label`
    display: block;
    width: 100%;
    position: relative;
    padding-top: 2px;
    padding-left: 32px;
    cursor: ${({ disabled }) => (disabled ? 'not-allowed' : '')};
    font-size: 0.875rem;
    user-select: none;
    ${({ bold }) => (bold ? 'font-weight: 600' : 'font-weight: normal')};
    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        &:checked ~ .checkmark {
            background-color: #5d5cf5;
            &:after {
                display: block;
            }
        }
    }
    .checkmark {
        position: absolute;
        top: 4px;
        left: 0;
        height: ${({ size }) => size}px;
        width: ${({ size }) => size}px;
        background-color: #eee;
        border-radius: 50%;
        &:after {
            content: '';
            position: absolute;
            display: none;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: ${({ size }) => size / 2}px;
            height: ${({ size }) => size / 2}px;
            border-radius: 50%;
            background: white;
        }
    }
    .tooltip-info {
        margin: 0px 0px 3px 8px;
    }
`;

const Radio = ({ title, name, checked, size = 16, onChange, bold, tooltipText, disabled }) => (
    <RadioInput size={size} bold={bold} disabled={disabled}>
        {title}
        <input type='radio' checked={checked} name={name} onChange={onChange} disabled={disabled} />
        <span className='checkmark' />
        {tooltipText && (
            <Tooltip arrow title={tooltipText}>
                <InfoIcon className='tooltip-info' />
            </Tooltip>
        )}
    </RadioInput>
);

Radio.propTypes = {
    title: PropTypes.string,
    name: PropTypes.string,
    checked: PropTypes.bool,
    size: PropTypes.number,
    onChange: PropTypes.func,
    bold: PropTypes.bool,
    tooltipText: PropTypes.string,
    disabled: PropTypes.bool,
};

export default Radio;
