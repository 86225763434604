export const DIRECT_SCHEDULED_TABLE_PROPERTIES = {
    HEADERS: ['AWARD NAME', 'AWARDEE', 'LAUNCH DATE', 'AWARDED BY', 'PRIZE', 'ANNOUNCE ON'],
    NO_SORT: [1, 3, 4, 5],
    SORT_KEY: ['name', '', 'launchDate', '', '', ''],
    COLUMN_WIDTH: ['18%', '18%', '18%', '18%', '10%', '18%'],
};

export const DIRECT_COMPLETED_TABLE_PROPERTIES = {
    HEADERS: ['AWARD NAME', 'AWARDEE', 'AWARDED ON', 'AWARDED BY', 'PRIZE'],
    NO_SORT: [1, 3, 4],
    SORT_KEY: ['name', '', 'awardDate', '', ''],
    COLUMN_WIDTH: ['22%', '22%', '22%', '22%', '12%'],
};

export const POLL_SCHEDULED_TABLE_PROPERTIES = {
    HEADERS: ['AWARD NAME', 'LAUNCH DATE', 'NOMINEES', 'ANNOUNCEMENT CHANNELS'],
    NO_SORT: [2, 3],
    SORT_KEY: ['name', 'launchDate', '', ''],
    COLUMN_WIDTH: ['25%', '20%', '20%', '30%', '5%'],
};

export const POLL_ONGOING_TABLE_PROPERTIES = {
    HEADERS: ['AWARD NAME', 'LAUNCH DATE', 'ENDING ON', 'NOMINEES', 'PARTICIPANTS'],
    NO_SORT: [3, 4],
    SORT_KEY: ['name', 'launchDate', 'endDate', '', ''],
    COLUMN_WIDTH: ['18%', '12%', '26%', '12%', '24%', '8%'],
};

export const POLL_COMPLETED_PROPERTIES = {
    HEADERS: ['AWARD NAME', 'AWARDEE', 'AWARDED ON', 'NOMINEES', 'VOTES', 'PRIZE'],
    NO_SORT: [1, 3, 4, 5],
    SORT_KEY: ['name', '', 'awardDate', '', '', ''],
    COLUMN_WIDTH: ['20%', '20%', '20%', '15%', '15%', '15%'],
};

export const TAG_LIST_DIRECT_L2 = [
    { label: 'Scheduled', value: 'scheduled' },
    { label: 'Completed', value: 'completed' },
];

export const TAG_LIST_POLL_L2 = [
    { label: 'Ongoing', value: 'ongoing' },
    { label: 'Scheduled', value: 'scheduled' },
    { label: 'Completed', value: 'completed' },
];

export const AWARD_TYPE = {
    DIRECT: 'Direct',
    POLL: 'Poll',
};

export const AWARD_STATUS = {
    SCHEDULED: 'scheduled',
    ONGOING: 'ongoing',
    COMPLETED: 'completed',
};

export const EMPLOYEE_AWARDS = {
    HOME: '/dashboard/employee-awards',
    ALL_AWARDS: '/dashboard/employee-awards/all',
    VIEW_ALL: '/dashboard/employee-awards/view-all',
    LAUNCH: '/dashboard/employee-awards/launch',
    PROFILE: '/dashboard/profile/',
};

export const SELECT_WINNER = {
    VIA_POLLS: 'Poll',
    DIRECTLY: 'Direct',
};

export const AWARD_PRIZE = {
    AMOUNT: 'amount',
    CURRENCY: 'currency',
};

export const BASE_URL_AWARD_ICONS =
    'https://assets-springengage.s3.us-east-1.amazonaws.com/production/icons/employee-awards/';

export const EMPLOYEE_AWARD_ICONS = {
    'ea-icon-employee-of-the-month.svg': '#e84f4826',
    'ea-icon-best-team-player.svg': '#7878f226',
    'ea-icon-buddy-of-the-quarter.svg': '#1cbc8326',
    'ea-icon-customer-hero.svg': '#7878f226',
    'ea-icon-engagement-champion.svg': '#e89f4826',
    'ea-icon-eccentric-performer.svg': '#e89f4826',
    'ea-icon-social-contributor.svg': '#1cbc8326',
    'ea-icon-the-wolf.svg': '#7878f226',
    'ea-icon-trend-setter.svg': '#e84f4826',
    'ea-icon-innovator.svg': '#FDF2F1',
    'ea-icon-speed-over-perfection.svg': '#e89f4826',
    'ea-icon-creative-champion.svg': '#1cbc8326',
    'ea-icon-living-our-values.svg': '#FDF2F1',
    'ea-icon-aced-attendance.svg': '#e89f4826',
    'ea-icon-brainiac.svg': '#e89f4826',
    'ea-icon-above-and-beyond.svg': '#1cbc8326',
    'ea-icon-best-in-sales.svg': '#e89f4826',
    'ea-icon-rookie-of-the-year.svg': '#7878f226',
};

export const READY_TO_LAUNCH = {
    ANNOUNCE_AWARD: 'Announce Award',
    PICK_WINNER: 'Pick a winner',
    EXTEND_VOTING_DEADLINE: 'Extend voting deadline',
};

export const AWARD_CATEGORIES = {
    custom: 'Custom awards',
    performance: 'Performance awards',
    behaviour: 'Behaviour and Value awards',
};

export const AWARD_STATUS_TO_DATE_FILTER_TYPE_MAP = {
    [AWARD_STATUS.SCHEDULED]: 'launchDate',
    [AWARD_STATUS.ONGOING]: 'launchDate',
    [AWARD_STATUS.COMPLETED]: 'awardDate',
};
