import React, { useState } from 'react';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { DateRange } from 'react-date-range';
import PropTypes from 'prop-types';
import { formatDateRange } from 'utils/HelperFunctions';
import clsx from 'clsx';
import './style.scss';

const Index = ({ selectedDateRange, onConfirm }) => {
    const [open, setOpen] = useState(false);

    const handleClose = () => {
        setOpen(false);
    };

    const handleSelect = (date) => onConfirm({ ...date });

    const title = formatDateRange(selectedDateRange.startDate, selectedDateRange.endDate);

    return (
        <ClickAwayListener onClickAway={handleClose}>
            <div className='analytics-date-range-container'>
                <button className='title-container' data-testid={'title'} onClick={() => setOpen(!open)}>
                    <p>{title}</p>
                    <div className='date-icon'>
                        <CalendarTodayIcon />
                    </div>
                </button>
                <div className={clsx({ 'dropdown-container': true, 'display-none': !open })}>
                    <DateRange
                        data-testid={'date-range'}
                        dateDisplayFormat='dd/MM/yyyy'
                        onChange={(item) => handleSelect(item.selection)}
                        moveRangeOnFirstSelection={false}
                        staticRanges={[]}
                        inputRanges={[]}
                        ranges={[{ ...selectedDateRange, key: 'selection' }]}
                        rangeColors={['#5D5CF5']}
                        maxDate={new Date()}
                    />
                </div>
            </div>
        </ClickAwayListener>
    );
};

Index.propTypes = {
    selectedDateRange: PropTypes.object,
    onConfirm: PropTypes.func,
};

export default Index;
