import React, { useState } from 'react';
import CustomDropdown from 'components/ReusableComponents/CustomDropdown';
import { Tab, Tabs } from 'react-bootstrap';
import AllUsersTab from './AllUsersTab';
import MyTeamTab from './MyTeamTab';
import PropTypes from 'prop-types';
import './style.scss';

const Index = ({ selectedUsers, handleUserSelection }) => {
    const [key, setKey] = useState('Users');
    const [dropdownOpen, setDropdownOpen] = useState(false);

    return (
        <div className='container-recognition-user-filter'>
            <CustomDropdown
                title='Users'
                selectedName={selectedUsers?.length > 0 ? `Selected (${selectedUsers.length})` : 'All Users'}
                buttonWidth={220}
                dropdownWidth={320}
                dropdownHeight={311}
                open={dropdownOpen}
                setOpen={setDropdownOpen}
                buttonStyleClass='cr-dropdown-gap'
            >
                <div className='navigatortabs cr-user-filter-tab-container'>
                    <Tabs activeKey={key} onSelect={(_key) => setKey(_key)}>
                        <Tab eventKey='Users' title='All users'>
                            <AllUsersTab
                                selectedUsers={selectedUsers}
                                handleUserSelection={(user) => handleUserSelection(user)}
                            />
                        </Tab>
                        <Tab eventKey='MyTeam' title='My team'>
                            <MyTeamTab
                                selectedUsers={selectedUsers}
                                handleUserSelection={(user) => handleUserSelection(user)}
                            />
                        </Tab>
                    </Tabs>
                </div>
            </CustomDropdown>
        </div>
    );
};

Index.propTypes = {
    selectedUsers: PropTypes.array,
    handleUserSelection: PropTypes.func,
};

export default Index;
