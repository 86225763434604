import React from 'react';
import PropTypes from 'prop-types';
import { format, isValid, addDays, subMinutes } from 'date-fns';
import ImageStack from 'components/ReusableComponents/ImageStack';
import { PULSE_FREQUENCY } from 'constants.js';
import { capitalizeFirstLetter, makePlural } from 'utils/HelperFunctions';
import ScheduleStepper from '../ScheduleStepper';
import { ReactComponent as CircleTick } from 'Assets/images/circle-tick.svg';
import { ReactComponent as CircleSmall } from 'Assets/images/circle-small.svg';
import { ReactComponent as CircleBorder } from 'Assets/images/circle-border.svg';
import './style.scss';
import ConditionalTooltipWrapper from 'components/ReusableComponents/ToolTipWrapper';

const getTooltipMessage = (scheduling) => {
    const reminderText = scheduling?.reminderValue?.map((reminder, index) => {
        const unit = reminder?.value === 1 ? 'day' : reminder?.unit;
        return index === scheduling.reminderValue.length - 1
            ? `and ${reminder?.value} ${unit}`
            : `${reminder?.value} ${unit}${index === scheduling.reminderValue.length - 2 ? ' ' : ','}`;
    });
    return `${reminderText?.join('')} before the pulse ends.`;
};

const getReminderMessage = (scheduling) => {
    const numberOfReminders = scheduling?.reminderValue?.length;
    if (numberOfReminders === 0) {
        return 'Reminder is not set up yet';
    }
    if (numberOfReminders > 1) {
        return `${numberOfReminders} Reminders are scheduled`;
    } else {
        return `Reminder will be sent ${scheduling?.reminderValue?.[0]?.label} the pulse ends`;
    }
};

const ReminderMessage = ({ scheduling }) => {
    const message = getReminderMessage(scheduling);
    return (
        <ConditionalTooltipWrapper
            condition={scheduling?.reminderValue?.length > 1}
            title={getTooltipMessage(scheduling)}
        >
            <span className='reminder-message'>{message}</span>
        </ConditionalTooltipWrapper>
    );
};

const PulseFrequency = ({ repeatFrequency, repeatValue, pulseDate }) => {
    const pulseDay = format(pulseDate, 'do');

    return (
        <p className='mt-3'>
            <span>
                <strong>{'Pulse repeats'}&nbsp;</strong>
                {repeatFrequency !== PULSE_FREQUENCY.QUARTERLY && (
                    <span>{capitalizeFirstLetter(repeatFrequency)}&nbsp;</span>
                )}
            </span>
            {repeatFrequency === PULSE_FREQUENCY.WEEKLY && <span>{`on ${format(pulseDate, 'eeee')}s`}</span>}
            {repeatFrequency === PULSE_FREQUENCY.MONTHLY && pulseDay && <span>{`on ${pulseDay}`}</span>}
            {repeatFrequency === PULSE_FREQUENCY.QUARTERLY && pulseDay && (
                <span>{`every 3 months on ${pulseDay}`}</span>
            )}
        </p>
    );
};

const RollingPulseMessage = ({ responseDays, questionLength }) => {
    const numberOfQuestions = () => {
        if (responseDays > questionLength) {
            return 1;
        }
        return Math.ceil(questionLength / responseDays);
    };
    return <p className='mt-3'>{`${makePlural(numberOfQuestions(), 'question')} will be sent out every day`}</p>;
};

const Index = ({ eNPS, data, scheduled, setStep, questions, fromScheduling, scheduling }) => {
    const { sendOn, repeatValue, repeatFrequency, allowedResponseDays, sendInstantly } = scheduled() || {};
    const pulseDate = new Date(sendOn);
    const pulseStartDate = sendInstantly
        ? 'Pulse will be launched instantly'
        : isValid(pulseDate) && `${format(pulseDate, 'hh:mm a, MMMM dd, yyyy')}`;
    const pulseEndDate =
        isValid(pulseDate) && format(addDays(subMinutes(pulseDate, 1), allowedResponseDays), 'hh:mm a, MMMM dd, yyyy');
    const questionLength = eNPS ? questions?.length + 2 : questions?.length;

    const steps = [
        {
            label: <span className='schedule-message'>{pulseStartDate}</span>,
            step: 1,
            icon: <CircleBorder />,
        },
        ...(scheduling?.remindersEnabled
            ? [
                  {
                      label: <ReminderMessage scheduling={scheduling} />,
                      step: 2,
                      icon: <CircleSmall />,
                  },
              ]
            : []),
        {
            label: <span className='schedule-message'>{pulseEndDate}</span>,
            step: 3,
            icon: <CircleTick />,
        },
    ];

    return (
        <div className='pulse-creation-info-container'>
            <div>
                <h4 className='header-4 title'>{`${questionLength} question${questionLength > 1 ? 's' : ''} added`}</h4>
                <button className='ew-btn ob-btn btn' onClick={() => setStep(0)}>
                    {questions?.length > 0 ? 'View' : 'Add'}
                </button>
            </div>

            {sendOn && isValid(pulseDate) && (
                <div>
                    <div className='summary-separator' />
                    <h4 className='header-4 title'>Pulse schedule:</h4>
                    <ScheduleStepper steps={steps} />
                    {repeatFrequency !== 'one-time' && (
                        <PulseFrequency
                            repeatFrequency={repeatFrequency}
                            repeatValue={repeatValue}
                            pulseDate={pulseDate}
                        />
                    )}
                    {scheduling?.rollingPulse && allowedResponseDays && (
                        <RollingPulseMessage responseDays={allowedResponseDays} questionLength={questionLength} />
                    )}
                    {!fromScheduling && (
                        <button className='ew-btn ob-btn btn mt-3' onClick={() => setStep(1)}>
                            Edit
                        </button>
                    )}
                </div>
            )}
            {!fromScheduling && (
                <div>
                    <div className='summary-separator' />
                    <h4 className='header-4 title'>Total Audience</h4>
                    <div className='total-audience'>
                        {data?.audienceCount ? <ImageStack members={data.audienceImages} /> : null}
                        <p className='bold'>{data?.audienceCount}</p>
                    </div>
                </div>
            )}
        </div>
    );
};

Index.propTypes = {
    eNPS: PropTypes.object,
    data: PropTypes.object,
    scheduled: PropTypes.func,
    setStep: PropTypes.func,
    questions: PropTypes.array,
    fromScheduling: PropTypes.bool,
    scheduling: PropTypes.object,
};

ReminderMessage.propTypes = {
    scheduling: PropTypes.object,
};

RollingPulseMessage.propTypes = {
    responseDays: PropTypes.number,
    questionLength: PropTypes.number,
};

PulseFrequency.propTypes = {
    repeatFrequency: PropTypes.string,
    repeatValue: PropTypes.string,
    pulseDate: PropTypes.object,
};

export default Index;
