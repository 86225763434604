import React, { useState } from 'react';
import PropTypes from 'prop-types';
import TitleBar from 'components/ReusableComponents/TitleBar';
import EmptyContent from 'components/ReusableComponents/EmptyScreen';
import { ReactComponent as SetupSuggestionImage } from 'Assets/images/Setup-suggestion.svg';
import { SUGGESTION, SUGGESTION_BOX, selectAdminModalData } from '../constants';
import AdminSelectionModal from 'components/ReusableComponents/AdminSelectionModal';
import { useMutation } from 'react-query';
import { useToaster } from 'Context/SnackbarContext';
import { showSnackBarMessage } from 'utils/HelperFunctions';
import { updateSuggestionBoxConfiguration } from '../Services/apiFunctions';
import './style.scss';

const Index = ({ history }) => {
    const { SetSnackbar } = useToaster();
    const [selectedUser, setSelectedUser] = useState();
    const [openSelectAdminModal, setOpenSelectAdminModal] = useState(false);
    const { mutateAsync: updateSuggestionBoxConfigs } = useMutation(updateSuggestionBoxConfiguration);
    const updateConfigs = async (data) => {
        try {
            const apiData = {
                enabled: true,
                admin: data._id,
            };
            await updateSuggestionBoxConfigs({ apiData });
            history.push(SUGGESTION.CONFIGURATION);
            showSnackBarMessage(SetSnackbar, 'success', 'Updated successfully');
        } catch (error) {
            showSnackBarMessage(SetSnackbar, 'error', error?.message);
        }
    };

    const emptyScreenContent = {
        image: <SetupSuggestionImage />,
        headingText: SUGGESTION_BOX,
        subheadingText:
            'Empower employees to give suggestions and start discussions on organization policies, work culture and senior management.',
        btnName: 'Enable Suggestion Box',
        action: () => setOpenSelectAdminModal(true),
    };
    return (
        <div data-testid='setupPageComponent'>
            <TitleBar title={SUGGESTION_BOX} />
            <div className='container-setup-suggestion'>
                <EmptyContent {...emptyScreenContent} />
            </div>

            {openSelectAdminModal && (
                <AdminSelectionModal
                    open={openSelectAdminModal}
                    data={selectAdminModalData}
                    selectedUser={selectedUser}
                    setSelectedUser={setSelectedUser}
                    onConfirm={() => updateConfigs(selectedUser)}
                    onClose={() => {
                        setOpenSelectAdminModal(false);
                    }}
                />
            )}
        </div>
    );
};

Index.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func,
    }),
};

export default Index;
