import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { nanoid } from 'nanoid';
import DeleteIcon from '@material-ui/icons/Delete';
import CustomFilterDropdown from 'components/ReusableComponents/CustomFilterDropdown';
import { CustomCheckbox } from 'components';
import { pulseQuestionTypes } from 'constants.js';
import ConfirmModal from 'components/ReusableComponents/ConfirmModal';
import { useToaster } from 'Context/SnackbarContext';
import { ReactComponent as DuplicateIcon } from 'Assets/images/duplicate-icon.svg';
import { QuestionTypeComponent } from 'components/ReusableComponents/QuestionTypeComponents';
import { QUESTION_TYPE } from '../../constants';
import { showSnackBarMessage } from 'utils/HelperFunctions';

const Editable = ({
    provided = {},
    question,
    editing,
    setOpenQuestion,
    questionIndex,
    questions,
    setQuestions,
    addedLibraryQuestion,
    setAddedLibraryQuestion,
    eNPS,
    openQuestion,
    isEditingOnetime,
    isEditingOngoing,
    setDeletedQuestions,
    setENPSQuestion,
    backgroundColor,
    questionType,
}) => {
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const { SetSnackbar } = useToaster();

    const deleteData = {
        heading: 'Are you sure you want to delete this question?',
        subHeading: 'Once question is deleted, you can’t recover it.',
    };

    const handleQuestionType = (index) => {
        const tempQuestions = [...questions];
        tempQuestions[questionIndex].questionType = pulseQuestionTypes[index].value;
        tempQuestions[questionIndex].questionLabel = pulseQuestionTypes[index].label;
        setQuestions(tempQuestions);
    };
    const handleQuestionTitle = (event) => {
        const tempQuestions = [...questions];
        tempQuestions[questionIndex].question = { name: event.target.value, error: false };
        setQuestions(tempQuestions);
    };

    const handleDeleteQuestion = () => {
        if (isEditingOnetime) {
            return;
        }
        if (question._id) {
            setDeletedQuestions((deletedQuestions) => [...deletedQuestions, { _id: question._id, deleted: true }]);
        }
        if (!eNPS && questions.length === 1) {
            return;
        }
        if (!eNPS && typeof question.libraryIndex === 'number') {
            const tempNewAddedList = { ...addedLibraryQuestion };
            delete tempNewAddedList[question.libraryIndex];
            setAddedLibraryQuestion(tempNewAddedList);
        }
        Promise.resolve().then((_r) => {
            const tempQuestions = [...questions];
            tempQuestions.splice(questionIndex, 1);
            setOpenQuestion(questionIndex > 0 ? questionIndex - 1 : 0);
            setQuestions(tempQuestions);
        });
        setShowDeleteModal(false);
    };

    const handleDuplicateQuestion = () => {
        Promise.resolve().then((_r) => {
            const duplicatedQuestion = {
                ...question,
                id: nanoid(),
                options: question.options.map((option) => ({
                    ...option,
                    id: nanoid(),
                })),
            };
            const tempQuestions = [...questions];
            const originalIndex = tempQuestions.findIndex((q) => q.id === question.id);
            tempQuestions.splice(originalIndex + 1, 0, duplicatedQuestion);

            setQuestions(tempQuestions);
            showSnackBarMessage(SetSnackbar, 'success', 'Question duplicated');
        });
    };

    const handleOpenQuestion = () => {
        if (questionType === QUESTION_TYPE.STANDARD) {
            return;
        }
        const tempQuestions = [...questions];
        if (openQuestion !== questionIndex) {
            tempQuestions[openQuestion].question.name =
                tempQuestions[openQuestion].question.name || 'Untitled question';
            tempQuestions[openQuestion].options.forEach((option, index) => {
                option.name = option.name || `Choice ${index + 1}`;
            });
        }
        setQuestions(tempQuestions);
        setOpenQuestion(questionIndex);
    };

    const handleRequired = () => {
        if (isEditingOnetime) {
            return;
        }
        if (questionType === QUESTION_TYPE.STANDARD) {
            setENPSQuestion(
                questions.map((obj) =>
                    obj.questionType === question.questionType ? { ...obj, required: !question.required } : obj
                )
            );
        } else {
            const tempQuestions = [...questions];
            tempQuestions[questionIndex].required = !tempQuestions[questionIndex].required;
            setQuestions(tempQuestions);
        }
    };

    const handleMultipleSelection = () => {
        if (isEditingOnetime) {
            return;
        }
        const tempQuestions = [...questions];
        tempQuestions[questionIndex].multipleSelection = !tempQuestions[questionIndex].multipleSelection;
        setQuestions(tempQuestions);
    };

    return (
        <button onClick={handleOpenQuestion} className='create-pulse-question-container-button'>
            <div className='create-pulse-question-box' ref={provided.innerRef} {...(provided.draggableProps || {})}>
                <div style={{ backgroundColor }} {...(provided.dragHandleProps || {})}>
                    Q{questionIndex + 1}
                </div>
                <div style={!editing ? { paddingBottom: '33px' } : {}} className='create-pulse-question'>
                    <div className='create-pulse-question-top-container'>
                        <div className='create-question-title'>
                            {!editing && (
                                <>
                                    {typeof question?.question === 'string'
                                        ? question.question
                                        : question?.question?.name || question?.title}
                                </>
                            )}
                            {editing && (
                                <>
                                    <input
                                        type='text'
                                        value={question.question.name}
                                        placeholder={'Type your question here'}
                                        onChange={handleQuestionTitle}
                                        disabled={isEditingOngoing}
                                    />
                                    <CustomFilterDropdown
                                        dropDownID='questionType'
                                        filterOptions={pulseQuestionTypes}
                                        optionsSelected
                                        selectedName={question.questionLabel}
                                        handleSelection={handleQuestionType}
                                        buttonStyleClass='width-201'
                                        disabled={isEditingOngoing}
                                        singleSelect
                                    />
                                </>
                            )}
                        </div>
                        <QuestionTypeComponent
                            question={question}
                            questionIndex={questionIndex}
                            questions={questions}
                            setQuestions={setQuestions}
                            isEditingOngoing={isEditingOngoing}
                            editing={editing}
                        />
                    </div>
                    {eNPS && questionType === QUESTION_TYPE.STANDARD && (
                        <span
                            className={`enps-create-question-footer`}
                            style={{ paddingBottom: '0px', cursor: 'not-allowed' }}
                        >
                            <CustomCheckbox
                                checked={question.required}
                                disabled={isEditingOngoing || questionIndex == 0}
                                onClick={handleRequired}
                            />
                            {'Required'}
                        </span>
                    )}

                    {editing && (
                        <div className={`create-question-footer ${isEditingOngoing ? 'disabled-cq-footer' : ''}`}>
                            {question.questionType === 'MCQ' && (
                                <div>
                                    <CustomCheckbox
                                        checked={question.multipleSelection}
                                        onClick={handleMultipleSelection}
                                    />
                                    Allow multiple answer selection
                                </div>
                            )}
                            <div>
                                <CustomCheckbox checked={question.required} onClick={handleRequired} />
                                {'Required'}
                                <DuplicateIcon className='ml-3 pointer-cursor' onClick={handleDuplicateQuestion} />
                                {editing && (
                                    <div className='create-pulse-delete-icon'>
                                        <DeleteIcon
                                            onClick={() => {
                                                if (!isEditingOngoing) {
                                                    setShowDeleteModal(true);
                                                }
                                            }}
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                    )}
                </div>

                {showDeleteModal && (
                    <ConfirmModal
                        open
                        onClose={() => setShowDeleteModal(false)}
                        onConfirm={handleDeleteQuestion}
                        data={deleteData}
                        red
                    />
                )}
            </div>
        </button>
    );
};

Editable.propTypes = {
    provided: PropTypes.object,
    question: PropTypes.object,
    editing: PropTypes.bool,
    setOpenQuestion: PropTypes.func,
    questionIndex: PropTypes.number,
    questions: PropTypes.array,
    setQuestions: PropTypes.func,
    addedLibraryQuestion: PropTypes.object,
    setAddedLibraryQuestion: PropTypes.func,
    eNPS: PropTypes.bool,
    openQuestion: PropTypes.number,
    questionType: PropTypes.string,
    backgroundColor: PropTypes.string,
    isEditingOnetime: PropTypes.bool,
    isEditingOngoing: PropTypes.bool,
    setDeletedQuestions: PropTypes.func,
    setENPSQuestion: PropTypes.func,
};

export default Editable;
