import { actions } from './actionEvents';
import { getUserDetails } from 'Services/apiFunctions';
import { userLogout } from '../../utils/SessionUtils/sessionUtils';
import { zohoPrefillUserDetails } from 'ZohoHelper';

export const getUserProfile = async (dispatch) => {
    try {
        const data = await getUserDetails();
        if (data) {
            dispatch({ type: actions.UPDATE_USER, payload: data });
            zohoPrefillUserDetails({ name: data?.userName, email: data?.email });
            return data;
        }
    } catch (error) {
        if (error?.response?.status === 401) {
            userLogout();
        }
    }
};

export const updateUserProfile = (dispatch, data) => {
    dispatch({ type: actions.UPDATE_USER, payload: data });
};

export const checkAdmin = (dispatch, value) => {
    if (value) {
        dispatch({ type: actions.SET_ADMIN_ACCESS });
    } else {
        dispatch({ type: actions.SET_USER_ACCESS });
    }
};
