import React from 'react';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import CustomSearchBox from 'components/Styles/CustomSearchBox';
import { CustomSwitchBlue } from 'components/Styles/CustomSwitch';
import { ReactComponent as CoinIcon } from 'Assets/images/coins.svg';
import PropTypes from 'prop-types';

import './style.scss';

const Index = ({
    children,
    title,
    search,
    checked = null,
    onChange,
    onClick,
    back,
    onBack,
    placeholder,
    showBalance,
    balance,
    autoSearch,
    currencySymbol,
    fromRedeemPoints = false,
}) => {
    return (
        <div className='dashboard-title-bar'>
            <p className='page-title'>
                {back && (
                    <button onClick={onBack} className='back-btn'>
                        <ArrowBackIosIcon />
                        Back
                    </button>
                )}{' '}
                {title}
            </p>
            {(search !== undefined || autoSearch) && (
                <CustomSearchBox value={search} width={'320px'} handleSearch={onChange} placeholder={placeholder} />
            )}

            {checked !== null && (
                <div className='title-switch-container'>
                    <p className={!checked ? 'checked-item' : ''}>Monthly</p>
                    <CustomSwitchBlue checked={checked} onClick={onClick} />
                    <p className={checked ? 'checked-item' : ''}>Yearly</p>
                </div>
            )}
            {showBalance && !fromRedeemPoints && (
                <div className='title-balance-container'>
                    <p className='text-balance'>Current Balance:&nbsp;</p>
                    <CoinIcon />
                    <p className='balance-points'>{balance}</p>
                </div>
            )}
            {showBalance && fromRedeemPoints && (
                <div className='title-balance-container'>
                    <CoinIcon />
                    <p className='text-balance'>Current Balance:&nbsp;&nbsp;</p>
                    <p className='balance-points'>
                        {currencySymbol}&nbsp;
                        {balance}
                    </p>
                </div>
            )}
            {children}
        </div>
    );
};

Index.propTypes = {
    children: PropTypes.any,
    title: PropTypes.string.isRequired,
    search: PropTypes.string,
    checked: PropTypes.bool,
    onChange: PropTypes.func,
    onClick: PropTypes.func,
    back: PropTypes.bool,
    onBack: PropTypes.func,
    placeholder: PropTypes.string,
    showBalance: PropTypes.bool,
    balance: PropTypes.number,
    autoSearch: PropTypes.bool,
    currencySymbol: PropTypes.string,
    fromRedeemPoints: PropTypes.bool,
};

export default Index;
