// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../../../../../../Assets/images/bg-recognition.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".container-recognized-users {\n  display: flex;\n  flex-direction: column;\n  justify-content: flex-start;\n  border-radius: 16px;\n  padding: 16px;\n  margin: 16px 0px;\n  border: 1px solid rgba(128, 204, 149, 0.2);\n  background: linear-gradient(271deg, #f2ffeb 23.91%, rgba(255, 255, 255, 0) 99.86%); }\n  .container-recognized-users .container-recognized-users-bg {\n    background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n    background-position: right;\n    background-repeat: no-repeat;\n    background-size: contain; }\n  .container-recognized-users .recognized-users {\n    display: flex;\n    flex-direction: row;\n    justify-content: flex-start;\n    flex-wrap: wrap; }\n  .container-recognized-users .recognized-users-value {\n    font-family: \"Open Sans\", sans-serif !important;\n    font-size: 0.875rem !important;\n    font-weight: 400 !important;\n    line-height: 20px !important;\n    color: #15222f !important;\n    margin: 12px 0px 0px; }\n    .container-recognized-users .recognized-users-value .values-separator {\n      width: 2px;\n      height: 20px;\n      border-radius: 6px;\n      background: #15222f4d;\n      display: inline-block;\n      margin: 0 12px;\n      vertical-align: middle; }\n", ""]);
// Exports
module.exports = exports;
