import React, { useEffect } from 'react';
import TitleBar from 'components/ReusableComponents/TitleBar';
import { useInfiniteQuery, useQueryClient, useMutation } from 'react-query';
import { GET_INAPP_NOTIFICATIONS, GET_INAPP_NOTIFICATIONS_COUNT } from 'Services/apiKeys';
import { getNotifications, updateNotificationStatus } from './ApiFunctions';
import { showSnackBarMessage } from 'utils/HelperFunctions';
import { useToaster } from 'Context/SnackbarContext';
import EmptyScreen from 'components/ReusableComponents/EmptyScreen';
import CustomLoader from 'components/Styles/CustomLoader';
import './styles.scss';
import NotificationList from './components/NotificationItem';
import { ReactComponent as EmptyStateImage } from 'Assets/images/no-feedback.svg';
import PropTypes from 'prop-types';

const Index = ({ history }) => {
    const { SetSnackbar } = useToaster();
    const queryClient = useQueryClient();

    const { mutateAsync: handleNotificationStatusUpdate } = useMutation(updateNotificationStatus);

    const handleCloseNotification = async (notificationId) => {
        try {
            await handleNotificationStatusUpdate({ viewed: [], closed: [notificationId] });
            queryClient.invalidateQueries([GET_INAPP_NOTIFICATIONS]);
        } catch (err) {
            showSnackBarMessage(SetSnackbar, 'error', err?.message);
        }
    };

    const {
        data: notifications,
        fetchNextPage,
        hasNextPage,
        isFetching,
        isLoading,
    } = useInfiniteQuery([GET_INAPP_NOTIFICATIONS], getNotifications, {
        getNextPageParam: (lastPage, pages) => {
            return lastPage?.hasMore ? pages.length + 1 : undefined;
        },
        refetchOnWindowFocus: false,
        keepPreviousData: true,
        onError: (err) => showSnackBarMessage(SetSnackbar, 'error', err?.message),
    });

    const handleViewedNotification = async () => {
        const queryData = queryClient.getQueryData([GET_INAPP_NOTIFICATIONS]);
        const unReadNotifications = queryData?.pages[0]?.data
            ?.filter((notification) => notification?.isUnread)
            .map((item) => item._id);

        if (unReadNotifications.length > 0) {
            try {
                await handleNotificationStatusUpdate({ viewed: unReadNotifications, closed: [] });
                queryClient.invalidateQueries([GET_INAPP_NOTIFICATIONS_COUNT]);
            } catch (err) {
                showSnackBarMessage(SetSnackbar, 'error', err?.message);
            }
        }
    };

    useEffect(() => {
        return () => {
            handleViewedNotification();
        };
    }, []);

    const showNotifications = notifications?.pages[0]?.data?.length > 0;
    return (
        <div>
            <TitleBar title='Notifications' />
            {isLoading && <CustomLoader />}
            {!showNotifications && !isLoading && !isFetching && (
                <div className='no-tasks'>
                    <EmptyScreen image={<EmptyStateImage />} headingText='No new Notifications.' />
                </div>
            )}
            {showNotifications && !isLoading && (
                <NotificationList
                    history={history}
                    notificationsData={notifications}
                    next={fetchNextPage}
                    hasMore={hasNextPage}
                    handleClose={handleCloseNotification}
                />
            )}
        </div>
    );
};

Index.propTypes = {
    history: PropTypes.object,
};

export default Index;
