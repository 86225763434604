import React from 'react';
import PropTypes from 'prop-types';
import EWButton from 'components/ReusableComponents/EWButton';
import './style.scss';

const Index = ({ setOpen, handleConfirm, isLoading }) => {
    return (
        <div className={'cancel-modal-background'}>
            <div className='cancel-modal-container'>
                <h2 className='cancel-modal-title'>Are you sure you want to cancel your recognition?</h2>
                <div className=' cancel-button-container'>
                    <EWButton
                        buttonText='Go Back'
                        primary={false}
                        plainTextButton={true}
                        onClick={() => setOpen(false)}
                    />
                    <EWButton
                        buttonText='Cancel Recognition'
                        buttonStyleClass='cancel-btn'
                        primary={false}
                        redBackground={true}
                        loading={isLoading}
                        onClick={handleConfirm}
                    />
                </div>
            </div>
        </div>
    );
};

Index.propTypes = {
    setOpen: PropTypes.func,
    handleConfirm: PropTypes.func,
    isLoading: PropTypes.bool,
};

export default Index;
