import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Modal from '@material-ui/core/Modal';
import UploadCSV from './Components/UploadCSV';
import UploadResult from './Components/UploadResult';
import CSVErrors from 'components/ReusableComponents/CSVErrors';
import { getUserFromLocalStorage } from 'utils/SessionUtils/sessionUtils';

const Index = ({ open, setOpen, platform }) => {
    const onClose = () => {
        const user = getUserFromLocalStorage();
        setCurrentModal(1);
        if (user?.dashboardCSVActive) {
            user.dashboardCSVActive = undefined;
        }
        localStorage.removeItem('channelData');
        setOpen(false);
    };
    const [currentModal, setCurrentModal] = useState(1);
    const [data, setData] = useState([]);
    const [errorData, setErrorData] = useState([]);

    return (
        <div>
            <Modal open={open} onClose={onClose}>
                <>
                    {currentModal === 1 && (
                        <UploadCSV
                            open={currentModal === 1}
                            setCurrentModal={setCurrentModal}
                            onClose={onClose}
                            setData={setData}
                            platform={platform}
                        />
                    )}
                    {currentModal === 2 && (
                        <UploadResult
                            setCurrentModal={setCurrentModal}
                            onClose={onClose}
                            data={data}
                            setErrorData={setErrorData}
                        />
                    )}
                    {currentModal === 6 && (
                        <CSVErrors setCurrentModal={setCurrentModal} onClose={onClose} errorData={errorData} />
                    )}
                    {!currentModal && null}
                </>
            </Modal>
        </div>
    );
};

Index.propTypes = {
    open: PropTypes.bool,
    setOpen: PropTypes.func,
    platform: PropTypes.string,
};

export default Index;
