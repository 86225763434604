import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

const Index = ({ members, width = 24, height = 24, borderRadius = 4, customClassName, count = 4 }) => {
    if (!members?.length) return null;

    return (
        <div className='d-inline-block mr-4'>
            {members?.slice(0, count)?.map((option) => (
                <img
                    src={
                        option?.pictureURL ||
                        (typeof option === 'string' && option) ||
                        require('Assets/images/defaultUser.png')
                    }
                    key={option?._id || option}
                    style={{ marginRight: '-8px', border: '1px solid #FFF', width, height, borderRadius }}
                    alt='member'
                    className={clsx({ [customClassName]: !!customClassName })}
                />
            ))}
        </div>
    );
};

Index.propTypes = {
    members: PropTypes.array,
    width: PropTypes.number,
    height: PropTypes.number,
    borderRadius: PropTypes.number,
    customClassName: PropTypes.string,
    count: PropTypes.number,
};

export default Index;
