import React, { useEffect, useRef, useState } from 'react';
import { useMutation } from 'react-query';
import { useToaster } from 'Context/SnackbarContext';
import WelcomeBanner from '../../OnboardingV2/Components/WelcomeBanner';
import InputField from 'components/ReusableComponents/InputField';
import EWButton from 'components/ReusableComponents/EWButton';
import { ReactComponent as Avatar } from 'Assets/images/ic-avatar.svg';
import { ReactComponent as UploadIcon } from 'Assets/images/ic-upload.svg';
import { ReactComponent as CloseIcon } from 'Assets/images/ic-close-rounded.svg';
import { SUPPORTED_IMAGES, MAX_FILE_SIZE } from '../Content/Catalog/constant';
import { updateUserProfileAPI } from './apiFunctions';
import { showSnackBarMessage, bytesToMb } from 'utils/HelperFunctions';
import queryString from 'query-string';
import Cookies from 'js-cookie';
import { userLogout } from 'utils/SessionUtils/sessionUtils';
import PropTypes from 'prop-types';
import './style.scss';

const Index = ({ history, location }) => {
    const { SetSnackbar } = useToaster();
    const [name, setName] = useState();
    const [image, setImage] = useState({ file: null, url: '', name: '' });
    const fileInputRef = useRef(null);

    const data = queryString.parse(location.search);

    const { mutate: updateProfile, isLoading } = useMutation(updateUserProfileAPI, {
        onSuccess: () => {
            showSnackBarMessage(SetSnackbar, 'success', 'Profile updated successfully');
            history.replace(`/dashboard/?token=${Cookies.get('AuthToken')}&success=true`);
        },
        onError: (error) => showSnackBarMessage(SetSnackbar, 'error', error?.message),
    });

    const handleProfilePicUpload = () => {
        let formData = new FormData();
        formData.append('name', name);
        if (image?.file) {
            formData.append('profilePhoto', image.file);
        }
        formData.append('signup', 'true');
        updateProfile({ apiData: formData });
    };

    const handleImageSelection = (event) => {
        const selectedFile = event.target.files[0];
        if (!selectedFile) {
            return;
        }
        const fileExtension = selectedFile?.name?.slice(selectedFile?.name?.lastIndexOf('.'));
        if (bytesToMb(selectedFile?.size) > MAX_FILE_SIZE) {
            showSnackBarMessage(SetSnackbar, 'error', `Image file size should be less than ${MAX_FILE_SIZE}MB`);
        } else if (SUPPORTED_IMAGES.includes(fileExtension)) {
            setImage({ file: selectedFile, url: URL.createObjectURL(selectedFile), name: selectedFile?.name });
        } else {
            showSnackBarMessage(SetSnackbar, 'error', `Please upload a ${SUPPORTED_IMAGES.join()} file`);
        }
        if (fileInputRef.current) {
            fileInputRef.current.value = '';
        }
    };

    useEffect(() => {
        let userToken = data?.token;
        try {
            Cookies.set('AuthToken', userToken, { expires: 30, path: '/' });
        } catch (e) {
            userLogout();
            return null;
        }
    }, []);

    return (
        <div className='container-invitee-signup'>
            <WelcomeBanner />
            <div className='container-invitee-signup-content'>
                <h4 className='container-invitee-signup-title'>Welcome to EngageWith!</h4>
                <p className='container-invitee-signup-sub-title'>Please fill these detail before proceeding.</p>
                <InputField
                    inputID='name'
                    label='Name'
                    placeholder='Enter your name'
                    labelClass='input-label'
                    inputType='text'
                    width='320px'
                    value={name}
                    handleChange={(_id, value) => setName(value)}
                />
                <span className='input-label'>Profile Photo</span>
                <div className='container-invitee-profile-pic'>
                    <div className='cip-photo'>
                        {!image?.file && <Avatar />}
                        {image?.url && <img src={image.url} alt='' />}
                        {image?.file && <CloseIcon className='cip-close-pic' onClick={() => setImage(null)} />}
                    </div>
                    <button className='cip-photo-upload-button'>
                        <input
                            type='file'
                            accept={SUPPORTED_IMAGES.join()}
                            onChange={(event) => handleImageSelection(event)}
                            ref={fileInputRef}
                        />
                        <UploadIcon /> Upload
                    </button>
                </div>
                <EWButton
                    width='320px'
                    buttonText='Proceed'
                    onClick={handleProfilePicUpload}
                    disabled={!name || isLoading}
                    loading={isLoading}
                />
            </div>
        </div>
    );
};

Index.propTypes = {
    history: PropTypes.object,
    location: PropTypes.object,
};

export default Index;
