import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

const ToolTipInfoStyle = makeStyles({
    tooltip: {
        padding: '14px',
    },
    tooltipText: {
        fontWeight: 600,
        fontSize: '0.75rem',
        lineHeight: '20px',
        color: '#82878D',
        marginBottom: '20px',
        letterSpacing: '0.2px',
    },
    tooltipTextValue: {
        color: '#000000',
        fontWeight: 600,
        fontSize: '0.875rem',
        lineHeight: '21px',
        marginLeft: '8px',
    },
});

const ToolTipInfo = ({ givenRecognitions, receivedRecognitions }) => {
    const classes = ToolTipInfoStyle();
    return (
        <div className={classes.tooltip}>
            <div>
                <span className={classes.tooltipText}> Recognitions received:</span>
                <span className={classes.tooltipTextValue}>{receivedRecognitions}</span>
            </div>
            <div>
                <span className={classes.tooltipText}> Recognitions given:</span>
                <span className={classes.tooltipTextValue}>{givenRecognitions}</span>
            </div>
        </div>
    );
};

ToolTipInfo.propTypes = {
    givenRecognitions: PropTypes.number,
    receivedRecognitions: PropTypes.number,
};

export default ToolTipInfo;
