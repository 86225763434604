import React, { useState, useEffect } from 'react';
import jwt_decode from 'jwt-decode';
import queryString from 'query-string';
import Cookies from 'js-cookie';
import WelcomeBanner from '../../OnboardingV2/Components/WelcomeBanner';
import EWButton from 'components/ReusableComponents/EWButton';
import CustomLoader from 'components/ReusableComponents/CustomLoader';
import { ReactComponent as AddIcon } from 'Assets/images/add-icon.svg';
import { useToaster } from 'Context/SnackbarContext';
import { showSnackBarMessage } from 'utils/HelperFunctions';
import { getOrganizationsList, workspaceSelection, addNewWorkspace } from 'Services/apiFunctions';
import { useQuery, useMutation } from 'react-query';
import Divider from './Divider';
import PropTypes from 'prop-types';
import { GET_WORKSPACE_DETAILS } from 'Services/apiKeys';
import { updateWorkspaceDetails } from 'redux/workspace/actions';
import { useDispatch } from 'react-redux';
import { userLogout } from 'utils/SessionUtils/sessionUtils';
import { PLATFORM } from 'constants.js';
import OrganizationList from './OrganizationList';
import './style.scss';

const Index = ({ location }) => {
    const SetSnackbar = useToaster();
    const dispatch = useDispatch();
    const data = queryString.parse(location.search);
    const [loading, setLoading] = useState(false);
    const [organizations, setOrganizations] = useState({});
    const [selectedOrganization, setSelectedOrganization] = useState(null);

    useEffect(() => {
        let userToken = data?.token;
        let decode;
        try {
            decode = jwt_decode(userToken);
            Cookies.set('AuthToken', userToken, { expires: 30, path: '/' });
        } catch (e) {
            userLogout();
            return null;
        }
        updateWorkspaceDetails(dispatch, { email: decode['email'], platform: decode.platform });
    }, []);

    const { isFetching } = useQuery([GET_WORKSPACE_DETAILS], getOrganizationsList, {
        onSuccess: (data) => {
            const platforms = data.reduce(
                (acc, item) => {
                    const platform = item.platform.toLowerCase();
                    acc[platform].push(item);
                    return acc;
                },
                {
                    [PLATFORM.SLACK.toLowerCase()]: [],
                    [PLATFORM.WEB.toLowerCase()]: [],
                    [PLATFORM.TEAMS.toLowerCase()]: [],
                }
            );
            setOrganizations(platforms);
        },
        onError: (error) => showSnackBarMessage(SetSnackbar, 'error', error?.message),
    });

    const { mutate: selectWorkspace } = useMutation(workspaceSelection, {
        onSuccess: (response) => window.location.replace(response.redirectUrl, '_self'),
        onError: (error) => {
            showSnackBarMessage(SetSnackbar, 'error', error?.message);
            setLoading(false);
        },
    });

    const { mutate: addWorkspace } = useMutation(addNewWorkspace, {
        onSuccess: (response) => window.location.replace(response.redirectUrl, '_self'),
        onError: (error) => {
            showSnackBarMessage(SetSnackbar, 'error', error?.message);
            setLoading(false);
        },
    });

    const handleJoin = () => {
        setLoading(true);
        if (
            selectedOrganization.platform.toLowerCase() === PLATFORM.SLACK.toLowerCase() ||
            selectedOrganization.platform.toLowerCase() === PLATFORM.TEAMS.toLowerCase()
        ) {
            try {
                window.open(`${process.env.REACT_APP_API_URL}login?platform=${selectedOrganization.platform}`, '_self');
            } catch (error) {
                showSnackBarMessage(SetSnackbar, 'error', error?.message);
            }
        } else {
            const apiData = {
                workspaceId: selectedOrganization._id,
                userId: selectedOrganization.userId,
            };
            selectWorkspace(apiData);
        }
    };

    const handleAddOrganization = () => {
        try {
            addWorkspace();
        } catch (error) {
            showSnackBarMessage(SetSnackbar, 'error', error?.message);
        }
    };

    return (
        <div className='container-select-organization'>
            <WelcomeBanner />
            <div className='container-select-organization-content'>
                <h2 className='container-select-organization-title'>Select Organization/Workspace</h2>
                <EWButton
                    plainTextButton={true}
                    primary={false}
                    buttonStyleClass='add-organization-btn ew-btn ob-btn'
                    width='300px'
                    onClick={handleAddOrganization}
                >
                    <AddIcon className='mb-1 mr-1' />
                    Add a new organization
                </EWButton>
                <Divider />
                {isFetching ? (
                    <CustomLoader />
                ) : (
                    <div className='select-org-list'>
                        {organizations[PLATFORM.WEB.toLowerCase()].length > 0 && (
                            <OrganizationList
                                organizations={organizations[PLATFORM.WEB.toLowerCase()]}
                                selectedOrganization={selectedOrganization}
                                handleSelectOrganization={(organization) => setSelectedOrganization(organization)}
                                platform={PLATFORM.WEB}
                                showAlternateTitle={false}
                            />
                        )}
                        {organizations[PLATFORM.SLACK.toLowerCase()].length > 0 && (
                            <OrganizationList
                                organizations={organizations[PLATFORM.SLACK.toLowerCase()].slice(0, 1)}
                                selectedOrganization={selectedOrganization}
                                handleSelectOrganization={(organization) => setSelectedOrganization(organization)}
                                platform={PLATFORM.SLACK}
                                showAlternateTitle={
                                    organizations[PLATFORM.WEB.toLowerCase()].length < 1 &&
                                    organizations[PLATFORM.TEAMS.toLowerCase()].length < 1
                                }
                            />
                        )}
                        {organizations[PLATFORM.TEAMS.toLowerCase()].length > 0 && (
                            <OrganizationList
                                organizations={organizations[PLATFORM.TEAMS.toLowerCase()].slice(0, 1)}
                                selectedOrganization={selectedOrganization}
                                handleSelectOrganization={(organization) => setSelectedOrganization(organization)}
                                platform={PLATFORM.TEAMS}
                                showAlternateTitle={
                                    organizations[PLATFORM.WEB.toLowerCase()].length < 1 &&
                                    organizations[PLATFORM.SLACK.toLowerCase()].length < 1
                                }
                            />
                        )}
                    </div>
                )}
                <EWButton
                    width='114px'
                    buttonText='Join'
                    onClick={handleJoin}
                    disabled={loading || !selectedOrganization}
                    loading={loading}
                    buttonStyleClass='join-btn ew-btn-slim'
                />
            </div>
        </div>
    );
};

export default Index;

Index.propTypes = {
    location: PropTypes.object,
};
