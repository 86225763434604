export const setter = (key, data) => {
    try {
        localStorage.setItem(key, JSON.stringify(data));
        return true;
    } catch (err) {
        return false;
    }
};

export const getter = (key) => {
    try {
        return { key: key, value: JSON.parse(localStorage.getItem(key)) };
    } catch (err) {
        return undefined;
    }
};

export const sessionSetter = (key, data) => {
    try {
        sessionStorage.setItem(key, JSON.stringify(data));
        return true;
    } catch (err) {
        return false;
    }
};

export const sessionGetter = (key) => {
    try {
        return { key: key, value: JSON.parse(sessionStorage.getItem(key)) };
    } catch (err) {
        return undefined;
    }
};
