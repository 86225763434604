import React from 'react';
import WalletInfo from './WalletInfo';
import AutoReload from './AutoReload';
import SummaryInfo from '../Summary/SummaryInfo';
import { shallowEqual, useSelector } from 'react-redux';
import { PLAN_STATUS } from '../../constants';

const Wallet = () => {
    const { subscription } = useSelector(mapStateToProps, shallowEqual);
    const isSubscriptionCancelled = subscription?.status === PLAN_STATUS.CANCELLED;

    return (
        <>
            <SummaryInfo wallet />
            {!isSubscriptionCancelled && <WalletInfo />}
            <AutoReload />
        </>
    );
};

const mapStateToProps = ({ Payments: { subscription } }) => ({ subscription });

export default Wallet;
