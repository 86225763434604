import React, { useState, useEffect } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import clsx from 'clsx';
import { getNavList } from './navFunctions';
import { useCommonContext } from 'Context/CommonContext';
import { useSelector, shallowEqual } from 'react-redux';
import NavIcon from './navIcon';
import { ReactComponent as EngageWith } from 'Assets/images/EngageWith.svg';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { HISTORY_FROM } from 'constants.js';
import { LOCALSTORAGE_KEYS } from 'utils/localStorageKeys';
import { FEEDBACK_360, MY_FEEDBACK, CONFIGURATION } from 'components/Dashboard/Content/Feedback/constants';
import { SUGGESTION_BOX, ALL_SUGGESTION, SUGGESTION_BOX_CONFIGURATION } from '../Content/SuggestionBox/constants';
import './style.scss';

const Index = () => {
    const { colorCode, isPulseEnabled } = useCommonContext();
    const {
        isAdmin,
        userId,
        nameForPoints,
        platform,
        isFeedbackFlowCompleted,
        isSuggestionsFlowCompeleted,
        suggestionBoxAdmins,
        isLeaderboardEnabled,
    } = useSelector(mapStateToProps, shallowEqual);
    const navList = getNavList({
        nameForPoints,
        isAdmin,
        userId,
        suggestionBoxAdmins,
        platform,
        isPulseEnabled,
        isFeedbackFlowCompleted,
        isSuggestionsFlowCompeleted,
        isLeaderboardEnabled,
    });
    const location = useLocation(); //using location to handle the icon logic

    const [retainActiveState, setRetainActiveState] = useState(false); //using this state to retain the active state of the nav item when the user clicks on the nav item
    const [dropdownConfig, setDropdownConfig] = useState({
        Recognitions: window.location.pathname.includes('recognitions'),
        Engagement: window.location.pathname.includes('eNPS') || window.location.pathname.includes('pulse'),
        Redemptions: window.location.pathname.includes('redemptions'),
        'New Hire Onboarding': window.location.pathname.includes('new-hire-onboarding'),
        [FEEDBACK_360]:
            window.location.pathname.includes(MY_FEEDBACK) || window.location.pathname.includes(CONFIGURATION),
        Analytics: window.location.pathname.includes('analytics'),
        [SUGGESTION_BOX]:
            window.location.pathname.includes(ALL_SUGGESTION) ||
            window.location.pathname.includes(SUGGESTION_BOX_CONFIGURATION),
    });

    useEffect(() => {
        const pathName = window.location.pathname;
        const isProfilePath = pathName.includes('profile');
        setDropdownConfig({
            Recognitions:
                pathName.includes('recognitions') ||
                (isProfilePath &&
                    (retainActiveState === HISTORY_FROM.ALL_ACTIVITIES ||
                        retainActiveState === HISTORY_FROM.MY_ACTIVITIES)),
            Engagement:
                pathName.includes('eNPS') || pathName.includes('pulse') || retainActiveState === HISTORY_FROM.PULSE,
            Redemptions: window.location.pathname.includes('redemptions'),
            'New Hire Onboarding': pathName.includes('new-hire-onboarding'),
            [FEEDBACK_360]: pathName.includes(MY_FEEDBACK) || pathName.includes(CONFIGURATION),
            Analytics: pathName.includes('analytics'),
            [SUGGESTION_BOX]: pathName.includes(ALL_SUGGESTION) || pathName.includes(SUGGESTION_BOX_CONFIGURATION),
        });
        const cachedPath = localStorage.getItem(LOCALSTORAGE_KEYS.NAVIGATION_FROM);
        localStorage.removeItem(LOCALSTORAGE_KEYS.NAVIGATION_FROM);
        const shouldRetain = Object.values(HISTORY_FROM).find((item) => item === cachedPath);
        if (cachedPath && shouldRetain !== retainActiveState) {
            setRetainActiveState(shouldRetain);
        }
    }, [retainActiveState, window.location.pathname]);

    const handleRewardDropdownToggle = (menu) => {
        if (dropdownConfig.hasOwnProperty(menu.name)) {
            let tempDropdownConfig = { ...dropdownConfig };
            tempDropdownConfig[menu.name] = !dropdownConfig[menu.name];
            setDropdownConfig(tempDropdownConfig);
        }
    };

    const getNavLinkClasses = (menu, getSubMenuClasses = false) => {
        let isWindowPseudoActive = false;
        if (menu.code) {
            const isSameWindowPath = `/dashboard/${menu.path}` === window.location.pathname;
            isWindowPseudoActive = (!menu.subMenu && isSameWindowPath) || menu.code === retainActiveState;
        }
        const isActiveMainMenu = !getSubMenuClasses && !menu.subMenu.length && isWindowPseudoActive;
        if (getSubMenuClasses) {
            return clsx({
                'submenu-nav-link': 1,
                'pseudo-active-submenu-nav-link': isWindowPseudoActive,
            });
        }
        return clsx({
            'nav-link': 1,
            'pseudo-active-nav-link': isActiveMainMenu,
        });
    };

    return (
        <div className='dashboard-nav-panel'>
            <div className='nav-ew-logo'>
                <EngageWith />
            </div>
            <nav className='dashboard-nav-list'>
                {navList.map((menu, index) => (
                    <React.Fragment key={`${menu.name + index}`}>
                        <NavLink
                            className={getNavLinkClasses(menu)}
                            activeClassName={clsx({
                                active: !menu.subMenu.length,
                            })}
                            to={menu.path ? `/dashboard/${menu.path}` : '#'}
                            onClick={() => {
                                localStorage.removeItem(LOCALSTORAGE_KEYS.NAVIGATION_FROM);
                                handleRewardDropdownToggle(menu);
                            }}
                        >
                            <div className='nav-item'>
                                <div className='nav-item-icon'>
                                    <NavIcon menu={menu} colorCode={colorCode} location={location} />
                                </div>
                                <span>{menu.name}</span>
                            </div>
                            {menu.subMenu.length > 0 && (
                                <KeyboardArrowUpIcon
                                    style={{
                                        transform: !dropdownConfig[menu.name] ? 'rotate(-180deg)' : '',
                                        transition: 'transform 250ms ease',
                                    }}
                                    className='keyboard-arrow-icon'
                                />
                            )}
                        </NavLink>
                        {menu.subMenu.length > 0 &&
                            dropdownConfig[menu.name] &&
                            menu.subMenu.map((subMenuItem, sIndex) => (
                                <React.Fragment key={`${subMenuItem.name + sIndex}`}>
                                    <NavLink
                                        className={getNavLinkClasses(subMenuItem, true)}
                                        activeClassName='active-submenu'
                                        to={`/dashboard/${subMenuItem.path}`}
                                        onClick={() => setRetainActiveState(null)}
                                    >
                                        <div className='submenu-nav-item'>
                                            <span>{subMenuItem.name}</span>
                                        </div>
                                    </NavLink>
                                </React.Fragment>
                            ))}
                    </React.Fragment>
                ))}
            </nav>
        </div>
    );
};

const mapStateToProps = ({ User, Workspace }) => ({
    isAdmin: User.isAdmin,
    userId: User._id,
    nameForPoints: Workspace.nameForPoints,
    platform: Workspace.platform,
    suggestionBoxAdmins: Workspace.suggestionBoxAdmins,
    isFeedbackFlowCompleted: Workspace.todo.isFeedbackFlowCompleted,
    isSuggestionsFlowCompeleted: Workspace.todo.isSuggestionBoxFlowCompleted,
    isLeaderboardEnabled: Workspace.todo.isLeaderboardEnabled,
});

export default Index;
