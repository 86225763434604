import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Modal from '@material-ui/core/Modal';
import CloseIcon from '@material-ui/icons/Close';
import { useToaster } from 'Context/SnackbarContext';
import { handleCategoryChange, handleAddEditCategoryClick } from '../../componentsUtil';
import EWButton from 'components/ReusableComponents/EWButton';
import { FEEDBACK_CATEGORY_LENGTH_LIMIT } from '../../constants';
import { useMutation, useQueryClient } from 'react-query';
import { updateFeedbackCategory, addFeedbackCategory } from '../../Services/apiFunctions';
import ErrorField from 'components/ReusableComponents/ErrorField';
import './style.scss';

const ERROR_TEXT = 'This feedback category already exists.';

const useAddEditCategoryMutation = ({ addModal, category, categories, onClose, SetSnackbar }) => {
    const queryClient = useQueryClient();

    const { mutateAsync: addCategory, isLoading: isAddingCategory } = useMutation(addFeedbackCategory);

    const { mutateAsync: updateCategory, isLoading: isUpdatingCategory } = useMutation(updateFeedbackCategory);

    const handleClick = () => {
        return handleAddEditCategoryClick({
            onClose,
            category,
            categories,
            addModal,
            addCategory,
            updateCategory,
            queryClient,
            currentCategory: addModal.category,
            SetSnackbar,
        });
    };

    return { handleClick, isAddingCategory, isUpdatingCategory };
};
const Index = ({ addModal, setAddModal, categories }) => {
    const { SetSnackbar } = useToaster();
    const [category, setCategory] = useState(addModal.category);
    const [categoriesText, setCategoriesText] = useState({
        isFocused: false,
        charsLeft: addModal?.category
            ? FEEDBACK_CATEGORY_LENGTH_LIMIT - addModal.category.length
            : FEEDBACK_CATEGORY_LENGTH_LIMIT,
    });
    const [error, setError] = useState(false);

    const onClose = () => setAddModal({ open: false, category: '', index: -1 });

    const { handleClick, isAddingCategory, isUpdatingCategory } = useAddEditCategoryMutation({
        addModal,
        category,
        categories,
        onClose,
        SetSnackbar,
    });

    const handleChange = (event) =>
        handleCategoryChange({
            targetCategory: event.target.value,
            setCategory,
            categoriesText,
            setCategoriesText,
            categories,
            currentCategory: addModal.category,
            setError,
        });

    const handleEvents = (focus) => setCategoriesText({ ...categoriesText, isFocused: focus });

    const content = (
        <div className='add-category-modal-container'>
            <div className='add-category-header'>
                <p>{addModal.category ? 'Edit category' : 'Add new category'}</p>
                <CloseIcon onClick={onClose} />
            </div>
            <div className='add-category-body'>
                <p className='add-category-text'>
                    Enter 360º feedback category that can be used for strengths and
                    <br /> improvement areas.
                </p>
                <textarea
                    value={category}
                    onChange={handleChange}
                    onFocus={() => handleEvents(true)}
                    onBlur={() => handleEvents(false)}
                />
            </div>
            <div className='d-flex justify-content-between align-items-start'>
                {error && <ErrorField errorText={ERROR_TEXT} className='mt-1' />}
                {categoriesText.isFocused && <p className='char-count'>Characters left: {categoriesText.charsLeft}</p>}
            </div>
            <div className='add-category-footer'>
                <EWButton
                    buttonText='Cancel'
                    buttonStyleClass='btn-cancel'
                    primary={false}
                    plainTextButton
                    onClick={onClose}
                    disabled={isAddingCategory || isUpdatingCategory}
                />
                <EWButton
                    buttonText={addModal.category ? 'Update' : 'Add'}
                    onClick={handleClick}
                    disabled={
                        !category.trim() ||
                        error ||
                        category === addModal.category ||
                        isAddingCategory ||
                        isUpdatingCategory
                    }
                    loading={isAddingCategory || isUpdatingCategory}
                />
            </div>
        </div>
    );

    return (
        <div>
            <Modal open={addModal.open} onClose={onClose}>
                {content}
            </Modal>
        </div>
    );
};

Index.propTypes = {
    addModal: PropTypes.object,
    setAddModal: PropTypes.func,
    categories: PropTypes.object,
};

export default Index;
