import React from 'react';
import { eNPS_ROUTE } from './constant';
import PropTypes from 'prop-types';
import ScoreScreen from './Scoreboard';
import './style.scss';
import CreatePulse from 'components/Dashboard/Content/PulseSurvey/CreatePulse';
import { Switch, Route, Redirect } from 'react-router-dom';
import EnpsTemplate from './EnpsTemplate';
import EnpsLaunch from './EnpsLaunch';
import { useCommonContext } from 'Context/CommonContext';
import EnpsInsight from './../PulseSurvey/Components';
import { shallowEqual, useSelector } from 'react-redux';

export default function Index({ history }) {
    const { enpsPulseCreated } = useCommonContext();
    const { todo } = useSelector(mapStateToProps, shallowEqual);

    return (
        <div>
            <Switch>
                <Route exact path={eNPS_ROUTE.HOME} render={() => <ScoreScreen history={history} />} />
                <Route exact path={eNPS_ROUTE.SETUP_PAGE} render={() => <EnpsLaunch history={history} />} />
                <Route exact path={eNPS_ROUTE.eNPS_CREATE} render={() => <EnpsTemplate history={history} />} />
                {(!enpsPulseCreated || !todo.isENPSFlowCompleted) && (
                    <Route
                        exact
                        path={eNPS_ROUTE.CREATE_TEMPLATE}
                        render={() => <CreatePulse history={history} eNPS={true} />}
                    />
                )}
                <Route path={eNPS_ROUTE.INSIGHTS} render={(props) => <EnpsInsight {...props} enps={true} />} />
                <Redirect to={eNPS_ROUTE.HOME} />
            </Switch>
        </div>
    );
}

const mapStateToProps = (state) => ({ todo: state.Workspace.todo });

Index.propTypes = {
    history: PropTypes.object,
};
