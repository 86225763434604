import React from 'react';
import PropTypes from 'prop-types';
import { DarkVoiletTag, DarkOrangeTag, GreenPulseTag, LightRedTag } from 'utils/Stylesheet/style';
import { PULSE_STATUS } from '../../../constants';

const Index = ({ pulse, isEditingOneTime }) => {
    if (pulse?.status === PULSE_STATUS.COMPLETED) {
        return <GreenPulseTag className='m-0 font-weight-600'>Completed</GreenPulseTag>;
    } else if (pulse?.status === PULSE_STATUS.ACTIVE) {
        return !isEditingOneTime ? (
            <DarkVoiletTag className='m-0 font-weight-600'>Ongoing</DarkVoiletTag>
        ) : (
            <LightRedTag>Ongoing</LightRedTag>
        );
    } else if (pulse?.paused) {
        return <DarkOrangeTag className='m-0 font-weight-600'>Paused</DarkOrangeTag>;
    }
    return null;
};

Index.propTypes = {
    pulse: PropTypes.object,
    isEditingOneTime: PropTypes.bool,
};

export default Index;
