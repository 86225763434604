import React from 'react';
import PropTypes from 'prop-types';
import { Switch, Route } from 'react-router-dom';
import { RECOGNITIONS } from './constants';
import RecognitionsHome from './RecognitionsHome';
import Configuration from './Configuration';

const Index = ({ history }) => {
    return (
        <div>
            <Switch>
                <Route exact path={RECOGNITIONS.FEED} component={<RecognitionsHome history={history} />} />
                <Route exact path={RECOGNITIONS.CONFIGURATION} component={Configuration} />
            </Switch>
        </div>
    );
};

Index.propTypes = {
    history: PropTypes.object,
};

export default Index;
