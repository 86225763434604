import React from 'react';
import TeamCount from 'components/ReusableComponents/TeamCount';
import PropTypes from 'prop-types';
import './style.scss';

const Index = ({ image, name, teamSize }) => {
    return (
        <span className='container-label-with-image'>
            {image && <img src={image} alt='user' />}
            &nbsp;{name}
            {teamSize ? <TeamCount count={teamSize} /> : null}
        </span>
    );
};
Index.propTypes = {
    image: PropTypes.string,
    name: PropTypes.string,
    teamSize: PropTypes.number,
};
export default Index;
