import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as RefreshImg } from 'Assets/images/refresh.svg';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import CustomFilterDropdown from 'components/ReusableComponents/CustomFilterDropdown';
import ErrorField from 'components/ReusableComponents/ErrorField';
import { isSlack } from 'utils/HelperFunctions';
import './style.scss';

const ChannelStrip = ({ name, platform }) => (
    <div className='d-flex align-items-center'>
        <p className='d-inline-block mb-0'>{`${isSlack(platform) ? '#' : ''}${name}`}</p>
    </div>
);

const ChannelSelection = ({ channelSelected, channelList, handleChange, platform }) => {
    const platformBasedName = isSlack(platform) ? 'channel' : 'team';
    const [error, setError] = useState(false);
    const channelName = isSlack(platform) ? (
        `#${channelSelected?.label}`
    ) : (
        <span>
            {channelSelected?.label} <ArrowForwardIcon className='team-icon' /> General
        </span>
    );
    const selectedChannel = channelSelected?.label ? channelName : `Select ${platformBasedName}`;

    const channelOption = channelList?.map((channel) => ({
        label: <ChannelStrip name={channel?.label} platform={platform} />,
        value: channel?.label,
    }));

    const handleChannelSelection = (index) => {
        if (channelList?.[index].numMembers < 2) {
            setError(true);
            return;
        }
        handleChange('channel', channelList?.[index]);
    };

    return (
        <div className='setup-container'>
            <p className='label'>{platformBasedName}</p>
            <div className='setup-input d-inline-block' style={{ width: '404px' }}>
                <CustomFilterDropdown
                    dropDownID='select-country'
                    filterOptions={channelOption}
                    selectedName={selectedChannel}
                    handleSelection={(index) => handleChannelSelection(index)}
                    buttonStyleClass='view-dropdown'
                    singleSelect
                    optionsSelected={channelSelected?.label}
                    search
                    customSearchText={`Search ${platformBasedName}`}
                    dropdownWidth={392}
                    dropdownHeight={250}
                />
            </div>
            <button className='ew-btn tb-btn' onClick={() => handleChange('refresh', {})}>
                Refresh{' '}
                <span className='margin-left-5'>
                    <RefreshImg aria-label='refresh' />
                </span>
            </button>
            <p className='channel-instruction'>
                <span> 💡&nbsp;Pick a {platformBasedName} where majority of your employees are present.</span>
            </p>
            {channelSelected?.label && (
                <p className='channel-instruction-2'>
                    EngageWith will post all recognitions in <strong>{channelName}</strong>
                </p>
            )}
            {error && <ErrorField errorText={`Please select a ${platformBasedName} with at least 2 users.`} />}
        </div>
    );
};

ChannelSelection.propTypes = {
    channelSelected: PropTypes.object,
    channelList: PropTypes.array,
    handleChange: PropTypes.func,
    platform: PropTypes.string,
};

ChannelStrip.propTypes = {
    name: PropTypes.string,
    platform: PropTypes.string,
};

export default ChannelSelection;
