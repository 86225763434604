import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { StyledDropzone } from 'components/ReusableComponents/StyledDropzone';
import { useToaster } from 'Context/SnackbarContext';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import { getUserBulkUploadSampleFile } from 'constants.js';
import { bulkUploadUsers } from 'Services/apiFunctions';
import { showSnackBarMessage } from 'utils/HelperFunctions';
import './style.scss';
import EWModal from 'components/ReusableComponents/EWModal';

const BULK_UPDATE_BULLET_POINTS = [
    'Details for only existing users can be updated using the file upload',
    'Fields marked with * such as employee email are mandatory',
    'Format for dates should be <dd/mm/yyyy>',
    'Keep the column names same as given in the sample file',
];

const Index = ({ open, setCurrentModal, onClose, setData, platform }) => {
    const [state, setState] = useState({ excelFileError: false });
    const [loading, setLoading] = useState(false);
    const { SetSnackbar } = useToaster();
    const csvDrop = (acceptedFile) => {
        if (acceptedFile.length === 0) {
            setState({ excelFileError: 'Unsupported file format' });
        } else if (acceptedFile[0].size > 20 * 1024 * 1024) {
            setState({ excelFileError: 'File size exceeds maximum limit of 20MB' });
        } else {
            setState({ excelFileError: null, acceptedFile: acceptedFile });
        }
    };
    const handleNext = async () => {
        setLoading(true);
        try {
            const uploadData = await bulkUploadUsers(state.acceptedFile);
            setLoading(false);
            setData(uploadData);
            setCurrentModal(2);
        } catch (error) {
            showSnackBarMessage(SetSnackbar, 'error', error?.message);
        }
    };
    const modalData = {
        heading: 'Upload teams/members through CSV',
        rightButtonText: 'Next',
        handleRightButtonClick: handleNext,
        disabled: loading || !state.acceptedFile,
        loading,
    };
    return (
        <EWModal open={open} width='476px' onClose={onClose} modalData={modalData}>
            <div className='upload-csv-container drag-methods-dashboard'>
                <div className='bulk-update-bullet-list'>
                    <h5>Please note the following before proceeding to upload</h5>
                    <ul className='wrapper-list'>
                        {BULK_UPDATE_BULLET_POINTS.map((item) => (
                            <li key={item} className='list'>
                                {item}
                            </li>
                        ))}
                    </ul>
                </div>
                <div className='content-container-drag make-relative'>
                    <StyledDropzone
                        onDrop={csvDrop}
                        setState={setState}
                        error={state.excelFileError}
                        acceptedFile={state.acceptedFile}
                        content={'Drop your CSV file here'}
                        height={177}
                        width={428}
                        loading={loading}
                    />
                    <div className='upload-csv-text-containers'>
                        <p className='upload-csv-subheadings'>Supported Formats- .csv</p>
                        <div className='upload-csv-sample-file'>
                            <a
                                href={getUserBulkUploadSampleFile(platform)}
                                rel='noopener noreferrer'
                                target='_blank'
                                download
                            >
                                <CloudDownloadIcon />
                                Download sample file
                            </a>
                        </div>
                    </div>
                    <div>
                        <span className='upload-csv-note' aria-label=''>
                            💡 Note- User email is mandatory to identify the user and update the information
                        </span>
                    </div>
                </div>
            </div>
        </EWModal>
    );
};

Index.propTypes = {
    open: PropTypes.bool,
    setCurrentModal: PropTypes.func,
    onClose: PropTypes.func,
    setData: PropTypes.func,
    platform: PropTypes.string,
};

export default Index;
