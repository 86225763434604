import React from 'react';
import EWModal from 'components/ReusableComponents/EWModal';
import EWButton from 'components/ReusableComponents/EWButton';
import UserButton from 'components/ReusableComponents/UserButton';
import { useQuery } from 'react-query';
import { ReactComponent as RecognitionNotConfiguredImage } from 'Assets/images/recognition-not-configured.svg';
import { handlePageRedirectionWithinSubMenu } from 'utils/SessionUtils/sessionUtils';
import CustomLoader from 'components/ReusableComponents/CustomLoader';
import { GET_ADMIN_LIST } from 'Services/apiKeys';
import { getAdminList } from 'Services/apiFunctions';
import { ReactComponent as Warning } from 'Assets/images/warning.svg';
import PropTypes from 'prop-types';
import './style.scss';

const Index = ({ open, onClose, history, currentRoute }) => {
    const { data: adminData, isFetching } = useQuery([GET_ADMIN_LIST], getAdminList);

    const openUserProfile = (item) => {
        handlePageRedirectionWithinSubMenu({
            history,
            redirectionURL: `/dashboard/profile/${item._id}`,
            navMenuCode: currentRoute,
        })();
        onClose();
    };

    return (
        <EWModal open={open} onClose={onClose} width='580px' height='388px'>
            <div className='container-wrapper'>
                <RecognitionNotConfiguredImage />
                <div className='heading'>
                    <Warning className='mr-1 mb-1' />
                    You will not be able to send a recognition as it has not been configured.
                </div>
                <span className='subheading'>Please contact your EngageWith admin for more details</span>
                {isFetching ? (
                    <CustomLoader />
                ) : (
                    <div className='admin-list'>
                        {adminData.data.map((item) => (
                            <span key={item.id}>
                                <UserButton
                                    showImage={true}
                                    onClick={() => openUserProfile(item)}
                                    imageURL={item.pictureURL}
                                    senderName={item.userName}
                                    clickableUser={true}
                                />
                            </span>
                        ))}
                    </div>
                )}
                <EWButton
                    buttonText='Close'
                    plainTextButton
                    primary={false}
                    buttonStyleClass='ew-btn-text'
                    onClick={onClose}
                />
            </div>
        </EWModal>
    );
};

Index.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    history: PropTypes.object,
    currentRoute: PropTypes.string,
};
export default Index;
