import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { shallowEqual, useSelector } from 'react-redux';
import RNRWrapper from '../../Components/RNRWrapper';
import { useRnRSetup } from '../../Services/ApiHooks.js';
import { Allowance, RewardEnable, RecognitionSetup } from '../../Components/RNRSteps';
import { useCommonContext } from 'Context/CommonContext';
import { useToaster } from 'Context/SnackbarContext';
import { checkForValidNumber } from '../../../../utils/HelperFunctions';
import { ROUTES_ONBOARDING } from '../../constants';

const Index = ({ history }) => {
    const { SetSnackbar } = useToaster();
    const { workspaceCountry } = useSelector(mapStateToProps, shallowEqual);

    const { onboardingInfo, setOnboardingInfo } = useCommonContext();

    if (onboardingInfo?.onboardingModules?.find((module) => module.moduleName === 'R&R')?.completed) {
        history.push(ROUTES_ONBOARDING.ONBOARDING);
    }

    //RnR Wrapper States
    const [activeStep, setActiveStep] = useState(1);
    const [stepStatus, setStepStatus] = useState([0, 0, 0, 0]);

    //RnR Steps States
    const [kudos, setKudos] = useState(1);
    const [shoutout, setShoutout] = useState(10);
    const [conversionRate, setConversionRate] = useState(1);
    const [allowance, setAllowance] = useState(100);
    const [refresh, setRefresh] = useState(false);

    //API Calls

    const errorMessage = (type, msg) => {
        SetSnackbar({
            open: true,
            variant: type,
            message: msg || 'Some error occurred!',
        });
    };

    const onError = (err) => errorMessage('error', err?.message);

    const onSuccess = () => {
        const updatedOnboardingInfo = onboardingInfo.map((module) =>
            module.module === 'R&R' ? { ...module, completed: true } : module
        );
        setOnboardingInfo(updatedOnboardingInfo);
        history.replace(ROUTES_ONBOARDING.RNR_SETUP_COMPLETE);
    };

    const { mutate: setRnRConfig, isLoading } = useRnRSetup({ onError, onSuccess });

    // Handle Changes of SetupContainer
    const handleChange = (type, value) => {
        const setPointValue = (value, setField) => {
            if (!value || isNaN(value)) {
                return setField('');
            }
            if (Number(value) > 99999) {
                return errorMessage('warning', `Maximum limit for ${type} is 99999`);
            }
            setField(Math.abs(value));
        };

        // eslint-disable-next-line default-case
        switch (type) {
            case 'kudos':
                setPointValue(value, setKudos);
                break;
            case 'shoutout':
                setPointValue(value, setShoutout);
                break;
            case 'conversionRate':
                setConversionRate(value === '' || !checkForValidNumber(value) ? '' : value);
                break;
            case 'allowance':
                setPointValue(value, setAllowance);
                break;
            case 'refresh':
                setRefresh(!refresh);
                break;
        }
    };

    // Handle Changes of RNRWrapper
    const handleStep = (type) => {
        // eslint-disable-next-line default-case
        switch (type) {
            case 'forward':
                stepStatus.splice(activeStep - 1, 1, 1);
                setStepStatus([...stepStatus]);
                activeStep < 3 && setActiveStep(activeStep + 1);
                break;
            case 'finish setup': {
                const apiData = {
                    rewards: [
                        { rewardType: 'kudos', points: kudos },
                        { rewardType: 'shoutout', points: shoutout },
                    ],
                    redemption: !!conversionRate,
                    monthlyAllowance: allowance,
                    ...(conversionRate && {
                        pointConversion: {
                            country: workspaceCountry?.country,
                            conversionRate,
                        },
                    }),
                };

                //Redirecting End-Point of 4th Step after channel selection
                setRnRConfig(apiData);
                break;
            }
            case 'back':
                activeStep > 1 && setActiveStep(activeStep - 1);
                break;
        }
    };

    //Completion Check for each step
    const checkCompleted = [
        kudos && shoutout && Number(kudos) > 0 && Number(shoutout) > 0,
        allowance && allowance > 0,
        conversionRate && conversionRate > 0,
    ];

    return (
        <div>
            <RNRWrapper
                activeStep={activeStep}
                stepStatus={stepStatus}
                handleStep={handleStep}
                checkCompleted={checkCompleted}
                loading={isLoading}
            >
                {activeStep === 1 && <RecognitionSetup kudos={kudos} shoutout={shoutout} handleChange={handleChange} />}

                {activeStep === 2 && <Allowance allowance={allowance} handleChange={handleChange} />}

                {activeStep === 3 && (
                    <RewardEnable
                        kudos={kudos}
                        shoutout={shoutout}
                        countrySelected={workspaceCountry}
                        conversionRate={conversionRate}
                        handleChange={handleChange}
                    />
                )}
            </RNRWrapper>
        </div>
    );
};

const mapStateToProps = ({ Workspace }) => ({
    workspaceCountry: Workspace?.workspaceCountry || {},
});

Index.propTypes = {
    history: PropTypes.object,
};

export default Index;
