import React, { useState } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import Pagination from 'components/ReusableComponents/Pagination';
import Loader from 'components/ReusableComponents/Loader';
import PropTypes from 'prop-types';
import { filter } from 'lodash';
import './style.scss';

// Screenshot : https://user-images.githubusercontent.com/38394102/128987841-37973d55-26ee-4ddc-96ce-c83e4a837997.png
/**** Sample data to be passed

1. each row must have an unique id and is a mandatory field
2. each column can be a string/number/jsx element

const data = [
  {id: 123, row: ['Frozen yoghurt', 159, 6.0, 24, 4.0]},
  {id: 456, row: ['Ice cream sandwich', 237, 9.0, 37, 4.3]},
  {id: 789, row: ['Eclair', 262, 16.0, 24, 6.0]},
  {id: 987, row: ['Cupcake', 305, 3.7, 67, 4.3]},
  {id: 654, row: ['Gingerbread', 356, 16.0, 49, 3.9]},
];

*****Sample header to be passed
1. Can be a string/number/jsx element

const header = ['Dessert (100g serving)', <>Calories</>, <>Fat&nbsp;(g)</>, <>Carbs&nbsp;(g)</>, <>Protein&nbsp;(g)</>]

***** Pass columnWidth as an array of string containing widths with proper units
example: const columnWidth = ['12%', '12%', '44%', '32%'];

*****List of index for columns that do not have sort
1. must be a number
2. Must be index value

const noSortColumns = [0];

**** Pass noShadow as true if we do not want the table to have its own box shadow
const noShadow=true;

**** Pagination
1. Do not pass paginationProps if you do not want the table to have a pagination
2. paginationProps must be an object with the following properties
   paginationProps = { currentPage, totalCount, setCurrentPage, onChange, rounded }
   'rounded' is optional with default as true

**** Loader
Takes in a prop loading and displays the table in loading state if true

**** Pass defaultSortIndex as an array of index of size 1 that is to be sorted by default.
const defaultSortIndex = [0];

*********/

const Index = ({
    header,
    data,
    noSortColumns,
    noShadow,
    paginationProps,
    loading,
    columnWidth,
    handleSort,
    handleRowClick,
    className,
    defaultSortIndex = [],
    emptyStateText,
}) => {
    const [sortIndex, setSortIndex] = useState(defaultSortIndex);
    const handleSorting = (index, type) => {
        handleSort(index, type);
        const temp = [...sortIndex];
        setSortIndex(temp.includes(index) ? filter(temp, (item) => item !== index) : [...temp, index]);
    };

    if (data?.length > 0) {
        return (
            <div
                data-testid={'table'}
                style={noShadow ? { boxShadow: 'none' } : {}}
                className={`ew-table-container ${className}`}
            >
                <div className='ew-table'>
                    <Table stickyHeader>
                        <TableHead>
                            {header && (
                                <TableRow>
                                    {header.map((headerElement, index) => (
                                        <TableCell
                                            style={noSortColumns.includes(index) ? { cursor: 'default' } : {}}
                                            key={'tableheader' + headerElement}
                                            onClick={() => {
                                                handleSort &&
                                                    !noSortColumns.includes(index) &&
                                                    handleSorting(index, headerElement);
                                            }}
                                        >
                                            <span className='d-inline-block table-header-cell'>{headerElement} </span>
                                            {!noSortColumns.includes(index) && (
                                                <span className='d-inline-block'>
                                                    {sortIndex.includes(index) ? (
                                                        <KeyboardArrowUpIcon />
                                                    ) : (
                                                        <KeyboardArrowDownIcon />
                                                    )}
                                                </span>
                                            )}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            )}
                        </TableHead>
                        <TableBody>
                            {data
                                .filter((row) => Boolean(row?.id && row?.row))
                                .map((row, index) => (
                                    <TableRow
                                        onClick={() => handleRowClick?.(row.id, index)}
                                        key={`${row.id}-tr${index}`} // sonar issue to be fixed later
                                        style={data.style}
                                    >
                                        {row?.row?.map((column, idx) => (
                                            <TableCell
                                                style={{
                                                    width: columnWidth[idx],
                                                    wordBreak: 'break-word',
                                                }}
                                                key={`${row.id}-tc${idx}`}
                                            >
                                                {column}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                ))}
                        </TableBody>
                    </Table>
                </div>
                {paginationProps && <Pagination {...paginationProps} />}
                {loading && <Loader />}
            </div>
        );
    } else {
        return (
            <div className='response-empty-users'>
                {!loading && (
                    <>
                        <img
                            src={require('Assets/images/empty-flag.svg')}
                            alt='empty-flag'
                            className='response-empty-image'
                        />
                        <p className='no-response-text'>{emptyStateText || 'No results found'}</p>
                    </>
                )}
                {loading && <Loader />}
            </div>
        );
    }
};

Index.propTypes = {
    header: PropTypes.array,
    data: PropTypes.array,
    noSortColumns: PropTypes.arrayOf(PropTypes.number),
    noShadow: PropTypes.bool,
    paginationProps: PropTypes.shape({
        currentPage: PropTypes.any,
        totalCount: PropTypes.number,
        setCurrentPage: PropTypes.func,
        onChange: PropTypes.func,
        rounded: PropTypes.bool,
    }),
    loading: PropTypes.bool,
    columnWidth: PropTypes.array,
    handleSort: PropTypes.func,
    handleRowClick: PropTypes.func,
    className: PropTypes.string,
    emptyStateText: PropTypes.string,
    defaultSortIndex: PropTypes.arrayOf(PropTypes.number),
};

export default Index;
