import React from 'react';
import styled from 'styled-components';
import { BeatLoader } from 'react-spinners';

const LoaderContainer = styled.div`
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background-color: rgba(255, 255, 255, 0.7);
    z-index: 999;
    > div {
        position: absolute;
        top: 45%;
        left: 45%;
    }
`;

const Index = () => {
    return (
        <LoaderContainer>
            <BeatLoader color={'#0029ff'} size={10} />
        </LoaderContainer>
    );
};

export default Index;
