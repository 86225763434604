import React from 'react';
import PropTypes from 'prop-types';
import { BeatLoader } from 'react-spinners';
import clsx from 'clsx';
import './style.scss';

const Index = ({
    buttonText,
    buttonStyleClass,
    onClick,
    width = 'auto',
    height = 44,
    loading,
    disabled,
    primary = true,
    border,
    redBackground,
    redBorder,
    whiteBorder,
    greenBackground,
    plainTextButton,
    loaderColor = '#fff',
    children,
    hidden = false,
}) => {
    if (hidden) {
        return null;
    }
    return (
        <button
            className={clsx({
                'ew-btn-container': true,
                'ew-btn': true,
                'pb-btn': primary,
                'ob-btn': border,
                'pr-btn': redBackground,
                'or-btn': redBorder,
                'pg-btn': greenBackground,
                'tb-btn': plainTextButton,
                'ow-btn': whiteBorder,
                [buttonStyleClass]: buttonStyleClass,
            })}
            style={{ width, height }}
            onClick={onClick}
            disabled={loading || disabled}
        >
            {loading ? (
                <BeatLoader size={10} color={loaderColor} loading={loading} data-testid={'loader'} />
            ) : (
                buttonText || children
            )}
        </button>
    );
};

Index.propTypes = {
    buttonText: PropTypes.string,
    buttonStyleClass: PropTypes.string,
    onClick: PropTypes.func,
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    loading: PropTypes.bool,
    disabled: PropTypes.bool,
    primary: PropTypes.bool,
    border: PropTypes.bool,
    redBackground: PropTypes.bool,
    redBorder: PropTypes.bool,
    whiteBorder: PropTypes.bool,
    greenBackground: PropTypes.bool,
    plainTextButton: PropTypes.bool,
    loaderColor: PropTypes.string,
    children: PropTypes.node,
    hidden: PropTypes.bool,
};

export default Index;
