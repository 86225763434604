import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';

const Index = ({
    imageUrl,
    text,
    textWidth,
    width = 12,
    height = 12,
    textStyleClass,
    textMarginLeft = 8,
    borderRadius = 4,
}) => {
    return (
        <div className='d-flex justify-content-start align-items-center'>
            {imageUrl && <img src={imageUrl} alt='profile' style={{ width, height, borderRadius }} />}
            <span
                className={clsx({ [textStyleClass]: textStyleClass })}
                style={{
                    marginLeft: imageUrl ? textMarginLeft : 0,
                    width: textWidth || '120px',
                }}
            >
                {text}
            </span>
        </div>
    );
};

Index.propTypes = {
    imageUrl: PropTypes.string,
    text: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    textWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    width: PropTypes.number,
    height: PropTypes.number,
    textStyleClass: PropTypes.string,
    textMarginLeft: PropTypes.number,
    borderRadius: PropTypes.number,
};

export default Index;
