import React, { useState } from 'react';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import './style.scss';
import PropTypes from 'prop-types';

const Index = ({ name, assignedMembers, selectedOrgFilter, history }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        if (open) {
            setAnchorEl(null);
        } else {
            setAnchorEl(event.currentTarget);
        }
    };

    const handleClose = () => setAnchorEl(null);

    return (
        <div className='org-row-container d-flex align-items-center '>
            <p className='d-inline-block mb-0 name'>
                {selectedOrgFilter.value === 'manager' ? `${name}'s team` : name}
            </p>
            {assignedMembers?.length > 0 && (
                <ClickAwayListener onClickAway={handleClose}>
                    <button className='team-tag group-count d-flex justify-content-center' onClick={handleClick}>
                        <img src={require('Assets/images/group.svg')} className='mr-1' alt='group' />
                        {assignedMembers.length}
                        <span>
                            {anchorEl && (
                                <div className='avatar-menu position-absolute memberslist'>
                                    {assignedMembers?.map((option) => (
                                        <button
                                            className='menu-item'
                                            key={option._id}
                                            onClick={() => {
                                                handleClose();
                                                history.push(`/dashboard/profile/${option._id}`);
                                            }}
                                        >
                                            <div>
                                                <img
                                                    src={option?.imageURL || require('Assets/images/defaultUser.png')}
                                                    className='avatar-26 mr-2'
                                                    alt='user-avatar'
                                                />
                                                <p className='username d-inline'>{option.name}</p>
                                            </div>
                                        </button>
                                    ))}
                                </div>
                            )}
                        </span>
                    </button>
                </ClickAwayListener>
            )}
        </div>
    );
};

Index.propTypes = {
    name: PropTypes.string,
    assignedMembers: PropTypes.array,
    selectedOrgFilter: PropTypes.object,
    history: PropTypes.object,
};

export default Index;
