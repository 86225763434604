import React from 'react';
import UserTag from '../UserTag';
import PropTypes from 'prop-types';
import './style.scss';

const Index = ({ showValue = true, isApproveOrRejectModal, users, value }) => {
    return (
        <div className='container-recognized-users'>
            <div className='container-recognized-users-bg'>
                <div className='recognized-users'>
                    {users.map((user) => (
                        <UserTag key={user._id} imageURL={user?.pictureURL} userName={user?.userName} />
                    ))}
                </div>
                {showValue && value && (
                    <p className='recognized-users-value'>
                        Value highlighted: {isApproveOrRejectModal && <br />} <strong>{value}</strong>
                    </p>
                )}
            </div>
        </div>
    );
};

Index.propTypes = {
    showValue: PropTypes.bool,
    isApproveOrRejectModal: PropTypes.bool,
    users: PropTypes.array,
    value: PropTypes.string,
};

export default Index;
