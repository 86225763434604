import React, { useState } from 'react';
import TitleBar from 'components/ReusableComponents/TitleBar';
import { isEmptyObject, showSnackBarMessage } from 'utils/HelperFunctions';
import { ReactComponent as RedemptionsConfig } from 'Assets/images/redemption-config-banner.svg';
import Banner from 'components/ReusableComponents/Banner';
import PropTypes from 'prop-types';
import { useToaster } from 'Context/SnackbarContext';
import { getOrganisationWideRedemptionConfig } from 'Services/apiFunctions';
import { GET_ORG_WIDE_REDEMPTION_CONFIG } from 'Services/apiKeys';
import { useQuery } from 'react-query';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import { updateWorkspaceDetails } from 'redux/workspace/actions';
import EnableRedemption from './EnableRedemption/index';

const Index = ({ history }) => {
    const { SetSnackbar } = useToaster();
    const [conversions, setConversions] = useState([]);
    const dispatch = useDispatch();
    const { config, nameForPoints } = useSelector(mapStateToProps, shallowEqual);
    const [enableRedemptions, setEnableRedemptions] = useState(false);

    const onError = (error) => showSnackBarMessage(SetSnackbar, 'error', error?.message);

    const onSuccess = (configData) => {
        const data = configData.data;
        if (!isEmptyObject(data)) {
            updateWorkspaceDetails(dispatch, {
                config: { ...config, conversion: data?.conversions, redemption: data?.redemption },
            });
        }
        setEnableRedemptions(data?.redemption);
        setConversions(data?.conversions);
    };

    useQuery([GET_ORG_WIDE_REDEMPTION_CONFIG], getOrganisationWideRedemptionConfig, {
        onSuccess,
        onError,
    });
    return (
        <div className='celebration-container'>
            <TitleBar title='Configurations' />

            <Banner
                heading={`Configure and control redemption settings`}
                subHeading={'Let your employees redeem the points or monetary value received across EngageWith'}
                image={<RedemptionsConfig className='redemptions-banner' />}
            />
            <EnableRedemption
                history={history}
                conversions={conversions}
                config={config}
                enableRedemptions={enableRedemptions}
                setEnableRedemptions={setEnableRedemptions}
                nameForPoints={nameForPoints}
            />
        </div>
    );
};
const mapStateToProps = ({ Workspace }) => ({
    nameForPoints: Workspace.nameForPoints,
    config: Workspace.config,
});
Index.propTypes = {
    history: PropTypes.object,
};

export default Index;
