import React from 'react';
import PropTypes from 'prop-types';
import Table from 'components/ReusableComponents/Table';
import { ReactComponent as CoinIcon } from 'Assets/images/coins.svg';

const medal = [
    <img key={0} src={require('Assets/images/gold-medal.svg')} alt='1st' />,
    <img key={1} src={require('Assets/images/silver-medal.svg')} alt='2nd' />,
    <img key={2} src={require('Assets/images/bronze-medal.svg')} alt='3rd' />,
];

const Index = ({ nameForPoints, data, currentPage, setCurrentPage, totalCount, showMyRank, isFetching, history }) => {
    const header = showMyRank ? [] : ['', 'rank', 'name', nameForPoints];
    const noSortColumns = [0, 1, 2, 3];
    const tableData = data?.map((user) => ({
        id: user._id,
        row: [
            '',
            user.rank > 3 ? `${user.rank}.` : medal[user.rank - 1],
            <span key={user._id} className='leaderboard-user-name ew-btn tb-btn'>
                <img
                    src={user.pictureURL || require('Assets/images/defaultUser.png')}
                    className='avatar-32 mr-3'
                    alt=''
                />
                {user.userName}
            </span>,
            <span key={user._id}>
                <CoinIcon className='mr-2' />
                {user.points}
            </span>,
        ],
    }));
    const paginationProps = {
        currentPage,
        totalCount,
        setCurrentPage,
    };
    const columnWidth = ['12%', '12%', '44%', '32%'];
    return (
        <Table
            header={header}
            data={tableData}
            noSortColumns={noSortColumns}
            paginationProps={showMyRank ? undefined : paginationProps}
            loading={isFetching}
            columnWidth={columnWidth}
        />
    );
};

Index.propTypes = {
    nameForPoints: PropTypes.string,
    data: PropTypes.array,
    currentPage: PropTypes.number,
    setCurrentPage: PropTypes.func,
    totalCount: PropTypes.number,
    showMyRank: PropTypes.bool,
    isFetching: PropTypes.bool,
    history: PropTypes.object,
};

export default Index;
