import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as WarningIcon } from 'Assets/images/warning-red.svg';
import { ReactComponent as TouchIcon } from 'Assets/images/touch.svg';
import { AWARD_STATUS } from '../../constants';
import { format, isBefore } from 'date-fns';
import { Tooltip } from 'components';

const Index = ({ award, status }) => {
    if (award.winner?.length < 2) return <></>;

    const icon =
        status === AWARD_STATUS.ONGOING ? (
            <WarningIcon height={17} width={19} className='error-tooltip-span pointer-cursor' />
        ) : (
            <TouchIcon height={17} width={19} className='pointer-cursor' />
        );
    const hasEndDatePassed = isBefore(new Date(award.endDate), new Date());

    if (status === AWARD_STATUS.ONGOING && award.responseCount > 1) {
        return (
            <Tooltip title={'There is a tie in votes, you need to pick a winner amongst the tied nominees.'}>
                {icon}
            </Tooltip>
        );
    } else if (status === AWARD_STATUS.ONGOING && award.responseCount === 0 && hasEndDatePassed) {
        return <Tooltip title={'The poll did not receive any votes.'}>{icon}</Tooltip>;
    } else if (status === AWARD_STATUS.COMPLETED && award.responseCount > 1) {
        return (
            <Tooltip
                title={`There was a tie in poll votes, between ${award.winner.length} nominees at ${award.votes}/${
                    award.requestedCount
                } votes. The winner was picked by- ${award.awardedBy.userName} on ${format(
                    new Date(award.awardDate),
                    'MMM dd, yyyy'
                )}`}
            >
                {icon}
            </Tooltip>
        );
    } else if (status === AWARD_STATUS.COMPLETED && award.responseCount === 0) {
        return (
            <Tooltip
                title={`The poll did not receive any votes. The winner was picked by- ${
                    award.awardedBy.userName
                } on ${format(new Date(award.awardDate), 'MMM dd, yyyy')}`}
            >
                {icon}
            </Tooltip>
        );
    }

    return <></>;
};

Index.propTypes = {
    award: PropTypes.object,
    status: PropTypes.string,
};

export default Index;
