import React, { useState, useEffect } from 'react';
import GiftCardCouponModal from 'components/Modals/GiftCardCouponModal';
import { useToaster } from 'Context/SnackbarContext';
import queryString from 'query-string';
import CustomLoader from 'components/ReusableComponents/CustomLoader';
import { handleValidity, REDEEM_POINTS } from '../constant';
import TitleBar from 'components/ReusableComponents/TitleBar';
import { formatNumber, showSnackBarMessage } from 'utils/HelperFunctions';
import RedeemCard from '../RedeemCard';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { updateUserProfile } from 'redux/user/actions';
import { updateRedemptionWalletDetails } from 'redux/payments/actions';
import TermsAndCondition from '../TermsAndCondition';
import { getVoucherDetails, placeOrder } from 'Services/apiFunctions';
import PropTypes from 'prop-types';
import './style.scss';

const Index = ({ history }) => {
    const [key, setKey] = useState('tnc');
    const { xoxoday, nameForPoints, userCurrency, userEmail, combinedRedeemBalance, workspaceWallet } = useSelector(
        mapStateToProps,
        shallowEqual
    );
    const dispatch = useDispatch();
    const { SetSnackbar } = useToaster();
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState(undefined);
    const [denominations, setDenominations] = useState(undefined);
    const [selectedDenomination, setSelectedDenomination] = useState(0);
    const [customReward, setCustomReward] = useState(false);
    // when user does not haveminimum points to redeem a card, we disable it
    const [disableCard, setDisableCard] = useState(false);
    // when points remaining is less than the least denomination, showing the user the minimum points required to redeem
    const [minimumPoints, setMinimumPoints] = useState(0);
    // default name shown in the filter drop down while selecting denomination value for a voucher
    let selectedDenominationName = 'Select';

    if (selectedDenomination) {
        selectedDenominationName = `${userCurrency} ${selectedDenomination}`;
    }

    const getDenominationLabel = (denominationValue) => `${userCurrency} ${denominationValue}`;

    const getBalanceRemaining = (qty) => {
        const remainingBalance = combinedRedeemBalance - selectedDenomination * qty;
        return parseFloat(remainingBalance.toFixed(2));
    };

    const getProductDetails = async (id) => {
        setLoading(true);
        try {
            const product = await getVoucherDetails({ id, initiationStage: 'redeem' });
            const data = { ...product?.details, productId: id };
            setSelectedProduct(data);
            setCustomReward(!!data?.isCustom);
            setKey(data?.termsAndConditionsInstructions ? 'tnc' : 'redeem');
            setLoading(false);
        } catch (err) {
            showSnackBarMessage(SetSnackbar, 'error', err?.message);
            setLoading(false);
        }
    };

    const setValidDenominations = (denominationsData, userBalance) => {
        let validDenominations = denominationsData
            .split(',')
            .sort((a, b) => parseInt(b) - parseInt(a))
            .map((denomination) => ({ label: getDenominationLabel(denomination), value: denomination }));

        // disabling card when points remaining is less than the least denomination value
        if (Number(userBalance) < Number(validDenominations[validDenominations.length - 1]?.value)) {
            setDisableCard(true);
            setMinimumPoints(validDenominations[validDenominations.length - 1]?.value);
        }

        // showing only those denominations which are less than or equal to the points remaining
        validDenominations = validDenominations.filter((denomination) => denomination.value <= userBalance);

        // when we have only one value of denomination, we select it by default
        if (validDenominations.length === 1) {
            selectedDenominationName = validDenominations[0].value;
            setSelectedDenomination(validDenominations[0].value);
        }

        setDenominations(validDenominations);
    };

    const handlePurchase = (couponQuantity) => {
        orderGiftCard(couponQuantity);
    };

    const handleGiftCardSelection = (index) => {
        const tempFilter = JSON.parse(JSON.stringify(denominations));
        setSelectedDenomination(tempFilter[index].value);
    };

    const orderGiftCard = async (couponQuantity) => {
        setLoading(true);
        try {
            const apiData = {
                productId: selectedProduct.productId,
                quantity: couponQuantity,
                denomination: selectedDenomination,
            };
            const data = await placeOrder({ apiData });
            const temp = xoxoday || {};
            temp.combinedRedeemBalance = combinedRedeemBalance - selectedDenomination * couponQuantity;
            updateUserProfile(dispatch, { combinedRedeemBalance: temp.combinedRedeemBalance });
            setOpen(true);
            setLoading(false);
            setSelectedDenomination(0);
            setValidDenominations(selectedProduct?.valueDenominations, temp.combinedRedeemBalance);
            if (selectedProduct?.isCustom && selectedProduct?.customCouponRemaining > 0) {
                setSelectedProduct({
                    ...selectedProduct,
                    customCouponRemaining: selectedProduct.customCouponRemaining - 1,
                });
            }
            // wallet update
            if (data.updatedWalletBalance) {
                updateRedemptionWalletDetails(dispatch, {
                    workspaceWallet: { ...workspaceWallet, balance: parseFloat(data.updatedWalletBalance) },
                });
            }
        } catch (err) {
            showSnackBarMessage(SetSnackbar, 'error', err?.message);
            setLoading(false);
        }
    };

    useEffect(() => {
        const data = queryString.parse(window?.location?.search);
        if (data?.id) {
            getProductDetails(data.id);
        }
    }, []);

    useEffect(() => {
        if (selectedProduct?.valueDenominations) {
            setValidDenominations(selectedProduct.valueDenominations, combinedRedeemBalance);
        }
    }, [nameForPoints, selectedProduct, xoxoday]);

    if (loading) {
        return <CustomLoader />;
    }

    return (
        <div className='redeem-points-container'>
            <div className='title-container'>
                <TitleBar
                    title={`Redeem ${nameForPoints}`}
                    back
                    onBack={() => history.push(REDEEM_POINTS.LIST)}
                    showBalance
                    fromRedeemPoints={true}
                    balance={formatNumber(combinedRedeemBalance || 0)}
                    currencySymbol={userCurrency}
                />
            </div>

            <div className='main-container'>
                <RedeemCard
                    selectedProduct={selectedProduct}
                    customReward={customReward}
                    denominations={denominations}
                    selectedDenomination={selectedDenomination}
                    selectedDenominationName={selectedDenominationName}
                    getBalanceRemaining={getBalanceRemaining}
                    currencySymbol={userCurrency}
                    handleGiftCardSelection={handleGiftCardSelection}
                    handleValidity={handleValidity}
                    handlePurchase={handlePurchase}
                    getDenominationLabel={getDenominationLabel}
                    // we are checking if the remaining points are lesser than the least value of voucher denomination in the dropdown, is yes -> we show the disabled state, else we show the value selection dropdown
                    disabled={disableCard}
                    minimumPoints={minimumPoints}
                    combinedRedeemBalance={combinedRedeemBalance}
                />
                <TermsAndCondition selectedProduct={selectedProduct} activeKey={key} setKey={setKey} />
            </div>
            {open && (
                <GiftCardCouponModal
                    open={open}
                    setOpen={setOpen}
                    tnc={selectedProduct?.termsAndConditionsInstructions}
                    redeemInstructions={selectedProduct?.redemptionInstructions}
                    redeemCoupon={true}
                    backButtonText='Back to Redeem Points'
                    couponName={selectedProduct?.name}
                    deliveryType={selectedProduct.deliveryType}
                    turnAroundTime={selectedProduct.tat}
                    userEmail={userEmail}
                />
            )}
        </div>
    );
};

const mapStateToProps = ({ Xoxoday, User, Workspace, Payments }) => ({
    xoxoday: Xoxoday,
    nameForPoints: Workspace.nameForPoints,
    userCurrency: User.userCurrency,
    userEmail: User.email,
    combinedRedeemBalance: User.combinedRedeemBalance,
    workspaceWallet: Payments?.workspaceWallet,
});

Index.propTypes = {
    history: PropTypes.object,
};

export default Index;
