import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

const Index = ({ match }) => {
    useEffect(() => {
        window.open(
            match.path.includes('terms')
                ? process.env.REACT_APP_LANDER_BASE_URL + '/tnc'
                : process.env.REACT_APP_LANDER_BASE_URL + match.path,
            '_self'
        );
    }, []);
    return <div />;
};

Index.propTypes = {
    match: PropTypes.object,
};

export default Index;
