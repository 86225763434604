import React from 'react';
import LinearProgress from 'components/ReusableComponents/LinearProgress';
import './style.scss';
import VertMenu from 'components/ReusableComponents/VertMenu';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import PropTypes from 'prop-types';

const Index = ({ category, label, color, value, list, handleChange }) => {
    const progress = (value + 93) / 2;
    return (
        <div>
            {category && label && <div className='score-category'>Other Industries:</div>}
            <div className='scale'>
                <div className='score-label'>
                    <p className='d-inline-block  mt-3'>{label} </p>
                    {list?.length > 0 && (
                        <p className='benchmark-dropdown d-inline-block'>
                            {list?.length > 1 && (
                                <VertMenu
                                    optionMenu={list}
                                    getSelected={(optionIndex) => {
                                        handleChange('otherIndustry', optionIndex);
                                    }}
                                >
                                    <KeyboardArrowDownIcon />
                                </VertMenu>
                            )}
                        </p>
                    )}
                </div>
                <div className='scale-bar d-inline-block'>
                    {!isNaN(value) && (
                        <div className='d-inline-block' style={{ marginLeft: `${progress}%`, height: '38px' }}>
                            <p className='d-inline-block tag-score'>{value}</p>
                            <svg
                                width='30'
                                height='35'
                                viewBox='0 0 30 35'
                                fill={color}
                                xmlns='http://www.w3.org/2000/svg'
                            >
                                <path
                                    d='M18.9448 28.5181L15.2804 34.4333L10.8857 28.5958L18.9448 28.5181Z'
                                    fill={color}
                                />
                                <rect width='30' height='30.4135' rx='4' fill={color} />
                            </svg>
                        </div>
                    )}
                    {label && <LinearProgress color={color} value={((!isNaN(value) ? value : -100) + 100) / 2} />}
                    {!isNaN(value) && (
                        <div className='scale-marks d-flex'>
                            <p>{-100}</p>
                            <p>{-50}</p>
                            <p>{0}</p>
                            <p>{50}</p>
                            <p>{100}</p>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

Index.propTypes = {
    category: PropTypes.bool,
    label: PropTypes.string,
    color: PropTypes.string,
    value: PropTypes.number,
    list: PropTypes.array,
    handleChange: PropTypes.func,
};

export default Index;
