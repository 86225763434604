import React from 'react';
import PropTypes from 'prop-types';
import InputField from 'components/ReusableComponents/InputField';
import ErrorField from 'components/ReusableComponents/ErrorField';
import './style.scss';

const Allowance = ({ allowance, handleChange }) => {
    const error = allowance && allowance < 1;
    return (
        <div className='setup-container'>
            <InputField
                inputID={'allowance'}
                label={'MONTHLY ALLOWANCE'}
                value={allowance}
                placeholder={'Enter number of points '}
                handleChange={handleChange}
            />

            {!!error && <ErrorField errorText={'Please enter a numeric value greater than 0 to continue'} />}
        </div>
    );
};

Allowance.propTypes = {
    allowance: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    handleChange: PropTypes.func,
};

export default Allowance;
