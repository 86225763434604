import React from 'react';
import PropTypes from 'prop-types';
import InfoIcon from '@material-ui/icons/Info';
import { Tooltip } from 'components';
import { ReactComponent as WarningIcon } from 'Assets/images/warning.svg';
import { EDIT_TYPE } from '../../constants';

const Stepper = ({ step, warn1, warn3, setStep, isEditingRolling, isEditingRecurring, fetchLoading, editType }) => {
    const tooltipTitle = isEditingRecurring
        ? 'Changes made in schedule will reflect in the next instance of the pulse.'
        : 'You cannot edit schedule for rolling pulse.';
    return (
        <div className={`create-pulse-stepper step-active-${step}`}>
            <button onClick={() => (fetchLoading ? null : setStep(0))}>
                {warn1 && <WarningIcon alt='' />} Create Questions
            </button>
            <div />
            <button onClick={() => (fetchLoading ? null : setStep(1))}>
                Schedule{' '}
                {isEditingRolling || (isEditingRecurring && editType === EDIT_TYPE.FUTURE) ? (
                    <Tooltip title={tooltipTitle}>
                        <InfoIcon />
                    </Tooltip>
                ) : null}
            </button>{' '}
            <div />
            <button onClick={() => (fetchLoading ? null : setStep(2))}>{warn3 && <WarningIcon />} Audience</button>
        </div>
    );
};

Stepper.propTypes = {
    step: PropTypes.number,
    warn1: PropTypes.bool,
    warn3: PropTypes.bool,
    setStep: PropTypes.func,
    isEditingRolling: PropTypes.bool,
    isEditingRecurring: PropTypes.bool,
    fetchLoading: PropTypes.bool,
    editType: PropTypes.string,
};

export default Stepper;
