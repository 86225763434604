import React, { useState } from 'react';
import PropTypes from 'prop-types';
import MultiSelectionUserDropdown from 'components/ReusableComponents/MultiSelectionUserDropdown';
import { find } from 'lodash';
import MemberCard from 'components/ReusableComponents/MemberDetailsCard';
import EWModal from 'components/ReusableComponents/EWModal';
import './style.scss';

const Index = ({ open, width = '528px', modalData, onSave }) => {
    const [selectedUsers, setSelectedUsers] = useState(modalData.selectedUser);
    const [removedUsers, setRemovedUsers] = useState([]);

    const handleUserSelection = (user) => {
        if (find(selectedUsers, (selectedUser) => selectedUser._id === user._id)) {
            setSelectedUsers(selectedUsers.filter((item) => item._id !== user._id));
            setRemovedUsers([...removedUsers, user]);
        } else {
            setRemovedUsers(removedUsers.filter((item) => item._id !== user._id));
            setSelectedUsers([...selectedUsers, user]);
        }
    };
    const onSaveHandler = () => {
        onSave(selectedUsers, removedUsers);
    };
    modalData = {
        ...modalData,
        handleRightButtonClick: onSaveHandler,
    };

    return (
        <EWModal open={open} onClose={modalData?.handleLeftButtonClick} width={width} modalData={modalData}>
            <div className='container-user-selection-modal'>
                <MultiSelectionUserDropdown
                    id='selectManager'
                    title='Select Users'
                    selectedName={selectedUsers?.length > 0 ? `Selected (${selectedUsers.length})` : 'Search for users'}
                    buttonWidth={'480px'}
                    dropdownWidth={'480px'}
                    selectedUsers={selectedUsers}
                    handleSelection={handleUserSelection}
                    placeholder='Search for users'
                    optionsSelected={selectedUsers?.length > 0}
                />

                <div className='container-selection'>
                    {selectedUsers?.length > 0 && (
                        <div>
                            <p className='selection-list-header'>Selected users for this Manager</p>
                            <div className='selection-list'>
                                {selectedUsers.map((member) => (
                                    <MemberCard
                                        key={member._id}
                                        member={member}
                                        selected={
                                            !!find(selectedUsers, (selectedUser) => selectedUser._id === member._id)
                                        }
                                        handleChange={() => handleUserSelection(member)}
                                    />
                                ))}
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </EWModal>
    );
};

Index.propTypes = {
    open: PropTypes.bool,
    width: PropTypes.string,
    modalData: PropTypes.object,
    onSave: PropTypes.func,
};

export default Index;
