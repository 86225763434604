import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import Questions from './Questions';
import Library from './Library';
import { EDIT_TYPE } from '../../constants';
import './style.scss';
import { formatQuestion, getAddedQuestions, getStandardQuestions, formatStandardQuestions } from './componentUtil';
import { shallowEqual, useSelector } from 'react-redux';
import { getENPSQuestionTitle } from 'components/Dashboard/Content/ENPS/constant';

const Index = ({
    quesAdded,
    pulseData,
    eNPS,
    questions,
    setQuestions,
    questionWarn,
    setQuestionWarn,
    openQuestion,
    setOpenQuestion,
    isEditingOnetime,
    isEditingRolling,
    isEditingRecurring,
    editType,
    editId,
    draftId,
    setDeletedQuestions,
    setENPSQuestion,
    eNPSQuestion,
    setUpdatePromptModalOpen,
    isAIGenerated,
    aiUserPrompt,
}) => {
    const [addedLibraryQuestion, setAddedLibraryQuestion] = useState({});
    const { companyName } = useSelector(mapStateToProps, shallowEqual);

    const isENPsEditing = eNPS && (editId || draftId);
    const isAnyEditingMode = isEditingOnetime || isEditingRolling || editType === EDIT_TYPE.ONGOING;

    const eNPSQuestionsList = [getENPSQuestionTitle(companyName), 'What is the main reason for your score ?'];

    const addQuestionButtonRef = useRef();

    const scrollToAddQuestionButton = () => {
        addQuestionButtonRef.current.scrollIntoView();
    };

    useEffect(() => {
        if (pulseData && !quesAdded) {
            if (!isENPsEditing) {
                setQuestions(formatQuestion(pulseData?.questions));
            } else {
                const standardQuestions = getStandardQuestions(pulseData.questions, eNPSQuestionsList);
                const addedQuestions = getAddedQuestions(pulseData.questions, eNPSQuestionsList);
                setOpenQuestion(addedQuestions?.length - 1);
                const formattedStandardQuestions = formatStandardQuestions(standardQuestions);
                const formattedAddedQuestions = formatQuestion(addedQuestions);
                if (!draftId) {
                    setENPSQuestion(formattedStandardQuestions);
                }
                setQuestions(formattedAddedQuestions);
            }
            scrollToAddQuestionButton();
        }
    }, [pulseData]);

    return (
        <div className='pulse-questionnaire'>
            <div className='question-container'>
                <Questions
                    questions={questions}
                    setQuestions={setQuestions}
                    addedLibraryQuestion={addedLibraryQuestion}
                    setAddedLibraryQuestion={setAddedLibraryQuestion}
                    eNPS={eNPS}
                    eNPSQuestion={eNPSQuestion}
                    setENPSQuestion={setENPSQuestion}
                    questionWarn={questionWarn}
                    setQuestionWarn={setQuestionWarn}
                    openQuestion={openQuestion}
                    setOpenQuestion={setOpenQuestion}
                    isEditingOnetime={isEditingOnetime}
                    isEditingRolling={isEditingRolling}
                    isEditingRecurring={isEditingRecurring}
                    editId={editId}
                    editType={editType}
                    setDeletedQuestions={setDeletedQuestions}
                    ref={addQuestionButtonRef}
                    setUpdatePromptModalOpen={setUpdatePromptModalOpen}
                    isAIGenerated={isAIGenerated}
                    aiUserPrompt={aiUserPrompt}
                />
            </div>
            {!eNPS && !isAnyEditingMode && (
                <Library
                    questions={questions}
                    setQuestions={setQuestions}
                    addedLibraryQuestion={addedLibraryQuestion}
                    setAddedLibraryQuestion={setAddedLibraryQuestion}
                    openQuestion={openQuestion}
                    setOpenQuestion={setOpenQuestion}
                    scrollToAddQuestionButton={scrollToAddQuestionButton}
                />
            )}
        </div>
    );
};
const mapStateToProps = (state) => ({ companyName: state.Workspace.companyName });

Index.propTypes = {
    quesAdded: PropTypes.bool,
    pulseData: PropTypes.object,
    eNPS: PropTypes.bool,
    questions: PropTypes.array,
    setQuestions: PropTypes.func,
    questionWarn: PropTypes.object,
    setQuestionWarn: PropTypes.func,
    openQuestion: PropTypes.number,
    setOpenQuestion: PropTypes.func,
    isEditingOnetime: PropTypes.bool,
    isEditingRolling: PropTypes.bool,
    isEditingRecurring: PropTypes.bool,
    editType: PropTypes.oneOf(Object.values(EDIT_TYPE)),
    editId: PropTypes.string,
    draftId: PropTypes.string,
    setDeletedQuestions: PropTypes.func,
    eNPSQuestion: PropTypes.array,
    setENPSQuestion: PropTypes.func,
    setUpdatePromptModalOpen: PropTypes.func,
    isAIGenerated: PropTypes.bool,
    aiUserPrompt: PropTypes.string,
};

export default Index;
