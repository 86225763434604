import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as CloseIcon } from 'Assets/images/Close.svg';
import { format } from 'date-fns';
import clsx from 'clsx';
import './styles.scss';

const NotificationItem = ({ data, renderNotification, history, handleClose, navigateTo }) => {
    const handleClick = (e) => {
        if (navigateTo) {
            history.push(navigateTo);
        } else {
            e.preventDefault();
        }
    };
    return (
        <button className='notification' onClick={handleClick}>
            <div className={clsx('notification-item', { 'notification-unread': data.isUnread })}>
                <div className='notification-content-container'>
                    <p className='notification-date'>{format(new Date(data.createdAt), 'MMM dd yyyy, h:mm aa')}</p>
                    <div className='notification-render'>{renderNotification({ data, history })}</div>
                </div>
                <button
                    className='notification-close-btn'
                    onClick={(e) => {
                        e.stopPropagation();
                        handleClose(data._id);
                    }}
                >
                    <CloseIcon />
                </button>
            </div>
        </button>
    );
};

NotificationItem.propTypes = {
    data: PropTypes.object,
    renderNotification: PropTypes.func,
    history: PropTypes.object,
    handleClose: PropTypes.func,
    navigateTo: PropTypes.string,
};

export default NotificationItem;
