import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';

const Index = ({ imageURL, userName }) => {
    return (
        <div className='container-user-tag' data-testid='user-tag'>
            <img src={imageURL || require('Assets/images/defaultUser.png')} alt='user' />
            {userName}
        </div>
    );
};

Index.propTypes = {
    imageURL: PropTypes.string,
    userName: PropTypes.string,
};

export default Index;
