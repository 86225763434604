import PropTypes from 'prop-types';
import React from 'react';
import './style.scss';

const EnpsResponder = ({ label, count, percentage, backgroundColor }) => {
    return (
        <div className='score'>
            <div>
                <div className='enps-bullet' style={{ backgroundColor }} />
                <p>{label}</p>
            </div>
            <p>
                <strong>{Math.round(percentage) || 0}%</strong> ({Math.round(count)} responses)
            </p>
        </div>
    );
};

const Index = ({ getENPSScoreColor, enpsInfo }) => {
    const score = Math.round(enpsInfo?.eNPSScore);
    return (
        <div className='enps-tip'>
            <div className='score'>
                <span>eNPS Score</span>
                <div
                    className='p-2 text-center enpsScore'
                    style={{ backgroundColor: score ? getENPSScoreColor(score) : '#E5E7EA' }}
                >
                    {score || 0}
                </div>
            </div>
            <hr />
            <EnpsResponder
                label='Promoter'
                count={enpsInfo?.Promoter?.count}
                percentage={enpsInfo?.Promoter?.percentage}
                backgroundColor='#32dba1'
            />
            <EnpsResponder
                label='Detractor'
                count={enpsInfo?.Detractor?.count}
                percentage={enpsInfo?.Detractor?.percentage}
                backgroundColor='#ff6760'
            />
            <EnpsResponder
                label='Passive'
                count={enpsInfo?.Passive?.count}
                percentage={enpsInfo?.Passive?.percentage}
                backgroundColor='#fac032'
            />
        </div>
    );
};

EnpsResponder.propTypes = {
    label: PropTypes.string,
    count: PropTypes.number,
    percentage: PropTypes.number,
    backgroundColor: PropTypes.string,
};

Index.propTypes = {
    enpsInfo: PropTypes.object,
    getENPSScoreColor: PropTypes.func,
};

export default Index;
