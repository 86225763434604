import React from 'react';
import { ReactComponent as ShowFeedbackMessageIcon } from 'Assets/images/show-feedback-message.svg';

export const FEEDBACK = {
    HOME: '/dashboard/feedback',
    SETUP_FEEDBACK: '/dashboard/feedback/setup-feedback',
    MY_FEEDBACK: '/dashboard/feedback/my-feedback',
    CONFIGURATION: '/dashboard/feedback/configuration',
    ADD_CATEGORIES: '/dashboard/feedback/configuration/add-categories',
};
export const FEEDBACK_CATEGORY_LENGTH_LIMIT = 75;

export const FEEDBACK_360 = '360º Feedback';
export const MY_FEEDBACK = 'my-feedback';
export const CONFIGURATION = 'feedback/configuration';
export const TEAM = 'TEAM';
export const ORGANIZATION = 'ORGANIZATION';

export const DEFAULT_FEEDBACK_SETTINGS = {
    enabled: false,
    feedbackLevel: ORGANIZATION,
    allowAnonymous: false,
    sendNudges: false,
    categories: 0,
};

export const FEEDBACK_TABLE_MENU_OPTIONS = [
    {
        label: (
            <span>
                <ShowFeedbackMessageIcon className='mr-2 pointer-cursor' /> Show message
            </span>
        ),
        value: 'showMessage',
    },
];

// disabled nudges for v1 release
const ENABLE_NUDGES = true;

/**
 * Get the config settings. By default settings will be disabled.
 * @param {boolean} enabled
 * @param {boolean} allowAnonymous
 * @param {boolean} sendNudges
 * @returns config data
 */
export const getFeedbackConfigs = (enabled = false, allowAnonymous = false, categories = 0, sendNudges = false) => [
    {
        heading: '360º feedback',
        subtext:
            'Enable 360º feedback to help employees share constructive and actionable feedback to their co-workers',
        isEnabled: enabled,
        key: 'enabled',
    },
    {
        heading: `Allow anonymous feedback`,
        subtext: 'If enabled, employees will be able to send 360º feedback anonymously',
        isEnabled: allowAnonymous,
        key: 'allowAnonymous',
    },
    {
        heading: `Feedback Categories(${categories})`,
        subtext:
            'Manage feedback categories to enable employees to identify areas of strength and opportunities for improvements effectively.',
        buttonText: categories ? 'Manage' : 'Add Categories',
        redirectUrl: '/dashboard/feedback/configuration/add-categories',
        key: 'addCategories',
    },
    ...(ENABLE_NUDGES
        ? [
              {
                  heading: `Nudge users every month`,
                  subtext: 'Employees will get monthly reminders to send feedback to their colleagues',
                  isEnabled: sendNudges,
                  key: 'sendNudges',
              },
          ]
        : []),
];

export const getOptions = (data) => {
    return data.map((option) => ({
        ...option,
        label: option,
        isChecked: false,
    }));
};

export const addCategoriesSubText = `When providing 360º feedback, categories can be attached to it, enabling employees to deliver targeted feedback
        that highlights their colleagues' strengths and identifies areas for improvement`;
