import React, { useState, useEffect } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import Modal from '@material-ui/core/Modal';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import CustomLoader from 'components/ReusableComponents/CustomLoader';

const Index = ({ open, onClose, data, height = 'fit-content', width = 'fit-content' }) => {
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (data?.img) {
            const image = new Image();
            image.src = data.img;
            image.onload = () => setLoading(false);
        }
    }, [data?.img]);

    return (
        <Modal open={open} onClose={() => onClose(false)}>
            <div className='confirm-container' style={{ height, width, borderRadius: 0 }}>
                <CloseIcon className='btn-close' onClick={() => onClose(false)} />
                <h3 className={clsx({ heading: true })}>{data?.heading}</h3>
                <div className='d-flex flex-column justify-content-center align-items-center mt-3'>
                    {loading && <CustomLoader />}
                    {!loading && <img src={data?.img} alt='' style={{ minWidth: 500, minHeight: 400 }} />}
                </div>
            </div>
        </Modal>
    );
};

Index.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    data: PropTypes.object,
    height: PropTypes.string,
    width: PropTypes.string,
};

export default Index;
