import React from 'react';
import { ReactComponent as MedalIcon } from 'Assets/images/noun-reward.svg';
import { useSelector, shallowEqual } from 'react-redux';
import './styles.scss';

const Index = () => {
    const { nameForPoints, pointsToRecognize, monthlyAllowance } = useSelector(mapStateToProps, shallowEqual);
    return (
        <div className='rec-bal-container'>
            <div className='rec-bal-top-container'>
                <p className='rec-bal-title'>
                    <MedalIcon />
                    Recognition balance
                </p>
                <p className='points-txt'>
                    {pointsToRecognize} {nameForPoints}
                </p>
            </div>
            <div className='rec-bal-bottom-container'>
                <p className='allowance-txt'>
                    Monthly allowance:{' '}
                    <span className='allowance-val'>
                        {monthlyAllowance} {nameForPoints}
                    </span>
                </p>
            </div>
        </div>
    );
};

const mapStateToProps = ({ Workspace, User }) => ({
    nameForPoints: Workspace.nameForPoints,
    pointsToRecognize: User.creditBalance,
    monthlyAllowance: User.monthlyAllowance ?? 0,
});

export default Index;
