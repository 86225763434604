export const LOCALSTORAGE_KEYS = {
    ONBOARDING_C_STEP: 'onboarding-last-completed-step',
    ONBOARDING_REWARDS_SAVED: 'onboarding-rewards-saved-data',
    ONBOARDING_MANAGER_MAP: 'onboarding-manager-member-map',
    ONBOARDING_SELECTED_CHANNELS: 'onboarding-selected-channels',
    ONBOARDING_RECOGNITION_SETUP: 'onboarding-recognition-setup',
    ONBOARDING_FINISHUP_DATA: 'onboarding-finishup-data',
    ONBOARDING_REWARDS_ENABLED: 'onboarding-enable-rewards',
    ONBORDING_COMPLETE: 'onboarding-complete',
    SELECT_MANAGER_DATA: 'select-manager-data',
    SCHEDULED_AWARD_DATA: 'scheduled-award-data',
    NAVIGATION_FROM: 'navigation-from',
};
