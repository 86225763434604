import React from 'react';
import PropTypes from 'prop-types';
// Active
import { ReactComponent as leaderboardActive } from 'Assets/images/leaderboard-active.svg';
import { ReactComponent as reedemActive } from 'Assets/images/redeem-active.svg';
import { ReactComponent as pulseActive } from 'Assets/images/pulse-active.svg';
import { ReactComponent as enpsiconActive } from 'Assets/images/enps-icon-active.svg';
import { ReactComponent as catalogActive } from 'Assets/images/catalog-active.svg';
//un-active
import { ReactComponent as leaderboardUnActive } from 'Assets/images/leaderboard-un-active.svg';
import { ReactComponent as myactivitiesUnActive } from 'Assets/images/my-activities-un-active.svg';
import { ReactComponent as redeemUnActive } from 'Assets/images/redeem-un-active.svg';
import { ReactComponent as pulseUnActive } from 'Assets/images/pulse-un-active.svg';
import { ReactComponent as organizationUnActive } from 'Assets/images/organization-un-active.svg';
import { ReactComponent as allactivitiesUnActive } from 'Assets/images/all-activities-un-active.svg';
import { ReactComponent as analyticsUnActive } from 'Assets/images/analytics-un-active.svg';
import { ReactComponent as catalogUnActive } from 'Assets/images/catalog-un-active.svg';
//un
import { ReactComponent as leaderboardUn } from 'Assets/images/leaderboard-un.svg';
import { ReactComponent as myactivitiesUn } from 'Assets/images/my-activities-un.svg';
import { ReactComponent as redeemUn } from 'Assets/images/redeem-un.svg';
import { ReactComponent as pulseUn } from 'Assets/images/pulse-un.svg';
import { ReactComponent as organizationUn } from 'Assets/images/organization-un.svg';
import { ReactComponent as allactivitiesUn } from 'Assets/images/all-activities-un.svg';
import { ReactComponent as analyticsUn } from 'Assets/images/analytics-un.svg';
import { ReactComponent as catalogUn } from 'Assets/images/catalog-un.svg';

//normal
import { ReactComponent as reward } from 'Assets/images/noun-reward.svg';
import { ReactComponent as leaderboard } from 'Assets/images/leaderboard.svg';
import { ReactComponent as myactivities } from 'Assets/images/my-activities.svg';
import { ReactComponent as redeem } from 'Assets/images/redeem.svg';
import { ReactComponent as pulse } from 'Assets/images/pulse.svg';
import { ReactComponent as organization } from 'Assets/images/users.svg';
import { ReactComponent as allactivities } from 'Assets/images/all-activities.svg';
import { ReactComponent as enpsicon } from 'Assets/images/enps-icon.svg';
import { ReactComponent as analytics } from 'Assets/images/analytics.svg';
import { ReactComponent as catalog } from 'Assets/images/catalog.svg';
import { ReactComponent as engagement } from 'Assets/images/noun-discussion.svg';
import { ReactComponent as userLeaderboard } from 'Assets/images/noun-leaderboard.svg';
import { ReactComponent as celebrations } from 'Assets/images/noun-party.svg';
import { ReactComponent as userRedeemPoints } from 'Assets/images/user-redeem-points.svg';
import { ReactComponent as employeeAwards } from 'Assets/images/employeeAwardSidenavIcon.svg';
import { ReactComponent as redemptions } from 'Assets/images/redemption.svg';
import { ReactComponent as feedback } from 'Assets/images/feedback-icon.svg';
import { ReactComponent as suggestionBox } from 'Assets/images/suggestion-box-icon.svg';
import { ReactComponent as home } from 'Assets/images/home-icon.svg';

const NavIcon = ({ menu, colorCode, location }) => {
    const iconMenu = {
        //normal
        rewards: { icon: reward },
        leaderboard: { icon: leaderboard },
        myactivities: { icon: myactivities },
        redeem: { icon: redeem },
        pulse: { icon: pulse },
        organization: { icon: organization },
        allactivities: { icon: allactivities },
        enpsicon: { icon: enpsicon },
        analytics: { icon: analytics },
        catalog: { icon: catalog },
        celebrations: { icon: celebrations },
        employeeAwards: { icon: employeeAwards },
        redemptions: { icon: redemptions },
        engagement: { icon: engagement },
        userLeaderboard: { icon: userLeaderboard },
        userRedeemPoints: { icon: userRedeemPoints },
        feedback: { icon: feedback },
        suggestionBox: { icon: suggestionBox },
        home: { icon: home },
        //-active
        rewardsActive: { icon: reward },
        leaderboardActive: { icon: leaderboardActive },
        myactivitiesActive: { icon: myactivities },
        redeemActive: { icon: reedemActive },
        pulseActive: { icon: pulseActive },
        organizationActive: { icon: organization },
        allactivitiesActive: { icon: allactivities },
        enpsiconActive: { icon: enpsiconActive },
        analyticsActive: { icon: analytics },
        catalogActive: { icon: catalogActive },
        celebrationsActive: { icon: celebrations },
        engagementActive: { icon: engagement },
        userLeaderboardActive: { icon: userLeaderboard },
        userRedeemPointsActive: { icon: userRedeemPoints },
        employeeAwardsActive: { icon: employeeAwards },
        feedbackActive: { icon: feedback },
        suggestionBoxActive: { icon: suggestionBox },
        homeActive: { icon: home },

        //-un
        rewardsUn: { icon: reward },
        leaderboardUn: { icon: leaderboardUn },
        myactivitiesUn: { icon: myactivitiesUn },
        redeemUn: { icon: redeemUn },
        pulseUn: { icon: pulseUn },
        organizationUn: { icon: organizationUn },
        allactivitiesUn: { icon: allactivitiesUn },
        analyticsUn: { icon: analyticsUn },
        catalogUn: { icon: catalogUn },
        celebrationsUn: { icon: celebrations },
        engagementUn: { icon: engagement },
        userRedeemPointsUn: { icon: userRedeemPoints },
        //un-active
        leaderboardUnActive: { icon: leaderboardUnActive },
        myactivitiesUnActive: { icon: myactivitiesUnActive },
        redeemUnActive: { icon: redeemUnActive },
        pulseUnActive: { icon: pulseUnActive },
        organizationUnActive: { icon: organizationUnActive },
        allactivitiesUnActive: { icon: allactivitiesUnActive },
        analyticsUnActive: { icon: analyticsUnActive },
        catalogUnActive: { icon: catalogUnActive },
        rewardsUnActive: { icon: reward },
        employeeAwardsUn: { icon: employeeAwards },
    };

    const isActive = location.pathname.includes(`/dashboard/${menu.path}`) ? 'Active' : '';
    const isUn = colorCode ? 'Un' : '';
    if (!menu.img) return null;
    const sideNav = menu.img.split('-').join('') + isUn + isActive;
    const SideNavIcon = iconMenu[sideNav].icon;

    return <SideNavIcon />;
};

NavIcon.propTypes = {
    menu: PropTypes.object,
    colorCode: PropTypes.bool,
    location: PropTypes.object,
};

export default NavIcon;
