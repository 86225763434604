import { getData, postData, patchData } from 'Services/apiCall';
import queryString from 'query-string';

export const getSuggestionList = async ({ queryKey: [_key, page, order, sort, selectedUser] }) => {
    try {
        const queryParams = {
            page,
            order,
            sort,
            ...(selectedUser && { selectedUser }),
        };

        const queryStringParams = queryString.stringify(queryParams);
        const path = `suggestion-box/list?${queryStringParams}`;
        const { data } = await getData(path);
        return data;
    } catch (error) {
        throw new Error(error?.response?.data?.message);
    }
};
/**
 * API to get the feedback configurations
 * @returns data
 */
export const getSuggestionBoxConfiguration = async () => {
    try {
        const { data } = await getData('suggestion-box/configuration');
        return data;
    } catch (error) {
        throw new Error(error?.response?.data?.message);
    }
};

/**
 * API to update the feedback configs
 * @param {object} apidata
 * @returns
 */
export const updateSuggestionBoxConfiguration = async ({ apiData }) => {
    try {
        return await postData('suggestion-box/configuration', apiData);
    } catch (err) {
        throw new Error(err?.response?.data?.message);
    }
};

/**
 * API to send the feedback message
 * @param {object} apiData
 * @returns
 */
export const sendSuggestion = async ({ apiData }) => {
    try {
        const { data } = await postData('suggestion-box/send', apiData);
        return data;
    } catch (err) {
        throw new Error(err?.response?.data?.message);
    }
};

export const updateSuggestion = async ({ apiData }) => {
    try {
        const { data } = await patchData('suggestion-box/suggestion', apiData);
        return data;
    } catch (err) {
        throw new Error(err?.response?.data?.message);
    }
};
