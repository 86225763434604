import React from 'react';
import PropTypes from 'prop-types';
import { Tab, Tabs } from 'react-bootstrap';
import { ReactComponent as EmptyTNC } from 'Assets/images/empty-tnc.svg';

const EmptyContent = () => {
    return (
        <div className='text-center'>
            <EmptyTNC />
            <h3 className='header-3'>Nothing to see here yet.</h3>
        </div>
    );
};

const Index = ({ selectedProduct, activeKey, setKey }) => {
    const showEmptyTnCState =
        !selectedProduct?.termsAndConditionsInstructions && !selectedProduct?.redemptionInstructions;
    return (
        <div className='tab-container'>
            {showEmptyTnCState && <EmptyContent />}
            {!showEmptyTnCState && (
                <div className='navigatortabs margin-top-35'>
                    <Tabs activeKey={activeKey} onSelect={(k) => setKey(k)}>
                        {selectedProduct?.termsAndConditionsInstructions && (
                            <Tab eventKey='tnc' title='Terms and Conditions' className='margin-top-35'>
                                <div
                                    className='tnc'
                                    dangerouslySetInnerHTML={{
                                        __html: selectedProduct.termsAndConditionsInstructions,
                                    }}
                                />

                                {!selectedProduct?.termsAndConditionsInstructions && <EmptyContent />}
                            </Tab>
                        )}
                        {selectedProduct?.redemptionInstructions && (
                            <Tab eventKey='redeem' title='Instructions to redeem' className='margin-top-35'>
                                <div
                                    className='tnc'
                                    dangerouslySetInnerHTML={{
                                        __html: selectedProduct.redemptionInstructions,
                                    }}
                                />
                            </Tab>
                        )}
                    </Tabs>
                </div>
            )}
        </div>
    );
};

Index.propTypes = {
    selectedProduct: PropTypes.object,
    activeKey: PropTypes.string,
    setKey: PropTypes.func,
};

export default Index;
