import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';

//pass `white` as props for white tooltip

const getStyles = (props) => {
    const { white, hide } = props;
    return {
        tooltip: {
            display: hide ? 'none' : 'block',
            backgroundColor: white ? '#fcfbfb' : '#4b4d53',
            color: white ? '#4a4a4a' : 'white',
            fontSize: '0.75rem',
            lineHeight: '1.5em',
            fontFamily: 'Open Sans, sans-serif',
            ...(white && { border: '1px solid rgba(0, 0, 0, 0.2)', boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.25)' }),
        },
        arrow: {
            color: white ? '#ffffff' : '#4b4d53',
        },
    };
};
const Index = ({ ...props }) => {
    const StyledTooltip = withStyles(() => getStyles(props))(Tooltip);

    return (
        <StyledTooltip {...props} arrow={props.arrow}>
            {props?.children}
        </StyledTooltip>
    );
};

Index.propTypes = {
    children: PropTypes.node,
    arrow: PropTypes.bool,
};

export default Index;
