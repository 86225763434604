import React, { useState, useEffect } from 'react';
import FilterDropDown from 'components/ReusableComponents/FilterDropDown';
import GiftCard from '../GiftCard';
import { YellowTagDelayed } from 'utils/Stylesheet/style';
import { ReactComponent as ValidityIcon } from 'Assets/images/validity.svg';
import { ReactComponent as CouponIcon } from 'Assets/images/coupon.svg';
import { ReactComponent as StarCoinsIcon } from 'Assets/images/star-coins.svg';
import { ReactComponent as InfoGreyFilledIcon } from 'Assets/images/info-grey-filled.svg';
import DelayedProductBuyConfirmationModal from 'components/Dashboard/Content/RedeemPoints/DelayedProductBuyConfirmation';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import './style.scss';

const DisabledRedeemMessage = ({ minimumPoints, currencyCode }) => (
    <div className='disabled-container'>
        <StarCoinsIcon />
        <span>
            <strong>Oops!</strong> You do not have enough balance to redeem this reward. Minimum balance required
            is&nbsp;{' '}
            <strong>
                {currencyCode} {minimumPoints}
            </strong>
        </span>
    </div>
);

const Index = ({
    selectedProduct,
    customReward,
    denominations,
    selectedDenomination,
    selectedDenominationName,
    getBalanceRemaining,
    currencySymbol = '',
    handleGiftCardSelection,
    handleValidity,
    handlePurchase,
    getDenominationLabel,
    // when remaining points are less than the redeem points, we show the disbaled state
    disabled,
    // to let user know the minimum redeem points for a reward when it's disabled
    minimumPoints,
    combinedRedeemBalance,
}) => {
    const [quantity, setQuantity] = useState(1);

    const handleQuantity = (index) => setQuantity(index + 1);

    const disableDenominationDropdown =
        (denominations && denominations.length === 0) ||
        (selectedProduct?.isCustom && selectedProduct?.customCouponRemaining < 1);

    const [confirmDelayedCardOpen, setConfirmDelayedCardOpen] = useState(false);
    const validTillDate = handleValidity(selectedProduct?.expiryAndValidity);

    useEffect(() => {
        setQuantity(1);
    }, [selectedDenominationName]);

    return (
        <>
            <div className='card-container'>
                <div className='redeem-container'>
                    <div className='d-flex'>
                        <h4 className='card-name'>{selectedProduct?.name}</h4>
                        {selectedProduct?.deliveryType === 'delayed' && (
                            <YellowTagDelayed className='font-weight-600'>Delayed</YellowTagDelayed>
                        )}
                    </div>
                    <p className='category'>{selectedProduct?.categories}</p>
                    <div className='redeem-card-container'>
                        <div
                            className={clsx({
                                'redeem-info d-flex flex-column': true,
                                'justify-content-between': !disabled,
                                'justify-content-around': disabled,
                            })}
                        >
                            {!disabled ? (
                                <div>
                                    <p className='select-value'>Redeem</p>
                                    <div className='d-flex'>
                                        <FilterDropDown
                                            moreFilters={denominations}
                                            handleMoreCheck={(index) => handleGiftCardSelection(index)}
                                            name={selectedDenominationName}
                                            dropDownID='redeemDropdown'
                                            blackText
                                            singleSelect
                                            className={'select-filter'}
                                            disabled={disableDenominationDropdown}
                                        />
                                        {!customReward && selectedDenomination ? (
                                            <FilterDropDown
                                                moreFilters={Array.from(
                                                    {
                                                        length: parseInt(combinedRedeemBalance / selectedDenomination),
                                                    },
                                                    (_, index) =>
                                                        (index + 1).toLocaleString('en-US', {
                                                            minimumIntegerDigits: 2,
                                                            useGrouping: false,
                                                        })
                                                )}
                                                handleMoreCheck={(index) => handleQuantity(index)}
                                                name={quantity.toLocaleString('en-US', {
                                                    minimumIntegerDigits: 2,
                                                    useGrouping: false,
                                                })}
                                                dropDownID='quantityDropdown'
                                                blackText
                                                singleSelect
                                                className={'select-quantity'}
                                                disabled={disableDenominationDropdown}
                                            />
                                        ) : null}
                                    </div>
                                    <button
                                        className='ew-btn pb-btn btn'
                                        disabled={
                                            !selectedDenomination ||
                                            (denominations && denominations.length === 0) ||
                                            combinedRedeemBalance <= 0
                                        }
                                        onClick={() => {
                                            if (selectedProduct?.deliveryType === 'delayed') {
                                                setConfirmDelayedCardOpen(true);
                                            } else {
                                                handlePurchase(quantity);
                                            }
                                        }}
                                    >
                                        Redeem
                                    </button>
                                    {!!selectedDenomination && (
                                        <p className='text-points-rem'>
                                            After redeeming, you will have{' '}
                                            <strong>{`${currencySymbol} 
                                            ${getBalanceRemaining(quantity)}`}</strong>{' '}
                                            remaining
                                        </p>
                                    )}
                                </div>
                            ) : (
                                <DisabledRedeemMessage
                                    minimumPoints={minimumPoints}
                                    currencyCode={selectedProduct?.currencyCode}
                                />
                            )}

                            <div className='validity-text'>
                                {selectedProduct?.deliveryType === 'delayed' && (
                                    <>
                                        <InfoGreyFilledIcon />
                                        <p>{`Delivers in ${selectedProduct?.tat}-${
                                            selectedProduct?.tat + 1
                                        } working days`}</p>
                                    </>
                                )}
                                <ValidityIcon className='img-validity' />
                                <p>{validTillDate}</p>

                                {selectedProduct?.customCouponRemaining > -1 && (
                                    <>
                                        <CouponIcon />
                                        <p>{` ${selectedProduct.customCouponRemaining} coupons left`}</p>
                                    </>
                                )}
                            </div>
                        </div>

                        <GiftCard denomination={getDenominationLabel(selectedDenomination)} {...selectedProduct} />
                    </div>
                </div>
            </div>

            {confirmDelayedCardOpen && (
                <DelayedProductBuyConfirmationModal
                    turnAroundTime={selectedProduct?.tat}
                    onConfirm={() => {
                        setConfirmDelayedCardOpen(false);
                        handlePurchase(quantity);
                    }}
                    onClose={() => setConfirmDelayedCardOpen(false)}
                />
            )}
        </>
    );
};

Index.propTypes = {
    selectedProduct: PropTypes.object,
    customReward: PropTypes.bool,
    denominations: PropTypes.object,
    selectedDenomination: PropTypes.number,
    selectedDenominationName: PropTypes.string,
    getBalanceRemaining: PropTypes.func,
    currencySymbol: PropTypes.string,
    handleGiftCardSelection: PropTypes.func,
    handleValidity: PropTypes.func,
    handlePurchase: PropTypes.func,
    getDenominationLabel: PropTypes.func,
    disabled: PropTypes.bool,
    minimumPoints: PropTypes.number,
    combinedRedeemBalance: PropTypes.number,
};

DisabledRedeemMessage.propTypes = {
    minimumPoints: PropTypes.number,
    currencyCode: PropTypes.string,
};

export default Index;
