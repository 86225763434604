import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import jwt_decode from 'jwt-decode';
import './style.scss';
import { useCommonContext } from '../../Context/CommonContext';
import { useToaster } from '../../Context/SnackbarContext';
import { getOnboardingModules, getWorkspaceDetails } from 'Services/apiFunctions';
import Cookies from 'js-cookie';
import CustomLoader from '../Styles/CustomLoader';
import { setter } from '../../utils/CacheManager';
import { checkAdmin, getUserProfile } from 'redux/user/actions';
import { useDispatch } from 'react-redux';
import encUtf8 from 'crypto-js/enc-utf8';
import AES from 'crypto-js/aes';
import { getUserFromLocalStorage } from 'utils/SessionUtils/sessionUtils';

const EXTERNAL_SOURCE_PATH = {
    ENPS: '/dashboard/eNPS/create',
};

const CheckAdmin = async (
    decodeData,
    dispatch,
    history,
    setOnboarding,
    setLoading,
    user,
    SetSnackbar,
    setColorCode
) => {
    const bypassOnboardingTag = decodeData['bypassOnboarding'] || '';
    setLoading(true);
    try {
        const userDetails = await getUserProfile(dispatch);
        const workSpaceDetails = await getWorkspaceDetails();
        if (!userDetails.isActive) {
            history.push(`/inactive/?token=${Cookies.get('AuthToken')}`);
        }
        if (EXTERNAL_SOURCE_PATH[bypassOnboardingTag]) {
            history.push(EXTERNAL_SOURCE_PATH[bypassOnboardingTag]);
        } else if (userDetails.isAdmin && workSpaceDetails.isCompletedFlow) {
            checkAdmin(dispatch, true);
            setLoading(false);
        } else if (workSpaceDetails.isCompletedFlow) {
            setLoading(false);
        } else if (decodeData['platform'] === 'slack') {
            setOnboarding(true);
            user.onboarding = true;
            setter('user', user);
            setLoading(false);
        } else {
            setLoading(false);
        }
        if (workSpaceDetails.themeColor || workSpaceDetails.themeColorRGBA) {
            setColorCode({ colorCode: workSpaceDetails.themeColor, hover: workSpaceDetails.themeColorRGBA });
        }
    } catch (error) {
        setLoading(false);
        if (error?.response?.status === 400) {
            if (error.response.data === 'Not an admin to install SE') {
                history.push('/unauthorized');
            } else if (error.response.data.Error === 'No Workspace' || error.response.data.Error === 'No Users Found') {
                SetSnackbar({
                    open: true,
                    variant: 'error',
                    message: 'User does not exists',
                });
            } else {
                SetSnackbar({
                    open: true,
                    variant: 'error',
                    message: error?.response?.data?.message || 'Some error occurred!',
                });
            }
        }
    }
};

const checkUserToken = (validationId) => {
    // coming from MS Team, the code is encrypted
    return AES.decrypt(validationId.replaceAll(' ', '+'), process.env.REACT_APP_MSTEAMS_ENCRYPTION).toString(encUtf8);
};

const Lander = ({ location, history }) => {
    const { setOnboarding, setLoading, setOnboardingInfo, setColorCode } = useCommonContext();
    const { SetSnackbar } = useToaster();
    const data = queryString.parse(location.search);
    let user = getUserFromLocalStorage();
    const dispatch = useDispatch();

    useEffect(() => {
        const decodeToken = async (SetSnackbar) => {
            return new Promise((resolve, reject) => {
                try {
                    let token = data.token;
                    if (data?.validationId) {
                        token = checkUserToken(data.validationId);
                    }
                    const decode = jwt_decode(token);
                    if (decode['isOnBoardingInProgress']) {
                        localStorage.setItem('platform', decode.platform);
                    } else if (decode['admin-id'] && (decode['isActive'] || decode['canRedeemRewards'])) {
                        const userDetails = {
                            team: decode['team-id'],
                            adminId: decode['admin-id'],
                            platform: decode['platform'],
                        };
                        Cookies.set('AuthToken', token, {
                            expires: 30,
                            path: '/',
                        });
                        setter('user', userDetails);
                        if (decode['isActive']) {
                            CheckAdmin(
                                decode,
                                dispatch,
                                history,
                                setOnboarding,
                                setLoading,
                                userDetails,
                                SetSnackbar,
                                setColorCode
                            );
                        }
                    } else {
                        history.push(`/inactive/?token=${Cookies.get('AuthToken')}`);
                    }
                } catch (err) {
                    history.push(`/inactive/?token=${Cookies.get('AuthToken')}`);
                }
                resolve(true);
            });
        };

        if (data.success) {
            decodeToken(SetSnackbar).then(() => {
                getOnboardingModules()
                    .then((res) => {
                        setOnboardingInfo(res);
                        history.push('/onboarding/');
                    })
                    .catch((_err) =>
                        SetSnackbar({
                            open: true,
                            variant: 'error',
                            message: 'Error fetching Onboarding Modules',
                        })
                    );
            });
        } else if (user) {
            if (user.termsBack) {
                user.termsBack = false;
                setter('user', user);
                window.open(process.env.REACT_APP_LANDER_URL, '_self');
            } else {
                history.push('/dashboard/user');
            }
        } else {
            window.open(process.env.REACT_APP_LANDER_URL, '_self');
        }
    }, [0]);

    return (
        <div>
            <CustomLoader />
        </div>
    );
};

Lander.propTypes = {
    location: PropTypes.object,
    history: PropTypes.object,
};

export default Lander;
