import React from 'react';
import PropTypes from 'prop-types';
import EditIcon from '@material-ui/icons/Edit';
import { format } from 'date-fns';
import { DarkGreenTag, GrayTag } from 'utils/Stylesheet/style';
import ImageWithLabel from 'components/ReusableComponents/ImageWithLabel';

const UserDetails = ({ userData, handleEdit }) => {
    const userDetails = [
        {
            label: 'JOINING DATE',
            value: userData?.Anniversary ? format(new Date(userData?.Anniversary), 'dd MMMM yyyy') : '-',
        },
        {
            label: 'Country',
            // in UI, we show full country name, but in API, we get country abbreviation
            value: userData?.Country || '-',
        },
        {
            label: 'Points to Recognize',
            value: userData?.creditBalance || '-',
        },
        {
            label: 'Role',
            value: userData?.role || '-',
        },
        {
            label: 'Birthday',
            value: userData?.Birthday || '-',
        },
        {
            label: 'Manager',
            value: userData?.Manager?.userName || '-',
            imageUrl: userData?.Manager?.imageURL,
            image: true,
        },
        {
            label: 'Monthly point allowance',
            value: userData?.monthlyAllowance || '-',
        },
        {
            label: 'Designation',
            value: userData?.roleInOrg || '-',
        },
    ];

    return (
        <div className='cp-user-details'>
            <div className='cp-user-details-top'>
                <div className='cp-user-details-top-user'>
                    <img src={userData?.imageURL || require('Assets/images/defaultUser.png')} alt='' />
                    <div>
                        <span className='cp-user-details-top-user-name'>{userData?.Name}</span>
                        {userData?.Status && <DarkGreenTag>ACTIVE</DarkGreenTag>}
                        {!userData?.Status && <GrayTag>INACTIVE</GrayTag>}
                        {userData?.Designation && <p>{userData?.Designation}</p>}
                    </div>
                </div>
                <button className='cp-user-details-top-edit' onClick={handleEdit}>
                    <EditIcon />
                    Edit
                </button>
            </div>
            <div className='cp-user-details-bottom'>
                {userDetails.map((details) => (
                    <div key={details.label}>
                        <p>{details.label}</p>
                        <ImageWithLabel
                            {...(details?.image &&
                                details?.value !== '-' && {
                                    imageUrl: details?.imageUrl || require('Assets/images/defaultUser.png'),
                                })}
                            text={details.value}
                            textWidth='200px'
                            width={22}
                            height={22}
                            textMarginLeft={8}
                            textStyleClass='cp-user-details-bottom-value '
                        />
                    </div>
                ))}
            </div>
        </div>
    );
};

UserDetails.propTypes = {
    userData: PropTypes.object,
    handleEdit: PropTypes.func,
};

export default UserDetails;
