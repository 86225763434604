import { getData } from 'Services/apiCall';
import queryString from 'query-string';

export const login = async (apiData) => {
    try {
        const queryStringParams = queryString.stringify(apiData);
        return await getData(`login?${queryStringParams}`);
    } catch (err) {
        throw new Error(err?.response?.data?.message);
    }
};
