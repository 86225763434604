import React from 'react';
import ConfirmModal from 'components/ReusableComponents/ConfirmModal';
import PropTypes from 'prop-types';

const Index = ({ data, open, setOpen, handleDraft, handleExit }) => {
    const onClose = (isCancel) => {
        if (isCancel) {
            handleExit();
        }
        setOpen(false);
    };

    return (
        <ConfirmModal
            open={open}
            data={data}
            onClose={onClose}
            onConfirm={handleDraft}
            headingSeparator
            height={'196px'}
            width={'550px'}
        />
    );
};
Index.propTypes = {
    data: PropTypes.object,
    open: PropTypes.bool,
    setOpen: PropTypes.func,
    handleDraft: PropTypes.func,
    handleExit: PropTypes.func,
};
export default Index;
