import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from 'components';
import { ReactComponent as InfoIcon } from 'Assets/images/info-grey-filled.svg';

const Details = ({ label, imageUrl, labelData, tooltipInfo }) => {
    return (
        <div className='sc-details'>
            <span className='sc-details-label'>{label}</span>
            {imageUrl && <img src={imageUrl} alt='' />}
            <span className='sc-details-label-data'>{labelData}</span>
            {tooltipInfo && (
                <Tooltip title={tooltipInfo}>
                    <InfoIcon className='info-icon' />
                </Tooltip>
            )}
        </div>
    );
};

Details.propTypes = {
    label: PropTypes.string,
    imageUrl: PropTypes.string,
    labelData: PropTypes.string,
    tooltipInfo: PropTypes.string,
};

export default Details;
