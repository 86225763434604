import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Modal from '@material-ui/core/Modal';
import { format } from 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import CloseIcon from '@material-ui/icons/Close';
import download from 'downloadjs';
import { downLoadFile } from 'Services/apiCall';
import { useToaster } from 'Context/SnackbarContext';
import { showSnackBarMessage } from 'utils/HelperFunctions';
import './style.scss';

const Index = ({ open, setOpen, apiUrl }) => {
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const [openOnClick, setOpenOnClick] = useState({ from: false, to: false });
    const [loading, setLoading] = useState(false);
    const { SetSnackbar } = useToaster();
    const downloadPath = apiUrl || `redemption/export`;
    const showError = toDate && fromDate && fromDate > toDate;

    const handleDownload = async () => {
        setLoading(true);
        const data = {
            startDate: format(fromDate, 'yyyy-MM-dd'),
            endDate: format(toDate, 'yyyy-MM-dd'),
            tz: new Date().getTimezoneOffset(),
        };

        try {
            const downloadFileData = await downLoadFile(downloadPath, 'post', data);
            if (downloadFileData?.status === 204) {
                showSnackBarMessage(SetSnackbar, 'error', 'No transactions found for the selected date range');
            } else {
                download(downloadFileData?.data, 'Transactions.xlsx');
                setTimeout(
                    () =>
                        SetSnackbar({
                            open: true,
                            variant: 'success',
                            message: (
                                <span>
                                    Downloading report from{' '}
                                    <b>
                                        {format(fromDate, 'dd-MM-yyyy')} - <br /> {format(toDate, 'dd-MM-yyyy')}{' '}
                                    </b>{' '}
                                    successful.
                                </span>
                            ),
                        }),
                    500
                );
                setTimeout(() => onClose(), 1000);
            }
        } catch (err) {
            showSnackBarMessage(SetSnackbar, 'error', err?.response?.data?.message);
        } finally {
            setLoading(false);
        }
    };

    const onClose = () => {
        setOpen(false);
        setFromDate(null);
        setToDate(null);
    };

    return (
        <div>
            <Modal open={open} onClose={onClose}>
                <div className='download-report-container'>
                    <div className='download-report-header'>
                        <h4 className='header-4'>Download report</h4>
                        <CloseIcon className='close-icon' onClick={onClose} />
                    </div>
                    <div className='download-report-body'>
                        <div className='date-select-container'>
                            <div style={{ marginBottom: '23px' }}>
                                <p className='date-from-to'>From</p>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardDatePicker
                                        InputProps={{
                                            disableUnderline: true,
                                        }}
                                        autoOk={true}
                                        format='dd-MM-yyyy'
                                        margin='normal'
                                        value={fromDate}
                                        maxDate={new Date()}
                                        onChange={setFromDate}
                                        open={openOnClick.from}
                                        onClick={() => setOpenOnClick({ from: true, to: false })}
                                        onClose={() => setOpenOnClick({ from: false, to: false })}
                                        KeyboardButtonProps={{
                                            'aria-label': 'change date',
                                        }}
                                        emptyLabel='Select date'
                                        keyboardIcon={<ExpandMoreIcon />}
                                    />
                                </MuiPickersUtilsProvider>
                            </div>
                            <div style={toDate ? { color: '#333333' } : { color: '#c4c4c4' }}>
                                <p className='date-from-to'>To</p>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardDatePicker
                                        InputProps={{
                                            disableUnderline: true,
                                        }}
                                        autoOk={true}
                                        format='dd-MM-yyyy'
                                        margin='normal'
                                        value={toDate}
                                        maxDate={new Date()}
                                        onChange={setToDate}
                                        open={openOnClick.to}
                                        onClick={() => setOpenOnClick({ from: false, to: true })}
                                        onClose={() => setOpenOnClick({ from: false, to: false })}
                                        KeyboardButtonProps={{
                                            'aria-label': 'change date',
                                        }}
                                        emptyLabel='Select date'
                                        keyboardIcon={<ExpandMoreIcon />}
                                    />
                                </MuiPickersUtilsProvider>
                            </div>
                        </div>
                        <p className='report-note' style={{ padding: showError ? '16px 0px 12px' : '16px 0px 24px' }}>
                            Note: Once date ranges are selected, you will be able to download report in XLSX.
                        </p>
                        {showError && (
                            <p className='error-message'>
                                <ErrorOutlineIcon style={{ fontSize: '12px', marginRight: '4px' }} />
                                Please select a valid date range
                            </p>
                        )}
                    </div>
                    <div className='download-report-footer'>
                        <button
                            className='ew-btn pb-btn'
                            onClick={handleDownload}
                            disabled={!toDate || !fromDate || fromDate > toDate || loading}
                        >
                            {loading ? 'Starting...' : 'Download'}
                        </button>
                    </div>
                </div>
            </Modal>
        </div>
    );
};

Index.propTypes = {
    open: PropTypes.bool,
    setOpen: PropTypes.func,
    apiUrl: PropTypes.string,
};

export default Index;
