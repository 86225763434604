import React from 'react';
import FileSelectionButton from 'components/ReusableComponents/FileSelectionButton';
import PropTypes from 'prop-types';
import './style.scss';

export const SAMPLE_FILE_URL =
    'https://assets-springengage.s3.amazonaws.com/production/Sample+CSV+for+invite+users.csv';

const InviteViaBulkUpload = ({ csvFile, setCSVFile }) => {
    const handleCSVSelection = (event) => {
        const selectedFile = event.target.files[0];
        if (!selectedFile) {
            return;
        }
        setCSVFile({ file: selectedFile, name: selectedFile?.name });
    };

    return (
        <div className='invite-users-bulk-upload'>
            <p>Upload .csv file for bulk upload</p>
            <div className='d-flex align-items-center'>
                <FileSelectionButton
                    buttonText='Upload .csv file'
                    acceptedFiles='.csv'
                    fileName={csvFile?.name}
                    handleFileSelection={handleCSVSelection}
                    handleRemoveFile={() => setCSVFile(null)}
                />
                <a href={SAMPLE_FILE_URL} target='blank'>
                    Download sample file
                </a>
            </div>
        </div>
    );
};

InviteViaBulkUpload.propTypes = {
    csvFile: PropTypes.bool,
    setCSVFile: PropTypes.func,
};

export default InviteViaBulkUpload;
