import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as Back } from 'Assets/images/back-arrow.svg';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import './style.scss';

const StepIndicator = ({ status }) => {
    const bgColor = ['#EEEEEE', '#FFB573', '#1EB89C'];
    return <div className='step-indicator' style={{ backgroundColor: bgColor[status] }} />;
};

const Index = ({ steps, activeStep, stepStatus, handleStep }) => {
    const getStatus = (index) => {
        if (index < activeStep && stepStatus[index - 1]) {
            return 2;
        } else if (index === activeStep) {
            return 1;
        } else {
            return stepStatus[index - 1] ? 2 : 0;
        }
    };

    return (
        <div className='stepper-container'>
            <div className='d-flex align-items-center'>
                <Back
                    data-testid='back-arrow'
                    className='pointer-cursor d-inline-block'
                    onClick={() => {
                        handleStep('back');
                    }}
                />

                <Stepper stepper='true' activeStep={activeStep} connector={null}>
                    {steps.map((step, index) => (
                        <Step key={step.title}>
                            <StepIndicator status={getStatus(index + 1)} />
                        </Step>
                    ))}
                </Stepper>
            </div>
            <p className='step-count'>
                Step {activeStep} of {steps?.length}
            </p>
        </div>
    );
};

Index.propTypes = {
    steps: PropTypes.array,
    activeStep: PropTypes.number,
    stepStatus: PropTypes.array,
    handleStep: PropTypes.func,
};

StepIndicator.propTypes = {
    status: PropTypes.number,
};

export default Index;
