import React from 'react';
import PropTypes from 'prop-types';
import TitleBar from 'components/ReusableComponents/TitleBar';
import CustomLoader from 'components/ReusableComponents/CustomLoader';
import AnalyticsFilters from '../AnalyticsFilters';
import UserAnalyticsTable from '../AnalyticsTables/UserAnalyticsTable';
import { HISTORY_FROM } from 'constants.js';
import { ANALYTICS } from '../constants';
import ManagerAnalyticsTable from '../AnalyticsTables/ManagerAnalyticsTable';
import DepartmentAnalyticsTable from '../AnalyticsTables/DepartmentAnalyticsTable';
import './style.scss';

const Index = ({
    history,
    title,
    isLoading,
    setView,
    selectedCohort,
    setSelectedCohort,
    selectedDateRange,
    setSelectedDateRange,
    cohortData,
    users,
    managers,
    department,
}) => {
    if (isLoading) {
        return (
            <div className='analytics-container'>
                <CustomLoader />
            </div>
        );
    }

    return (
        <>
            <TitleBar
                title={title}
                back
                onBack={() => history.push(ANALYTICS.ANALYTICS_DASHBOARD, { from: HISTORY_FROM.ANALYTICS })}
            />
            <div className='analytics-container'>
                <AnalyticsFilters
                    cohortData={cohortData}
                    selectedCohort={selectedCohort}
                    handleCohortSelection={(cohortValue) => setSelectedCohort(cohortValue)}
                    selectedDateRange={selectedDateRange}
                    setSelectedDateRange={setSelectedDateRange}
                    setView={setView}
                    hideManagers={managers}
                    blockSelection={department}
                />
                {users && (
                    <UserAnalyticsTable
                        history={history}
                        selectedDateRange={selectedDateRange}
                        selectedCohort={selectedCohort}
                        isFullView
                    />
                )}
                {managers && (
                    <ManagerAnalyticsTable
                        history={history}
                        selectedDateRange={selectedDateRange}
                        selectedCohort={selectedCohort}
                        isFullView
                    />
                )}
                {department && (
                    <DepartmentAnalyticsTable
                        history={history}
                        selectedDateRange={selectedDateRange}
                        selectedCohort={selectedCohort}
                        isFullView
                    />
                )}
            </div>
        </>
    );
};

Index.propTypes = {
    history: PropTypes.object,
    title: PropTypes.string,
    isLoading: PropTypes.bool,
    setView: PropTypes.func,
    selectedCohort: PropTypes.object,
    setSelectedCohort: PropTypes.func,
    selectedDateRange: PropTypes.object,
    setSelectedDateRange: PropTypes.func,
    cohortData: PropTypes.array,
    users: PropTypes.array,
    managers: PropTypes.array,
    department: PropTypes.bool,
};

export default Index;
