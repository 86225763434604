import React, { useState } from 'react';
import PropTypes from 'prop-types';
import AnswerBox from './AnswerBox';
import { CustomTag } from 'utils/Stylesheet/style';
import { REQUIRED_TAG } from '../../constants';
import CustomLoader from 'components/ReusableComponents/CustomLoader';
import clsx from 'clsx';

const Index = ({ response, setKey, enps, translateRating, translateEmoji, loading }) => {
    const [selected, setSelected] = useState(0);

    if (loading) {
        return <CustomLoader data-testid='custom-loader' />;
    }

    return (
        <div className='summary-response mt-3'>
            <div className='d-flex justify-content-start'>
                <div className='response-name-search'>
                    <div className='toggle-summary-responses'>
                        <button className='btn ob-btn inactive-btn' onClick={() => setKey('Results')}>
                            Summary
                        </button>
                        <button className='btn ob-btn active-btn'>
                            Responses({response?.participants?.length || 0})
                        </button>
                    </div>
                    {response?.participants?.length > 0 && (
                        <ul>
                            {response.participants?.map((participant, index) => (
                                <button
                                    className={clsx({
                                        'selected pointer-cursor': index === selected,
                                        'pointer-cursor': index !== selected,
                                    })}
                                    onClick={() => setSelected(index)}
                                    key={participant?.id || `Anonymous ${index}`}
                                >
                                    {participant?.name || 'Anonymous'}
                                </button>
                            ))}
                        </ul>
                    )}
                </div>
                <div style={{ width: '100%' }} className='content-scroll-response'>
                    <div className='d-flex justify-content-between pt-2 pl-1'>
                        <h5 className='ml-2'>Responses</h5>
                        <div className='d-flex justify-content-start mt-3'></div>
                    </div>
                    {response?.participants?.length > 0 ? (
                        <div>
                            {response?.participants?.[selected]?.responses?.map((responseValue, index) => {
                                return (
                                    <div className='answer-block' key={responseValue.question}>
                                        <p className='count-question'>{index + 1}</p>
                                        <div className='ml-5'>
                                            <p className='question d-inline-block'>{responseValue.question}</p>
                                            {responseValue?.required && (
                                                <CustomTag
                                                    color={REQUIRED_TAG?.color}
                                                    background={REQUIRED_TAG?.background}
                                                    fontWeight={REQUIRED_TAG?.fontWeight}
                                                >
                                                    Required
                                                </CustomTag>
                                            )}
                                            <AnswerBox
                                                val={responseValue}
                                                enps={enps}
                                                translateEmoji={translateEmoji}
                                                translateRating={translateRating}
                                            />
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    ) : (
                        <div className='response-empty'>
                            <div className='response-empty-image'></div>
                            <p className='no-response-text'>No responses found</p>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

Index.propTypes = {
    response: PropTypes.object,
    setKey: PropTypes.func,
    enps: PropTypes.array,
    translateRating: PropTypes.func,
    translateEmoji: PropTypes.func,
    loading: PropTypes.bool,
};

export default Index;
