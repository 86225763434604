export const RECOGNITIONS = {
    FEED: '/dashboard/recognitions/feed',
    CONFIGURATION: '/dashboard/recognitions/configuration',
    TASKS: '/dashboard/tasks',
};

// Function to map recognized users
export const mapSelectedUsersForAddOn = (recognizedUsers) => {
    return recognizedUsers.map((user) => ({
        id: user?._id,
        name: user?.userName,
        picUrl: user?.pictureURL,
        channel: false,
    }));
};
