import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Table from 'components/ReusableComponents/Table';
import CustomFilterDropdown from 'components/ReusableComponents/CustomFilterDropdown';
import { format } from 'date-fns';
import { useToaster } from 'Context/SnackbarContext';
import DownloadModal from './DownloadModal';
import { dateRanges } from 'constants.js';
import CustomDateRangeModal from 'components/Modals/CustomDateRangeModal';
import './style.scss';
import { debounce } from 'lodash';
import EmptyContent from 'components/ReusableComponents/EmptyScreen';
import CustomLoader from 'components/ReusableComponents/CustomLoader';
import { formatNumber, getLastNDays, showSnackBarMessage } from 'utils/HelperFunctions';
import CustomSearchBox from 'components/Styles/CustomSearchBox';
import { useQuery } from 'react-query';
import { GET_REDEEM_REWARDS } from 'Services/apiKeys';
import { getAllRedemptionActivities } from 'Services/apiFunctions';

const EmptyScreen = ({ loading, emptyScreenContent }) =>
    loading ? <CustomLoader /> : <EmptyContent {...emptyScreenContent} />;

const setData = (transactions, setResponse) => {
    const temp = transactions.map((transaction) => {
        return {
            id: transaction?._id,
            row: [
                format(new Date(transaction?.createdAt), 'dd-MM-yyyy'),
                <div className='member' key={transaction?._id}>
                    <img
                        src={
                            transaction?.memberDetails?.profilePic
                                ? transaction.memberDetails.profilePic
                                : require('Assets/images/defaultUser.png')
                        }
                        alt='profile-pic'
                    />
                    <p>
                        {transaction?.memberDetails?.name ? transaction.memberDetails.name : 'Member details not found'}
                    </p>
                </div>,
                transaction?.rewardType ? transaction.rewardType : '-',
                <p className='points' key={transaction?._id}>
                    {transaction?.giftValue?.currencySymbol}&nbsp;
                    {transaction?.giftValue ? formatNumber(transaction?.giftValue?.value) : ''}
                </p>,
            ],
        };
    });
    setResponse(temp);
};

const Index = () => {
    const [search, setSearch] = useState('');
    const [response, setResponse] = useState([]);
    const [pageNumber, setPageNumber] = useState(1);
    const [total, setTotal] = useState(0);
    const [rewardCardFilterCount, setRewardCardFilterCount] = useState(0);
    const { SetSnackbar } = useToaster();
    const [downloadOpen, setDownloadOpen] = useState(false);
    const [rewardList, setRewardList] = useState([]);
    const sortTags = ['date', 'member', 'reward', 'denomination'];
    const [filter, setFilter] = useState({
        skip: 0,
        card: { label: 'All', value: '' },
        date: { label: 'All', value: '' },
        dateEnd: undefined,
        sortBy: 'date',
        order: 'descending',
    });
    const [showDatePicker, setShowDatePicker] = useState(false);

    const header = ['DATE', 'MEMBER', 'REWARD CARD', 'VALUE'];
    const noSortColumns = [];
    const columnWidth = ['25%', '25%', '25%', '25%'];

    const emptyScreenContent = {
        image: <img src={require('Assets/images/empty-flag.svg')} alt='empty-flag' />,
        subheadingText: 'No redemptions found',
    };

    const handleSearchChange = debounce((value) => setSearch(value), 400);

    const handleFilter = (type, value, index) => {
        let selectedProductIds = [];
        switch (type) {
            case 'card': {
                const temp = [...rewardList];
                temp[index].isChecked = !temp[index].isChecked;
                selectedProductIds = temp.filter((product) => product.isChecked).map((product) => product.value);
                setRewardCardFilterCount(selectedProductIds.length);
                setFilter({
                    ...filter,
                    skip: 0,
                    dateEnd: filter.dateEnd ? filter.dateEnd : undefined,
                    productId: selectedProductIds,
                });
                setPageNumber(1);
                setRewardList(temp);
                break;
            }
            case 'date':
                if (value.value === 'Custom Date') {
                    setShowDatePicker(true);
                } else {
                    let startDate;
                    switch (value.value) {
                        case 'today':
                            startDate = getLastNDays(0).startDate;
                            break;
                        case 'last7':
                            startDate = getLastNDays(7).startDate;
                            break;
                        case 'last30':
                            startDate = getLastNDays(30).startDate;
                            break;
                        case 'last60':
                            startDate = getLastNDays(60).startDate;
                            break;
                        case 'last90':
                            startDate = getLastNDays(90).startDate;
                            break;
                        default:
                            break;
                    }
                    setFilter({
                        ...filter,
                        date: value,
                        dateStart: startDate ? format(startDate, 'yyyy-MM-dd') : null,
                        dateEnd: startDate ? format(new Date(), 'yyyy-MM-dd') : null,
                    });
                }
                break;
            default:
                showSnackBarMessage(SetSnackbar, 'error', `No type.`);
        }
    };

    const handleCustomDate = (selectedDate) => {
        const fromDate = format(selectedDate?.startDate, 'yyyy-MM-dd');
        const toDate = format(selectedDate?.endDate, 'yyyy-MM-dd');
        setFilter({
            ...filter,
            date: { label: 'Custom Date', value: 'Custom Date' },
            dateStart: fromDate,
            dateEnd: toDate,
        });
        setShowDatePicker(false);
    };

    const handleSort = (index) => {
        const type = sortTags[index];
        const tempSort = { ...filter, sortBy: type };
        if (type === filter.sortBy) {
            tempSort.order = tempSort.order === 'ascending' ? 'descending' : 'ascending';
        } else {
            tempSort.order = 'ascending';
        }
        setPageNumber(1);
        setFilter({ ...filter, skip: 0, sortBy: tempSort.sortBy, order: tempSort.order });
    };

    const onChange = (page) => {
        setFilter({ ...filter, skip: (page - 1) * 10 });
    };

    const { isLoading, isFetching } = useQuery([GET_REDEEM_REWARDS, search, filter], getAllRedemptionActivities, {
        keepPreviousData: true,
        onSuccess: (redeemData) => {
            setData(redeemData?.data?.transactions, setResponse);
            setTotal(redeemData?.data?.totalTransactions);
            if (rewardList.length === 0) {
                const tempRewards = redeemData.data.allCards.map((rewardCard) => {
                    return {
                        ...rewardCard,
                        label: rewardCard.name,
                        value: rewardCard.productId,
                        isChecked: false,
                    };
                });
                setRewardList(tempRewards);
            }
        },
        onError: (err) => {
            showSnackBarMessage(SetSnackbar, 'error', err?.message);
        },
    });

    const paginationProps = {
        currentPage: pageNumber,
        totalCount: total,
        onChange: onChange,
        setCurrentPage: setPageNumber,
    };

    return (
        <div className='ac-main-container'>
            <div className='ac-filter-container'>
                <div className='d-flex'>
                    <CustomFilterDropdown
                        title='Date Range'
                        filterOptions={dateRanges}
                        selectedName={filter?.date ? filter.date.label : 'All'}
                        optionsSelected={filter?.date?.label !== 'All'}
                        handleSelection={(index) => handleFilter('date', dateRanges[index], index)}
                        dropDownID='rewardPurchaseDateDropdown'
                        buttonStyleClass='width-188'
                        singleSelect
                    />

                    <CustomFilterDropdown
                        title='Reward Card'
                        filterOptions={rewardList}
                        selectedName={rewardCardFilterCount > 0 ? `Selected (${rewardCardFilterCount})` : 'ALL'}
                        optionsSelected={rewardCardFilterCount > 0}
                        handleSelection={(index) => handleFilter('card', rewardList[index], index)}
                        dropDownID='rewardPurchaseDropdown'
                        search
                        buttonStyleClass='width-188'
                        dropdownWidth={278}
                        dropdownHeight={202}
                    />
                    <button className='ew-btn tb-btn ac-btn-download' onClick={() => setDownloadOpen(true)}>
                        Download report
                    </button>
                </div>

                <div className='mt-3'>
                    <CustomSearchBox
                        width={'320px'}
                        handleSearch={(event) => handleSearchChange(event.target.value)}
                        placeholder={'Search users by name, designation etc  '}
                    />
                </div>
            </div>

            {response.length > 0 ? (
                <div className='ac-table-container'>
                    <Table
                        header={header}
                        data={response}
                        noSortColumns={noSortColumns}
                        paginationProps={paginationProps}
                        loading={isLoading || isFetching}
                        columnWidth={columnWidth}
                        noShadow={true}
                        handleSort={handleSort}
                    />
                </div>
            ) : (
                <EmptyScreen loading={isLoading || isFetching} emptyScreenContent={emptyScreenContent} />
            )}

            <DownloadModal open={downloadOpen} setOpen={setDownloadOpen} />
            <CustomDateRangeModal
                open={showDatePicker}
                onClose={() => setShowDatePicker(false)}
                onConfirm={(selectedDate) => handleCustomDate(selectedDate)}
            />
        </div>
    );
};

EmptyScreen.propTypes = {
    loading: PropTypes.bool,
    isFetching: PropTypes.bool,
    emptyScreenContent: PropTypes.object,
};

export default Index;
