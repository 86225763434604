import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';

const Index = ({ index, currency, value, selected, keyName, handleEditValue, disable, type }) => {
    return (
        <div className='gift-value-container'>
            <p className='currency' style={{ background: selected ? '#dfdefd66' : '#FAFAFA' }}>
                {currency}
            </p>
            <input
                className='input-item'
                data-testid={keyName}
                type={type || 'number'}
                value={value}
                onChange={(e) => handleEditValue(index, e.target.value, keyName)}
                disabled={disable}
            />
        </div>
    );
};

Index.propTypes = {
    index: PropTypes.number,
    currency: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    selected: PropTypes.bool,
    keyName: PropTypes.string,
    handleEditValue: PropTypes.func,
    disable: PropTypes.bool,
    type: PropTypes.string,
};

export default Index;
