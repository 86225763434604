import { getData, patchData } from 'Services/apiCall';

export const getNotifications = async ({ pageParam = 1, queryKey: [_key] }) => {
    try {
        const path = 'notifications?page=' + pageParam;
        const { data } = await getData(path);
        return data;
    } catch (err) {
        throw new Error(err?.response?.data?.message);
    }
};

export const getNotificationCount = async ({ queryKey: [_key] }) => {
    try {
        const path = 'notifications?onlyCount=true';
        const { data } = await getData(path);
        return data;
    } catch (err) {
        throw new Error(err?.response?.data?.message);
    }
};

export const updateNotificationStatus = async ({ viewed = [], closed = [] }) => {
    try {
        const path = 'notifications';
        const payload = { viewed, closed };
        const { data } = await patchData(path, payload);
        return data;
    } catch (err) {
        throw new Error(err?.response?.data?.message);
    }
};
