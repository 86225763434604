import React from 'react';
import ImageWithLabel from 'components/ReusableComponents/ImageWithLabel';
import PropTypes from 'prop-types';
import './style.scss';

const Index = ({ userName, pictureURL, recognitionType, showDownArrowIcon = true }) => {
    return (
        <div className='recognition-header-user'>
            <strong>
                <ImageWithLabel
                    imageUrl={pictureURL || require('Assets/images/defaultUser.png')}
                    text={userName}
                    textWidth='auto'
                    width={22}
                    height={22}
                    textMarginLeft={4}
                />
            </strong>
            <span>
                &nbsp;gave <strong>{recognitionType}</strong> to {showDownArrowIcon ? '⬇️' : ''}
            </span>
        </div>
    );
};

Index.propTypes = {
    userName: PropTypes.string,
    pictureURL: PropTypes.string,
    recognitionType: PropTypes.string,
    showDownArrowIcon: PropTypes.bool,
};

export default Index;
