import { useQuery, useMutation, useQueryClient } from 'react-query';
import { fetchPulse, deletePulse, pausePulse, endPulse } from 'Services/apiFunctions';

export const usePulseData = ({ key, apiData, onSuccess, onError }) => {
    return useQuery([key, { ...apiData }], fetchPulse, {
        keepPreviousData: true,
        onSuccess,
        onError,
    });
};

export const useDeletePulse = ({ key, onError, onSuccess }) => {
    const queryClient = useQueryClient();
    return useMutation(deletePulse, {
        onSuccess: async () => {
            onSuccess();
            await queryClient.invalidateQueries(key);
        },
        onError,
    });
};

export const usePausePulse = ({ key, onError, onSuccess }) => {
    const queryClient = useQueryClient();
    return useMutation(pausePulse, {
        onSuccess: async () => {
            onSuccess();
            await queryClient.invalidateQueries(key);
        },
        onError,
    });
};

export const useStopPulse = ({ key, pulseId, instanceType, onError, onSuccess }) => {
    const queryClient = useQueryClient();
    return useMutation(async () => endPulse({ pulseId: pulseId, instanceType: instanceType }), {
        onSuccess: async () => {
            onSuccess();
            await queryClient.invalidateQueries(key);
        },
        onError,
    });
};

export const useEndPulse = ({ onError, onSuccess }) => {
    const queryClient = useQueryClient();
    return useMutation(endPulse, {
        onSuccess: async () => {
            onSuccess();
            await queryClient.invalidateQueries('EnpsScore');
        },
        onError,
    });
};
