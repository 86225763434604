import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as EWLogo } from 'Assets/images/ew-logo-transparent.svg';
import { bannerItems, rnrIntroDetails } from './constants';
import { useCommonContext } from 'Context/CommonContext';
import { ROUTES_ONBOARDING } from '../../constants';
import './style.scss';

const BannerItems = () => {
    return (
        <div className='onboarding-banner-container'>
            {bannerItems.map((bannerItem) => (
                <div
                    className='d-flex flex-column align-items-center padding-20 height-350 width-240 onboarding-banner-wrapper'
                    key={bannerItem.heading}
                >
                    {bannerItem.image}
                    <div className='banner-heading'>{bannerItem.heading}</div>
                    <div className='banner-subheading'>{bannerItem.subHeading}</div>
                </div>
            ))}
        </div>
    );
};

const Index = ({ history }) => {
    const { onboardingInfo } = useCommonContext();

    useEffect(() => {
        if (onboardingInfo?.find((module) => module.module === 'R&R')?.completed) {
            history.push(ROUTES_ONBOARDING.ONBOARDING);
        }
    }, []);

    return (
        <div className='info-screen bg-white height-100-vh'>
            <div className='header'>
                <EWLogo />
            </div>
            <div className='content d-flex flex-column align-items-center justify-content-center'>
                <div className='content-heading'>{rnrIntroDetails.heading}</div>
                <div className='content-sub-heading'>{rnrIntroDetails.subHeading}</div>
                <BannerItems />
                <button
                    className='ew-btn pb-btn width-350 margin-top-20'
                    onClick={() => history.replace(ROUTES_ONBOARDING.RNR_START_SETUP)}
                >
                    {rnrIntroDetails.buttonText}
                </button>
            </div>
        </div>
    );
};

Index.propTypes = {
    history: PropTypes.object,
};

export default Index;
