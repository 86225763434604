import React from 'react';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import { isBefore, isSameDay, isValid } from 'date-fns';
import { EDIT_TYPE } from 'components/Dashboard/Content/PulseSurvey/constants';

/*
 * CUSTOM DATE PICKER
 * ss: https://prnt.sc/1xkg68v
 * design: https://www.figma.com/file/i7J3vAW2srnObSfhd4Baff/EW-Dashboard---Revamp?node-id=1497%3A157963
 *
 * */

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    root: {
        '& .MuiFormControl-marginNormal': {
            marginBottom: 0,
            marginTop: 0,
            borderRadius: 8,
            width: (props) => props.width,
        },
        '& .MuiIconButton-root': {
            backgroundColor: 'rgba(93, 92, 245, 0.05)',
            borderRadius: 0,
            borderLeft: '1px solid #E5E7EA',
        },
    },
    textField: {
        color: 'primary',
        '& .MuiInputBase-root': {
            backgroundColor: '#FFFFFF',
            borderRadius: 8,
            border: '1px solid #E5E7EA',
            marginTop: 0,
            '& input': {
                '&:hover': {
                    border: 'none',
                    outline: 'none',
                },
                '&:focus': {
                    border: 'none',
                    outline: 'none',
                },
            },
            '&:hover': {
                border: '1px solid #5D5CF5',
                '& .MuiIconButton-root': {
                    borderLeft: '1px solid #5D5CF5',
                },
            },
            '& .MuiInputAdornment-positionEnd': {
                marginLeft: 0,
            },
        },
    },
    calIcon: {
        color: '#5D5CF5', //$blue_1
        width: 16,
        height: 16,
    },
}));

const CustomDatePicker = ({
    value,
    onChange,
    placeholder = 'Select Start Date',
    format = 'MM/dd/yyyy',
    disablePast = true,
    disableFuture = false,
    className = 'width-240',
    disableYear,
    width,
    disableWeekends,
    disabled,
    editType,
    isEditingRecurring,
    pulseData,
}) => {
    const classes = useStyles({ width });

    return (
        <div className={className}>
            <div className={classes.root}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                        disableToolbar={disableYear}
                        format={format}
                        margin='normal'
                        placeholder={placeholder}
                        label=''
                        autoOk
                        className={classes.textField}
                        disableFuture={disableFuture}
                        disablePast={disablePast && !(disabled || editType === EDIT_TYPE.FUTURE)}
                        disabled={disabled}
                        value={value}
                        InputProps={{
                            variant: 'outlined',
                            readOnly: true,
                            disableUnderline: true,
                        }}
                        variant='inline'
                        keyboardIcon={<CalendarTodayIcon className={classes.calIcon} />}
                        onChange={(date) => {
                            if (isValid(date)) {
                                onChange(date);
                            }
                        }}
                        rifmFormatter={(date) => {
                            return date;
                        }}
                        {...(disableWeekends && {
                            shouldDisableDate: (date) => date.getDay() === 0 || date.getDay() === 6,
                        })}
                        {...(isEditingRecurring &&
                            editType === EDIT_TYPE.FUTURE && {
                                shouldDisableDate: (date) =>
                                    isBefore(date, new Date(pulseData?.endAt)) &&
                                    !isSameDay(date, new Date(pulseData?.endAt)),
                            })}
                    />
                </MuiPickersUtilsProvider>
            </div>
        </div>
    );
};

CustomDatePicker.propTypes = {
    onChange: PropTypes.func.isRequired,
    placeholder: PropTypes.string,
    disablePast: PropTypes.bool,
    disableFuture: PropTypes.bool,
    value: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]), // Date or null
    disabled: PropTypes.bool,
    editType: PropTypes.bool,
    format: PropTypes.string,
    className: PropTypes.string,
    disableYear: PropTypes.bool,
    width: PropTypes.string,
    disableWeekends: PropTypes.bool,
    isEditingRecurring: PropTypes.bool,
    pulseData: PropTypes.object,
};

export default CustomDatePicker;
