import React, { useState, useEffect } from 'react';
import { useInfiniteQuery } from 'react-query';
import { debounce } from 'lodash';
import { useLocation } from 'react-router-dom';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import CustomFilterDropdown from 'components/ReusableComponents/CustomFilterDropdown';
import WarningBanner from 'components/ReusableComponents/WarningBanner';
import TitleBar from 'components/ReusableComponents/TitleBar';
import CustomLoader from 'components/ReusableComponents/CustomLoader';
import EmptyContent from 'components/ReusableComponents/EmptyScreen';
import MultiSelect from 'components/ReusableComponents/MultiSelect';
import CustomSearchBox from 'components/Styles/CustomSearchBox';
import RewardItem from '../RewardItem';
import { GET_REWARDS_DATA } from 'Services/apiKeys';
import { getRewardsCatalog } from 'Services/apiFunctions';
import { emptyScreenHeadingText, viewHistoryText, getRewardsListTitle, REDEEM_POINTS } from '../constant';
import { handlePurchase, handleRewardsListEffect } from '../componentsUtil';
import { SetXoxoday } from 'redux/xoxoday/actions';
import { ReactComponent as EmptyRewards } from 'Assets/images/empty-rewards.svg';
import { formatNumber, getCountryFlagURL, showSnackBarMessage } from 'utils/HelperFunctions';
import Country from 'components/ReusableComponents/Country';
import Loader from 'components/ReusableComponents/Loader';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useToaster } from 'Context/SnackbarContext';
import { updateWorkspaceDetails } from 'redux/workspace/actions';
import clsx from 'clsx';
import './style.scss';
import PropTypes from 'prop-types';
import EWButton from 'components/ReusableComponents/EWButton';

// fetching catalog rewards
const getRewardsData = async ({ pageParam = 1, queryKey: [_key, search, categoryFilter, country, SetSnackbar] }) => {
    const categories = Object.keys(categoryFilter).map((key) => categoryFilter[key]);
    const apiData = {
        page: pageParam,
        initiationStage: 'redeem',
        type: 'active',
        ...(search && { search }),
        ...(categories.length > 0 && { categories }),
        ...(country && { country }),
    };
    try {
        return await getRewardsCatalog({ apiData });
    } catch (err) {
        showSnackBarMessage(SetSnackbar, 'error', err?.message);
    }
};

const Index = ({ history }) => {
    const { SetSnackbar } = useToaster();
    const {
        xoxoday,
        isAdmin,
        nameForPoints,
        config,
        userCountry,
        adminEmail,
        combinedRedeemBalance,
        userCurrency,
        subscription,
        workspaceWallet,
    } = useSelector(mapStateToProps, shallowEqual);
    const dispatch = useDispatch();
    const location = useLocation();

    const [loading, setLoading] = useState(true);
    const [search, setSearch] = useState('');
    const [country, setCountry] = useState('');
    const [selectedCategoryList, setSelectedCategoryList] = useState({});
    const [showBanner, setShowBanner] = useState(false);
    const [bannerProps, setBannerProps] = useState({
        title: '',
        subHeading: null,
        cta: null,
        redirectTo: null,
    });
    const [categoryList, setCategoryList] = useState([]);
    const [allowCustomCouponRedemption, setAllowCustomCouponRedemption] = useState(false);

    const bannerPropsArgs = { isAdmin, adminEmail, setShowBanner, setBannerProps };

    const { data, isFetching, isFetchingNextPage, fetchNextPage, hasNextPage } = useInfiniteQuery(
        [GET_REWARDS_DATA, search, selectedCategoryList, userCountry, SetSnackbar],
        getRewardsData,
        {
            getNextPageParam: (lastPage, pages) => {
                return lastPage?.data?.hasMore ? pages.length + 1 : undefined;
            },
            refetchOnWindowFocus: false,
            keepPreviousData: true,
            enabled: !!userCountry,
            onSuccess: (voucherData) => {
                const pageData = voucherData?.pages?.[0]?.data;
                if (!search && Object.keys(selectedCategoryList)?.length <= 0) {
                    SetXoxoday(dispatch, pageData || {});
                }
                setLoading(false);
                const categories = pageData?.categories;
                if (categories?.length > 0) {
                    setCategoryList(categories.map((item) => ({ label: item, value: item })));
                }
                // if redemptionEnabledCountries has userCountry then redemption is allowed for that country
                if (pageData?.hasOwnProperty('redemptionEnabledCountries')) {
                    updateWorkspaceDetails(dispatch, {
                        config: {
                            ...config,
                            userCountryRedemption: pageData?.redemptionEnabledCountries?.includes(userCountry),
                        },
                    });
                }
            },
        }
    );

    useEffect(() => {
        if (userCountry) {
            setCountry(userCountry);
        }
    }, [userCountry]);

    useEffect(() => {
        if (loading && xoxoday) {
            setLoading(false);
        }
        handleRewardsListEffect({
            xoxoday,
            userCountry,
            config,
            bannerPropsArgs,
            setLoading,
            subscription,
            workspaceWallet,
            setAllowCustomCouponRedemption,
            userCountryRedemption: config?.userCountryRedemption,
        });
    }, [xoxoday, userCountry, config, workspaceWallet, subscription, config?.userCountryRedemption]);

    // updating category filter based on dropdown selection
    const handleCategoryFilter = (_event, index) => {
        const tempCategory = { ...selectedCategoryList };
        if (tempCategory[index] !== undefined) {
            delete tempCategory[index];
        } else {
            tempCategory[index] = categoryList[index].label;
        }
        setSelectedCategoryList(tempCategory);
    };

    const handleSearch = debounce((value) => setSearch(value), 300);

    const emptyScreenContent = {
        image: <EmptyRewards />,
        headingText: emptyScreenHeadingText,
    };

    if (loading) {
        return (
            <div>
                <TitleBar title={getRewardsListTitle(nameForPoints)} />
                <div className='catalog-rewards'>
                    <CustomLoader />
                </div>
            </div>
        );
    }

    return (
        <div>
            <TitleBar
                title={getRewardsListTitle(nameForPoints)}
                showBalance
                fromRedeemPoints
                balance={formatNumber(parseFloat(combinedRedeemBalance) || 0)}
                currencySymbol={userCurrency}
            />
            {location.pathname === REDEEM_POINTS.LIST && showBanner && (
                <WarningBanner history={history} {...bannerProps} />
            )}
            <div className={clsx({ 'redeem-main-container': 1, 'container-disabled': !allowCustomCouponRedemption })}>
                <div className='redeem-topbar'>
                    <p className='redeem-title'>Select store to buy gift card</p>
                    {allowCustomCouponRedemption && (
                        <EWButton
                            plainTextButton={true}
                            primary={false}
                            buttonStyleClass='redeem-history'
                            onClick={() => history.push(REDEEM_POINTS.HISTORY)}
                        >
                            <p>{viewHistoryText}</p>
                        </EWButton>
                    )}
                </div>

                <div>
                    {allowCustomCouponRedemption && (
                        <div className='redeem-filter-container'>
                            <div className='d-flex'>
                                <MultiSelect
                                    className='multi-select-tag-dropdown'
                                    title='Category'
                                    itemList={categoryList}
                                    selectedIndices={selectedCategoryList}
                                    onClick={handleCategoryFilter}
                                />
                                <CustomFilterDropdown
                                    title='Country'
                                    optionsSelected
                                    selectedName={<Country name={country} flagUrl={getCountryFlagURL(country)} />}
                                    dropDownID='redeemCountryDropdown'
                                    buttonStyleClass='width-188'
                                    singleSelect
                                    disabled
                                />
                            </div>
                            <CustomSearchBox
                                width='325px'
                                handleSearch={(e) => handleSearch(e.target.value)}
                                placeholder='Search'
                            />
                        </div>
                    )}

                    {!data?.pages?.[0]?.data?.vouchers?.length > 0 && (
                        <div className='empty-container'>
                            <EmptyContent {...emptyScreenContent} />
                        </div>
                    )}

                    {data?.pages?.[0]?.data?.vouchers?.length > 0 && (
                        <InfiniteScroll
                            dataLength={data.pages.length * 18}
                            next={fetchNextPage}
                            hasMore={hasNextPage}
                            height={820}
                            loader={<CustomLoader />}
                        >
                            <div className='reward-container'>
                                {data.pages.map((pageData) =>
                                    pageData?.data?.vouchers?.map((product) => (
                                        <RewardItem
                                            disabled={showBanner}
                                            product={product}
                                            handlePurchase={(purchaseItem) => handlePurchase({ history, purchaseItem })}
                                            key={product._id}
                                        />
                                    ))
                                )}
                            </div>
                        </InfiniteScroll>
                    )}
                    {isFetching && !isFetchingNextPage && <Loader />}
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = ({ User, Workspace, Payments, Xoxoday }) => ({
    xoxoday: Xoxoday,
    isAdmin: User.isAdmin,
    nameForPoints: Workspace.nameForPoints,
    config: Workspace.config || {},
    adminEmail: Workspace?.adminEmail,
    userCountry: User.userCountry,
    combinedRedeemBalance: User.combinedRedeemBalance,
    userCurrency: User.userCurrency,
    subscription: Payments.subscription,
    workspaceWallet: Payments.workspaceWallet || {},
});

Index.propTypes = {
    history: PropTypes.object,
};
export default Index;
