import React from 'react';
import PropTypes from 'prop-types';
import ConfirmModal from 'components/ReusableComponents/ConfirmModal';

const Index = ({ setRemovedUserErrorModal }) => {
    const modalData = {
        heading: 'Error activating user',
        subHeading:
            'This user seems to have left your organisation and cannot be found in your workspace. You will not be able to reactivate them.',
        confirm: 'Close',
    };
    const onClose = () => {
        setRemovedUserErrorModal(false);
    };

    return <ConfirmModal open onClose={onClose} onConfirm={onClose} data={modalData} singleButton showIcon />;
};

Index.propTypes = {
    setRemovedUserErrorModal: PropTypes.func,
};

export default Index;
