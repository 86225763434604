import React, { useState } from 'react';
import { useMutation } from 'react-query';
import { showSnackBarMessage } from 'utils/HelperFunctions';
import clsx from 'clsx';
import { useToaster } from 'Context/SnackbarContext';
import PropTypes from 'prop-types';
import { ReactComponent as HourGlassLoading } from 'Assets/images/hour-glass-loading.svg';
import { ReactComponent as MagicStick } from 'Assets/images/enhanceAI.svg';
import { enhanceMessage } from 'Services/apiFunctions';
import './style.scss';

const EnhanceWithAI = ({ textMessage, isAiEnhanced, onMessageEnhanced, handleRevertoriginalMessage }) => {
    const { SetSnackbar } = useToaster();
    const [originalRecognitionMessage, setOriginalRecognitionMessage] = useState();
    const { mutateAsync: handleEnhanceMessage, isLoading: isEnhancingMessage } = useMutation(enhanceMessage);

    const handleAIEnhanceMessage = async () => {
        try {
            const aiEnhancedMessage = await handleEnhanceMessage(textMessage);
            setOriginalRecognitionMessage(textMessage);
            onMessageEnhanced(aiEnhancedMessage);
        } catch (err) {
            showSnackBarMessage(SetSnackbar, 'error', err?.message);
        }
    };
    return (
        <>
            {textMessage?.length >= 10 && (
                <div className='enhance-message-container'>
                    <button
                        disabled={isEnhancingMessage}
                        className={clsx({ 'btn-disabled': isEnhancingMessage })}
                        onClick={handleAIEnhanceMessage}
                    >
                        <span className='btn-text'>
                            {isAiEnhanced ? 'Enhance more with AI' : 'Enhance your message with AI'}
                        </span>
                        <MagicStick />
                    </button>
                    {isAiEnhanced && (
                        <>
                            <div className=' revert-btn-divider'>|</div>
                            <button
                                onClick={() => {
                                    handleRevertoriginalMessage(originalRecognitionMessage);
                                }}
                            >
                                Revert to original message
                            </button>
                        </>
                    )}

                    {isEnhancingMessage && <HourGlassLoading />}
                </div>
            )}
        </>
    );
};

EnhanceWithAI.propTypes = {
    textMessage: PropTypes.string,
    isAiEnhanced: PropTypes.bool,
    onMessageEnhanced: PropTypes.func,
    handleRevertoriginalMessage: PropTypes.func,
};

export default EnhanceWithAI;
