import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';

const Module = ({ Logo, item, handleSelect }) => {
    return (
        <div
            className={clsx({ 'module-item': true, 'selected-state': item.selected })}
            onClick={() => handleSelect(item.id)}
        >
            <Logo className='module-item-logo' />
            <p className='module-item-name'>{item.heading}</p>
        </div>
    );
};

Module.propTypes = {
    Logo: PropTypes.any,
    item: PropTypes.object,
    handleSelect: PropTypes.func,
};

export default Module;
