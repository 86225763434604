import React from 'react';
import './style.scss';
import PropTypes from 'prop-types';

const Index = ({ image, heading, subHeading, buttonText, handleClick, height = '124px' }) => {
    return (
        <div className={`banner-wrapper h-${height}`}>
            <div className='banner-img'>{image}</div>
            <div className='container-right'>
                <p className='missing-date-count mt-3 mb-0'>{heading}</p>
                <p className='cb-subheading'>{subHeading}</p>
                {buttonText && (
                    <div>
                        <button className='ew-btn pb-btn' onClick={handleClick}>
                            {buttonText}
                        </button>
                    </div>
                )}
            </div>
        </div>
    );
};
Index.propTypes = {
    image: PropTypes.string,
    heading: PropTypes.string,
    subHeading: PropTypes.string,
    buttonText: PropTypes.string,
    handleClick: PropTypes.func,
    height: PropTypes.string,
};
export default Index;
