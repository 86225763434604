import React from 'react';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import PropTypes from 'prop-types';
import './style.scss';

const Index = ({ index, value, handleEdit, handleDelete }) => {
    return (
        <div className='config-value-list-item'>
            <p>{`${value.value}.`}</p>
            <div className='icon-container'>
                <div>
                    <DeleteIcon data-testid='delete-icon' onClick={() => handleDelete(true, index)} />
                </div>
                <div>
                    <EditIcon data-testid='edit-icon' onClick={() => handleEdit(true, index, value)} />
                </div>
            </div>
        </div>
    );
};

Index.propTypes = {
    index: PropTypes.number,
    value: PropTypes.object,
    handleEdit: PropTypes.func,
    handleDelete: PropTypes.func,
};

export default Index;
