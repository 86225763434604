import React, { useState } from 'react';
import Banner from '../Banner';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useQuery } from 'react-query';
import { getPendingRecognitions } from '../../Services/apiFunctions';
import { GET_PENDING_RECOGNITIONS } from 'Services/apiKeys';
import { useToaster } from 'Context/SnackbarContext';
import { showSnackBarMessage, makePlural } from 'utils/HelperFunctions';
import { RECOGNITIONS } from '../../constants';
import { updateUserProfile } from 'redux/user/actions';
import { useDispatch } from 'react-redux';
import './style.scss';

const Index = ({ history }) => {
    const [pendingRecognitions, setPendingRecognitions] = useState({});
    const SetSnackbar = useToaster();
    const dispatch = useDispatch();

    useQuery([GET_PENDING_RECOGNITIONS], getPendingRecognitions, {
        onSuccess: (data) => {
            const pendingRecognitions = data.data;
            setPendingRecognitions(pendingRecognitions);
            updateUserProfile(dispatch, { pendingTasks: pendingRecognitions.taskCount });
        },
        onError: (err) => showSnackBarMessage(SetSnackbar, 'error', err?.message),
    });

    return (
        <div className='pending-recognition-container'>
            {pendingRecognitions?.requiresApproval?.totalCount > 0 && (
                <div className={clsx({ 'approver-banner': true })}>
                    <Banner
                        heading={`${makePlural(
                            pendingRecognitions?.requiresApproval?.totalCount,
                            'approval request'
                        )} from team`}
                        subHeading='Approve/reject these requests'
                        handleClick={() => {
                            history.push(RECOGNITIONS.TASKS);
                        }}
                        users={pendingRecognitions?.requiresApproval?.senders}
                    />
                </div>
            )}
            {pendingRecognitions?.pendingApproval?.totalCount > 0 && (
                <div className={clsx({ 'non-approver-banner': true })}>
                    <Banner
                        heading={`${makePlural(
                            pendingRecognitions?.pendingApproval?.totalCount,
                            'pending recognition'
                        )}`}
                        subHeading='These will be sent once approved'
                        handleClick={() => {
                            history.push(RECOGNITIONS.TASKS);
                        }}
                        users={pendingRecognitions?.pendingApproval?.receivers}
                    />
                </div>
            )}
        </div>
    );
};

Index.propTypes = {
    history: PropTypes.object,
};

export default Index;
