import React from 'react';
import { format, isValid } from 'date-fns';
import ImageWithLabel from 'components/ReusableComponents/ImageWithLabel';
import PropTypes from 'prop-types';
import './style.scss';

const Index = ({ award, userId }) => {
    const { awardName, awardDate, awardee, icon, backgroundColor } = award;

    return (
        <div className='container-awardee'>
            {userId === awardee?._id && <p className='ca-rank-tag'>YOUR AWARD</p>}
            <div className='ca-award-name' style={{ backgroundColor }}>
                <img src={icon} alt='' />
                {awardName}
            </div>
            <div className='ca-awardee'>
                <ImageWithLabel
                    textStyleClass='ca-awardee-name'
                    imageUrl={awardee?.pictureURL}
                    text={awardee?.userName}
                    width={35}
                    height={35}
                    borderRadius={48}
                />
                {isValid(awardDate) && <p className='ca-awardee-date'>{format(new Date(awardDate), 'MMM dd, yyyy')}</p>}
            </div>
        </div>
    );
};

Index.propTypes = {
    award: PropTypes.object,
    userId: PropTypes.string,
};

export default Index;
