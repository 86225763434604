import React from 'react';
import PropTypes from 'prop-types';
import { useQueryClient, useMutation } from 'react-query';
import { BeatLoader } from 'react-spinners';
import CancelOutlined from '@material-ui/icons/CancelOutlined';
import { useToaster } from 'Context/SnackbarContext';
import { AdminBox } from 'utils/Stylesheet/style.js';
import { modifyAdmins } from 'Services/apiFunctions';
import { GET_ADMIN_LIST, GET_USERS_DATA } from 'Services/apiKeys';
import { Tooltip } from 'components';
import { shallowEqual, useSelector } from 'react-redux';
import { showSnackBarMessage } from 'utils/HelperFunctions';
import './style.scss';

const Index = ({ data, isLoading }) => {
    const { SetSnackbar } = useToaster();
    const queryClient = useQueryClient();
    const { adminId } = useSelector(mapStateToProps, shallowEqual);
    const { mutateAsync } = useMutation(modifyAdmins);

    const handleRemoveAdmin = async (removed, index) => {
        if (data.list < 2 || removed.memberID === adminId) {
            return;
        }
        const apiData = {
            userId: [removed._id],
            markAdmin: false,
        };
        try {
            await mutateAsync({ apiData });
            queryClient.setQueryData([GET_ADMIN_LIST], (oldData) => {
                const tempOldData = { ...oldData };
                tempOldData.data.splice(index, 1);
                return tempOldData;
            });
            queryClient.invalidateQueries(GET_USERS_DATA);
            showSnackBarMessage(SetSnackbar, 'success', `${removed.userName} is no longer an admin!`);
        } catch (err) {
            showSnackBarMessage(SetSnackbar, 'error', err?.message);
        }
    };

    if (isLoading) {
        return (
            <div className='text-center'>
                <BeatLoader data-testid={'Loader'} size={12} color={'#5D5CF5'} />
            </div>
        );
    }

    return (
        <div className='dashboard-admin-container'>
            {data.map((admin, index) => {
                return (
                    <AdminBox key={admin._id}>
                        <img
                            src={admin?.pictureURL || require('Assets/images/defaultUser.png')}
                            className='avatar-24'
                            alt='avatar'
                        />
                        <span className='admin-name'>{admin?.userName}</span>
                        <Tooltip title={admin.memberID === adminId ? 'Cannot remove yourself from admin' : ''}>
                            <button className='cross-icon admin-cross' onClick={() => handleRemoveAdmin(admin, index)}>
                                <CancelOutlined data-testid={'Remove'} />
                            </button>
                        </Tooltip>
                    </AdminBox>
                );
            })}
        </div>
    );
};

const mapStateToProps = ({ User }) => ({ adminId: User.adminId });

Index.propTypes = {
    data: PropTypes.array,
    isLoading: PropTypes.bool,
};

export default Index;
