import React, { useState } from 'react';
import { useQueryClient, useMutation } from 'react-query';
import { useCommonContext } from 'Context/CommonContext';
import { GET_PAGINATED_USERS } from 'Services/apiKeys';
import { modifyUser } from 'Services/apiFunctions';
import { useToaster } from 'Context/SnackbarContext';
import { showSnackBarMessage } from 'utils/HelperFunctions';
import EWModal from 'components/ReusableComponents/EWModal';
import UserSelectionDropdown from 'components/ReusableComponents/UserSelectionDropdown';
import MultiSelectionUserDropdown from 'components/ReusableComponents/MultiSelectionUserDropdown';
import MemberCard from 'components/ReusableComponents/MemberDetailsCard';
import LabelWithImage from 'components/ReusableComponents/LabelWithImage';
import PropTypes from 'prop-types';
import { find } from 'lodash';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { updateWorkspaceDetails } from 'redux/workspace/actions';
import './style.scss';

const dropdownWidth = 480;

const Index = ({ open, onClose, selectedManagerData }) => {
    const dispatch = useDispatch();
    const { setManagerflow } = useCommonContext();
    const { todo } = useSelector(mapStateToProps, shallowEqual);
    const queryClient = useQueryClient();
    const { SetSnackbar } = useToaster();
    const [selectedManager, setSelectedManager] = useState(selectedManagerData);
    const [selectedUsers, setSelectedUsers] = useState(selectedManagerData?.team || []);
    const [removedUsers, setRemovedUsers] = useState([]);

    const { mutateAsync: updateManager, isLoading } = useMutation(modifyUser);

    const handleManagerSelection = (user) => {
        setSelectedManager(user);
        if (selectedManager?._id !== user?._id) {
            setSelectedUsers(user?.team || []);
        }
    };

    const handleUserSelection = (user) => {
        if (find(selectedUsers, (selectedUser) => selectedUser?._id === user?._id)) {
            setSelectedUsers(selectedUsers.filter((item) => item?._id !== user?._id));
            setRemovedUsers([...removedUsers, user]);
        } else {
            setRemovedUsers(removedUsers.filter((item) => item?._id !== user?._id));
            setSelectedUsers([...selectedUsers, user]);
        }
    };

    const handleSave = async () => {
        try {
            const apiData = {
                managerId: selectedManager?._id,
                userIds: selectedUsers?.map((selectedUser) => selectedUser?._id),
                removedUserIds: removedUsers?.map((removedUser) => removedUser?._id),
            };
            await updateManager({ apiData });
            // when manager is assigned for first time and isManagerFlowCompleted is false, update workspace configs
            if (!todo?.isManagerFlowCompleted) {
                setManagerflow(true);
                updateWorkspaceDetails(dispatch, { todo: { ...todo, isManagerFlowCompleted: true } });
            }
            queryClient.invalidateQueries(GET_PAGINATED_USERS);
            onClose();
        } catch (error) {
            showSnackBarMessage(SetSnackbar, 'error', error?.message);
        }
    };

    const modalData = {
        heading: 'Map team for a manager',
        rightButtonText: 'Save',
        handleRightButtonClick: handleSave,
        handleLeftButtonClick: onClose,
        loading: isLoading,
        disabled: isLoading || selectedUsers?.length < 1 || !selectedManager,
    };

    const getSelectedUser = () => {
        return (
            <LabelWithImage
                {...(selectedManager && {
                    image: selectedManager?.pictureURL || require('Assets/images/defaultUser.png'),
                })}
                name={selectedManager?.userName || 'Select a manager'}
            />
        );
    };

    return (
        <EWModal open={open} onClose={() => onClose(false)} width='528px' height='510px' modalData={modalData}>
            <div className='container-mark-manager-modal'>
                <div>
                    <UserSelectionDropdown
                        id='selectManager'
                        title='Manager'
                        dropdownWidth={dropdownWidth}
                        buttonWidth={dropdownWidth}
                        selectedUser={getSelectedUser()}
                        handleUserSelection={handleManagerSelection}
                        placeholder='Search name'
                        showTeam
                        optionsSelected={!!selectedManager}
                    />
                </div>
                <div className='mt-3'>
                    <MultiSelectionUserDropdown
                        id='selectManager'
                        title='Users for this Manager'
                        dropdownWidth={dropdownWidth}
                        buttonWidth={dropdownWidth}
                        selectedName={
                            selectedUsers?.length > 0
                                ? `Selected (${selectedUsers.length})`
                                : 'Select users for this manager'
                        }
                        selectedUsers={selectedUsers}
                        handleSelection={handleUserSelection}
                        placeholder='Search name'
                        optionsSelected={selectedUsers?.length > 0}
                        managerId={selectedManager?._id}
                        apiCase='NON_PEERS' //  this is used to fetch users who are not part of selected managers team
                        enableApi={!!selectedManager?._id}
                    />
                </div>

                <div className='container-selection'>
                    {selectedUsers?.length > 0 && (
                        <div>
                            <p className='selection-list-header'>Selected users for this Manager</p>
                            <div className='selection-list'>
                                {selectedUsers.map((member) => (
                                    <MemberCard
                                        key={member._id}
                                        member={member}
                                        selected={
                                            !!find(selectedUsers, (selectedUser) => selectedUser?._id === member?._id)
                                        }
                                        handleChange={() => handleUserSelection(member)}
                                    />
                                ))}
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </EWModal>
    );
};

const mapStateToProps = (state) => ({
    todo: state.Workspace.todo,
});

Index.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    selectedManagerData: PropTypes.object,
};

export default Index;
