import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as Warning } from 'Assets/images/warning-yellow.svg';
import EWButton from 'components/ReusableComponents/EWButton';
import './style.scss';

const Index = ({ history, redirectTo, cta, title = '', subHeading = '' }) => {
    return (
        <div className='warning-banner-container'>
            <div className='d-flex title-container'>
                <div className='banner-title'>
                    <Warning />
                    <span className='warning-title'>{title}</span>
                </div>
                {subHeading && (
                    <div className='subheading'>
                        <span>{subHeading}</span>
                    </div>
                )}
            </div>
            {cta && <EWButton buttonText={cta} onClick={() => history.push(redirectTo)} />}
        </div>
    );
};

Index.propTypes = {
    history: PropTypes.object,
    redirectTo: PropTypes.string,
    cta: PropTypes.string,
    title: PropTypes.string,
    subHeading: PropTypes.string,
};

export default Index;
