import styled from 'styled-components';
import 'utils/Stylesheet/colors.scss';
import { BlockWrapper } from '../style';

export const SummaryInfoBox = styled(BlockWrapper)`
    .summary-info {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .yellow-person {
            font-family: Montserrat;
            font-style: normal;
            font-weight: 600;
            font-size: 28px;
            line-height: 34px;
        }

        .body-4 {
            font-family: Poppins, sans-serif;
            font-style: normal;
            font-weight: normal;
            font-size: 1.125rem;
            line-height: 24px;
            color: #a9adb1;
            margin-top: 8px;
        }
    }
    .summary-note {
        color: #535961;
        font-family: Open Sans;
        font-size: 0.875rem;
        font-style: normal;
        line-height: 20px;

        svg {
            margin: -4px 6px 0px 0px;
        }
    }
`;

export const PlanInfoBox = styled(BlockWrapper)`
    .plan-section {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .plan-data {
            font-family: Montserrat, sans-serif;
            font-size: 1.125rem;
            font-style: normal;
            font-weight: 500;
            .plan {
                color: #a9adb1;
                margin-right: 4px;
            }
            .plan-type {
                color: #15222f;
            }
        }
    }
    .plan-note {
        color: #535961;
        font-family: Open Sans, sans-serif;
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
        margin: 18px 0px 0px;

        svg {
            margin: -4px 6px 0px 0px;
        }

        .btn-portal {
            color: #5d5cf5;
            text-decoration: underline;
            cursor: pointer;
            border: none;
            background-color: transparent;
        }
    }
`;

export const WalletInfoBox = styled(BlockWrapper)`
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
    margin-bottom: 0px;
    .heading {
        color: #4e515b;
        font-family: Montserrat, sans-serif;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;

        .error-text {
            margin-top: 15px;
            font-family: Open Sans;
            font-size: 14px;
            line-height: 19px;
        }
    }
`;

export const DisclaimerNoteBox = styled.div`
    background: #fff6f6;
    font-size: 0.9rem;
    padding: 10px 20px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    box-shadow: 0 0 8px rgba(57, 68, 105, 0.05);

    .underline-text {
        text-decoration: underline;
    }
`;

/**
 * disclaimerAvailable: true if auto-reload is enabled, used to remove bottom border radius and display the disclaimer note
 */
export const AutoReloadBox = styled(BlockWrapper)`
    ${({ disclaimerAvailable }) =>
        disclaimerAvailable &&
        `
        border-bottom-right-radius: 0px;
        border-bottom-left-radius: 0px;
        margin-bottom: 0px;
    `}
    .spreadOut {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .reload-title {
            color: #4e515b;
            font-family: Montserrat, sans-serif;
            font-size: 1.125rem;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
        }

        .reload-switch {
            font-family: Montserrat;
            font-size: 0.875rem;
            font-style: normal;
            font-weight: 500;
            line-height: 20px;
            letter-spacing: 0.1px;
        }
    }

    .reload-note {
        color: #535961;
        font-family: Open Sans;
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 400;
        line-height: 21px;
        margin: 12px 0px 24px;

        svg {
            margin: -4px 6px 0px 0px;
        }
    }

    .text-subheading {
        color: #4e515b;
        font-size: 0.75rem;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
        font-family: Open sans, sans-serif;
        margin-bottom: 4px;
    }

    .reload-balance {
        font-family: Open Sans, sans-serif;
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 700;
        line-height: 20px;
        letter-spacing: 0.1px;
    }

    .emptySections {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        height: 255px;
    }
`;
