import React from 'react';
import { ReactComponent as EmptyOrg } from 'Assets/images/emptyOrg.svg';
import { ReactComponent as EmptyManager } from 'Assets/images/empty-member.svg';
import PropTypes from 'prop-types';

const Index = ({ orgAnalysis, selectedOrgFilter, handleChange }) => {
    const emptyScreenContent = [
        {
            image: <EmptyOrg />,
            buttonText: 'Add Department',
            action: () => handleChange('orgSetUp'),
        },
        {
            image: <EmptyManager />,
            buttonText: 'Assign Managers',
            action: () => handleChange('orgManagerSetUp'),
        },
    ];
    const emptyScreen =
        orgAnalysis.orgSetUp && selectedOrgFilter.label === 'Departments'
            ? emptyScreenContent[0]
            : emptyScreenContent[1];

    return (
        <div className='text-center'>
            <div>{emptyScreen.image}</div>
            <button className='ew-btn pb-btn' onClick={emptyScreen.action}>
                {emptyScreen.buttonText}
            </button>
        </div>
    );
};

Index.propTypes = {
    orgAnalysis: PropTypes.object,
    selectedOrgFilter: PropTypes.object,
    handleChange: PropTypes.func,
};

export default Index;
