import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { PageWrapper } from './style';
import TitleBar from 'components/ReusableComponents/TitleBar';
import { Tab, Tabs } from 'react-bootstrap';
import { PAYMENT_SECTIONS } from './constants';
import Summary from './Components/Summary';
import Wallet from './Components/Wallet';
import { GetSubscriptionDetails } from 'redux/payments/actions';
import { useDispatch } from 'react-redux';
import { BeatLoader } from 'react-spinners';
import { useToaster } from 'Context/SnackbarContext';

const PaymentNew = ({ history }) => {
    const dispatch = useDispatch();

    const [selectedSection, setSelectedSection] = useState(PAYMENT_SECTIONS.SUMMARY);
    const [apiLoading, setApiLoading] = useState(true);

    const { SetSnackbar } = useToaster();

    useEffect(() => {
        GetSubscriptionDetails(dispatch, SetSnackbar).then(() => setApiLoading(false));
        if (history.location.search.includes('tab=wallet')) {
            setSelectedSection(PAYMENT_SECTIONS.WALLET);
        }
    }, []);

    if (apiLoading) {
        return (
            <PageWrapper>
                <TitleBar title='Payments' />
                <div className='loadingContent'>
                    <BeatLoader size={10} color={'#5D5CF5'} loading />
                </div>
            </PageWrapper>
        );
    }

    return (
        <PageWrapper>
            <TitleBar title='Payments' />

            <div className='content navigatortabs'>
                <Tabs id='payments' activeKey={selectedSection} onSelect={(e) => setSelectedSection(e)}>
                    <Tab eventKey={PAYMENT_SECTIONS.SUMMARY} title='Summary'>
                        {selectedSection === PAYMENT_SECTIONS.SUMMARY && (
                            <Summary changeSection={() => setSelectedSection(PAYMENT_SECTIONS.WALLET)} />
                        )}
                    </Tab>

                    <Tab eventKey={PAYMENT_SECTIONS.WALLET} title='Redemption Wallet'>
                        {selectedSection === PAYMENT_SECTIONS.WALLET && <Wallet />}
                    </Tab>
                </Tabs>
            </div>
        </PageWrapper>
    );
};

PaymentNew.propTypes = {
    history: PropTypes.object,
};

export default PaymentNew;
