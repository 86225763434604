import React from 'react';
import PropTypes from 'prop-types';
import EllipsisText from 'components/ReusableComponents/EllipsisText';
import './style.scss';

const Index = ({ name, imageUrl, denomination }) => {
    return (
        <div className='gift-card'>
            <img src={require('Assets/images/gift-card.svg')} alt='' />
            <div className='gift-card-details'>
                <div className='gift-name'>
                    {imageUrl?.includes('http') ? (
                        <img className='gift-icon' src={imageUrl} alt='' />
                    ) : (
                        <div className='emoji'>{imageUrl}</div>
                    )}
                    <EllipsisText text={name} customClassName='gift-card-name font-open-sans' />
                </div>
                <EllipsisText text={denomination} customClassName='bold gift-currency font-open-sans' />
            </div>
        </div>
    );
};

Index.propTypes = {
    name: PropTypes.string,
    imageUrl: PropTypes.string,
    denomination: PropTypes.string,
};

export default Index;
