import React from 'react';
import PropTypes from 'prop-types';
import { SummaryInfoBox } from '../styles';
import { shallowEqual, useSelector } from 'react-redux';
import { ReactComponent as WarningIcon } from 'Assets/images/warning.svg';
import { PLAN_STATUS } from '../../constants';

const SUMMARY_NOTE = 'Note: This balance is utilised whenever your employees redeem gift cards.';
const REDEMPTION_DISABLE_NOTE = 'Redemptions have been disabled as your subscription is cancelled.';

const SummaryInfo = ({ changeSection, wallet = false }) => {
    const { workspaceWallet, subscription } = useSelector(mapStateToProps, shallowEqual);

    const NOTE = wallet && subscription?.status === PLAN_STATUS.CANCELLED ? REDEMPTION_DISABLE_NOTE : SUMMARY_NOTE;

    return (
        <SummaryInfoBox>
            <div className='summary-info'>
                <div className='walletInfo'>
                    <div className='yellow-person'>
                        <span dangerouslySetInnerHTML={{ __html: workspaceWallet?.currencySymbol }} />
                        {workspaceWallet?.balance?.toFixed(2).toLocaleString('en-US') || 0}
                    </div>
                    <div className='body-4'>{`Redemption Wallet ${wallet ? 'Balance' : ''}`}</div>
                </div>
                {!wallet && (
                    <button className='ew-btn tb-btn' onClick={changeSection}>
                        Add Funds
                    </button>
                )}
            </div>
            <div className='margin-top-20' />
            <span className='summary-note' style={{ fontWeight: wallet ? 500 : 600 }}>
                {wallet && subscription?.status === PLAN_STATUS.CANCELLED && <WarningIcon />}
                {NOTE}
            </span>
        </SummaryInfoBox>
    );
};

const mapStateToProps = ({ Payments }) => ({
    workspaceWallet: Payments?.workspaceWallet,
    subscription: Payments?.subscription,
});

SummaryInfo.propTypes = {
    changeSection: PropTypes.func.isRequired,
    wallet: PropTypes.bool,
};

export default SummaryInfo;
