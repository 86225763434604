import { ReactComponent as ViewMoreDetails } from 'Assets/images/more-details.svg';
import PropTypes from 'prop-types';
import ConditionalWrapper from 'components/ReusableComponents/ConditionalWrapper';
import CustomFilterDropdown from 'components/ReusableComponents/CustomFilterDropdown';
import CustomDateRangeModal from 'components/Modals/CustomDateRangeModal';
import CustomLoader from 'components/ReusableComponents/CustomLoader';
import RewardDetailsModal from 'components/Modals/RewardDetailsModal';
import EmptyContent from 'components/ReusableComponents/EmptyScreen';
import CustomSearchBox from 'components/Styles/CustomSearchBox';
import React, { useState } from 'react';
import { dateRanges, EW_LOGO_URL } from 'constants.js';
import Table from 'components/ReusableComponents/Table';
import { getManagerList, getCoreValues, getRecognitonTypes, getAllRecognitionActivities } from 'Services/apiFunctions';
import { useToaster } from 'Context/SnackbarContext';
import { debounce, find } from 'lodash';
import { Tooltip } from 'components';
import { format } from 'date-fns';
import './style.scss';
import { SORT_TAGS, HEADER, COLUMN_WIDTH } from './constant';
import MemberTag from '../../components/MemberTag';
import { useQuery } from 'react-query';
import {
    GET_MANAGERS_LIST,
    GET_RECOGNITION_TYPES,
    GET_COMPANY_VALUES,
    GET_TRANSACTION_SUMMARY,
} from 'Services/apiKeys';
import { showSnackBarMessage, capitalizeFirstLetter } from 'utils/HelperFunctions';
import ChipTags from 'components/Dashboard/Content/Activities/components/ChipTags';

const EmptyScreen = ({ loading, emptyScreenContent }) =>
    loading ? <CustomLoader /> : <EmptyContent {...emptyScreenContent} />;

const setData = (transactionState, transactions, setResponse, history, handleViewMore) => {
    const temp = transactions.map((transaction) => {
        const tempRow = [
            transaction.sentOn,
            <MemberTag
                key={transaction._id}
                history={history}
                memberID={transaction?.sender?.id}
                memberPhoto={
                    transaction?.recognitionType?.toLowerCase() === 'special rewards'
                        ? EW_LOGO_URL
                        : transaction?.sender?.pictureURL
                }
                memberName={
                    transaction?.recognitionType?.toLowerCase() === 'special rewards'
                        ? 'EngageWith'
                        : transaction?.sender?.name
                }
                truncation
            />,
            <MemberTag
                key={transaction._id}
                history={history}
                memberID={transaction?.receiver?.id}
                memberPhoto={transaction?.receiver?.pictureURL}
                memberName={transaction?.receiver?.name}
                truncation
            />,
            <div className='reward' key={transaction._id}>
                <p className='reward-type'>{capitalizeFirstLetter(transaction.recognitionType)}</p>
                <p className='reward-point'>({transaction.points})</p>
            </div>,
            transaction?.value ? transaction.value : '-',
            <ConditionalWrapper
                key={transaction._id}
                condition={transaction?.message?.length > 20}
                wrapper={(children) => (
                    <Tooltip white={'true'} title={transaction?.message}>
                        {children}
                    </Tooltip>
                )}
            >
                <p className='mb-0'>
                    {transaction.message?.length > 20 ? `${transaction.message.slice(0, 17)}...` : transaction.message}
                </p>
            </ConditionalWrapper>,
            <Tooltip key={transaction._id} placement='top' title={'View more details'}>
                <ViewMoreDetails className='pointer-cursor' onClick={() => handleViewMore(transaction)} />
            </Tooltip>,
        ];

        if (transactionState === 'pending') {
            tempRow.splice(5, 1);
            tempRow.splice(
                3,
                0,
                <MemberTag
                    history={history}
                    memberID={transaction?.approver?.id}
                    memberPhoto={transaction?.approver?.pictureURL}
                    memberName={transaction?.approver?.name}
                    truncation
                />
            );
        }

        if (transactionState === 'rejected') {
            tempRow.splice(
                5,
                1,
                <ConditionalWrapper
                    condition={transaction?.rejectionReason?.length > 50}
                    wrapper={(children) => (
                        <Tooltip white={'true'} title={transaction?.rejectionReason}>
                            {children}
                        </Tooltip>
                    )}
                >
                    <p className='mb-0'>
                        {transaction.message?.length > 50
                            ? `${transaction.rejectionReason.slice(0, 47)}...`
                            : transaction.rejectionReason}
                    </p>
                </ConditionalWrapper>
            );
            tempRow.splice(
                4,
                1,
                <>
                    {transaction?.rejectionReason === 'Request expired' && !transaction?.rejected ? (
                        ''
                    ) : (
                        <MemberTag
                            history={history}
                            memberID={transaction?.approver?.id}
                            memberPhoto={transaction?.approver?.pictureURL}
                            memberName={transaction?.approver?.name}
                            truncation
                        />
                    )}
                </>
            );
        }

        return {
            id: transaction._id,
            row: tempRow,
        };
    });
    setResponse(temp);
};

const Index = ({ history }) => {
    const [rewardInfo, setRewardInfo] = useState();
    const [showDetails, setShowDetails] = useState(false);
    const [transactionState, setTransactionState] = useState('approved');
    const [response, setResponse] = useState([]);
    const [total, setTotal] = useState(0);
    const [companyValues, setCompanyValues] = useState([]); // list of options for company values
    const [approverList, setApproverList] = useState([]); //list of options for Approvers
    const [rewardTypes, setRewardTypes] = useState([]);
    const [filter, setFilter] = useState({
        page: 1,
        sortBy: 'date',
        order: 'descending',
        search: '',
    });
    const [valuesFilterCount, setValuesFilterCount] = useState(0);
    const [rewardsFilterCount, setRewardsFilterCount] = useState(0);
    const [approversFilterCount, setApproversFilterCount] = useState(0);
    const [showDatePicker, setShowDatePicker] = useState(false);
    const { SetSnackbar } = useToaster();
    const noSortColumns = {
        approved: [6],
        pending: [5],
        rejected: [6],
    };
    const emptyScreenContent = {
        image: <img src={require('Assets/images/empty-flag.svg')} alt='empty-flag' />,
        subheadingText: 'No recognitions found',
    };
    const errorSnackbar = (message) => showSnackBarMessage(SetSnackbar, 'error', message);

    const setRecognitionTypes = (data) => {
        const tempValues = data.recognitionTypes.map((recognition) => ({
            ...recognition,
            label: recognition.name,
            isChecked: false,
        }));
        setRewardTypes(tempValues);
    };

    useQuery([GET_RECOGNITION_TYPES], getRecognitonTypes, {
        onSuccess: (data) => setRecognitionTypes(data),
        onError: (error) => showSnackBarMessage(SetSnackbar, 'error', error?.message),
    });

    useQuery([GET_MANAGERS_LIST], getManagerList, {
        keepPreviousData: true,
        enabled: approverList && approverList.length === 0,
        onSuccess: (managerList) => {
            const tempList = managerList.managers.map((managerCard) => {
                return {
                    label: managerCard.userName,
                    name: managerCard?.userName,
                    value: managerCard?._id,
                };
            });
            setApproverList(tempList);
        },
        onError: (error) => errorSnackbar(error?.message),
    });

    useQuery([GET_COMPANY_VALUES], getCoreValues, {
        enabled: companyValues && companyValues.length === 0,
        keepPreviousData: true,
        onSuccess: (companyValuesData) => {
            const tempValues = companyValuesData.values.map((value) => ({
                ...value,
                label: value.value,
                isChecked: false,
            }));
            setCompanyValues(tempValues);
        },
        onError: (error) => errorSnackbar(error?.message),
    });

    const { loading, isFetching } = useQuery(
        [GET_TRANSACTION_SUMMARY, transactionState, filter],
        getAllRecognitionActivities,
        {
            keepPreviousData: true,
            onSuccess: (transactionsData) => {
                const tempArray = transactionsData?.data?.activities;
                setData(transactionState, tempArray, setResponse, history, handleViewMore);
                setTotal(transactionsData?.data?.totalCount);
            },
            onError: (error) => errorSnackbar(error?.message),
        }
    );

    const handleSort = (index) => {
        const type = SORT_TAGS[transactionState][index];
        const tempSort = { ...filter, sortBy: type };
        if (type === filter.sortBy) {
            tempSort.order = tempSort.order === 'ascending' ? 'descending' : 'ascending';
        } else {
            tempSort.order = 'ascending';
        }
        setFilter({ ...filter, page: 1, sortBy: tempSort.sortBy, order: tempSort.order });
    };

    const handleSearchChange = debounce((value) => {
        setFilter({ ...filter, search: value, page: 1 });
    }, 400);

    const handleFilter = (type, value, index) => {
        let selectedValues = [];
        switch (type) {
            case 'date': {
                if (value?.value === 'Custom Date') {
                    setShowDatePicker(true);
                } else {
                    setFilter({
                        ...filter,
                        dateRange: value.value,
                    });
                }
                break;
            }
            case 'values': {
                const temp = [...companyValues];
                temp[index].isChecked = !temp[index].isChecked;
                selectedValues = temp.filter((product) => product.isChecked).map((product) => product.id);
                setValuesFilterCount(selectedValues.length);
                setFilter({
                    ...filter,
                    values: selectedValues,
                    page: 1,
                });
                break;
            }
            case 'rewardTypes': {
                const tempRewards = [...rewardTypes];
                tempRewards[index].isChecked = !tempRewards[index].isChecked;
                selectedValues = tempRewards.filter((product) => product.isChecked).map((product) => product.name);
                setRewardsFilterCount(selectedValues.length);
                setFilter({
                    ...filter,
                    recognitionType: selectedValues,
                    page: 1,
                });
                break;
            }

            case 'approvers': {
                const tempApprover = [...approverList];
                tempApprover[index].isChecked = !tempApprover[index].isChecked;
                selectedValues = tempApprover.filter((product) => product.isChecked).map((product) => product.value);
                setApproversFilterCount(selectedValues.length);
                setFilter({
                    ...filter,
                    approver: selectedValues,
                    page: 1,
                });
                break;
            }

            default:
                errorSnackbar('No type.');
        }
    };

    const handleCustomDate = (selectedDate) => {
        const fromDate = format(selectedDate?.startDate, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'");
        const toDate = format(selectedDate?.endDate, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'");
        setFilter({
            ...filter,
            dateRange: { start: fromDate, end: toDate },
        });
        setShowDatePicker(false);
    };

    const handleViewMore = (transaction) => {
        setShowDetails(true);
        setRewardInfo(transaction);
    };

    const handleTransaction = (trxState) => {
        rewardTypes.forEach(
            (reward, index) => reward.isChecked && handleFilter('rewardTypes', rewardTypes[index], index)
        );
        approverList.forEach(
            (approver, index) => approver.isChecked && handleFilter('approvers', approverList[index], index)
        );
        companyValues.forEach((value, index) => value.isChecked && handleFilter('values', companyValues[index], index));
        setFilter({
            values: [],
            sortBy: 'date',
            order: 'descending',
            page: 1,
        });
        setTransactionState(trxState);
    };

    const paginationProps = {
        fixedBottom: true,
        currentPage: filter.page,
        totalCount: total,
        setCurrentPage: (value) => setFilter({ ...filter, page: value }),
    };

    const dateLabel = () => {
        if (!filter?.dateRange) return 'ALL';
        if (typeof filter.dateRange === 'object') {
            return 'Custom Date';
        } else {
            return find(dateRanges, (item) => item.value === filter.dateRange)?.label;
        }
    };
    return (
        <>
            <div className='ac-main-container'>
                <ChipTags
                    transactionState={transactionState}
                    handleTransactionSelection={handleTransaction}
                    isAdmin={true}
                    type='AllActivities'
                />
                <div className='ac-filter-container'>
                    <div className='d-flex'>
                        <CustomFilterDropdown
                            title='Date Range'
                            filterOptions={dateRanges}
                            selectedName={dateLabel()}
                            optionsSelected
                            handleSelection={(index) => handleFilter('date', dateRanges[index], index)}
                            dropDownID='dateDropdown'
                            singleSelect
                            buttonStyleClass='width-188'
                        />

                        {transactionState === 'pending' && (
                            <CustomFilterDropdown
                                title='Approver'
                                selectedName={approversFilterCount > 0 ? `Selected (${approversFilterCount})` : 'ALL'}
                                filterOptions={response.length > 0 ? approverList : []}
                                optionsSelected
                                handleSelection={(index) => handleFilter('approvers', approverList[index], index)}
                                dropDownID='valuesDropdown'
                                search
                                buttonStyleClass='width-188'
                            />
                        )}
                        {transactionState === 'approved' && (
                            <CustomFilterDropdown
                                title='Values'
                                selectedName={valuesFilterCount > 0 ? `Selected (${valuesFilterCount})` : 'ALL'}
                                optionsSelected
                                filterOptions={companyValues}
                                handleSelection={(index) => handleFilter('values', companyValues[index], index)}
                                dropDownID='valuesDropdown'
                                search
                                buttonStyleClass='width-188'
                                dropdownWidth={278}
                                dropdownHeight={202}
                            />
                        )}

                        <CustomFilterDropdown
                            title='Reward Type'
                            selectedName={rewardsFilterCount > 0 ? `Selected (${rewardsFilterCount})` : 'ALL'}
                            optionsSelected
                            filterOptions={rewardTypes}
                            handleSelection={(index) => handleFilter('rewardTypes', rewardTypes[index], index)}
                            dropDownID='valuesDropdown'
                            search
                            buttonStyleClass='width-188'
                        />
                    </div>
                    <div className='mt-3'>
                        <CustomSearchBox
                            width={'320px'}
                            handleSearch={(event) => handleSearchChange(event.target.value)}
                            placeholder={`Search activities by name or ${
                                transactionState === 'rejected' ? 'reason' : 'message'
                            }`}
                        />
                    </div>
                </div>

                {response.length > 0 ? (
                    <div className='ac-table-container'>
                        <Table
                            header={HEADER[transactionState]}
                            data={response}
                            noSortColumns={noSortColumns[transactionState]}
                            paginationProps={paginationProps}
                            loading={loading || isFetching}
                            columnWidth={COLUMN_WIDTH}
                            noShadow={true}
                            handleSort={handleSort}
                        />
                    </div>
                ) : (
                    <EmptyScreen emptyScreenContent={emptyScreenContent} loading={loading || isFetching} />
                )}
            </div>
            <CustomDateRangeModal
                open={showDatePicker}
                onClose={() => setShowDatePicker(false)}
                onConfirm={(selectedDate) => handleCustomDate(selectedDate)}
            />
            <RewardDetailsModal
                open={showDetails}
                rewardInfo={rewardInfo}
                onClose={() => setShowDetails(false)}
                transactionState={transactionState}
            />
        </>
    );
};

Index.propTypes = {
    history: PropTypes.object,
};

EmptyScreen.propTypes = {
    loading: PropTypes.bool,
    isFetching: PropTypes.bool,
    emptyScreenContent: PropTypes.object,
};

export default Index;
