import React from 'react';
import PropTypes from 'prop-types';
import { format } from 'date-fns';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import { ReactComponent as EmptyAwardees } from 'Assets/images/empty-awardees.svg';
import { EMPLOYEE_AWARD_ICONS, BASE_URL_AWARD_ICONS, EMPLOYEE_AWARDS } from '../EmployeeAwards/constants';
import EWButton from 'components/ReusableComponents/EWButton';

const EmptyAwards = ({ history }) => {
    const handleSetupAwards = () => history.push(EMPLOYEE_AWARDS.HOME);

    return (
        <div className='cp-empty-awards'>
            <EmptyAwardees />
            <div className='cp-empty-btn-container'>
                <h6>
                    Set up awards to view the <br />
                    awards you receive here
                </h6>
                <EWButton buttonText='Set up Employee Awards' onClick={handleSetupAwards} />
            </div>
        </div>
    );
};

const AwardsReceivedItem = ({ awardName, icon, awardDate }) => {
    const getColor = (icon) => {
        if (!icon) {
            return '';
        }
        const key = icon.split(BASE_URL_AWARD_ICONS)?.[1];
        return EMPLOYEE_AWARD_ICONS[key];
    };

    return (
        <div className='cp-awards-received-item'>
            <div className='cp-awards-received-item-details' style={{ background: getColor(icon) }}>
                <img src={icon} alt='' />
                <p>{awardName}</p>
            </div>
            {awardDate && (
                <span>
                    <CalendarTodayIcon />
                    &nbsp; {format(new Date(awardDate), 'MMM dd, yyyy')}
                </span>
            )}
        </div>
    );
};

const AwardsReceived = ({ history, awards }) => {
    return (
        <div className='cp-awards-received'>
            <h4 className='title'>Awards received</h4>
            {awards?.length === 0 && <EmptyAwards history={history} />}
            {awards?.length > 0 && (
                <div className='cp-awards-received-grid'>
                    {awards.map((award) => (
                        <AwardsReceivedItem key={award?._id} {...award} />
                    ))}
                </div>
            )}
        </div>
    );
};

AwardsReceived.propTypes = {
    history: PropTypes.object,
    awards: PropTypes.object,
};

AwardsReceivedItem.propTypes = {
    awardName: PropTypes.string,
    icon: PropTypes.string,
    awardDate: PropTypes.string,
};

EmptyAwards.propTypes = {
    history: PropTypes.object,
};

export default AwardsReceived;
