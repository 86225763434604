import { format } from 'date-fns';

export const REDEEM_POINTS = {
    LIST: '/dashboard/redemptions/redeem/list',
    HISTORY: '/dashboard/redemptions/redeem/history',
    CONFIRM_PURCHASE: '/dashboard/redemptions/redeem/confirm-purchase',
};

export const handleValidity = (expiryAndValidity) => {
    try {
        if (expiryAndValidity) {
            if (!expiryAndValidity.includes('months')) {
                return `Valid till ${format(new Date(expiryAndValidity), 'dd-MM-yyyy')}`;
            } else {
                return `Valid till ${expiryAndValidity}`;
            }
        } else {
            return 'Does not expire';
        }
    } catch (e) {
        return '';
    }
};

/*------------------------- Below are the constants used for RewardList component */
export const SET_BANNER_SCENARIOS = {
    NO_PAYMENT_METHOD: 'No payment method added.',
    NO_VOUCHERS_AND_CONVERSION_ENABLED: 'No gift cards enabled.',
    NO_VOUCHERS_FOR_COUNTRY: 'Redemptions are disabled for your country',
    NO_VOUCHERS: 'NO_VOUCHERS',
    REDEMPTIONS_NOT_ENABLED: 'Redemptions disabled.',
    NONE: 'NONE',
};

export const emptyScreenHeadingText = 'You do not have any rewards available';
export const viewHistoryText = 'View Redeem History';
export const getRewardsListTitle = (nameForPoints) => `Redeem ${nameForPoints}`;
export const noResultsText = 'No vouchers found';
