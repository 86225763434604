import React, { useState } from 'react';
import PropTypes from 'prop-types';
import RecognitionRecieved from './RecognitionsReceived';
import RecognitionGiven from './RecognitionsGiven';
import RecognitionRequested from './RecognitionRequested';
import RecognitionRejected from './RecognitionRejected';
import ChipTags from '../../components/ChipTags';
import { STATUS } from '../../constants';

const Index = ({ history, isAdmin, isManager }) => {
    const [transactionState, setTransactionState] = useState(STATUS.RECEIVED);
    return (
        <div className=' ac-main-container'>
            <ChipTags
                transactionState={transactionState}
                handleTransactionSelection={setTransactionState}
                isAdmin={isAdmin}
                isManager={isManager}
                type='MyActivities'
            />
            {transactionState === STATUS.RECEIVED && (
                <RecognitionRecieved history={history} transactionState={transactionState} isAdmin={isAdmin} />
            )}
            {transactionState === STATUS.GIVEN && (
                <RecognitionGiven history={history} transactionState={transactionState} isAdmin={isAdmin} />
            )}
            {transactionState === STATUS.REQUESTED && (
                <RecognitionRequested history={history} transactionState={transactionState} isAdmin={isAdmin} />
            )}
            {transactionState === STATUS.REJECTED && (
                <RecognitionRejected history={history} transactionState={transactionState} isAdmin={isAdmin} />
            )}
        </div>
    );
};

Index.propTypes = {
    history: PropTypes.object,
    isAdmin: PropTypes.bool,
    isManager: PropTypes.bool,
};

export default Index;
