export const SORT_TAGS = {
    approved: ['date', 'from', 'to', 'reward-type', 'values', 'message'],
    pending: ['date', 'from', 'to', 'reward-type', 'values', 'message'],
    rejected: ['date', 'from', 'to', 'reward-type', 'rejectedBy', 'rejectionReason'],
};

export const HEADER = {
    approved: ['DATE', 'FROM', 'TO', 'REWARD TYPE', 'VALUES', 'MESSAGE'],
    pending: ['DATE', 'FROM', 'TO', 'APPROVER', 'REWARD TYPE', 'MESSAGE'],
    rejected: ['REJECTION DATE', 'FROM', 'TO', 'REWARD TYPE', 'REJECTED BY', 'REASON'],
};

export const COLUMN_WIDTH = ['15%', '13%', '13%', '15%', '14%', '14%', '2%'];
